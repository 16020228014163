import React from 'react'
import dashboard_err_img from "../../images/dashboard_err.png";
import "./style.scss";

 const MessageComp = (props) => {
  return (
    <div className={"no-access-page message-height"}>
        <img src={dashboard_err_img} className="No_Access lock" alt="No_Access" />
        <h1 className="mt-4 fs-20 text-center">
            {props.message}
        </h1>
        {/* <p className="fs-16">
            {props.message}
        </p> */}
    </div>
  )
}
export default MessageComp