import React, { useState } from 'react'
import { Link, useLocation, navigate } from "@reach/router";
import { Card, Tooltip } from 'react-bootstrap';
import { ERROR_MESSAGES, FETCH_LIMIT, SUCCESS_MESSAGES } from '../../constants/app-constants';
import { AuthService } from '../../_services/auth.service';
import Loader from '../../widgets/loader/Loader';
import ConfirmationPopup from '../../widgets/Popups/Confirmation-Popup';
import { executionEngineService } from '../../_services/executionengine.service';
import { toast } from 'react-toastify';
import ExecutionEngineSidebar from './ExecutionEngineSidebar';
import ExecutionEngineForm from './ExecutionEngineForm';
import AuditLog from '../../widgets/AuditLog/AuditLog';

const ExecutionEngineDetail = (props) => {
  const location = useLocation();
  const accessModule = AuthService.getAllAccessList("executionengine");
  const [loadMore, setMoreExecutionEngine] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [executionEngineList, setExecutionEngineList] = useState(null);
  const [executionEngineDetail, setExecutionEngineDetail] = useState(null);

  const [confirmData, setConfirmData] = useState({
    title: "",
    message: "",
    closeLabel: "",
    okLabel: "",
    isShow: false,
    selectedId: "",
  });

  const [error, setError] = useState({
    errorStatus: false,
    errorData: "",
    noAccessModule: false,
    noAccessModuleMessage: "",
  });

  const [errorSide, setErrorSide] = useState({
    isError: false,
    errMsg: "",
  });

  const [isLoadingSidebar, setIsLoadingSidebar] = useState(false);
  function closeConfirm() {
    setConfirmData({
      ...confirmData,
      isShow: false,
      selectedId: "",
    });
  }

  const [selectedValue, setSelectedValue] = useState({
    showFilter: true,
    limit: FETCH_LIMIT,
    offset: 0,
    pageNumber: 0,
  });

  const [settings, setSettings] = useState({
    showFilter: false,
    isEditable: false,
    buttonIndex: 0,
  });

  React.useEffect(() => {
    getAllExecutionEngine();
  }, []);

  function getAllExecutionEngine() {
    setIsLoadingSidebar(true);
    executionEngineService
      .getAllExecutionEngine(selectedValue.limit, selectedValue.offset)
      .then((resp) => {
        if (resp.status == 200 && resp?.data) {
          setExecutionEngineList(resp.data);
          setIsLoadingSidebar(false);
        } else if (resp?.status == 408) {
          setErrorSide({
            ...errorSide,
            isError: true,
            errMsg: ERROR_MESSAGES.timeExceedError,
          });
          setIsLoadingSidebar(false);
        } else {
          setErrorSide({
            ...errorSide,
            isError: true,
            errMsg: ERROR_MESSAGES.failedLoadRecordsError,
          });
          setIsLoadingSidebar(false);
          toast.error(
            resp?.data?.detail ? resp?.data?.detail : resp?.data?.error
          );
        }
      });
  }

  React.useEffect(() => {
    getExecutionEngineDetail();
    editExecutionEngine(false);
  }, [location]);

  function getExecutionEngineDetail() {
    setLoading(true);
    executionEngineService.getExecutionEngineDetail(props?.execution_engine_key).then((resp) => {
      if (resp.status == 200 && resp?.data) {
        setError({
          ...error,
          noAccessModule: false,
          noAccessModuleMessage: "",
        });
        setExecutionEngineDetail(resp?.data);
        setLoading(false);
      } else if (resp?.status == 408) {
        setError({
          ...error,
          noAccessModule: true,
          noAccessModuleMessage: ERROR_MESSAGES.timeExceedError,
        });
        setLoading(false);
      } else {
        setLoading(false);
        if (resp?.status === 422) {
          setError({
            ...error,
            noAccessModule: false,
            noAccessModuleMessage: "",
          });
          toast.error(resp?.data?.detail[0].msg);
        } else if (resp?.status === 404) {
          setError({
            ...error,
            noAccessModule: true,
            noAccessModuleMessage: resp?.data?.error
              ? resp?.data?.error
              : ERROR_MESSAGES.noAccessMsg,
          });
        } else {
          setError({
            ...error,
            noAccessModule: true,
            noAccessModuleMessage: resp?.data?.error
              ? resp?.data?.error
              : ERROR_MESSAGES?.detailsFailsMessage,
          });
        }
      }
    });
  }

  function editExecutionEngine(event) {
    setIsEdit(event);
  }

  function editExecutionEngineAfterEdit(event) {
    setIsEdit(event);
    getExecutionEngineDetail();
    getAllExecutionEngine();
  }

  function editAfterFeture() {
    getExecutionEngineDetail();
  }

  function handelLoading(event) {
    setLoading(event);
  }

  function deleteExecutionEngine() {
    setConfirmData({
      ...confirmData,
      title: "Alert",
      message: ERROR_MESSAGES.reConformMessage,
      closeLabel: "Cancel",
      okLabel: "Confirm",
      isShow: true,
      selectedId: props?.execution_engine_key,
    });
  }


  function deleteConfirm() {
    closeConfirm();
    setLoading(true);
    executionEngineService
      .deleteExecutionEngine(confirmData?.selectedId)
      .then((appRes) => {
        if (appRes.status == 200 && appRes?.data) {
          setLoading(false);
          toast.success(SUCCESS_MESSAGES.delete);
          navigate(`/app/execution-engines`);
        } else if (appRes?.status == 408) {
          setLoading(false);
          toast.error(ERROR_MESSAGES.timeExceedError);
        } else {
          setLoading(false);
          if (appRes?.status === 422) {
            toast.error(appRes?.data?.detail[0].msg);
          } else {
            toast.error(appRes?.data?.detail);
          }
        }
      });
  }

  function fetchMoreData(e) {
    if (e) {
      let pgNo = selectedValue.pageNumber + 1;
      let offset = pgNo * selectedValue.limit;
      setSelectedValue({ ...selectedValue, pageNumber: pgNo });
      executionEngineService
        .getAllExecutionEngine(selectedValue.limit, offset)
        .then((resp) => {
          if (resp.status == 200 && resp?.data) {
            if (resp?.data.length > 0) {
              let newlist = executionEngineList.concat(resp?.data);
              setMoreExecutionEngine(true);
              setExecutionEngineList(newlist);
            } else {
              setMoreExecutionEngine(false);
            }
          }
        });
    }
  }

  return (
    <div className="containWrap-inner">
      {loading ? <Loader /> : ""}
      {confirmData.isShow ? (
        <ConfirmationPopup
          data={confirmData}
          confirmCloseEvent={closeConfirm}
          confirmOkEvent={deleteConfirm}
        />
      ) : (
        ""
      )}

      {!error.noAccessModule ? (
        <div className="col-12">
          <div className="row">
            <div className="col-4">
              <Card className="bg-white custom-card list-option h-100">
                <Card.Header className="shadow-none p-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <Card.Title>
                      <h4 className="m-0">Execution Engines</h4>
                    </Card.Title>
                    <div className="d-flex align-items-center justify-content-between">
                      <button
                        disabled={!accessModule.add}
                        onClick={() => navigate("/app/execution-engines/new")}
                        className="btn btn-sm btn-primary"
                      >
                        Add Execution Engine
                      </button>
                      {!accessModule.add && (
                        <i
                          className="fa fa-info-circle ms-2"
                          aria-hidden="true"
                          data-tip
                          data-for="addTip"
                        ></i>
                      )}
                      <Tooltip
                        id="addTip"
                        place="right"
                        effect="solid"
                      />
                    </div>
                  </div>
                </Card.Header>
                <Card.Body>
                  {isLoadingSidebar ? (
                    <div className="text-center"> Please Wait....</div>
                  ) : errorSide.isError ? (
                    <div className="text-center text-danger">
                      {" "}
                      {errorSide.errMsg}
                    </div>
                  ) : (
                    <ExecutionEngineSidebar
                      sidedata={executionEngineList}
                      getMoreData={fetchMoreData}
                      loadFeature={loadMore}
                    />
                  )}
                </Card.Body>
              </Card>
            </div>
            <div className="col-8 ps-0">
              <div className="col-12">
                <div className="d-flex align-items-center justify-content-between">
                  <Card className="bg-white custom-card custom-card-full">
                    <Card.Header className="shadow-none p-3">
                      <div className="d-flex align-items-start justify-content-between">
                        <Card.Title className="m-0">
                          <h4 className="">
                            {!isEdit
                              ? "Edit Execution Engine"
                              : "view Execution Engine detail"}
                          </h4>
                        </Card.Title>

                        <div className="d-flex align-items-center">
                          {!isEdit && (
                            <button
                              disabled={!accessModule.delete}
                              onClick={deleteExecutionEngine}
                              className="text-danger fs-20 py-0 mx-2 btn btn-link"
                            >
                              <i className="fa fa-trash" aria-hidden="true"></i>
                            </button>
                          )}
                          {!accessModule.delete && (
                            <i
                              className="fa fa-info-circle me-3"
                              aria-hidden="true"
                              data-tip
                              data-for="deleteTip"
                            ></i>
                          )}
                          <Tooltip
                            id="deleteTip"
                            place="left"
                            effect="solid"
                          />

                          <Link className="no-link" to={`/app/execution-engines`}>
                            <i className="fa fa-times" aria-hidden="true"></i>
                          </Link>
                        </div>
                      </div>
                    </Card.Header>
                    <Card.Body>
                      {executionEngineDetail && settings?.buttonIndex == 0 && (
                        <ExecutionEngineForm
                          isNew={isEdit}
                          isLoading={handelLoading}
                          handelEditWithoutChange={editExecutionEngine}
                          handelEdit={editExecutionEngineAfterEdit}
                          isEditForm={true}
                          id={props?.id}
                          executionEngineDetail={executionEngineDetail}
                        />
                      )}

                      {!isEdit && settings?.buttonIndex == 0 && (
                        <div className="d-flex justify-content-between align-items-center mt-3 mb-1">
                          <AuditLog logData={executionEngineDetail} />
                          <div>
                            <button
                              disabled={!accessModule.update}
                              className="btn btn-primary"
                              onClick={() => editExecutionEngine(true)}
                            >
                              Edit
                            </button>
                            {!accessModule.add && (
                              <i
                                className="fa fa-info-circle ms-2"
                                aria-hidden="true"
                                data-tip
                                data-for="updateTip"
                              ></i>
                            )}
                            <Tooltip
                              id="updateTip"
                              place="left"
                              effect="solid"
                            />
                          </div>
                        </div>
                      )}
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="row err-msg-container">
          <div className="col-12">
            <Card className="bg-white custom-card list-option">
              <Card.Body>
                <div className="row err-msg">
                  <label className="text-center">
                    {error.noAccessModuleMessage
                      ? error.noAccessModuleMessage
                      : ERROR_MESSAGES.noAccessMsg}
                  </label>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      )}
    </div>
  );
}

export default ExecutionEngineDetail