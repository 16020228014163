import React, { Component } from "react";
// import ReactDOM from "react-dom";
import "./styleLoader.scss";

const Loader = (props) => {
  return (
    <div className="loader-wrap">
      <div className="loading">
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Loader;
