import api from "./api";

export const productService = {
  getAllProducts,
  deleteProduct,
  createProduct,
  getProductDetail,
  updateProduct,
};

function getAllProducts(limit = null, offset = null) {
  // api/products?
  let limitParam = limit ? "?limit=" + limit : "";
  let offsetParam = offset
    ? "&offset=" + offset
    : offset == 0
    ? "&offset=0"
    : "";
  return api.get(`api/products${limitParam}${offsetParam}`);
}

function deleteProduct(param: any) {
  return api.delete(`api/products/${param}`);
}

function createProduct(param) {
  return api
    .post(`api/products`, {
      prod_key: param?.prod_key,
      name: param?.name,
      owner_id: param?.owner_id,
      enabled: param?.enabled,
      portals: param?.portals ? param.portals : "",
      report_portal_id: param?.report_portal_id ? param?.report_portal_id : 0,
      report_portal_name: param?.report_portal_name
        ? param?.report_portal_name
        : "",
    })
    .then((response) => {
      return response;
    });
}

function updateProduct(param) {
  return api
    .post(`api/products/${param.old_prod_key}`, {
      prod_key: param?.prod_key,
      name: param?.name,
      owner_id: param?.owner_id,
      enabled: param?.enabled,
      portals: param?.portals ? param.portals : "",
      report_portal_id: param?.report_portal_id ? param.report_portal_id : 0,
      report_portal_name: param?.report_portal_name
        ? param?.report_portal_name
        : "",
    })
    .then((response) => {
      return response;
    });
}

function getProductDetail(param) {
  return api.get(`api/products/${param}`);
}

