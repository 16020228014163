import React, { Component, useState } from "react";
import "./style.scss";
import { featureService } from "../../_services/feature.service";
import Inputcomp from "../../widgets/Form/input/Input";
import { Link, useLocation, navigate } from "@reach/router";
import { toast } from "react-toastify";
import ConfirmationPopup from "../../widgets/Popups/Confirmation-Popup";
import AuditLog from "../../widgets/AuditLog/AuditLog";
import {
  REQUIRED_ERROR,
  SUCCESS_MESSAGES,
  ERROR_MESSAGES,
} from "../../constants/app-constants";

const FeaturesForm = (props) => {
  const search = useLocation().search;
  const location = useLocation();
  const q_FromUrl = new URLSearchParams(search).get("q");
  // //
  const [featureForm, setFeatureForm] = useState({
    name: "",
    value: "",
  });

  const [error, setError] = useState({
    errorStatus: false,
    errorData: "",
    isValid: false,
    name: "Feature Name Required",
    value: "Feature Value Required",
  });

  const [confirmData, setConfirmData] = useState({
    title: "",
    message: "",
    closeLabel: "",
    okLabel: "",
    isShow: false,
    selectedId: "",
  });

  React.useEffect(() => {
    // //
    let feature = props?.featueDetail;
    setFeatureForm({
      ...featureForm,
      name: feature?.name,
      value: feature?.value,
    });
  }, [props?.featueDetail]);

  function handleChangeForm(event) {
    Object.keys(event).forEach(function (key) {
      setFeatureForm({ ...featureForm, [key]: event[key] });
    });
  }

  function onSubmit(event) {
    // //
    props?.isLoading(true);
    if (!validateForm()) {
      toast.error(ERROR_MESSAGES.checkAllFieldsMessage);
      props?.isLoading(false);
      return false;
    }
    let req = [
      {
        name: featureForm.name,
        value: featureForm.value,
      },
    ];
    featureService.createFeatures(req).then((resp) => {
      if (resp.status == 200 && resp?.data) {
        props?.isLoading(false);
        let q = q_FromUrl ? "?q=" + q_FromUrl : "";
        toast.success(SUCCESS_MESSAGES.add);
        navigate(`/app/features/${resp?.data[0]?.id}${q}`);
      } else if (resp?.status == 408) {
        toast.error(ERROR_MESSAGES.timeExceedError);
        props?.isLoading(false);
      } else {
        if (resp?.status === 422) {
          // toast.error(resp?.data?.error);
          toast.error(resp?.data?.detail[0].msg);
        } else {
          toast.error(
            resp?.data?.detail ? resp?.data?.detail : resp?.data?.error
          );
        }
        props?.isLoading(false);
      }
    });
  }

  function onUpdate() {
    props?.isLoading(true);
    if (!validateForm()) {
      toast.error(ERROR_MESSAGES.checkAllFieldsMessage);
      props?.isLoading(false);
      return false;
    }
    let req = {
      name: featureForm.name,
      value: featureForm.value,
    };

    featureService.updateFeatures(props?.id, req).then((resp) => {
      if (resp.status == 200 && resp?.data) {
        toast.success(SUCCESS_MESSAGES.update);
        props?.isLoading(false);
        props?.handelEdit(false);
        // let q = selectedDropdown.q ? `?q=${selectedDropdown.q}` : ''
        // let q = q_FromUrl ? '?q='+q_FromUrl : '';
        // navigate(`/app/features${q}`)
      } else if (resp?.status == 408) {
        toast.error(ERROR_MESSAGES.timeExceedError);
        props?.isLoading(false);
      } else {
        if (resp?.status === 422) {
          // toast.error(resp?.data?.error);
          toast.error(resp?.data?.detail[0].msg);
        } else {
          toast.error(
            resp?.data?.detail ? resp?.data?.detail : resp?.data?.error
          );
        }
        props?.isLoading(false);
      }
    });
  }

  function validateForm() {
    if (featureForm.name && featureForm.value) {
      setError({ ...error, isValid: false });
      return true;
    } else {
      setError({ ...error, isValid: true });
      return false;
    }
  }

  function onUpdateCancelConfirm() {
    setConfirmData({
      ...confirmData,
      title: "Alert",
      message: ERROR_MESSAGES.reConformFormNotSaveMessage,
      closeLabel: "Cancel",
      okLabel: "Confirm",
      isShow: true,
    });
  }

  function closeConfirm() {
    setConfirmData({
      ...confirmData,
      isShow: false,
    });
  }

  function onUpdateCancel() {
    closeConfirm();
    props?.handelEditWithoutChange(false);
  }

  return (
    <>
      {confirmData.isShow ? (
        <ConfirmationPopup
          data={confirmData}
          confirmCloseEvent={closeConfirm}
          confirmOkEvent={() => onUpdateCancel()}
        />
      ) : (
        ""
      )}

      <form noValidate onClick={(e)=>e.preventDefault()}>
        <div className="row">
          <div className="col-9">
            <Inputcomp
              type="text"
              nameClass="form-control custom-form-lh2"
              name="name"
              handleChange={true}
              isDisabled={!props?.isNew}
              onChangeInput={handleChangeForm}
              label="Name"
              isRequired={true}
              value={featureForm.name}
            />
            {!featureForm.name && error.isValid && (
              <label className="text-danger fs-12">{REQUIRED_ERROR}</label>
            )}
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-9 ">
            <Inputcomp
              type="text"
              nameClass="form-control custom-form-lh2"
              name="value"
              handleChange={true}
              isDisabled={!props?.isNew}
              onChangeInput={handleChangeForm}
              label="Value"
              isRequired={true}
              value={featureForm.value}
            />
            {!featureForm.name && error.isValid && (
              <label className="text-danger fs-12">{REQUIRED_ERROR}</label>
            )}
          </div>
        </div>

        {props?.isNew && !props?.isEditForm && (
          <div className="d-flex justify-content-end my-3">
            <Link to={"/app/features"} className="btn btn-secondary me-3">
              Cancel
            </Link>
            <button className="btn btn-primary" onClick={onSubmit}>
              Submit
            </button>
          </div>
        )}
        {props?.isNew && props?.isEditForm && (
          <div className="d-flex justify-content-between my-3">
            <AuditLog logData={props?.featueDetail} />
            <div>
              <button
                className="btn btn-secondary  me-3"
                onClick={onUpdateCancelConfirm}
              >
                Cancel
              </button>
              <button className="btn btn-primary" onClick={onUpdate}>
                Save
              </button>
            </div>
          </div>
        )}
      </form>
    </>
  );
};
export default FeaturesForm;
