import React from "react";
import "./styleButton.scss";

import { ButtonGroup, Button } from "react-bootstrap";

const ButtonGroupComponent = (props) => {
  function getData(event) {
    props?.onClick(event.target.value);
  }
  return (
    <ButtonGroup size="sm">
      {props.options &&
        props.options.map((data, index) => {
          const { label, tabindex } = data;
          return (
            <Button
              key={tabindex}
              onClick={getData}
              value={tabindex}
              className={"btn btn-borderd"}
              variant={props.valueSelected == tabindex ? "primary" : ""}
            >
              {label}
            </Button>
          );
        })}
    </ButtonGroup>
  );
};

export default ButtonGroupComponent;
