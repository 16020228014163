import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { navigate } from "gatsby";
import { useLocation } from '@reach/router';
import LimitedAccess from "../partials/OtherPages/LimitedAccess";
import { AuthService } from "../_services/auth.service";

const PrivateRoute = ({
  component: Component,
  accessNeeded: Roles,
  ...rest
}) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (!AuthService.isLoggedIn()) {
      setLoggedIn(false);
      navigate(`/app/login`, { state: { from: location.pathname } });
    } else {
      setLoggedIn(true);
    }
  }, [location.pathname]);

  return !loggedIn ? null : AuthService.hasAccess(
      Roles["module"],
      Roles["operation"]
    ) ? (
    <Component {...rest} />
  ) : (
    <LimitedAccess />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
  accessNeeded: PropTypes.any.isRequired,
};

export default PrivateRoute;
