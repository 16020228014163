import { Link, useLocation, navigate } from "@reach/router";
import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { productService } from "../../_services/products.service";
import Loader from "../../widgets/loader/Loader";
import ErrorPopup from "../../widgets/Popups/Error-Popup";
import ProductSidebar from "./Product-Sidebar";
import ProductForm from "./ProductForm";
import ProductUser from "./ProductUser";
import ProductApplication from "./ProductApplication";
import ButtonGroupComponent from "../../widgets/Button/Buttongroup/Button-Group";
import { tabItems } from "./Tabhelper";
import { toast } from "react-toastify";
import ConfirmationPopup from "../../widgets/Popups/Confirmation-Popup";
import { AuthService } from "../../_services/auth.service";
import Tooltip from "../../widgets/Tooltip/Tooltip";
import AuditLog from "../../widgets/AuditLog/AuditLog";
import {
  ERROR_MESSAGES,
  SUCCESS_MESSAGES,
} from "../../constants/app-constants";

const ProductsDetail = (props) => {
  const accessModule = AuthService.getAllAccessList("products");

  const location = useLocation();

  const [loading, setLoading] = useState(false);

  const [isEdit, setIsEdit] = useState(false);

  const [productDetail, setProductDetail] = useState(null);

  const [error, setError] = useState({
    errorStatus: false,
    errorData: "",
    noAccessModule: false,
    noAccessModuleMessage: "",
  });
  const [productList, setProductList] = useState(null);

  const [settings, setSettings] = useState({
    showFilter: false,
    isEditable: false,
    buttonIndex: 0,
  });

  const [confirmData, setConfirmData] = useState({
    title: "",
    message: "",
    closeLabel: "",
    okLabel: "",
    isShow: false,
    selectedId: "",
  });

  const [errorSide, setErrorSide] = useState({
    isError: false,
    errMsg: "",
  });

  const [isLoadingSidebar, setIsLoadingSidebar] = useState(false);

  const handleChange = (event) => {
    setSettings({ ...settings, buttonIndex: event });
  };

  React.useEffect(() => {
    // tabParam ? setTabFromurl(tabParam) : '';
    editProduct(false);
    setSettings({ ...settings, buttonIndex: 0 });
  }, [location]);

  function closeError() {
    setError({ ...error, errorStatus: false });
  }

  React.useEffect(() => {
    getProductList();
  }, []);

  React.useEffect(() => {
    // getProductList();
    getProductDetails();
  }, [props?.prodkey]);

  function getProductList() {
    // setLoading(true)
    setIsLoadingSidebar(true);
    productService.getAllProducts().then((products) => {
      if (products.status == 200 && products?.data) {
        const options = products?.data.map((d) => ({
          id: d.id,
          prod_key: d.prod_key,
          name: d.name,
          owner: d.owner?.first_name + " " + d.owner?.last_name,
          enabled: d.enabled,
        }));
        setProductList(options);
        // setLoading(false);
        setIsLoadingSidebar(false);
        setErrorSide({
          ...errorSide,
          isError: false,
          errMsg: "",
        });
      } else if (products?.status == 408) {
        setErrorSide({
          ...errorSide,
          isError: true,
          errMsg: ERROR_MESSAGES.timeExceedError,
        });
        //   setLoading(false);
        setIsLoadingSidebar(false);
      } else {
        setErrorSide({
          ...errorSide,
          isError: true,
          errMsg: ERROR_MESSAGES.failedLoadRecordsError,
        });
        // setLoading(false)
        setIsLoadingSidebar(false);
        toast.error(
          products?.data?.detail
            ? products?.data?.detail
            : products?.data?.error
        );
      }
    });
  }

  function getProductDetails() {
    setLoading(true);
    productService.getProductDetail(props?.prodkey).then((resp) => {
      if (resp.status == 200 && resp?.data) {
        if (resp?.data?.portals == "{}") resp.data.portals = "";
        setError({
          ...error,
          noAccessModule: false,
          noAccessModuleMessage: "",
        });
        setProductDetail(resp?.data);
        setLoading(false);
      } else if (resp?.status == 408) {
        setError({
          ...error,
          noAccessModule: true,
          noAccessModuleMessage: ERROR_MESSAGES.timeExceedError,
        });
        setLoading(false);
      } else {
        setLoading(false);
        if (resp?.status === 422) {
          setError({
            ...error,
            noAccessModule: false,
            noAccessModuleMessage: "",
          });
          toast.error(resp?.data?.detail[0].msg);
        } else if (resp?.status === 404) {
          setError({
            ...error,
            noAccessModule: true,
            noAccessModuleMessage: resp?.data?.error
              ? resp?.data?.error
              : ERROR_MESSAGES.noAccessMsg,
          });
        } else {
          // setError({...error, noAccessModule:false, noAccessModuleMessage:''})
          // toast.error(resp?.data?.detail ?
          //   resp?.data?.detail : resp?.data?.error
          // );
          setError({
            ...error,
            noAccessModule: true,
            noAccessModuleMessage: resp?.data?.error
              ? resp?.data?.error
              : ERROR_MESSAGES.detailsFailsMessage,
          });
        }
      }
    });
  }

  function editProduct(event) {
    setIsEdit(event);
  }

  function editProductAfterEdit(event) {
    setIsEdit(event);
    getProductList();
    getProductDetails();
  }

  function handelLoading(event) {
    setLoading(event);
  }

  function getActiveButton(event) {
    let newArray = {
      created_at: productDetail?.created_at,
      enabled: event.target.checked ? true : false,
      id: productDetail?.id,
      name: productDetail?.name,
      owner: productDetail?.owner,
      portals: productDetail?.portals,
      prod_key: productDetail?.prod_key,
      updated_at: productDetail?.updated_at,
    };

    setLoading(true);

    let appTestForm = {
      old_prod_key: productDetail?.prod_key,
      prod_key: productDetail?.prod_key,
      name: productDetail?.name,
      owner_id: productDetail?.owner?.id,
      enabled: event.target.checked ? true : false,
      portals: productDetail?.portals.length > 0 ? productDetail?.portals : "",
      report_portal_id: productDetail?.owner?.report_portal_id,
      report_portal_name: productDetail?.owner?.report_portal_user_id,
    };

    productService.updateProduct(appTestForm).then((resp) => {
      if (resp.status == 200 && resp?.data) {
        setProductDetail(newArray);
        getProductList();
        setLoading(false);
        editProductAfterEdit(false);
      } else {
        if (resp?.status === 422) {
          toast.error(resp?.data?.detail[0].msg);
        } else {
          toast.error(
            resp?.data?.detail ? resp?.data?.detail : resp?.data?.error
          );
        }
        setLoading(false);
      }
    });
  }

  function deleteProduct() {
    setConfirmData({
      ...confirmData,
      title: "Alert",
      message: ERROR_MESSAGES.reConformMessage,
      closeLabel: "Cancel",
      okLabel: "Confirm",
      isShow: true,
      selectedId: productDetail.prod_key,
    });
  }

  function closeConfirm() {
    setConfirmData({
      ...confirmData,
      isShow: false,
      selectedId: "",
    });
  }

  function deleteConfirm() {
    closeConfirm();
    setLoading(true);
    productService.deleteProduct(productDetail.prod_key).then((resp) => {
      if (resp.status == 200 && resp?.data) {
        setLoading(false);
        toast.success(SUCCESS_MESSAGES.delete);
        navigate(`/app/products`);
      } else {
        if (resp?.status === 422) {
          toast.error(resp?.data?.detail[0].msg);
        } else {
          toast.error(
            resp?.data?.detail ? resp?.data?.detail : resp?.data?.error
          );
        }
        setLoading(false);
      }
    });
  }

  return (
    <div className="containWrap-inner">
      {loading ? <Loader /> : ""}

      {error.errorStatus ? (
        <ErrorPopup data={error.errorData} closePopup={closeError} />
      ) : (
        ""
      )}

      {confirmData.isShow ? (
        <ConfirmationPopup
          data={confirmData}
          confirmCloseEvent={closeConfirm}
          confirmOkEvent={deleteConfirm}
        />
      ) : (
        ""
      )}

      {!error.noAccessModule ? (
        <div className="col-12">
          <div className="row">
            <div className="col-4">
              <Card className="bg-white custom-card list-option h-100">
                <Card.Header className="shadow-none p-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <Card.Title>
                      <h4 className="m-0">Products</h4>
                    </Card.Title>
                    <div className="d-flex align-items-center justify-content-between">
                    <Tooltip
                        id="addTipPro"
                        place="right"
                        effect="solid"
                        msgType="operation"
                      />
                     <i
                        aria-hidden="true"
                        data-tip
                        data-for={!accessModule.add && "addTipPro"}
                      >
                        <button
                          disabled={!accessModule.add}
                          onClick={() => navigate("/app/products/new")}
                          className="btn btn-sm btn-primary"
                        >
                          Add Product
                        </button>
                      </i>
                      {/* {!accessModule.add && <i className="fa fa-info-circle ms-2" aria-hidden="true" data-tip data-for="addTip"></i>} */}
                     
                    </div>
                  </div>
                </Card.Header>
                <Card.Body>
                  {isLoadingSidebar ? (
                    <div className="text-center"> Please Wait....</div>
                  ) : errorSide.isError ? (
                    <div className="text-center text-danger">
                      {" "}
                      {errorSide.errMsg}
                    </div>
                  ) : (
                    <ProductSidebar productList={productList} />
                  )}
                  {/* <ProductSidebar productList={productList} getMoreData={fetchMoreData}/> */}
                </Card.Body>
              </Card>
            </div>
            <div className="col-8 ps-0">
              <div className="col-12">
                <div className="d-flex align-items-center justify-content-between">
                  <Card className="bg-white custom-card custom-card-full">
                    <Card.Header className="shadow-none p-3">
                      <div className="d-flex align-items-start justify-content-between mt-3 mb-1">
                        <Card.Title>
                          <h4 className="">
                            {productDetail ? (
                              <span>
                                {productDetail?.name} ({productDetail?.prod_key}
                                )
                              </span>
                            ) : (
                              <span>Product Detail</span>
                            )}
                          </h4>
                        </Card.Title>
                        <div className="d-flex align-items-center">
                          <div className="d-flex align-items-center">
                            <i
                              aria-hidden="true"
                              data-tip
                              data-for={!accessModule.enabled && "deleteTip"}
                            >
                              <div
                                className={
                                  !accessModule.enabled
                                    ? "toggle row-disabled-opacity"
                                    : "toggle"
                                }
                              >
                                <input
                                  type="checkbox"
                                  disabled={!accessModule.enabled}
                                  className="toggle-checkbox"
                                  id="proddisabled"
                                  name="darkmode"
                                  value="on"
                                  onChange={getActiveButton}
                                  checked={productDetail?.enabled}
                                />
                                <label
                                  className="toggle-label"
                                  htmlFor="proddisabled"
                                >
                                  <span className="toggle-inner"></span>
                                  <span className="toggle-switch"></span>
                                </label>
                              </div>
                            </i>

                            <Tooltip
                              id="enabledTip"
                              place="left"
                              effect="solid"
                              msgType="operation"
                            />

                            <i
                              aria-hidden="true"
                              data-tip
                              data-for={
                                (!accessModule.delete ||
                                  !accessModule.delete) &&
                                "deleteTip"
                              }
                            >
                              <button
                                disabled={!accessModule.delete}
                                onClick={deleteProduct}
                                className="text-danger fs-20 py-0 mx-2 btn btn-link"
                              >
                                <i
                                  className="fa fa-trash"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            </i>
                            {/* {(!accessModule.delete || !accessModule.delete) && <i className="fa fa-info-circle me-3" aria-hidden="true" data-tip data-for="deleteTip"></i>} */}
                            <Tooltip
                              id="deleteTip"
                              place="left"
                              effect="solid"
                              msgType="operation"
                            />
                          </div>

                          <Link className="no-link" to={"/app/products"}>
                            <i className="fa fa-times" aria-hidden="true"></i>
                          </Link>
                        </div>
                      </div>
                      {/* Start Tabs */}
                      <ButtonGroupComponent
                        valueSelected={settings.buttonIndex}
                        options={tabItems}
                        onClick={handleChange}
                      />
                      {/* End Tabs */}
                    </Card.Header>
                    <Card.Body>
                      {settings?.buttonIndex == 0 && (
                        <ProductForm
                          isNew={isEdit}
                          productDetail={productDetail}
                          isEditForm={true}
                          isLoading={handelLoading}
                          handelEdit={editProductAfterEdit}
                          handelEditWithoutChange={editProduct}
                        />
                      )}
                      {settings?.buttonIndex == 1 && (
                        <ProductApplication
                          prod_key={productDetail?.prod_key}
                          isLoading={handelLoading}
                        />
                      )}
                      {settings?.buttonIndex == 2 && (
                        <ProductUser
                          prod_key={productDetail?.prod_key}
                          isLoading={handelLoading}
                        />
                      )}
                      {/* {isEdit ? <ProductForm isNew={isEdit}/> : <ProductDetailForm />} */}

                      {settings?.buttonIndex == 0 && !isEdit && (
                        <div className="d-flex justify-content-between align-items-center mt-3 mb-1">
                          <AuditLog logData={productDetail} />
                          <div>
                            <i
                              aria-hidden="true"
                              data-tip
                              data-for={!accessModule.update && "updateTip"}
                            >
                              <button
                                disabled={!accessModule.update}
                                className="btn btn-primary"
                                onClick={() => editProduct(true)}
                              >
                                Edit
                              </button>
                            </i>
                            {/* {!accessModule.add && <i className="fa fa-info-circle ms-2" aria-hidden="true" data-tip data-for="updateTip"></i>} */}
                            <Tooltip
                              id="updateTip"
                              place="left"
                              effect="solid"
                              msgType="operation"
                            />
                          </div>
                        </div>
                      )}
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="row err-msg-container">
          <div className="col-12">
            <Card className="bg-white custom-card list-option">
              <Card.Body>
                <div className="row err-msg">
                  <label className="text-center">
                    {error.noAccessModuleMessage
                      ? error.noAccessModuleMessage
                      : ERROR_MESSAGES.noAccessMsg}
                  </label>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductsDetail;
