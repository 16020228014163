import { Link, useLocation, navigate } from "@reach/router";
import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { PAGINATION_FETCH_LIMIT } from "../../constants/app-constants";
import { featureService } from "../../_services/feature.service";

import { toast } from "react-toastify";
import Inputcomp from "../../widgets/Form/input/Input";
import Loader from "../../widgets/loader/Loader";
import ErrorPopup from "../../widgets/Popups/Error-Popup";
import ConfirmationPopup from "../../widgets/Popups/Confirmation-Popup";
import { AuthService } from "../../_services/auth.service";
import Tooltip from "../../widgets/Tooltip/Tooltip";

import {
  SUCCESS_MESSAGES,
  CONTENT_MESSAGES,
  ERROR_MESSAGES,
} from "../../constants/app-constants";

const FeatureList = (props) => {
  const search = useLocation().search;
  const accessModule = AuthService.getAllAccessList("feature");

  const [featurelist, setFeaturesList] = useState(null);
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState({
    errorStatus: false,
    errorData: "",
    faildToLoad: false,
    timeExceedError: false,
  });

  const [confirmData, setConfirmData] = useState({
    title: "",
    message: "",
    closeLabel: "",
    okLabel: "",
    isShow: false,
    selectedId: "",
  });

  const [selectedDropdown, setSelectedDropdown] = useState({
    q: "",
    showFilter: false,
    limit: PAGINATION_FETCH_LIMIT,
    offset: 0,
    pageNumber: 0,
  });

  React.useEffect(() => {
    setFeaturesList(null);
    getAllFeature();
  }, []);

  function getAllFeature() {
    setLoading(true);
    featureService.getAllFeatures(selectedDropdown).then((features) => {
      if (features.status == 200 && features?.data) {
        setFeaturesList(features.data);
        setLoading(false);
        setError({
          ...error,
          faildToLoad: false,
          timeExceedError: false,
        });
      } else if (features?.status == 408) {
        setError({
          ...error,
          timeExceedError: true,
        });
        setLoading(false);
      } else {
        setLoading(false);
        // if (features?.status === 422) {
        //     toast.error(features?.data?.detail[0].msg);
        // } else {
        //     toast.error(features?.data?.detail);
        // }
        setError({
          ...error,
          faildToLoad: true,
        });
      }
    });
  }

  function closeError() {
    setError({ ...error, errorStatus: false });
    window.localStorage.removeItem("localUser");
    navigate(`/app/login`);
  }

  function onSubmit() {
    let q = selectedDropdown.q ? `?q=${selectedDropdown.q}` : "";
    navigate(`/app/features${q}`);
    getAllFeature();
  }

  function toggleOpen() {
    setSelectedDropdown({
      ...selectedDropdown,
      showFilter: !selectedDropdown.showFilter,
    });
  }

  function handleChangeForm(event) {
    Object.keys(event).forEach(function (key) {
      setSelectedDropdown({ ...selectedDropdown, [key]: event[key] });
    });
  }

  React.useEffect(() => {
    getAllFeature();
  }, [selectedDropdown.offset]);

  function resetPage(param) {
    if (param === "next") {
      let pgNo = selectedDropdown.pageNumber + 1;
      let offset = pgNo * selectedDropdown.limit;
      setSelectedDropdown({
        ...selectedDropdown,
        offset: offset,
        pageNumber: pgNo,
      });
    } else {
      if (selectedDropdown.pageNumber > 0) {
        let pgNo = selectedDropdown.pageNumber - 1;
        let offset = pgNo * selectedDropdown.limit;
        setSelectedDropdown({
          ...selectedDropdown,
          offset: offset,
          pageNumber: pgNo,
        });
      }
    }
  }

  // function handleRemoveFeature(id) {
  //     setLoading(true)
  //     featureService.deleteFeatures(id).then(features => {
  //
  //         if (features.status == 200 ) {
  //             getAllFeature()
  //         } else {
  //             setLoading(false)
  //             if (features?.status === 422) {
  //                 toast.error(features?.data?.detail[0].msg);
  //             } else {
  //                 toast.error(features?.data?.detail);
  //             }

  //         }
  //     });
  // }

  function handleRemoveFeature(id) {
    setConfirmData({
      ...confirmData,
      title: "Alert",
      message: ERROR_MESSAGES.reConformMessage,
      closeLabel: "Cancel",
      okLabel: "Confirm",
      isShow: true,
      selectedId: id,
    });
  }

  function deleteConfirm(id) {
    closeConfirm();
    setLoading(true);
    featureService.deleteFeatures(id).then((resp) => {
      //
      if (resp.status == 200 && resp?.data) {
        toast.success(SUCCESS_MESSAGES.delete);
        getAllFeature();
      } else {
        if (resp?.status == 408) {
          toast.error(ERROR_MESSAGES.timeExceedError);
        }
        if (resp?.status === 422) {
          // toast.error(resp?.data?.error)
          toast.error(resp?.data?.detail[0].msg);
        } else {
          toast.error(
            resp?.data?.detail ? resp?.data?.detail : resp?.data?.error
          );
        }
        setLoading(false);
      }
    });
  }

  function closeConfirm() {
    setConfirmData({
      ...confirmData,
      isShow: false,
      selectedId: "",
    });
  }

  function getNew() {
    let q = selectedDropdown.q ? `?q=${selectedDropdown.q}` : "";
    navigate(`/app/features/new${q}`);
  }

  function getDetails(id) {
    let q = selectedDropdown.q ? `?q=${selectedDropdown.q}` : "";
    navigate(`/app/features/${id}${q}`);
  }

  return (
    <div className="containWrap-inner">
      {loading ? <Loader /> : ""}
      {error.errorStatus ? (
        <ErrorPopup data={error.errorData} closePopup={closeError} />
      ) : (
        ""
      )}
      {confirmData.isShow ? (
        <ConfirmationPopup
          data={confirmData}
          confirmCloseEvent={closeConfirm}
          confirmOkEvent={() => deleteConfirm(confirmData?.selectedId)}
        />
      ) : (
        ""
      )}

      <Card className="bg-white custom-card">
        <Card.Body>
          <div className="row">
            <div className="col-9">
              <Card.Title>
                <h4 className="m-0">Features</h4>
              </Card.Title>
              <Card.Text>{CONTENT_MESSAGES.fetureListTitalDesc}</Card.Text>
            </div>

            <div className="col-3">
              <div className="d-flex align-items-center justify-content-end">
                <div className="py-1 px-2">
                  <i
                    className="fa fa-filter"
                    aria-hidden="true"
                    onClick={toggleOpen}
                  ></i>
                </div>

                <div className="ms-2">
                  <button
                    disabled={!accessModule.add}
                    onClick={getNew}
                    className="btn btn-sm btn-primary"
                  >
                    Add Feature
                  </button>
                </div>
                {!accessModule.add && (
                  <i
                    className="fa fa-info-circle ms-2"
                    aria-hidden="true"
                    data-tip
                    data-for="addTip"
                  ></i>
                )}
                <Tooltip
                  id="addTip"
                  place="left"
                  effect="solid"
                  msgType="operation"
                />
              </div>
            </div>
          </div>

          {selectedDropdown.showFilter && (
            <div className="col-12 bg-d-gray p-4 mt-3">
              <div className="row">
                <div className="col-12 col-md-6">
                  <Inputcomp
                    type="text"
                    nameClass="form-control custom-form-lh2"
                    name="q"
                    handleChange={true}
                    isDisabled={false}
                    onChangeInput={handleChangeForm}
                    label=""
                    isRequired={false}
                    placeholder={"Search by feature name or value"}
                    value={selectedDropdown.q}
                    onKeyPress={onSubmit}
                  />
                </div>

                <div className="col-12 col-md-6">
                  <input
                    className="btn btn-primary pull-right"
                    type="submit"
                    value="Search"
                    onClick={onSubmit}
                  />
                </div>
              </div>
            </div>
          )}
        </Card.Body>
      </Card>

      <Card className="bg-white custom-card mt-3 mb-3 custom-card-full">
        <Card.Body>
          <div className="table-responsive table-custom">
            <table className="table data-table data-table-vertically-center  ">
              {/*  table-hover  data-table */}
              <thead>
                <tr className="table-primary">
                  <th>Name</th>
                  <th>Value</th>
                  <th className="text-center">
                    {!accessModule.delete && (
                      <i
                        className="fa fa-info-circle"
                        aria-hidden="true"
                        data-tip
                        data-for="deleteTip"
                      ></i>
                    )}
                    <Tooltip
                      id="deleteTip"
                      place="left"
                      effect="solid"
                      msgType="operation"
                    />
                  </th>
                </tr>
              </thead>

              <tbody>
                {featurelist && featurelist.length > 0 ? (
                  featurelist.map((one, index) => (
                    <tr key={index}>
                      <td>
                        <a
                          onClick={() => getDetails(one?.id)}
                          className="no-link click-able"
                        >
                          {one?.name}
                        </a>
                      </td>
                      <td> {one?.value} </td>

                      <td className="text-center actions">
                        {/* className="align-middle" */}
                        <button
                          disabled={!accessModule.delete}
                          onClick={() => handleRemoveFeature(one?.id)}
                          className="pull-right btn link text-danger"
                        >
                          <i className="fa fa-trash" aria-hidden="true"></i>
                        </button>
                      </td>
                    </tr>
                  ))
                ) : error.timeExceedError ? (
                  <tr>
                    <td colSpan={10} className="text-center text-danger ">
                      {ERROR_MESSAGES.timeExceedError}
                    </td>
                  </tr>
                ) : error.faildToLoad ? (
                  <tr>
                    <td colSpan={10} className="text-center text-danger ">
                      {ERROR_MESSAGES.failedLoadRecordsError}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td colSpan={10} className="text-center">
                      {ERROR_MESSAGES.noRecordsFound}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {featurelist && (
              <div className="d-flex justify-content-end pt-3 pb-1">
                {selectedDropdown.offset < 1 ? (
                  <button className="btn btn-primary btn-sm" disabled>
                    <i className="fa fa-chevron-left" aria-hidden="true"></i>
                  </button>
                ) : (
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={() => resetPage("prev")}
                  >
                    <i className="fa fa-chevron-left" aria-hidden="true"></i>
                  </button>
                )}

                {featurelist && featurelist.length > 0 ? (
                  <button
                    className="btn btn-primary btn-sm ms-2"
                    onClick={() => resetPage("next")}
                  >
                    <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  </button>
                ) : (
                  <button className="btn btn-primary btn-sm ms-2" disabled>
                    <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  </button>
                )}
              </div>
            )}
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default FeatureList;
