import { Link, useLocation, navigate } from "@reach/router";
import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { productService } from "../../_services/products.service";
import { buildsService } from "../../_services/builds.service";
import { buildsApplicationService } from "../../_services/builds_application.service";

import Inputcomp from "../../widgets/Form/input/Input";
import SearchableSelect from "../../widgets/Form/Select/Searchable-Select";
import Loader from "../../widgets/loader/Loader";
import ErrorPopup from "../../widgets/Popups/Error-Popup";
import BadgeComp from "../../widgets/Badges/BadgeComp";
import CHOICES from "../../_helpers/CHOICES";
import CheckboxComponent from "../../widgets/Form/Checkbox/CheckboxComponent";
import { toast } from "react-toastify";
import { TokenService } from "../../_services/token.service";
import moment from "moment";
import {
  DATE_FORMAT,
  ERROR_MESSAGES,
  CONTENT_MESSAGES,
  PAGINATION_FETCH_LIMIT,
} from "../../constants/app-constants";

import { AuthService } from "../../_services/auth.service";

import Tooltip from "../../widgets/Tooltip/Tooltip";

const Builds = () => {
  const search = useLocation().search;
  const accessModule = AuthService.getAllAccessList("builds");

  let user = AuthService.getCurrentUser();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    errorStatus: false,
    errorData: "",
    err_product: "",
    err_application: "",
    noAccessModule: false,
    noAccessModuleMessage: "",
    faildToLoad: false,
    timeExceedError: false,
  });

  const [productDropdown, setProductDropdown] = useState(null);
  const [appDropdown, setAppDropdown] = useState(null);
  const [appBuilds, setAppBuilds] = useState(null);

  const [selectedDropdown, setSelectedDropdown] = useState({
    product: "",
    application: "",
    requestor_id: TokenService.getUserId(),
    q: "",
    by_requestor_id: false,
    failedOnly: false,
    showFilter: true,
    limit: PAGINATION_FETCH_LIMIT,
    offset: 0,
    appkeyFlag: false,
    pageNumber: 0,
  });

  React.useEffect(() => {
    setAppBuilds("");
    setProductDropdown("");
    setAppDropdown("");
    setLoading(true);

    const appkey = new URLSearchParams(search).get("app");
    if (appkey) {
      getByQuery(appkey);
    } else {
      getProduct();
    }
  }, []);

  function getProduct() {
    getProductList();
    setLoading(false);
  }

  function getProductList() {
    productService.getAllProducts().then((products) => {
      //
      if (products.status == 200 && products?.data) {
        const options = products?.data.map((d) =>
          d.enabled
            ? {
                value: d.prod_key,
                label: d.prod_key + " - " + d.name,
                enabled: d.enabled,
              }
            : ""
        );
        let k = options.filter((x) => x.enabled);
        setProductDropdown(k);
        setError({
          ...error,
          faildToLoad: false,
        });
      } else {
        setError({
          ...error,
          faildToLoad: true,
        });
      }
    });
  }

  function onProductChange(event) {
    setLoading(true);
    // toast.error("ok");
    navigate(`/app/builds`);
    setSelectedDropdown({
      ...selectedDropdown,
      product: event,
      application: "",
      failedOnly: false,
    });
    setAppDropdown("");
    setAppBuilds("");

    if (event) {
      setError({ ...error, err_product: "" });
      getApplicationList(event);
      setLoading(false);
    }
  }

  function getApplicationList(event) {
    buildsApplicationService.getAllApplication(event).then((applications) => {
      if (applications.status == 200 && applications?.data) {
        const optionsApp = applications?.data.map((d) => ({
          value: d.app_key,
          label: d.app_key + " - " + d.name,
          enabled: d.enabled,
        }));

        let k = optionsApp.filter((x) => x.enabled);
        setAppDropdown(k);
      }
    });
  }

  function onApplicationChange(event) {
    if (event) {
      setError({ ...error, err_application: "" });
      setSelectedDropdown({
        ...selectedDropdown,
        product: selectedDropdown?.product,
        application: event,
        failedOnly: false,
      });
    }
  }

  React.useEffect(() => {
    searchRequest();
  }, [selectedDropdown.appkeyFlag]);

  function getByQuery(event) {
    let failed_builds_only = new URLSearchParams(search).get(
      "failed_builds_only"
    );
    let my_builds_only = new URLSearchParams(search).get("my_builds_only");
    let q = new URLSearchParams(search).get("q");

    buildsApplicationService.getApplicationDetails(event).then((resp) => {
      if (resp.status == 200 && resp?.data) {
        setError({ ...error, err_application: "", err_product: "" });
        getProductList();
        resp?.data?.product?.prod_key
          ? getApplicationList(resp?.data?.product?.prod_key)
          : "";

        setSelectedDropdown({
          ...selectedDropdown,
          product: resp?.data?.product?.prod_key,
          application: resp?.data?.app_key,
          q: q,
          by_requestor_id: my_builds_only === "true" ? true : false,
          failedOnly: failed_builds_only === "true" ? true : false,
          appkeyFlag: true,
        });
      } else {
        setLoading(false);
        if (resp?.status === 422) {
          setError({
            ...error,
            noAccessModule: false,
            noAccessModuleMessage: "",
          });
          toast.error(resp?.data?.detail[0].msg);
        } else if (resp?.status === 404) {
          setError({
            ...error,
            noAccessModule: true,
            noAccessModuleMessage: resp?.data?.error
              ? resp?.data?.error
              : ERROR_MESSAGES.noAccessMsg,
          });
        } else {
          setError({
            ...error,
            noAccessModule: false,
            noAccessModuleMessage: "",
          });
          toast.error(
            resp?.data?.detail ? resp?.data?.detail : resp?.data?.error
          );
        }
      }
    });
  }

  function closeError() {
    setError({ ...error, errorStatus: false });
    window.localStorage.removeItem("localUser");
    navigate(`/app/login`);
  }

  function failedOnlyChange(event) {
    setSelectedDropdown({ ...selectedDropdown, failedOnly: event });
  }

  function requesterIdChange(event) {
    setSelectedDropdown({ ...selectedDropdown, by_requestor_id: event });
  }

  const onSubmit = () => {
    if (!getValidateFilter()) {
      return false;
    }

    let app = `app=${selectedDropdown.application}`;
    let failed_builds_only = `&failed_builds_only=${selectedDropdown.failedOnly}`;
    let my_builds_only = `&my_builds_only=${selectedDropdown.by_requestor_id}`;
    let q = selectedDropdown.q ? `&q=${selectedDropdown.q}` : "";

    navigate(`/app/builds?${app}${failed_builds_only}${my_builds_only}${q}`);
    selectedDropdown?.pageNumber > 0 &&
      setSelectedDropdown({ ...selectedDropdown, pageNumber: 0, offset: 0 });
    searchRequest();
  };

  function searchRequest() {
    if (selectedDropdown.application) {
      setLoading(true);
      setError({ ...error, err_application: "", err_product: "" });
      buildsService.getAppBuilds(selectedDropdown).then((applist) => {
        if (applist.status == 200 && applist?.data) {
          const applicationList = applist?.data.map((d) => ({
            id: d.id,
            name: d.name,
            submitted: moment.utc(d.created_at).format(DATE_FORMAT), //formatDate(d.created_at),
            test_build_count: d.test_build_count,
            builds_completed: d.status,
            builds_successful: d.result,
            user: d.requestor.first_name + " " + d.requestor.last_name,
          }));
          setAppBuilds(applicationList);

          applicationList ? setLoading(false) : "";
        } else if (applist?.status == 408) {
          setError({
            ...error,
            timeExceedError: true,
          });
          setLoading(false);
          setAppBuilds("");
        } else {
          setLoading(false);
          setAppBuilds("");
          setError({
            ...error,
            faildToLoad: true,
          });
        }
        // else {
        //   setLoading(false);
        //   setAppBuilds("");
        // }
      });
    }
  }

  function getValidateFilter() {
    if (!selectedDropdown.application && !selectedDropdown.product) {
      setError({
        ...error,
        err_product: "Please Select Product",
        err_application: "Please Select Application",
      });
      return false;
    } else {
      if (!selectedDropdown.product) {
        setError({
          ...error,
          err_product: "Please Select Product",
          err_application: "",
        });
        return false;
      } else if (!selectedDropdown.application) {
        setError({
          ...error,
          err_product: "",
          err_application: "Please Select Application",
        });
        return false;
      } else {
        setError({ ...error, err_application: "", err_product: "" });
        return true;
      }
    }
  }

  function toggleOpen() {
    setSelectedDropdown({
      ...selectedDropdown,
      showFilter: !selectedDropdown.showFilter,
    });
  }

  function handleChangeForm(event) {
    Object.keys(event).forEach(function (key) {
      setSelectedDropdown({ ...selectedDropdown, [key]: event[key] });
    });
  }

  React.useEffect(() => {
    searchRequest();
  }, [selectedDropdown.offset]);

  function resetPage(param) {
    if (param === "next") {
      let pgNo = selectedDropdown.pageNumber + 1;
      let offset = pgNo * selectedDropdown.limit;
      setSelectedDropdown({
        ...selectedDropdown,
        offset: offset,
        pageNumber: pgNo,
      });
    } else {
      if (selectedDropdown.pageNumber > 0) {
        let pgNo = selectedDropdown.pageNumber - 1;
        let offset = pgNo * selectedDropdown.limit;
        setSelectedDropdown({
          ...selectedDropdown,
          offset: offset,
          pageNumber: pgNo,
        });
      }
    }
  }

  function navigateToDetails(id, type) {
    let app = `app=${selectedDropdown.application}`;
    let failed_builds_only = `&failed_builds_only=${selectedDropdown.failedOnly}`;
    let my_builds_only = `&my_builds_only=${selectedDropdown.by_requestor_id}`;
    let q = selectedDropdown.q ? `&q=${selectedDropdown.q}` : "";
    if (type === "manage") {
      navigate(
        `/app/builds/${id}?${app}${failed_builds_only}${my_builds_only}${q}`
      );
    } else if (type === "new") {
      navigate(
        `/app/builds/new?${app}${failed_builds_only}${my_builds_only}${q}`
      );
    }
  }

  return (
    <div className="containWrap-inner">
      {loading ? <Loader /> : ""}
      {error.errorStatus ? (
        <ErrorPopup data={error.errorData} closePopup={closeError} />
      ) : (
        ""
      )}

      <Card className="bg-white custom-card">
        <Card.Body>
          <div className="row">
            <div className="col-9">
              <Card.Title>
                <h4 className="m-0">Application Test</h4>
              </Card.Title>
              <Card.Text>{CONTENT_MESSAGES.buildListTitalDesc}</Card.Text>
            </div>

            <div className="col-3">
              <div className="d-flex align-item-center justify-content-end">
                <div className="py-1 px-2">
                  <i
                    className="fa fa-filter"
                    aria-hidden="true"
                    onClick={toggleOpen}
                  ></i>
                </div>

                {selectedDropdown?.application ? (
                  <div className="ms-2">
                    <button
                      disabled={!accessModule.add}
                      onClick={() =>
                        navigateToDetails(selectedDropdown.application, "new")
                      }
                      className="btn btn-sm btn-primary"
                    >
                      Submit Build
                    </button>
                    {!accessModule.add && (
                      <i
                        className="fa fa-info-circle ms-2"
                        aria-hidden="true"
                        data-tip
                        data-for="addTip"
                      ></i>
                    )}
                    <Tooltip
                      id="addTip"
                      place="left"
                      effect="solid"
                      msgType="operation"
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          {selectedDropdown.showFilter && (
            <div className="col-12 bg-d-gray p-2 mt-3">
              <div className="row">
                <div className="col-12 col-md-4">
                  <SearchableSelect
                    optionList={productDropdown ? productDropdown : ""}
                    selectedOption={selectedDropdown.product}
                    dropdownName={"Product"}
                    placeholder={"Select Product"}
                    onClick={onProductChange}
                    isEditable={productDropdown ? true : false}
                    isRequired={true}
                  />
                  {error.err_product && (
                    <label className="text-danger">{error.err_product}</label>
                  )}
                </div>
                <div className="col-12 col-md-4">
                  <SearchableSelect
                    optionList={appDropdown ? appDropdown : ""}
                    selectedOption={selectedDropdown.application}
                    dropdownName={"Application"}
                    placeholder={"Select Application"}
                    onClick={onApplicationChange}
                    isEditable={appDropdown ? true : false}
                    isRequired={true}
                    subLabel={""}
                  />
                  {error.err_application && (
                    <label className="text-danger">
                      {error.err_application}
                    </label>
                  )}
                </div>

                <div className="col-12 col-md-4">
                  <Inputcomp
                    type="text"
                    nameClass="form-control custom-form-lh2"
                    name="q"
                    handleChange={true}
                    isDisabled={false}
                    onChangeInput={handleChangeForm}
                    label="Build Name/ Build ID"
                    isRequired={false}
                    placeholder={"Build name / Exact (full) Build ID"}
                    value={selectedDropdown.q}
                    onKeyPress={onSubmit}
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-12 col-md-4">
                  <CheckboxComponent
                    label={"Failed Build Only"}
                    type={"checkbox"}
                    id={"checkbox_01"}
                    nameField={"failed_only"}
                    defaultChecked={selectedDropdown.failedOnly}
                    onClick={failedOnlyChange}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <CheckboxComponent
                    label={"My Builds"}
                    type={"checkbox"}
                    id={"checkbox_02"}
                    nameField={"by_requestor_id"}
                    defaultChecked={selectedDropdown.by_requestor_id}
                    onClick={requesterIdChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <input
                    className="btn btn-primary mt-3 pull-right"
                    type="submit"
                    value="Search"
                    onClick={onSubmit}
                  />
                  {/* disabled={!props.isValidform} */}
                </div>
              </div>
            </div>
          )}
        </Card.Body>
      </Card>

      <Card className="bg-white custom-card mt-3 mb-3 ">
        {/* custom-card-full */}
        <Card.Body>
          <div className="table-responsive table-custom">
            <table className="table table-striped data-table">
              <thead>
                <tr className="table-primary">
                  <th>App Build ID</th>
                  <th>Name</th>
                  <th>Submitted On</th>
                  <th>No. of Test Builds</th>
                  <th>Status / Result</th>
                </tr>
              </thead>
              <tbody>
                {appBuilds && appBuilds.length > 0 ? (
                  appBuilds.map((appone, index) => (
                    <tr key={index}>
                      <td>
                        <a
                          onClick={() => navigateToDetails(appone.id, "manage")}
                          className="no-link click-able"
                        >
                          {appone.id}
                        </a>
                      </td>
                      <td> {appone.name} </td>
                      <td>{appone.submitted}</td>
                      <td>{appone.test_build_count}</td>
                      <td className="align-middle">
                        <div className="d-flex result-capsule">
                          {appone?.builds_completed ===
                            CHOICES.BuildStatus.IN_PROGRESS && (
                            <BadgeComp
                              badgePosition={"left"}
                              badgeType={"bg-warning text-dark left"}
                              label={CHOICES.BuildStatus.CHOICE_MAP.InProgress}
                            />
                          )}
                          {appone?.builds_completed ===
                            CHOICES.BuildStatus.ERROR && (
                            <BadgeComp
                              badgePosition={"left"}
                              badgeType={"bg-danger left"}
                              label={CHOICES.BuildStatus.CHOICE_MAP.Error}
                            />
                          )}
                          {appone?.builds_completed ===
                            CHOICES.BuildStatus.REQUESTED && (
                            <BadgeComp
                              badgePosition={"left"}
                              badgeType={"bg-info text-dark left"}
                              label={CHOICES.BuildStatus.CHOICE_MAP.Requested}
                            />
                          )}
                          {appone?.builds_completed ===
                            CHOICES.BuildStatus.SUCCEEDED && (
                            <BadgeComp
                              badgePosition={"left"}
                              badgeType={"bg-success left"}
                              label={CHOICES.BuildStatus.CHOICE_MAP.Succeeded}
                            />
                          )}
                          {appone?.builds_completed !==
                            CHOICES.BuildStatus.IN_PROGRESS &&
                            appone?.builds_completed !==
                              CHOICES.BuildStatus.ERROR &&
                            appone?.builds_completed !==
                              CHOICES.BuildStatus.REQUESTED &&
                            appone?.builds_completed !==
                              CHOICES.BuildStatus.SUCCEEDED && (
                              <BadgeComp
                                badgePosition={"left"}
                                badgeType={"bg-warning text-dark left"}
                                label={"Unknown"}
                              />
                            )}

                          {appone?.builds_successful ===
                            CHOICES.BuildResult.FAILURE && (
                            <BadgeComp
                              badgePosition={"left"}
                              badgeType={"bg-danger right"}
                              label={CHOICES.BuildResult.CHOICE_MAP.Failure}
                            />
                          )}
                          {appone?.builds_successful ===
                            CHOICES.BuildResult.SUCCESS && (
                            <BadgeComp
                              badgePosition={"left"}
                              badgeType={"bg-success right"}
                              label={CHOICES.BuildResult.CHOICE_MAP.Success}
                            />
                          )}
                          {appone?.builds_successful ===
                            CHOICES.BuildResult.UNKNOWN && (
                            <BadgeComp
                              badgePosition={"left"}
                              badgeType={"bg-secondary right"}
                              label={CHOICES.BuildResult.CHOICE_MAP.Unknown}
                            />
                          )}
                          {appone?.builds_successful !==
                            CHOICES.BuildResult.FAILURE &&
                            appone?.builds_successful !==
                              CHOICES.BuildResult.SUCCESS &&
                            appone?.builds_successful !==
                              CHOICES.BuildResult.UNKNOWN && (
                              <BadgeComp
                                badgePosition={"left"}
                                badgeType={"bg-secondary right"}
                                label={CHOICES.BuildResult.CHOICE_MAP.Unknown}
                              />
                            )}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : error.timeExceedError ? (
                  <tr>
                    <td colSpan={10} className="text-center text-danger ">
                      {ERROR_MESSAGES.timeExceedError}
                    </td>
                  </tr>
                ) : error.faildToLoad ? (
                  <tr>
                    <td colSpan={10} className="text-center text-danger ">
                      {ERROR_MESSAGES.failedLoadRecordsError}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td colSpan={10} className="text-center">
                      {ERROR_MESSAGES.noRecordsFound}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {appBuilds && (
              <div className="d-flex justify-content-end pt-3 pb-1">
                {selectedDropdown.offset < 1 ? (
                  <button className="btn btn-primary btn-sm" disabled>
                    <i className="fa fa-chevron-left" aria-hidden="true"></i>
                  </button>
                ) : (
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={() => resetPage("prev")}
                  >
                    <i className="fa fa-chevron-left" aria-hidden="true"></i>
                  </button>
                )}
                {appBuilds && appBuilds.length > 0 ? (
                  <button
                    className="btn btn-primary btn-sm ms-2"
                    onClick={() => resetPage("next")}
                  >
                    <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  </button>
                ) : (
                  <button className="btn btn-primary btn-sm ms-2" disabled>
                    <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  </button>
                )}
              </div>
            )}
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Builds;
