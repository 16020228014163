import api from "./api";

export const buildsApplicationService = {
  getAllApplication,
  getApplicationDetails,
};


function getAllApplication(apiKey: any) {
    return api.get(`api/products/${apiKey}/applications`);
}
  
function getApplicationDetails(appkey: any) {
    return api.get(`api/applications/${appkey}`);
    // return fetch(`${process.env.API_URL}api/applications/${appkey}`, requestOptions).then(handleResponse);
}
  