import api from "./api";

export const executionEngineService = {
  getAllExecutionEngine,
  getExecutionEngineDetail,
  createExecutionEngine,
  updateExecutionEngine,
  deleteExecutionEngine,
};

// master Test Engine List
function getAllExecutionEngine(limit = 100, offset = 0) {
  // 'https://api.kltaf.frizbane.io/api/applications/gfdgdfgfdg/test-engines?limit=100&offset=0'
  let limitParam = limit ? "?limit=" + limit : "";
  let offsetParam = offset
    ? "&offset=" + offset
    : offset == 0
    ? "&offset=0"
    : "";
  return api.get(`api/execution-engine${limitParam}${offsetParam}`);
}

function getExecutionEngineDetail(engine_key: any) {
  // 'https://api.kltaf.frizbane.io/api/execution-engine/WebAppBehaviourRemoteSauceLabs
  return api.get(`api/execution-engine/${engine_key}`);
}

function createExecutionEngine(param: any) {
  // 'https://api.kltaf.frizbane.io/api/applications/gfdgdfgfdg/test-engine'
  return api.post(`api/execution-engine`, param).then((response) => {
    return response;
  });
}

function updateExecutionEngine(engine_key: any, res: any) {
  // 'https://api.kltaf.frizbane.io/api/test-engine/656667e3-2285-4b33-8bb1-0c78f600bc15'
  return api.put(`api/execution-engine/${engine_key}`, res).then((response) => {
    return response;
  });
}

function deleteExecutionEngine(id: any) {
  // https://api.kltaf.frizbane.io/api/execution-engine/fdsfdsf
  return api.delete(`api/execution-engine/${id}`);
}
