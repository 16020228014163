import { Link, useLocation, navigate } from "@reach/router";
import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { userService } from "../../_services/user.service";
import Loader from "../../widgets/loader/Loader";
import UsersSidebar from "./UsersSidebar";
import UsersForm from "./UsersForm";
import { FETCH_LIMIT } from "../../constants/app-constants";
import { toast } from "react-toastify";
import Inputcomp from "../../widgets/Form/input/Input";
import CHOICES from "../../_helpers/CHOICES";
import SelectInputChoises from "../../widgets/Form/Select/SelectInputChoises";
import { ERROR_MESSAGES } from "../../constants/app-constants";

const FeaturesNew = (props) => {
  const search = useLocation().search;
  const location = useLocation();
  const q_FromUrl = new URLSearchParams(search).get("q");
  const kind_FromUrl = new URLSearchParams(search).get("kind");
  const [loadMore, setLoadMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [userList, setUserList] = useState(null);
  const [isEdit, setIsEdit] = useState(true);

  const [selectedValue, setSelectedValue] = useState({
    q: q_FromUrl ? q_FromUrl : "",
    kind: kind_FromUrl ? kind_FromUrl : "",
    showFilter: false,
    limit: FETCH_LIMIT,
    offset: 0,
    pageNumber: 0,
  });

  const [errorSide, setErrorSide] = useState({
    isError: false,
    errMsg: "",
  });

  const [isLoadingSidebar, setIsLoadingSidebar] = useState(false);

  React.useEffect(() => {
    !q_FromUrl && !kind_FromUrl ? getAllUsers() : "";
  }, []);

  React.useEffect(() => {
    setSelectedValue({
      ...selectedValue,
      q: q_FromUrl ? q_FromUrl : "",
      kind: kind_FromUrl ? kind_FromUrl : "",
    });
    getAllUsers();
  }, [location]);

  function onSubmit() {
    let q = selectedValue.q ? `?q=${selectedValue.q}` : "";
    let kind = "";
    if (q) {
      kind = selectedValue.kind ? `&kind=${selectedValue.kind}` : "";
    } else {
      kind = selectedValue.kind ? `?kind=${selectedValue.kind}` : "";
    }
    navigate(`/app/users/new${q}${kind}`);
  }

  function getAllUsers() {
    // setLoading(true)
    setIsLoadingSidebar(true);
    userService.getAllUser(selectedValue).then((resp) => {
      // //
      if (resp.status == 200 && resp?.data) {
        setUserList(resp.data);
        if (resp?.data.length > 0) {
          setLoadMore(true);
        } else {
          setLoadMore(false);
        }
        // setLoading(false)
        setIsLoadingSidebar(false);
        setErrorSide({
          ...errorSide,
          isError: false,
          errMsg: "",
        });
      } else if (resp?.status == 408) {
        setErrorSide({
          ...errorSide,
          isError: true,
          errMsg: ERROR_MESSAGES.timeExceedError,
        });
        //   setLoading(false);
        setIsLoadingSidebar(false);
      } else {
        // setLoading(false)
        setIsLoadingSidebar(false);
        setErrorSide({
          ...errorSide,
          isError: true,
          errMsg: ERROR_MESSAGES.failedLoadRecordsError,
        });
      }
    });
  }

  function handelLoading(event) {
    setLoading(event);
  }

  function fetchMoreData(e) {
    if (e) {
      let pgNo = selectedValue.pageNumber + 1;
      let offset = pgNo * selectedValue.limit;

      setSelectedValue({ ...selectedValue, pageNumber: pgNo });

      let appReq = {
        q: selectedValue.q,
        kind: selectedValue.kind,
        limit: selectedValue.limit,
        offset: offset,
      };

      userService.getAllUser(appReq).then((resp) => {
        if (resp.status == 200 && resp?.data) {
          if (resp?.data.length > 0) {
            let newlist = userList.concat(resp?.data);
            setLoadMore(true);
            setUserList(newlist);
          } else {
            setLoadMore(false);
          }
        }
      });
    }
  }

  function toggleOpen() {
    setSelectedValue({
      ...selectedValue,
      showFilter: !selectedValue.showFilter,
    });
  }

  function handleChangeForm(event) {
    ////
    Object.keys(event).forEach(function (key) {
      setSelectedValue({ ...selectedValue, [key]: event[key] });
    });
  }

  function handleChangeFormSelect(event) {
    Object.keys(event).forEach(function (key) {
      setSelectedValue({ ...selectedValue, [key]: event[key] });
    });
  }

  return (
    <div className="containWrap-inner">
      {loading ? <Loader /> : ""}
      <div className="col-12">
        <div className="row">
          <div className="col-4">
            <Card className="bg-white custom-card list-option h-100">
              <Card.Header className="shadow-none p-3">
                <div className="d-flex align-items-center justify-content-between">
                  <Card.Title>
                    <h4 className="m-0">Users</h4>
                  </Card.Title>
                  <div className="text-end my-2">
                    <i
                      className="fa fa-filter"
                      aria-hidden="true"
                      onClick={toggleOpen}
                    ></i>
                  </div>
                </div>
              </Card.Header>
              <Card.Body>
                <div className="h-100  d-flex flex-column">
                  <div
                    className={
                      selectedValue.showFilter
                        ? "d-block mb-3 bg-d-gray p-2"
                        : "d-none"
                    }
                  >
                    <div className="">
                      <div className="mt-2">
                        <Inputcomp
                          type="text"
                          nameClass="form-control custom-form-lh2"
                          name="q"
                          handleChange={true}
                          isDisabled={false}
                          onChangeInput={handleChangeForm}
                          label="Search by username/ name/ email"
                          isRequired={false}
                          placeholder={"Search by username, name or email"}
                          value={selectedValue.q}
                          onKeyPress={onSubmit}
                        />
                      </div>

                      <div className="mt-2">
                        <SelectInputChoises
                          label="User Role"
                          onChangeInput={handleChangeFormSelect}
                          name="kind"
                          optionArray={CHOICES?.UserKind?.CHOICE_LIST}
                          nameClass="form-control custom-form-lh2 custom-select"
                          selectedOption={selectedValue?.kind}
                        />
                      </div>

                      <div className="d-flex justify-content-end mt-3">
                        <input
                          className="btn btn-primary"
                          type="submit"
                          value="Search"
                          onClick={onSubmit}
                        />
                      </div>
                    </div>
                  </div>
                  {isLoadingSidebar ? (
                    <div className="text-center"> Please Wait....</div>
                  ) : errorSide.isError ? (
                    <div className="text-center text-danger">
                      {" "}
                      {errorSide.errMsg}
                    </div>
                  ) : (
                    <UsersSidebar
                      sidedata={userList}
                      getMoreData={fetchMoreData}
                      loadFeature={loadMore}
                    />
                  )}
                </div>
              </Card.Body>
            </Card>
          </div>
          <div className="col-8 ps-0">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between">
                <Card className="bg-white custom-card custom-card-full">
                  <Card.Header className="shadow-none p-3">
                    <div className="d-flex align-items-center justify-content-between">
                      <Card.Title className="m-0">
                        <h4 className="m-0">New User</h4>
                      </Card.Title>
                      <Link className="no-link" to={`/app/users`}>
                        <i className="fa fa-times" aria-hidden="true"></i>
                      </Link>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <UsersForm
                      isNew={isEdit}
                      isLoading={handelLoading}
                      isEditForm={false}
                      loadmore={loadMore}
                    />
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturesNew;
