import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import InputWithIcon from "../../widgets/Form/input/Input-Icon";
import { featureService } from "../../_services/feature.service";
import { toast } from "react-toastify";
import "./style.scss";

const FeatureSearchDialog = (props) => {
  const [searchString, setSearchString] = useState("");
  const [feature, setFeature] = useState({
    featureList: null,
    selectedFeatures: null,
  });
  const [isLoading, setIsLoading] = useState(false);

  //

  function getInputChange(event) {
    Object.keys(event).forEach(function (key) {
      setSearchString(event[key]);
    });
  }

  function fetchFeature() {
    setIsLoading(true);
    if (!searchString) {
      setIsLoading(false);
      return false;
    }
    // //
    let req = {
      q: searchString,
      limit: 100,
    };

    featureService.getAllFeatures(req).then((feature) => {
      //
      if (feature.status == 200 && feature?.data) {
        const dataList = feature?.data?.map((d) => ({
          id: d.id,
          name: d.name,
          value: d.value,
          isChecked: false,
        }));

        props?.selected_flist &&
          props?.selected_flist.forEach((element) => {
            dataList.forEach((elementInner, index) => {
              if (elementInner.id === element) {
                dataList[index].isChecked = true;
              }
            });
          });

        setFeature({
          ...feature,
          featureList: dataList,
          selectedFeatures: null,
        });
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    });
  }

  // function getSelectedFeature(idx, f) {
  //     let flist = featureSelected;
  //     flist.push(f)
  //     setFeatureSelected(flist);

  //     feature[idx].isSelected = true
  //     //
  //     setFeature(feature)
  // }

  const addFeature = () => {
    if (feature?.selectedFeatures.length > 0) {
      props?.addSelectedFeature(feature?.selectedFeatures);
    } else {
      toast.error("Please select at least one feature to add in list.");
    }
  };

  function handleCheckChange(event) {
    var value = event.target.value;
    feature.featureList &&
      feature?.featureList.forEach((element, index) => {
        if (element?.id == value) {
          feature.featureList[index].isChecked = event.target.checked;
        }
      });
    //
    let k = feature?.featureList.filter((x) => x.isChecked);
    setFeature({
      ...feature,
      featureList: feature?.featureList,
      selectedFeatures: k ? k : null,
    });
    // feature?.selectedFeatures
  }

  return (
    <div className="popup-fixed">
      <div className="popup_inner">
        <Card className="bg-white custom-card" style={{ border: 0 }}>
          <Card.Header className="bg-gray">
            <div className="row">
              <div className="col-12">
                <Card.Title className="card-title fs-22">
                  {props.title}
                </Card.Title>
              </div>
            </div>
          </Card.Header>
          <Card.Body className="min83vh">
            <div className="row">
              <div className="col-12 col-md-12">
                <InputWithIcon
                  name={"searchString"}
                  nameClass="form-control custom-form-lh2 nosubmit"
                  type={"search"}
                  placeholder={"Search feature name or value"}
                  onKeyPress={fetchFeature}
                  onChangeInput={getInputChange}
                  btnName={"SEARCH"}
                  ipWithButton={"searchInput"}
                  getResult={fetchFeature}
                  isDisabledButton={isLoading}
                />
              </div>
              {feature ? (
                <div className="col-12 mt-3">
                  <div className="table-responsive mt-2 tb-height-69vh">
                    <table className="table table-bordered data-table data-table-vertically-center">
                      <tbody>
                        {feature &&
                          feature?.featureList &&
                          feature?.featureList.map((item, index) => (
                            <tr id={"tr_" + index} key={index}>
                              <td className="text-center">
                                <input
                                  type="checkbox"
                                  value={item.id}
                                  name={"check_" + index}
                                  onChange={handleCheckChange}
                                  checked={item.isChecked}
                                  disabled={false}
                                  className="checklist"
                                />
                              </td>

                              <td>
                                <div className="feature-value">
                                  <span className="fs-14 fw-500">
                                    {" "}
                                    {item.value}
                                  </span>
                                  <span className="fs-12"> {item.name}</span>
                                </div>
                              </td>
                            </tr>
                          ))}
                        {feature?.featureList &&
                          feature?.featureList.length < 1 && (
                            <tr>
                              <td colSpan={4} className="text-center">
                                <div className="p-2">No Feature To Display</div>
                              </td>
                            </tr>
                          )}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : (
                <div className="col-12 mt-3">
                  <div className="text-center">
                    Please search by user or email to get user list
                  </div>
                </div>
              )}
            </div>
          </Card.Body>
          <Card.Footer className="bg-white border-0 py-3">
            <div className="d-flex justify-content-end align-items-center">
              <button
                className="btn btn-secondary me-3"
                onClick={props?.closePopup}
              >
                Cancel
              </button>
              <button className="btn btn-primary" onClick={addFeature}>
                Done
              </button>
            </div>
          </Card.Footer>
        </Card>
      </div>
    </div>
  );
};

export default FeatureSearchDialog;
