export const tabItems = [
  {
    label: "Details",
    tabindex: 0,
  },
  {
    label: "Test Engines",
    tabindex: 1,
  },
];
