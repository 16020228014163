import React from "react";
import "./styleBadge.scss";

import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

// import { Badge } from "react-bootstrap";

const BadgeComp = (props) => {
  function getData(event) {
    props?.onClick(event.target.value);
  }
  return (
    <OverlayTrigger
      delay={{ hide: 450, show: 300 }}
      overlay={(props) => (
        <Tooltip {...props}>Build Status : Build Result</Tooltip>
      )}
      placement={props?.badgePosition}
    >
      <span className={"badge " + `${props?.badgeType}`}>{props?.label}</span>
    </OverlayTrigger>
  );
};

export default BadgeComp;
