import React from "react";
// import PropTypes from "prop-types"
import { view } from "./view.module.css";

const View = ({ children }) => <section className={view}>{children}</section>;

// View.propTypes = {
//   title: PropTypes.string.isRequired,
// }

export default View;
