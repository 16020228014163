import React from "react";
import "./styleAudit.scss";

const AuditLog = (props) => {
  return (
    <div>
      {(props?.logData?.created_by || props?.logData?.created_at) && (
        <p className="fs-10 fs-i text-secondary p-0 m-0">
          Created{" "}
          {props?.logData?.created_by ? "by " + props?.logData?.created_by : ""}{" "}
          on{" "}
          {props?.logData?.created_at
            ? new Intl.DateTimeFormat('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
              }).format(new Date(props?.logData?.created_at))
            : "-"}
        </p>
      )}
      {(props?.logData?.updated_by || props?.logData?.updated_at) && (
        <p className="fs-10 fs-i text-secondary p-0 m-0">
          Updated{" "}
          {props?.logData?.updated_by ? "by " + props?.logData?.updated_by : ""}{" "}
          on{" "}
          {props?.logData?.updated_at
            ? new Intl.DateTimeFormat('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                timeZoneName: 'short',
              }).format(new Date(props?.logData?.updated_at))
            : "-"}
        </p>
      )}
    </div>
  );  
};

export default AuditLog;
