import { Link, useLocation, navigate } from "@reach/router";
import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { productService } from "../../_services/products.service";
import Loader from "../../widgets/loader/Loader";
import ErrorPopup from "../../widgets/Popups/Error-Popup";
import ConfirmationPopup from "../../widgets/Popups/Confirmation-Popup";
import { PAGINATION_FETCH_LIMIT } from "../../constants/app-constants";
import { AuthService } from "../../_services/auth.service";
import Tooltip from "../../widgets/Tooltip/Tooltip";
import { toast } from "react-toastify";
import {
  SUCCESS_MESSAGES,
  CONTENT_MESSAGES,
  ERROR_MESSAGES,
} from "../../constants/app-constants";

const Products = () => {
  const accessModule = AuthService.getAllAccessList("products");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    errorStatus: false,
    errorData: "",
    faildToLoad: false,
    timeExceedError: false,
  });
  const [productList, setProductList] = useState(null);
  const [page, setPage] = useState({
    limit: PAGINATION_FETCH_LIMIT,
    offset: 0,
    pageNumber: 0,
  });
  const [confirmData, setConfirmData] = useState({
    title: "",
    message: "",
    closeLabel: "",
    okLabel: "",
    isShow: false,
    selectedId: "",
  });

  function closeError() {
    setError({ ...error, errorStatus: false });
    window.localStorage.removeItem("localUser");
    navigate(`/app/login`);
  }

  function getProduct() {
    setLoading(true);
    productService.getAllProducts(page.limit, page.offset).then((products) => {
      if (products.status == 200 && products?.data) {
        const options = products?.data.map((d) => ({
          id: d.id,
          prod_key: d.prod_key,
          name: d.name,
          owner: d.owner?.first_name + " " + d.owner?.last_name,
          enabled: d.enabled,
        }));
        setLoading(false);
        setProductList(options);
        setError({
          ...error,
          faildToLoad: false,
          timeExceedError: false,
        });
      } else if (products?.status == 408) {
        setError({
          ...error,
          timeExceedError: true,
        });
        setLoading(false);
      } else {
        setLoading(false);
        setError({
          ...error,
          faildToLoad: true,
        });
      }
    });
  }

  function deleteProduct(id) {
    // //
    setConfirmData({
      ...confirmData,
      title: "Alert",
      message: ERROR_MESSAGES.reConformMessage,
      closeLabel: "Cancel",
      okLabel: "Confirm",
      isShow: true,
      selectedId: id,
    });
  }

  React.useEffect(() => {
    getProduct();
  }, [page.offset]);

  function resetPage(param) {
    //
    if (param === "next") {
      let pgNo = page.pageNumber + 1;
      let offset = pgNo * page.limit;

      setPage({ ...page, offset: offset, pageNumber: pgNo });
    } else {
      if (page.pageNumber > 0) {
        let pgNo = page.pageNumber - 1;
        let offset = pgNo * page.limit;
        setPage({ ...page, offset: offset, pageNumber: pgNo });
      }
    }
  }

  function closeConfirm() {
    setConfirmData({
      ...confirmData,
      isShow: false,
      selectedId: "",
    });
  }

  function deleteConfirm(id) {
    closeConfirm();
    setLoading(true);
    productService.deleteProduct(id).then((resp) => {
      if (resp.status == 200 && resp?.data) {
        toast.success(SUCCESS_MESSAGES.delete);
        getProduct();
        setLoading(false);
      } else {
        if (resp?.status == 408) {
          toast.error(ERROR_MESSAGES.timeExceedError);
        }
        if (resp?.status === 422) {
          toast.error(resp?.data?.detail[0].msg);
        } else {
          toast.error(
            resp?.data?.detail ? resp?.data?.detail : resp?.data?.error
          );
        }
        setLoading(false);
      }
    });
  }

  return (
    <div className="containWrap-inner">
      {loading ? <Loader /> : ""}
      {error.errorStatus ? (
        <ErrorPopup data={error.errorData} closePopup={closeError} />
      ) : (
        ""
      )}
      {confirmData.isShow ? (
        <ConfirmationPopup
          data={confirmData}
          confirmCloseEvent={closeConfirm}
          confirmOkEvent={() => deleteConfirm(confirmData?.selectedId)}
        />
      ) : (
        ""
      )}

      <Card className="bg-white custom-card mb-3">
        <Card.Body>
          <div className="row mb-3">
            <div className="col-12 col-md-6">
              <Card.Title>
                <h4 className="m-0">Product List</h4>
              </Card.Title>
              <Card.Text>{CONTENT_MESSAGES.productListTitalDesc}</Card.Text>
            </div>

            <div className="col-12 col-md-6  my-auto text-end">
              <i
                aria-hidden="true"
                data-tip
                data-for={!accessModule.add && "addTip"}
              >
                <button
                  disabled={!accessModule.add}
                  onClick={() => navigate("/app/products/new")}
                  className="btn btn-sm btn-primary"
                >
                  Add Product
                </button>
              </i>
              {/* {!accessModule.add && <i className="fa fa-info-circle ms-2" aria-hidden="true" data-tip data-for="addTip"></i>} */}
              <Tooltip
                id="addTip"
                place="left"
                effect="solid"
                msgType="operation"
              />
            </div>
          </div>

          <div className="table-responsive mb-3 custom-card-full">
            <table className="table data-table data-table-vertically-center  ">
              {/*  table-hover  data-table */}
              <thead>
                <tr className="table-primary">
                  <th>Key</th>
                  <th>Name</th>
                  <th>Owner</th>
                  {/* <th>Enabled</th> */}
                  <th className="text-center">
                    {/* {!accessModule.delete && <i className="fa fa-info-circle" aria-hidden="true" data-tip data-for="deleteTip"></i>} */}
                    {/* <Tooltip id="deleteTip" place="left" effect="solid" msgType="operation" /> */}
                  </th>
                </tr>
              </thead>

              <tbody>
                {productList && productList.length > 0 ? (
                  productList.map((product) => (
                    <tr
                      key={product.id}
                      className={`${product.enabled ? "" : "row-disabled"}`}
                    >
                      <td>
                        <Link
                          to={"/app/products/" + product.prod_key}
                          className="no-link click-able"
                        >
                          {product.prod_key}
                        </Link>
                      </td>
                      <td> {product.name}</td>
                      <td>{product.owner}</td>

                      <td className="text-center actions">
                        <i
                          aria-hidden="true"
                          data-tip
                          data-for={!accessModule.delete && "deleteTip"}
                        >
                          <button
                            disabled={!accessModule.delete}
                            onClick={() => deleteProduct(product.prod_key)}
                            className="btn link text-danger"
                          >
                            <i className="fa fa-trash" aria-hidden="true"></i>
                          </button>
                        </i>
                        <Tooltip
                          id="deleteTip"
                          place="left"
                          effect="solid"
                          msgType="operation"
                        />
                      </td>
                    </tr>
                  ))
                ) : error.timeExceedError ? (
                  <tr>
                    <td colSpan={10} className="text-center text-danger ">
                      {ERROR_MESSAGES.timeExceedError}
                    </td>
                  </tr>
                ) : error.faildToLoad ? (
                  <tr>
                    <td colSpan={10} className="text-center text-danger ">
                      {ERROR_MESSAGES.failedLoadRecordsError}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td colSpan={10} className="text-center">
                      {ERROR_MESSAGES.noRecordsFound}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            {productList && (
              <div className="d-flex justify-content-end pt-3 pb-1">
                {page.offset < 1 ? (
                  <button className="btn btn-primary btn-sm" disabled>
                    <i className="fa fa-chevron-left" aria-hidden="true"></i>
                  </button>
                ) : (
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={() => resetPage("prev")}
                  >
                    <i className="fa fa-chevron-left" aria-hidden="true"></i>
                  </button>
                )}

                {productList && productList.length > 0 ? (
                  <button
                    className="btn btn-primary btn-sm ms-2"
                    onClick={() => resetPage("next")}
                  >
                    <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  </button>
                ) : (
                  <button className="btn btn-primary btn-sm ms-2" disabled>
                    <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  </button>
                )}
              </div>
            )}
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Products;
