import React from "react";
import "./style.scss";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link, useLocation, navigate } from "@reach/router";
import BadgeComp from "../../widgets/Badges/BadgeComp";
import CHOICES from "../../_helpers/CHOICES";

import { ERROR_MESSAGES } from "../../constants/app-constants";
const BuildsSidebar = (props) => {
  const search = useLocation().search;
  const appFromUrl = new URLSearchParams(search).get("app");
  const failed_builds_only_FromUrl = new URLSearchParams(search).get(
    "failed_builds_only"
  );
  const my_builds_only_FromUrl = new URLSearchParams(search).get(
    "my_builds_only"
  );
  const q_FromUrl = new URLSearchParams(search).get("q");

  let app = `app=${appFromUrl}`;
  let failed_builds_only = `&failed_builds_only=${failed_builds_only_FromUrl}`;
  let my_builds_only = `&my_builds_only=${my_builds_only_FromUrl}`;
  let q = q_FromUrl ? `&q=${q_FromUrl}` : "";

  function fetchMoreData() {
    props?.getMoreData(true);
  }

  return (
    <div className="mngScroll_outer">
      {props?.sidedata ? (
        props?.sidedata.length < 1 ? (
          <div className="text-center text-danger">
            {ERROR_MESSAGES.noRecordsFound}
          </div>
        ) : (
          <div id="scrollableDiv" className="app-build-list mngScroll_Content">
            <InfiniteScroll
              dataLength={props?.sidedata.length}
              next={fetchMoreData}
              hasMore={props?.loadmore}
              loader={
                props?.sidedata.length < 1 ? (
                  <p className="fw-600 fs-14 text-center">
                    {ERROR_MESSAGES.noRecordsFound}
                  </p>
                ) : (
                  <h4>Loading...</h4>
                )
              }
              scrollableTarget="scrollableDiv"
            >
              {props?.sidedata &&
                props?.sidedata.map((appone, index) => (
                  <div className="scrollChild" key={index}>
                    <Link
                      to={`/app/builds/${appone.id}?${app}${failed_builds_only}${my_builds_only}${q}`}
                      className="no-link"
                    >
                      <div className="fw-600 fs-14 mb-1 click-able">
                        {" "}
                        {appone.name}{" "}
                      </div>
                      <div> {appone.id} </div>
                      <div className="pull-right result-capsule">
                        {appone?.builds_completed ===
                          CHOICES.BuildStatus.IN_PROGRESS && (
                          <BadgeComp
                            badgePosition={"top"}
                            badgeType={"bg-warning text-dark left"}
                            label={CHOICES.BuildStatus.CHOICE_MAP.InProgress}
                          />
                        )}
                        {appone?.builds_completed ===
                          CHOICES.BuildStatus.ERROR && (
                          <BadgeComp
                            badgePosition={"top"}
                            badgeType={"bg-danger left"}
                            label={CHOICES.BuildStatus.CHOICE_MAP.Error}
                          />
                        )}
                        {appone?.builds_completed ===
                          CHOICES.BuildStatus.REQUESTED && (
                          <BadgeComp
                            badgePosition={"top"}
                            badgeType={"bg-info text-dark left"}
                            label={CHOICES.BuildStatus.CHOICE_MAP.Requested}
                          />
                        )}
                        {appone?.builds_completed ===
                          CHOICES.BuildStatus.SUCCEEDED && (
                          <BadgeComp
                            badgePosition={"top"}
                            badgeType={"bg-success left"}
                            label={CHOICES.BuildStatus.CHOICE_MAP.Succeeded}
                          />
                        )}
                        {appone?.builds_completed !==
                          CHOICES.BuildStatus.IN_PROGRESS &&
                          appone?.builds_completed !==
                            CHOICES.BuildStatus.ERROR &&
                          appone?.builds_completed !==
                            CHOICES.BuildStatus.REQUESTED &&
                          appone?.builds_completed !==
                            CHOICES.BuildStatus.SUCCEEDED && (
                            <BadgeComp
                              badgePosition={"left"}
                              badgeType={"bg-warning text-dark left"}
                              label={"Unknown"}
                            />
                          )}

                        {appone?.builds_successful ===
                          CHOICES.BuildResult.FAILURE && (
                          <BadgeComp
                            badgePosition={"top"}
                            badgeType={"bg-danger right"}
                            label={CHOICES.BuildResult.CHOICE_MAP.Failure}
                          />
                        )}
                        {appone?.builds_successful ===
                          CHOICES.BuildResult.SUCCESS && (
                          <BadgeComp
                            badgePosition={"top"}
                            badgeType={"bg-success right"}
                            label={CHOICES.BuildResult.CHOICE_MAP.Success}
                          />
                        )}
                        {appone?.builds_successful ===
                          CHOICES.BuildResult.UNKNOWN && (
                          <BadgeComp
                            badgePosition={"top"}
                            badgeType={"bg-secondary right"}
                            label={CHOICES.BuildResult.CHOICE_MAP.Unknown}
                          />
                        )}
                        {appone?.builds_successful !==
                          CHOICES.BuildResult.FAILURE &&
                          appone?.builds_successful !==
                            CHOICES.BuildResult.SUCCESS &&
                          appone?.builds_successful !==
                            CHOICES.BuildResult.UNKNOWN && (
                            <BadgeComp
                              badgePosition={"left"}
                              badgeType={"bg-secondary right"}
                              label={CHOICES.BuildResult.CHOICE_MAP.Unknown}
                            />
                          )}
                      </div>

                      <div className="mt-1">
                        {" "}
                        {appone.user}, {appone.submitted}{" "}
                      </div>
                    </Link>
                  </div>
                ))}
            </InfiniteScroll>
          </div>
        )
      ) : (
        <div className="text-center text-danger">
          {" "}
          {ERROR_MESSAGES.noRecordsFound}
        </div>
      )}
    </div>
  );
};

export default BuildsSidebar;
