import React from "react";
import { Card } from "react-bootstrap";
import "./styleModel.scss";

const ConfirmationPopup = (props) => {
  const data = props?.data;

  return (
    <div className="popup-error">
      <div className="popup_inner">
        <Card className="bg-white custom-card" style={{ border: 0 }}>
          <Card.Header className="bg-gray">
            <div className="row">
              <div className="col-12">
                <Card.Title className="card-title fs-22">
                  {data.title}
                </Card.Title>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <div className="row">
              <div className="col-12">{data.message}</div>
              <div className="d-flex justify-content-end align-items-center mt-3">
                <div>
                  <button
                    className="btn btn-light me-2"
                    onClick={props?.confirmCloseEvent}
                  >
                    {data.closeLabel}
                  </button>
                </div>
                <div>
                  <button
                    className="btn btn-primary"
                    onClick={props?.confirmOkEvent}
                  >
                    {data.okLabel}
                  </button>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default ConfirmationPopup;
