import React from "react";
import "./styleCheck.scss";

const CheckboxComponent = (props) => {
  return (
    <label className={props?.labelClassName}>
      <input
        type={props?.type}
        id={props?.id}
        name={props?.nameField}
        value={props?.value}
        onChange={(event) => props?.onClick(event.target.checked)}
        className={"me-2"}
        checked={props?.defaultChecked ? true : false}
      />
      {props?.label}
    </label>
  );
};

export default CheckboxComponent;
