import React, { useState } from "react";
import "./style.scss";
import { userService } from "../../_services/user.service";
import Inputcomp from "../../widgets/Form/input/Input";
import { useLocation, navigate } from "@reach/router";
import { toast } from "react-toastify";
import ConfirmationPopup from "../../widgets/Popups/Confirmation-Popup";
import CHOICES from "../../_helpers/CHOICES";
import SelectInputChoises from "../../widgets/Form/Select/SelectInputChoises";
import {
  EMAIL_PATTERN,
  REQUIRED_ERROR,
  SUCCESS_MESSAGES,
  ERROR_MESSAGES,
} from "../../constants/app-constants";
import AuditLog from "../../widgets/AuditLog/AuditLog";

const UsersForm = (props) => {
  const emailPattern = EMAIL_PATTERN;
  const search = useLocation().search;
  const location = useLocation();
  const q_FromUrl = new URLSearchParams(search).get("q");
  const kind_FromUrl = new URLSearchParams(search).get("kind");
  const [passwordShown, setPasswordShown] = useState(false);

  const [userForm, setUserForm] = useState({
    id: "",
    username: "",
    first_name: "",
    last_name: "",
    email: "",
    is_active: true,
    kind: "",
    report_portal_id: 0,
    report_portal_user_id: "",
    password: "",
  });

  const [error, setError] = useState({
    errorStatus: false,
    errorData: "",
    isValid: false,
    username: "User Name Required",
    first_name: "First Name Required",
    last_name: "Last Name Required",
    email: "Email Required",
    kind: "Role Required",
    password: "Password Required",
    invalidEmail: false,
    invalidEmailError: "Invalid Email ID",
  });

  const [confirmData, setConfirmData] = useState({
    title: "",
    message: "",
    closeLabel: "",
    okLabel: "",
    isShow: false,
    selectedId: "",
  });

  React.useEffect(() => {
    let user = props?.userDetail;
    setUserForm({
      ...userForm,
      email: user?.email,
      first_name: user?.first_name,
      id: user?.id,
      is_active: user?.is_active ? true : false,
      kind: user?.kind,
      last_name: user?.last_name,
      report_portal_id: user?.report_portal_id ? user?.report_portal_id : 0,
      report_portal_user_id: user?.report_portal_user_id
        ? user?.report_portal_user_id
        : "",
      username: user?.username,
    });
  }, [props?.userDetail]);

  function handleChangeForm(event) {
    Object.keys(event).forEach(function (key) {
      if (
        key == "email" &&
        event["email"] != "" &&
        !emailPattern.test(event[key])
      ) {
        setError({ ...error, invalidEmail: true });
        setUserForm({ ...userForm, [key]: event[key] });
      } else {
        if (key == "email") {
          setError({ ...error, invalidEmail: false });
        }
        setUserForm({ ...userForm, [key]: event[key] });
      }
    });
  }

  function onSubmit(event) {
    props?.isLoading(true);
    if (!validateAddForm()) {
      toast.error(ERROR_MESSAGES.checkAllFieldsMessage);
      props?.isLoading(false);
      return false;
    }

    let req = {
      username: userForm?.username,
      first_name: userForm?.first_name,
      last_name: userForm?.last_name,
      email: userForm?.email,
      is_active: true,
      kind: userForm?.kind,
      password: userForm?.password,
    };

    userService.createUser(req).then((resp) => {
      if (resp.status == 200 && resp?.data) {
        props?.isLoading(false);
        let q = q_FromUrl ? `?q=${q_FromUrl}` : "";
        let kind = "";
        if (q) {
          kind = kind_FromUrl ? `&kind=${kind_FromUrl}` : "";
        } else {
          kind = kind_FromUrl ? `?kind=${kind_FromUrl}` : "";
        }
        toast.success(SUCCESS_MESSAGES.add);
        navigate(`/app/users/${resp?.data?.username}${q}${kind}`);
      } else if (resp?.status == 408) {
        toast.error(ERROR_MESSAGES.timeExceedError);
        props?.isLoading(false);
      } else {
        if (resp?.status === 422) {
          toast.error(resp?.data?.detail[0].msg);
        } else {
          toast.error(
            resp?.data?.detail ? resp?.data?.detail : resp?.data?.error
          );
        }
        props?.isLoading(false);
      }
    });
  }

  function onUpdate() {
    props?.isLoading(true);
    if (!validatUpdateForm()) {
      toast.error(ERROR_MESSAGES.checkAllFieldsMessage);
      props?.isLoading(false);
      return false;
    }
    let req = {
      first_name: userForm?.first_name,
      last_name: userForm?.last_name,
      email: userForm?.email,
      is_active: true,
      kind: userForm?.kind,
      password: userForm?.password,
    };

    userService.updateUser(props?.username, req).then((resp) => {
      if (resp.status == 200 && resp?.data) {
        props?.isLoading(false);
        props?.handelEdit(false);
        toast.success(SUCCESS_MESSAGES.update);
      } else if (resp?.status == 408) {
        toast.error(ERROR_MESSAGES.timeExceedError);
        props?.isLoading(false);
      } else {
        if (resp?.status === 422) {
          toast.error(resp?.data?.detail[0].msg);
        } else {
          toast.error(
            resp?.data?.detail ? resp?.data?.detail : resp?.data?.error
          );
        }
        props?.isLoading(false);
      }
    });
  }

  function validateAddForm() {
    if (
      userForm.first_name &&
      userForm.last_name &&
      userForm.email &&
      userForm.username &&
      userForm.kind &&
      userForm.password &&
      emailPattern.test(userForm.email)
    ) {
      setError({ ...error, isValid: false, invalidEmail: false });
      return true;
    } else if (
      userForm.first_name &&
      userForm.last_name &&
      userForm.email &&
      userForm.username &&
      userForm.kind &&
      userForm.password &&
      !emailPattern.test(userForm.email)
    ) {
      setError({ ...error, invalidEmail: true });
      return false;
    } else {
      setError({ ...error, isValid: true });
      return false;
    }
  }

  function validatUpdateForm() {
    if (
      userForm.first_name &&
      userForm.last_name &&
      userForm.email &&
      userForm.kind
    ) {
      setError({ ...error, isValid: false });
      return true;
    } else {
      setError({ ...error, isValid: true });
      return false;
    }
  }

  function onUpdateCancelConfirm() {
    setConfirmData({
      ...confirmData,
      title: "Alert",
      message: ERROR_MESSAGES.reConformFormNotSaveMessage,
      closeLabel: "Cancel",
      okLabel: "Confirm",
      isShow: true,
    });
  }

  function closeConfirm() {
    setConfirmData({
      ...confirmData,
      isShow: false,
    });
  }

  function onUpdateCancel() {
    closeConfirm();
    props?.handelEditWithoutChange(false);
  }

  function handleChangeFormSelect(event) {
    Object.keys(event).forEach(function (key) {
      setUserForm({ ...userForm, [key]: event[key] });
    });
  }

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  return (
    <>
      {confirmData.isShow ? (
        <ConfirmationPopup
          data={confirmData}
          confirmCloseEvent={closeConfirm}
          confirmOkEvent={() => onUpdateCancel()}
        />
      ) : (
        ""
      )}

      <form noValidate onSubmit={(e)=>e.preventDefault()}>
        <div className="row">
          <div className="col-6">
            <Inputcomp
              type="text"
              nameClass="form-control custom-form-lh2"
              name="first_name"
              handleChange={true}
              isDisabled={!props?.isNew}
              onChangeInput={handleChangeForm}
              label="First Name"
              isRequired={true}
              value={userForm.first_name}
            />
            {!userForm.first_name && error.isValid && (
              <label className="text-danger fs-12">{REQUIRED_ERROR}</label>
            )}
          </div>

          <div className="col-6">
            <Inputcomp
              type="text"
              nameClass="form-control custom-form-lh2"
              name="last_name"
              handleChange={true}
              isDisabled={!props?.isNew}
              onChangeInput={handleChangeForm}
              label="Last Name"
              isRequired={true}
              value={userForm.last_name}
            />
            {!userForm.last_name && error.isValid && (
              <label className="text-danger fs-12">{REQUIRED_ERROR}</label>
            )}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-6">
            <Inputcomp
              type="text"
              nameClass="form-control custom-form-lh2"
              name="username"
              handleChange={true}
              isDisabled={!props?.isNew || props?.isEditForm}
              onChangeInput={handleChangeForm}
              label="Username"
              isRequired={true}
              value={userForm.username}
            />
            {!userForm.username && error.isValid && (
              <label className="text-danger fs-12">{REQUIRED_ERROR}</label>
            )}
          </div>

          <div className="col-6">
            <Inputcomp
              type="email"
              nameClass="form-control custom-form-lh2"
              name="email"
              handleChange={true}
              isDisabled={!props?.isNew}
              onChangeInput={handleChangeForm}
              label="Email"
              isRequired={true}
              value={userForm.email}
            />
            {!userForm.email && error.isValid && !error.invalidEmail && (
              <label className="text-danger fs-12">{REQUIRED_ERROR}</label>
            )}
            {error.invalidEmail && (
              <label className="text-danger fs-12">
                {ERROR_MESSAGES.patternMatchErr}
              </label>
            )}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-6 ">
            <SelectInputChoises
              label="Role"
              onChangeInput={handleChangeFormSelect}
              name="kind"
              optionArray={CHOICES?.UserKind?.CHOICE_LIST}
              nameClass="form-control custom-form-lh2 custom-select"
              selectedOption={userForm?.kind}
              isDisabled={!props?.isNew}
              isRequired={true}
            />
            {!userForm.kind && error.isValid && (
              <label className="text-danger fs-12">{REQUIRED_ERROR}</label>
            )}
          </div>

          {(props?.isNew || (props?.isNew && props?.isEditForm)) && (
            <div className="col-6 ">
              <div className="ipwithimag">
                <Inputcomp
                  type={passwordShown ? "text" : "password"}
                  nameClass="form-control custom-form-lh2"
                  name="password"
                  handleChange={true}
                  isDisabled={!props?.isNew}
                  onChangeInput={handleChangeForm}
                  label="Password"
                  isRequired={true}
                  value={userForm.password}
                />
                <span onClick={togglePasswordVisiblity} className="imgPass">
                  {passwordShown ? (
                    <i className="fa fa-eye" aria-hidden="true"></i>
                  ) : (
                    <i className="fa fa-eye-slash" aria-hidden="true"></i>
                  )}
                </span>
              </div>
              {!userForm.password && error.isValid && (
                <label className="text-danger fs-12">{REQUIRED_ERROR}</label>
              )}
            </div>
          )}
        </div>

        <div className="row mt-3">
          {props?.isNew && !props?.isEditForm && (
            <div className="d-flex justify-content-end my-3">
              {/* <Link to={'/app/features'} className="btn btn-secondary me-3">Cancel</Link> */}
              <button className="btn btn-primary" onClick={onSubmit}>
                Submit
              </button>
            </div>
          )}
          {props?.isNew && props?.isEditForm && (
            <div className="d-flex justify-content-between my-3">
              <AuditLog logData={props?.userDetail} />
              <div>
                <button
                  className="btn btn-secondary  me-3"
                  onClick={onUpdateCancelConfirm}
                >
                  Cancel
                </button>
                <button className="btn btn-primary" onClick={onUpdate}>
                  Save
                </button>
              </div>
            </div>
          )}
        </div>
      </form>
    </>
  );
};
export default UsersForm;
