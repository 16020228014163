import { Link, useLocation, navigate } from "@reach/router";
import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { productService } from "../../_services/products.service";
import Loader from "../../widgets/loader/Loader";
import ErrorPopup from "../../widgets/Popups/Error-Popup";
import ProductSidebar from "./Product-Sidebar";
import ProductForm from "./ProductForm";
import { FETCH_LIMIT, ERROR_MESSAGES } from "../../constants/app-constants";
import { toast } from "react-toastify";

const ProductsNew = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    errorStatus: false,
    errorData: "",
  });
  const [productList, setProductList] = useState(null);
  const [page, setPage] = useState({
    limit: FETCH_LIMIT,
    offset: 0,
    pageNumber: 0,
  });
  const [isEdit, setIsEdit] = useState(true);

  function closeError() {
    setError({ ...error, errorStatus: false });
    window.localStorage.removeItem("localUser");
    navigate(`/app/login`);
  }

  const [errorSide, setErrorSide] = useState({
    isError: false,
    errMsg: "",
  });

  const [isLoadingSidebar, setIsLoadingSidebar] = useState(false);

  React.useEffect(() => {
    getProductList(page.limit, page.offset);
  }, []);

  function getProductList(limit, offset) {
    // setLoading(true)
    setIsLoadingSidebar(true);

    productService.getAllProducts(limit, offset).then((products) => {
      // //
      if (products.status == 200 && products?.data) {
        const options = products?.data.map((d) => ({
          id: d.id,
          prod_key: d.prod_key,
          name: d.name,
          owner: d.owner?.first_name + " " + d.owner?.last_name,
          enabled: d.enabled,
        }));
        setProductList(options);
        // setLoading(false)
        setIsLoadingSidebar(false);
        setErrorSide({
          ...errorSide,
          isError: false,
          errMsg: "",
        });
      } else if (products?.status == 408) {
        setErrorSide({
          ...errorSide,
          isError: true,
          errMsg: ERROR_MESSAGES.timeExceedError,
        });
        //   setLoading(false);
        setIsLoadingSidebar(false);
      } else {
        setErrorSide({
          ...errorSide,
          isError: true,
          errMsg: ERROR_MESSAGES.failedLoadRecordsError,
        });
        // setLoading(false)
        setIsLoadingSidebar(false);
        toast.error(
          products?.data?.detail
            ? products?.data?.detail
            : products?.data?.error
        );
      }
    });
  }

  function fetchMoreData(e) {
    // //
    if (e) {
      let pgNo = page.pageNumber + 1;
      let newOffset = pgNo * page.limit;
      setPage({ ...page, pageNumber: pgNo });
      getProductList(page.limit, newOffset);
    }
  }

  function handelLoading(event) {
    setLoading(event);
  }

  function editProductAfterEdit(event) {
    getProductList(page.limit, page.offset);
  }

  return (
    <div className="containWrap-inner">
      {loading ? <Loader /> : ""}
      {error.errorStatus ? (
        <ErrorPopup data={error.errorData} closePopup={closeError} />
      ) : (
        ""
      )}
      <div className="col-12">
        <div className="row">
          <div className="col-4">
            <Card className="bg-white custom-card list-option h-100">
              <Card.Header className="shadow-none p-3">
                <div className="d-flex align-items-center justify-content-between">
                  <Card.Title>
                    <h4 className="m-0">Products</h4>
                  </Card.Title>
                </div>
              </Card.Header>
              <Card.Body>
                {isLoadingSidebar ? (
                  <div className="text-center"> Please Wait....</div>
                ) : errorSide.isError ? (
                  <div className="text-center text-danger">
                    {" "}
                    {errorSide.errMsg}
                  </div>
                ) : (
                  <ProductSidebar
                    productList={productList}
                    getMoreData={fetchMoreData}
                  />
                )}
              </Card.Body>
            </Card>
          </div>

          <div className="col-8 ps-0">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between">
                <Card className="bg-white custom-card custom-card-full">
                  <Card.Header className="shadow-none p-3">
                    <div className="d-flex align-items-center justify-content-between">
                      <Card.Title className="m-0">
                        <h4 className="m-0">Add new product</h4>
                      </Card.Title>
                      <Link className="no-link" to={"/app/products"}>
                        <i className="fa fa-times" aria-hidden="true"></i>
                      </Link>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <ProductForm
                      isNew={isEdit}
                      isLoading={handelLoading}
                      isEditForm={false}
                    />
                    {/* handelEdit={editProductAfterEdit} */}
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsNew;
