import { Link, useLocation, navigate } from "@reach/router";
import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { featureService } from "../../_services/feature.service";
import Loader from "../../widgets/loader/Loader";
import FeaturesSidebar from "./FeaturesSidebar";
import FeaturesForm from "./FeaturesForm";
import { FETCH_LIMIT } from "../../constants/app-constants";
import { toast } from "react-toastify";
import Inputcomp from "../../widgets/Form/input/Input";
import { ERROR_MESSAGES } from "../../constants/app-constants";

const FeaturesNew = (props) => {
  const search = useLocation().search;
  const location = useLocation();
  const q_FromUrl = new URLSearchParams(search).get("q");

  const [loadMore, setLoadMore] = useState(true);

  const [loading, setLoading] = useState(false);
  const [featurelist, setFeaturesList] = useState(null);
  const [isEdit, setIsEdit] = useState(true);

  const [errorSide, setErrorSide] = useState({
    isError: false,
    errMsg: "",
  });

  const [isLoadingSidebar, setIsLoadingSidebar] = useState(false);

  const [selectedValue, setSelectedValue] = useState({
    q: q_FromUrl ? q_FromUrl : "",
    showFilter: false,
    limit: FETCH_LIMIT,
    offset: 0,
    pageNumber: 0,
  });

  React.useEffect(() => {
    getAllFeatureList();
  }, []);

  React.useEffect(() => {
    setSelectedValue({ ...selectedValue, q: q_FromUrl });
    getAllFeatureList();
  }, [q_FromUrl]);

  function onSubmit() {
    let q = selectedValue.q ? `?q=${selectedValue.q}` : "";
    navigate(`/app/features/new${q}`);
  }

  function getAllFeatureList() {
    // setLoading(true)
    setIsLoadingSidebar(true);
    featureService.getAllFeatures(selectedValue).then((options) => {
      if (options.status == 200 && options?.data) {
        setFeaturesList(options?.data);
        // setLoading(false)
        setIsLoadingSidebar(false);
      } else if (options?.status == 408) {
        setErrorSide({
          ...errorSide,
          isError: true,
          errMsg: ERROR_MESSAGES.timeExceedError,
        });
        //   setLoading(false);
        setIsLoadingSidebar(false);
      } else {
        setErrorSide({
          ...errorSide,
          isError: true,
          errMsg: ERROR_MESSAGES.failedLoadRecordsError,
        });
        // setLoading(false)
        setIsLoadingSidebar(false);
      }

      // else if(options?.status == 408){
      //     toast.error(ERROR_MESSAGES.timeExceedError);
      //     setLoading(false)
      // } else {
      //     setLoading(false)
      //     if (options?.status === 422) {
      //         toast.error(options?.data?.detail[0].msg);
      //     } else {
      //         toast.error(options?.data?.detail);
      //     }
      // }
    });
  }

  function handelLoading(event) {
    setLoading(event);
  }

  function fetchMoreData(e) {
    if (e) {
      let pgNo = selectedValue.pageNumber + 1;
      let offset = pgNo * selectedValue.limit;
      setSelectedValue({ ...selectedValue, pageNumber: pgNo });
      let appReq = {
        q: selectedValue.q,
        limit: selectedValue.limit,
        offset: offset,
      };
      featureService.getAllFeatures(appReq).then((applist) => {
        if (applist.status == 200 && applist?.data) {
          if (applist?.data.length > 0) {
            let newlist = featurelist.concat(applist?.data);
            setLoadMore(true);
            setFeaturesList(newlist);
          } else {
            setLoadMore(false);
          }
        }
      });
    }
  }

  function toggleOpen() {
    setSelectedValue({
      ...selectedValue,
      showFilter: !selectedValue.showFilter,
    });
  }

  function handleChangeForm(event) {
    ////
    Object.keys(event).forEach(function (key) {
      setSelectedValue({ ...selectedValue, [key]: event[key] });
    });
  }

  return (
    <div className="containWrap-inner">
      {loading ? <Loader /> : ""}

      <div className="col-12">
        <div className="row">
          <div className="col-4">
            <Card className="bg-white custom-card list-option h-100">
              <Card.Header className="shadow-none p-3">
                <div className="d-flex align-items-center justify-content-between">
                  <Card.Title>
                    <h4 className="m-0">Features</h4>
                  </Card.Title>
                  <div className="text-end my-2">
                    <i
                      className="fa fa-filter"
                      aria-hidden="true"
                      onClick={toggleOpen}
                    ></i>
                  </div>
                </div>
              </Card.Header>
              <Card.Body>
                <div className="h-100 h-100 d-flex flex-column">
                  <div
                    className={
                      selectedValue.showFilter
                        ? "d-block mb-3 bg-d-gray p-2"
                        : "d-none"
                    }
                  >
                    <div className="">
                      <div className="mt-2">
                        <Inputcomp
                          type="text"
                          nameClass="form-control custom-form-lh2"
                          name="q"
                          handleChange={true}
                          isDisabled={false}
                          onChangeInput={handleChangeForm}
                          label=""
                          isRequired={false}
                          placeholder={
                            "Search by feature name, key, description or  value"
                          }
                          value={selectedValue.q}
                          onKeyPress={onSubmit}
                        />
                      </div>

                      <div className="d-flex justify-content-end mt-3">
                        <input
                          className="btn btn-primary"
                          type="submit"
                          value="Search"
                          onClick={onSubmit}
                        />
                      </div>
                    </div>
                  </div>
                  {isLoadingSidebar ? (
                    <div className="text-center"> Please Wait ....</div>
                  ) : errorSide.isError ? (
                    <div className="text-center text-danger">
                      {" "}
                      {errorSide.errMsg}
                    </div>
                  ) : (
                    <FeaturesSidebar
                      sidedata={featurelist}
                      getMoreData={fetchMoreData}
                      loadFeature={loadMore}
                    />
                  )}
                </div>
              </Card.Body>
            </Card>
          </div>
          <div className="col-8 ps-0">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between">
                <Card className="bg-white custom-card custom-card-full">
                  <Card.Header className="shadow-none p-3">
                    <div className="d-flex align-items-center justify-content-between">
                      <Card.Title className="m-0">
                        <h4 className="m-0">Add Feature</h4>
                      </Card.Title>
                      <Link className="no-link" to={`/app/features}`}>
                        <i className="fa fa-times" aria-hidden="true"></i>
                      </Link>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <FeaturesForm
                      isNew={isEdit}
                      isLoading={handelLoading}
                      isEditForm={false}
                      loadmore={loadMore}
                    />
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturesNew;
