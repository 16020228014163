import api from "./api";

export const productsUserService = {
  getAllProductUser,
  createProductUser,
  updateProductUser,
  deleteProductUser,
  getProductUserDetail,
};

// product user

function getAllProductUser(prod_key: any, limit = 100, offset = 0) {
  // https://api.kltaf.frizbane.io/api/products/test-key-1/users?limit=100&offset=0
  let limitParam = limit ? "?limit=" + limit : "";
  let offsetParam = offset
    ? "&offset=" + offset
    : offset == 0
    ? "&offset=0"
    : "";
  return api.get(`api/products/${prod_key}/users${limitParam}${offsetParam}`);
}

function getProductUserDetail(id: any) {
  // api/product-users/ea54d392-7193-42be-bc9e-1a9dbd878f98
  return api.get(`api/product-users/${id}`);
}

function createProductUser(prod_key: any, param: any) {
  // https://api.kltaf.frizbane.io/api/products/test-key-1/users
  return api
    .post(`api/products/${prod_key}/users`, {
      user_id: param.id,
      role: param.role,
    })
    .then((response) => {
      return response;
    });
}

function updateProductUser(id: any, param: any) {
  // https://api.kltaf.frizbane.io/api/product-users/ea54d392-7193-42be-bc9e-1a9dbd878f98
  return api
    .post(`api/product-users/${id}`, {
      user_id: param.user_id,
      role: param.role,
    })
    .then((response) => {
      return response;
    });
}

function deleteProductUser(id: any) {
  // api/product-users/ea54d392-7193-42be-bc9e-1a9dbd878f98
  return api.delete(`api/product-users/${id}`);
}
