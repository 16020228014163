import React, { Component, useState } from "react";
import "./style.scss";

import { Link } from "@reach/router";

const TestEngineSidebar = (props) => {
  return (
    <div className="mngScroll_outer">
      {props?.testEngineList ? (
        <div className="app-build-list mngScroll_Content">
          {props?.testEngineList &&
            props?.testEngineList.map((app, index) => (
              <div className={`scrollChild`} key={index}>
                <Link
                  to={`/app/products/test-engines/${app.id}${
                    "?product=" + props?.prod_key
                  }${"&app=" + props?.app_key}`}
                  className="no-link"
                >
                  <span className="fw-600 fs-16 mb-1 click-able">
                    {" "}
                    {app.core_engine}{" "}
                  </span>{" "}
                  <br />
                  <span className="fs-16 mb-1"> {app.test_type} </span> <br />
                  <span className="fs-14 mb-1"> {app.execution} </span>
                </Link>
              </div>
            ))}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default TestEngineSidebar;
