import api from "./api";

export const coreEngineService = {
  getAllCoreEngine,
  getCoreEngineDetail,
  createCoreEngine,
  updateCoreEngine,
  deleteCoreEngine,
};

// master Test Engine List
function getAllCoreEngine(limit = 100, offset = 0) {
  // 'https://api.kltaf.frizbane.io/api/applications/gfdgdfgfdg/test-engines?limit=100&offset=0'
  let limitParam = limit ? "?limit=" + limit : "";
  let offsetParam = offset
    ? "&offset=" + offset
    : offset == 0
    ? "&offset=0"
    : "";
  return api.get(`api/core-engines${limitParam}${offsetParam}`);
}

function getCoreEngineDetail(engine_key: any) {
  // 'https://api.kltaf.frizbane.io/api/core-engines/WebAppBehaviourRemoteSauceLabs
  return api.get(`api/core-engines/${engine_key}`);
}

function createCoreEngine(param: any) {
  // 'https://api.kltaf.frizbane.io/api/applications/gfdgdfgfdg/test-engines'
  return api.post(`api/core-engines`, param).then((response) => {
    return response;
  });
}

function updateCoreEngine(engine_key: any, res: any) {
  // 'https://api.kltaf.frizbane.io/api/test-engines/656667e3-2285-4b33-8bb1-0c78f600bc15'
  return api.put(`api/core-engines/${engine_key}`, res).then((response) => {
    return response;
  });
}

function deleteCoreEngine(id: any) {
  // https://api.kltaf.frizbane.io/api/core-engines/fdsfdsf
  return api.delete(`api/core-engines/${id}`);
}
