import React, { useState } from "react";
import "./style.scss";
import { applicationService } from "../../_services/application.service";
import Inputcomp from "../../widgets/Form/input/Input";
import { Link, navigate } from "@reach/router";
import { toast } from "react-toastify";
import ConfirmationPopup from "../../widgets/Popups/Confirmation-Popup";
import AuditLog from "../../widgets/AuditLog/AuditLog";
import {
  REQUIRED_ERROR,
  SUCCESS_MESSAGES,
  CONTENT_MESSAGES,
  ERROR_MESSAGES,
} from "../../constants/app-constants";

const ApplicationForm = (props) => {
  const [prodKey, setProdKey] = useState("");

  const [appTestForm, setAppTestForm] = useState({
    old_app_key: "",
    app_key: "",
    name: "",
    enabled: true,
    framework_auth_key: "",
    portals: {},
    alt_app_name: "",
    alt_prod_name: "",
    ART_name: ""
  });

  const [error, setError] = useState({
    errorStatus: false,
    errorData: "",
    isValid: false,
    name: "App Name Required",
    prod_key: "Product Key Required",
    app_key: "App Key Required",
  });

  const [confirmData, setConfirmData] = useState({
    title: "",
    message: "",
    closeLabel: "",
    okLabel: "",
    isShow: false,
    selectedId: "",
  });

  const [copyKey, setCopyKey] = useState({
    isCopy: "0",
  });

  React.useEffect(() => {
    let app = props?.appDetail;
    setAppTestForm({
      ...appTestForm,
      old_app_key: app?.app_key,
      app_key: app?.app_key,
      name: app?.name,
      enabled: app?.enabled ? true : false,
      framework_auth_key: app?.framework_auth_key,
      alt_app_name: app?.alt_app_name,
      alt_prod_name: app?.alt_prod_name,
      ART_name: app?.ART_name
    });
  }, [props?.appDetail]);

  React.useEffect(() => {
    setProdKey(props?.prod_key);
  }, [props?.prod_key]);

  function handleChangeForm(event, isSpace = false) {
    Object.keys(event).forEach(function (key) {
      let value;
      if (isSpace) {
        value = event[key].replace(/\s/g, "");
      } else {
        value = event[key];
      }

      setAppTestForm({ ...appTestForm, [key]: value });
    });
  }

  function onSubmit(event) {
    props?.isLoading(true);
    if (!validateForm()) {
      toast.error(ERROR_MESSAGES.checkAllFieldsMessage);
      props?.isLoading(false);
      return false;
    }

    let prodApp = {
      app_key: appTestForm?.app_key,
      name: appTestForm?.name,
      enabled: true,
      portals: {},
      alt_app_name: appTestForm?.alt_app_name,
      alt_prod_name: appTestForm?.alt_prod_name,
      ART_name: appTestForm?.ART_name
    };

    applicationService.createApplication(prodApp, prodKey).then((resp) => {
      if (resp.status == 200 && resp?.data) {
        props?.isLoading(false);
        toast.success(SUCCESS_MESSAGES.add);
        navigate(
          `/app/products/product-apps/${appTestForm?.app_key}${
            "?product=" + prodKey
          }`
        );
      } else {
        if (resp?.status === 422) {
          toast.error(resp?.data?.detail[0].msg);
        } else {
          toast.error(
            resp?.data?.detail ? resp?.data?.detail : resp?.data?.error
          );
        }
        props?.isLoading(false);
      }
    });
  }

  function onUpdate() {
    props?.isLoading(true);
    if (!validateForm()) {
      toast.error(ERROR_MESSAGES.checkAllFieldsMessage);
      props?.isLoading(false);
      return false;
    }

    let prodApp = {
      name: appTestForm?.name,
      enabled: true,
      portals: {},
      alt_app_name: appTestForm?.alt_app_name,
      alt_prod_name: appTestForm?.alt_prod_name,
      ART_name: appTestForm?.ART_name
    };

    applicationService
      .updateApplication(prodApp, appTestForm?.app_key)
      .then((resp) => {
        if (resp.status == 200 && resp?.data) {
          props?.isLoading(false);
          props?.handelEdit(false);
          toast.success(SUCCESS_MESSAGES.update);

          navigate(
            `/app/products/product-apps/${resp?.data?.app_key}${
              "?product=" + prodKey
            }`
          );
        } else {
          if (resp?.status === 422) {
            toast.error(resp?.data?.detail[0].msg);
          } else {
            toast.error(
              resp?.data?.detail ? resp?.data?.detail : resp?.data?.error
            );
          }
          props?.isLoading(false);
        }
      });
  }

  function validateForm() {
    if (appTestForm.name && appTestForm.app_key) {
      setError({ ...error, isValid: false });
      return true;
    } else {
      setError({ ...error, isValid: true });
      return false;
    }
  }

  function onUpdateCancelConfirm() {
    setConfirmData({
      ...confirmData,
      title: "Alert",
      message: ERROR_MESSAGES?.reConformFormNotSaveMessage,
      closeLabel: "Cancel",
      okLabel: "Confirm",
      isShow: true,
    });
  }

  function closeConfirm() {
    setConfirmData({
      ...confirmData,
      isShow: false,
    });
  }

  function onUpdateCancel() {
    closeConfirm();
    props?.handelEditWithoutChange(false);
  }

  function copyToClipboard(id) {
    setCopyKey({ ...copyKey, isCopy: "1" });
    navigator.clipboard.writeText(id);
    setTimeout(() => {
      setCopyKey({ ...copyKey, isCopy: "0" });
    }, 3000);
  }

  return (
    <>
      {confirmData.isShow ? (
        <ConfirmationPopup
          data={confirmData}
          confirmCloseEvent={closeConfirm}
          confirmOkEvent={() => onUpdateCancel()}
        />
      ) : (
        ""
      )}

      <form noValidate onSubmit={(e)=>e.preventDefault()}>
        <div className="row">
          <div className="col-12 col-md-6">
            <Inputcomp
              type="text"
              nameClass="form-control custom-form-lh2"
              name="app_key"
              handleChange={true}
              isDisabled={!props?.isNew || props?.isEditForm}
              onChangeInput={(e) => handleChangeForm(e, true)}
              label="Key (App Key)"
              isRequired={true}
              value={appTestForm.app_key}
              placeholder={"App Key"}
              isMaxLength={15}
            />
            {!appTestForm.app_key && error.isValid && (
              <label className="text-danger fs-12">{REQUIRED_ERROR}</label>
            )}
          </div>

          <div className="col-12 col-md-6">
            <Inputcomp
              type="text"
              nameClass="form-control custom-form-lh2"
              name="name"
              handleChange={true}
              isDisabled={!props?.isNew}
              onChangeInput={handleChangeForm}
              label="Name"
              isRequired={true}
              value={appTestForm.name}
              placeholder={"App Name"}
            />
            {!appTestForm.name && error.isValid && (
              <label className="text-danger fs-12">{REQUIRED_ERROR}</label>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-8 col-md-4">
            <Inputcomp
              type="text"
              nameClass="form-control custom-form-lh2"
              name="Alt app name"
              handleChange={true}
              isDisabled={!props?.isNew}
              onChangeInput={(e) => handleChangeForm(e, true)}
              label="Alt app name"
              isRequired={false}
              value={appTestForm.alt_app_name}
              placeholder={"Alt app name"}
              isMaxLength={15}
            />
          </div>

          <div className="col-8 col-md-4">
            <Inputcomp
              type="text"
              nameClass="form-control custom-form-lh2"
              name="Alt prod name"
              handleChange={true}
              isDisabled={!props?.isNew}
              onChangeInput={handleChangeForm}
              label="Alt prod name"
              isRequired={false}
              value={appTestForm.alt_prod_name}
              placeholder={"Alt prod name"}
            />
          </div>
          <div className="col-8 col-md-4">
            <Inputcomp
              type="text"
              nameClass="form-control custom-form-lh2"
              name="ART name"
              handleChange={true}
              isDisabled={!props?.isNew}
              onChangeInput={handleChangeForm}
              label="ART name"
              isRequired={false}
              value={appTestForm.ART_name}
              placeholder={"ART name"}
            />
          </div>
        </div>

        {appTestForm?.framework_auth_key && (
          <div className="col-12 mt-4">
            <div className="d-flex flex-column">
              <label className="mb-1 fw-500 fs-14">Authkey</label>
              <p className="mb-1 fs-12">{CONTENT_MESSAGES.appAuthkeyDesc}</p>
              <label className="fs-14">
                <span className="me-2">{appTestForm?.framework_auth_key}</span>
                <span
                  className={
                    copyKey.isCopy == "1"
                      ? "bg-success badge border border-dark pe-none"
                      : "badge bg-light text-dark border border-dark shadow-sm pe-cursor"
                  }
                  onClick={() =>
                    copyToClipboard(appTestForm?.framework_auth_key)
                  }
                >
                  {copyKey.isCopy == "1" ? "Copied" : "Copy"}
                </span>
              </label>
            </div>
          </div>
        )}

        {props?.isNew && !props?.isEditForm && (
          <div className="d-flex justify-content-end my-3">
            <Link to={"/app/products"} className="btn btn-secondary me-3">
              Cancel
            </Link>
            <button className="btn btn-primary" onClick={onSubmit}>
              Submit
            </button>
          </div>
        )}
        {props?.isNew && props?.isEditForm && (
          <div className="d-flex justify-content-between my-3">
            <AuditLog logData={props?.appDetail} />
            <div>
              <button
                className="btn btn-secondary  me-3"
                onClick={onUpdateCancelConfirm}
              >
                Cancel
              </button>
              <button className="btn btn-primary" onClick={onUpdate}>
                Save
              </button>
            </div>
          </div>
        )}
      </form>
    </>
  );
};
export default ApplicationForm;
