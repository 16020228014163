import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, navigate } from "@reach/router";
// SERVICES
import { productService } from "../../_services/products.service";
import { AuthService } from "../../_services/auth.service.js";
// let user = AuthService.getCurrentUser()
//COMPONENTS
import { Card } from "react-bootstrap";
import SearchableSelect from "../../widgets/Form/Select/Searchable-Select";
import Loader from "../../widgets/loader/Loader";
import ErrorPopup from "../../widgets/Popups/Error-Popup";
import MessageComp from "./MessageComp";
import { toast } from "react-toastify";
import {
  ERROR_MESSAGES,
  CONTENT_MESSAGES,
  PORTAL_KEY_TO_MAP,
} from "../../constants/app-constants";

import LimitedAccess from "../../partials/OtherPages/LimitedAccess";
const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  const [loadingProChange, setLoadingProChange] = useState(false);
  const iframeRef = useRef(null);

  const [loadingContentInIframe, setLoadingContentInIframe] = useState(false);
  const [error, setError] = useState({
    errorStatus: false,
    errorData: "",
    err_product: "",
    err_application: "",
    noAccessModule: false,
    noAccessModuleMessage: "",
    faildToLoad: false,
    timeExceedError: false,
    failedToLoadContentInIframe: "",
  });
  const [productDropdown, setProductDropdown] = useState(null);

  const [allProducts, setAllProducts] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedPortal, setSelectedPortal] = useState(null);

  const [isOwnProduct, setIsOwnProduct] = useState(false);

  function getProductList() {
    setLoading(true);
    productService.getAllProducts().then((products) => {
      if (products.status == 200 && products?.data) {
        let user = AuthService.getCurrentUser();
        // console.log("Dash User",user)
        let ownProducts = [];
        // console.log("Dash product",products?.data)

        if (user.kind !== "Admin") {
          ownProducts = products?.data.filter((prod_item) => {
            return user.roles.some((role_item) => {
              return (
                role_item.product_key == prod_item.prod_key &&
                role_item.role == "Owner"
              );
            });
          });
        } else {
          ownProducts = [...products?.data];
        }

        // console.log("Dash ownProducts",ownProducts)
        if (ownProducts.length > 0) {
          setIsOwnProduct(true);
          setAllProducts(ownProducts);
          const options = ownProducts.map(
            (d: { enabled: any; prod_key: string; name: string }) =>
              d.enabled
                ? {
                    value: d.prod_key,
                    label: d.prod_key + " - " + d.name,
                    enabled: d.enabled,
                  }
                : ""
          );
          // let k = options?.filter((x: { enabled: any }) => x.enabled);
          let k = options?.filter((x: any) => x.enabled);
          setProductDropdown(k);
          let selected_pro = ownProducts[0];
          setSelectedProduct(ownProducts[0]);
          let selected_portal = null;
          if (
            selected_pro?.portals &&
            Object.keys(selected_pro?.portals).length !== 0
          ) {
            selected_portal = selected_pro?.portals?.find(
              (portalItem) =>
                portalItem.key.toLowerCase() === PORTAL_KEY_TO_MAP.toLowerCase()
            );
          }
          setSelectedPortal(selected_portal);
          setLoadingContentInIframe(true);
        }
        setError({
          ...error,
          faildToLoad: false,
        });
        setLoading(false);
      } else {
        setError({
          ...error,
          faildToLoad: true,
        });
        setLoading(false);
      }
    });
  }

  // useEffect(() => {
  //   if (iframeRef && iframeRef.current) {
  //     iframeRef.current.contentWindow.onerror = () => {
  //       setLoadingContentInIframe(false);
  //       alert("error");
  //     };
  //   }
  // }, [selectedPortal]);

  useEffect(() => {
    setProductDropdown("");
    getProductList();
  }, []);

  function closeError() {
    setError({ ...error, errorStatus: false });
  }

  function onProductChange(event) {
    setLoading(true);
    setLoadingContentInIframe(true);
    setLoadingProChange(true);
    const selected_item = allProducts.find(
      (product) => product.prod_key === event
    );
    setSelectedProduct(selected_item);

    let selected_portal = null;
    if (
      selected_item?.portals &&
      Object.keys(selected_item?.portals).length !== 0
    ) {
      selected_portal = selected_item?.portals.find(
        (portalItem) =>
          portalItem.key.toLowerCase() === PORTAL_KEY_TO_MAP.toLowerCase()
      );
    }
    setSelectedPortal(selected_portal);
    setLoading(false);
    // setLoadingProChange(false);
  }

  if (loading) return <Loader />;

  if (!isOwnProduct) return <LimitedAccess />;

  return (
    <div className="containWrap-inner">
      {loading ? (
        <Loader />
      ) : error.errorStatus ? (
        <ErrorPopup data={error.errorData} closePopup={closeError} />
      ) : (
        ""
      )}
      <Card className="bg-white custom-card">
        <Card.Body>
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="col-11">
                <Card.Title>
                  <h4 className="m-0">Dashboard: {selectedProduct?.name}</h4>
                </Card.Title>
                <Card.Text>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: CONTENT_MESSAGES.dashboardTitalDesc,
                    }}
                  />
                </Card.Text>
              </div>
            </div>

            <div className="col-12 col-md-6 mt-3 mt-md-0">
              {/* d-flex justify-content-end */}
              <div className="col-12 col-md-6 offset-md-6">
                <SearchableSelect
                  optionList={productDropdown ? productDropdown : ""}
                  selectedOption={selectedProduct?.prod_key}
                  dropdownName={"Product"}
                  placeholder={"Select Product"}
                  onClick={onProductChange}
                  isEditable={productDropdown ? true : false}
                  isRequired={true}
                />
                {error.err_product && (
                  <label className="text-danger">{error.err_product}</label>
                )}
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>

      <div className="col-12 my-2 mb-3">
        <Card className="bg-white custom-card">
          <Card.Body>
            {!selectedPortal || selectedPortal?.length < 1 ? (
              <MessageComp
                message={ERROR_MESSAGES.dashbordNoConfigureMessage}
              />
            ) : (
              <div className="row">
                <div className="col-12 mt-2">
                  {loadingContentInIframe && <div>Loading...</div>}
                  {error?.failedToLoadContentInIframe && (
                    <div>failedToLoadContentInIframe</div>
                  )}

                  <iframe
                    seamless
                    ref={iframeRef}
                    // onLoadStart={() => {
                    //   console.log("onloadstart");
                    //   setLoadingContentInIframe(true);
                    // }}
                    onLoad={(e) => {
                      setLoadingContentInIframe(false);
                    }}
                    onError={() => {
                      // console.log("on error");
                      // console.log("iframe onAbort", e);
                      // setError({
                      //   ...error,
                      //   failedToLoadContentInIframe: e.toString(),
                      // });
                    }}
                    id="dashboardIFrame"
                    className="i-frm"
                    // width="100%"
                    // height="100%"
                    src={selectedPortal?.url}
                    title={selectedPortal?.name}
                  ></iframe>
                </div>
              </div>
            )}
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default Dashboard;
