import React, { useState } from "react";
import { Card } from "react-bootstrap";
import InputWithIcon from "../../widgets/Form/input/Input-Icon";
import { userService } from "../../_services/user.service";
import { FETCH_LIMIT } from "../../constants/app-constants";
import "./style.scss";
// import {AuthService} from "../../_services/auth.service";

const PopupUsers = (props) => {
  // const [searchString, setSearchString] = useState('');
  const [users, setUsers] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  // const isAccess = AuthService.getAllAccessList("products");
  const [selectedVales, setSelectedVales] = useState({
    q: "",
    limit: 100,
    offset: 0,
  });

  function getInputChange(event) {
    Object.keys(event).forEach(function (key) {
      setSelectedVales({ ...selectedVales, q: event[key] });
    });
  }

  function fetchUser() {
    setIsLoading(true);
    if (!selectedVales.q) {
      setIsLoading(false);
      return false;
    }
    // //
    userService.getAllUser(selectedVales).then((users) => {
      // //
      if (users.status == 200 && users?.data) {
        setUsers(users?.data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    });
  }

  function getSelectedUser(user) {
    props?.addSelectedUser(user);
  }

  return (
    <div className="popup-fixed">
      <div className="popup_inner">
        <Card className="bg-white custom-card" style={{ border: 0 }}>
          <Card.Header className="bg-gray">
            <div className="row">
              <div className="col-12">
                <Card.Title className="card-title fs-22">
                  {props.title}
                </Card.Title>
              </div>
            </div>
          </Card.Header>
          <Card.Body className="min83vh">
            <div className="row">
              <div className="col-12 col-md-12">
                <InputWithIcon
                  name={"searchString"}
                  nameClass="form-control custom-form-lh2 nosubmit"
                  type={"search"}
                  placeholder={"Search by name or email of the user"}
                  onChangeInput={getInputChange}
                  onKeyPress={fetchUser}
                  btnName={"SEARCH"}
                  ipWithButton={"searchInput"}
                  getResult={fetchUser}
                  isDisabledButton={isLoading}
                />
              </div>
              {users ? (
                <div className="col-12 mt-3">
                  <div className="user-list">
                    {users &&
                      users.map((user, index) => (
                        <div
                          className="scrollChild"
                          key={index}
                          onClick={() => getSelectedUser(user)}
                        >
                          <a className="no-link">
                            <span className="fw-600 fs-16 mb-1">
                              {" "}
                              {user.first_name} {user.last_name}
                            </span>{" "}
                            <br />
                            <span className="fs-14 mb-1">
                              {" "}
                              {user.email}, {user.username}{" "}
                            </span>
                          </a>
                        </div>
                      ))}
                  </div>
                </div>
              ) : (
                <div className="col-12 mt-3">
                  <div className="text-center">
                    Please search by user or email to get user list
                  </div>
                </div>
              )}
            </div>
          </Card.Body>
          <Card.Footer className="bg-white border-0 py-3">
            <div className="d-flex justify-content-end align-items-center">
              <button className="btn btn-primary" onClick={props?.closePopup}>
                Cancel
              </button>
            </div>
          </Card.Footer>
        </Card>
      </div>
    </div>
  );
};

export default PopupUsers;
