import React, { Component, useState } from "react";
import "./style.scss";
import { coreEngineService } from "../../_services/coreengine.service";
import Inputcomp from "../../widgets/Form/input/Input";
import { Link, useLocation, navigate } from "@reach/router";
import { toast } from "react-toastify";
import ConfirmationPopup from "../../widgets/Popups/Confirmation-Popup";
import SelectInputChoises from "../../widgets/Form/Select/SelectInputChoises";
import FeatureSearchDialog from "./FeatureSearchDialog";
import CHOICES from "../../_helpers/CHOICES";
import AuditLog from "../../widgets/AuditLog/AuditLog";
import {
  REQUIRED_ERROR,
  SUCCESS_MESSAGES,
  CONTENT_MESSAGES,
  ERROR_MESSAGES,
} from "../../constants/app-constants";

const CoreEngineForm = (props) => {
  const search = useLocation().search;
  const location = useLocation();
  const q_FromUrl = new URLSearchParams(search).get("q");

  ////
  const [coreEngineForm, setCoreEngineForm] = useState({
    id: "",
    old_engine_key: "",
    engine_key: "",
    test_type: "",
    execution: "",
    provider: null,
    capacity_uom: "Unlimited",
    capacity_units: 0,
    variables: null,
    is_heavy_duty: false,
    features: [],
  });

  const [error, setError] = useState({
    errorStatus: false,
    errorData: "",
    isValid: false,
    engine_key: "Engine Key Required",
    test_type: "Test Type Required",
    execution: "Execution Required",
    capacity_units: "Capacity Units Required",
    capacity_uom: "Capacity Uom Required",
    features: "Feature Required",
    variables: "Configuration Required",
  });

  const [confirmData, setConfirmData] = useState({
    title: "",
    message: "",
    closeLabel: "",
    okLabel: "",
    isShow: false,
    selectedId: "",
  });

  const [state, setState] = useState({
    rows: [],
    buildArgs: [],
  });
  const [state1, setState1] = useState({
    rows1: [],
    buildArgs1: [],
  });

  const [showPopup, setShowPopup] = useState(false);
  const [selectedFeature, setSelectedFeature] = useState([]);

  React.useEffect(() => {
    state.rows.length == 0 ? handleAddRow() : "";
    state1.rows1.length == 0 ? handleAddRow1() : "";
  }, []);

  React.useEffect(() => {
    let core = props?.coreEngineDetail;
    let featuresSelection = []; //coreEngineForm?.features;
    core?.features.forEach((element) => {
      featuresSelection.push(element.id);
    });

    setCoreEngineForm({
      ...coreEngineForm,
      id: core?.id,
      old_engine_key: core?.engine_key,
      engine_key: core?.engine_key,
      test_type: core?.test_type,
      execution: core?.execution,
      provider: core?.provider ? core?.provider : null,
      capacity_uom: core?.capacity_uom ? core?.capacity_uom : "Unlimited",
      capacity_units: core?.capacity_units ? core?.capacity_units : 0,
      variables: core?.variables ? core?.variables : null,
      is_heavy_duty: false,
      features: featuresSelection,
    });

    setSelectedFeature(core?.features ? core?.features : []);

    let rows = [];
    core?.provider &&
      Object.keys(core?.provider).map((key, index) => {
        let k = {
          key: key,
          value: core?.provider[key],
        };
        rows.push(k);
      });
    setState({ ...state, rows, buildArgs: core?.provider });

    let rows1 = [];
    core?.variables &&
      Object.keys(core?.variables).map((key, index) => {
        let k = {
          key: key,
          value: core?.variables[key],
        };
        rows1.push(k);
      });
    setState1({ ...state, rows1, buildArgs1: core?.variables });
  }, [props?.coreEngineDetail]);

  function handleChangeForm(event) {
    Object.keys(event).forEach(function (key) {
      setCoreEngineForm({ ...coreEngineForm, [key]: event[key] });
    });
  }

  const handleChangeFormWithouspace = (event) => {
    Object.keys(event).forEach(function (key) {
      let value = event[key].replace(/\s/g, "");
      setCoreEngineForm({ ...coreEngineForm, [key]: value });
    });
  };

  function onSubmit(event) {
    // ////
    props?.isLoading(true);

    if (!validateForm()) {
      toast.error(ERROR_MESSAGES.checkAllFieldsMessage);
      props?.isLoading(false);
      return false;
    }

    let req = {
      engine_key: coreEngineForm?.engine_key,
      test_type: coreEngineForm?.test_type,
      execution: coreEngineForm?.execution,
      provider: coreEngineForm?.provider ? coreEngineForm?.provider : null,
      capacity_uom: coreEngineForm?.capacity_uom
        ? coreEngineForm?.capacity_uom
        : "Unlimited",
      capacity_units: coreEngineForm?.capacity_units
        ? coreEngineForm?.capacity_units
        : 0,
      variables: coreEngineForm?.variables,
      is_heavy_duty: false,
      features: coreEngineForm?.features,
    };

    //   ////
    coreEngineService.createCoreEngine(req).then((resp) => {
      ////
      if (resp.status == 200 && resp?.data) {
        props?.isLoading(false);
        toast.success(SUCCESS_MESSAGES.add);
        // let q = q_FromUrl ? '?q=' + q_FromUrl : '';
        navigate(`/app/core-engines/${resp?.data?.engine_key}`);
      } else if (resp?.status == 408) {
        toast.error(ERROR_MESSAGES.timeExceedError);
        props?.isLoading(false);
      } else {
        if (resp?.status === 422) {
          toast.error(resp?.data?.detail[0].msg);
        } else {
          toast.error(
            resp?.data?.detail ? resp?.data?.detail : resp?.data?.error
          );
        }
        props?.isLoading(false);
      }
    });
  }

  function onUpdate() {
    props?.isLoading(true);
    if (!validateForm()) {
      toast.error(ERROR_MESSAGES.checkAllFieldsMessage);
      props?.isLoading(false);
      return false;
    }

    let req = {
      engine_key: coreEngineForm?.engine_key,
      test_type: coreEngineForm?.test_type,
      execution: coreEngineForm?.execution,
      provider: coreEngineForm?.provider ? coreEngineForm?.provider : {},
      capacity_uom: coreEngineForm?.capacity_uom
        ? coreEngineForm?.capacity_uom
        : "Unlimited",
      capacity_units: coreEngineForm?.capacity_units
        ? coreEngineForm?.capacity_units
        : 0,
      variables: coreEngineForm?.variables ? coreEngineForm?.variables : {},
      is_heavy_duty: false,
      features: coreEngineForm?.features ? coreEngineForm?.features : [],
    };

    // //

    coreEngineService
      .updateCoreEngine(coreEngineForm?.old_engine_key, req)
      .then((resp) => {
        ////
        if (resp.status == 200 && resp?.data) {
          props?.isLoading(false);
          toast.success(SUCCESS_MESSAGES.update);
          props?.handelEdit(false);
        } else if (resp?.status == 408) {
          toast.error(ERROR_MESSAGES.timeExceedError);
          props?.isLoading(false);
        } else {
          if (resp?.status === 422) {
            toast.error(resp?.data?.detail[0].msg);
          } else {
            toast.error(
              resp?.data?.detail ? resp?.data?.detail : resp?.data?.error
            );
          }
          props?.isLoading(false);
        }
      });
  }

  function variableValidation(param) {
    if (!param) {
      return false;
    }
    for (const [key, value] of Object.entries(param)) {
      if (key.trim() == "" || value == "") {
        return false;
      }
    }
    return true;
  }

  function validateForm() {
    let variable_vali = variableValidation(coreEngineForm.variables);
    if (
      coreEngineForm.engine_key &&
      coreEngineForm.test_type &&
      coreEngineForm.execution &&
      coreEngineForm.test_type &&
      coreEngineForm?.features.length > 0 &&
      coreEngineForm?.capacity_uom &&
      variableValidation(coreEngineForm.variables)
    ) {
      if (
        coreEngineForm?.capacity_uom != "Unlimited" &&
        !coreEngineForm.capacity_units &&
        !variable_vali
      ) {
        ////
        setError({ ...error, isValid: true });
        return false;
      } else {
        ////
        setError({ ...error, isValid: false });
        return true;
      }
    } else {
      ////
      setError({ ...error, isValid: true });
      return false;
    }
  }

  function onUpdateCancelConfirm() {
    setConfirmData({
      ...confirmData,
      title: "Alert",
      message: ERROR_MESSAGES.reConformFormNotSaveMessage,
      closeLabel: "Cancel",
      okLabel: "Confirm",
      isShow: true,
    });
  }

  function closeConfirm() {
    setConfirmData({
      ...confirmData,
      isShow: false,
    });
  }

  function onUpdateCancel() {
    closeConfirm();
    props?.handelEditWithoutChange(false);
  }

  function handleChangeBuildArgs(idx, fieldName, e) {
    const { key, value } = e;
    const rows = [...state.rows];

    // rows[idx] = {
    //     "key": key ? key : rows[idx].key,
    //     "value": value ? value : rows[idx].value
    // };

    switch (fieldName) {
      case "key":
        rows[idx] = {
          key: key,
          value: value ? value : rows[idx].value,
        };
        break;
      case "value":
        rows[idx] = {
          key: key ? key : rows[idx].key,
          value: value,
        };
        break;
      default:
        break;
    }

    let result;
    if (rows.length > 0) {
      result = rows.reduce(function (r, e) {
        r[e.key] = e.value;
        return r;
      }, {});
    }

    setState({ ...state, rows, buildArgs: result });
    setCoreEngineForm({ ...coreEngineForm, provider: result });
  }

  function handleAddRow() {
    const item = {
      key: "",
      value: "",
    };
    setState({ ...state, rows: [...state.rows, item] });
  }

  function handleRemoveRow(idx) {
    const newList = [...state.rows.slice(0, idx), ...state.rows.slice(idx + 1)];
    setState({ ...state, rows: newList });

    let result;
    if (newList.length > 0) {
      result = newList.reduce(function (r, e) {
        r[e.key] = e.value;
        return r;
      }, {});

      ////
    }
    setCoreEngineForm({ ...coreEngineForm, provider: result });
  }

  function handleChangeBuildArgs1(idx, fieldName, e) {
    const { key, value } = e;
    const rows1 = [...state1.rows1];

    // rows1[idx] = {
    //     "key": key ? key : rows1[idx].key,
    //     "value": value ? value : rows1[idx].value
    // };

    switch (fieldName) {
      case "key":
        rows1[idx] = {
          key: key,
          value: value ? value : rows1[idx].value,
        };
        break;
      case "value":
        rows1[idx] = {
          key: key ? key : rows1[idx].key,
          value: value,
        };
        break;
      default:
        break;
    }

    let result;
    if (rows1.length > 0) {
      result = rows1.reduce(function (r, e) {
        r[e.key] = e.value;
        return r;
      }, {});
    }

    setState1({ ...state1, rows1, buildArgs1: result });
    setCoreEngineForm({ ...coreEngineForm, variables: result });
  }

  function handleAddRow1() {
    const item = {
      key: "",
      value: "",
    };

    //
    setState1({ ...state1, rows1: [...state1.rows1, item] });

    //
    // let rowsOld = state1.rows1
    // let newRow = rowsOld.concat(item)
    //

    // setState1({ ...state1, rows1: newRow });
  }

  function handleRemoveRow1(idx) {
    const newList = [
      ...state1.rows1.slice(0, idx),
      ...state1.rows1.slice(idx + 1),
    ];
    setState1({ ...state1, rows1: newList });

    let result;
    if (newList.length > 0) {
      result = newList.reduce(function (r, e) {
        r[e.key] = e.value;
        return r;
      }, {});

      ////
    }

    setCoreEngineForm({ ...coreEngineForm, variables: result });
  }

  function handleChangeFormSelect(event) {
    Object.keys(event).forEach(function (key) {
      // ////
      setCoreEngineForm({ ...coreEngineForm, [key]: event[key] });
    });
    error.isValid && validateForm();
  }

  function togglePopup() {
    setShowPopup(!showPopup);
  }

  const getSelectedFeature = (event) => {
    // let selectedFeatureLocal = selectedFeature ? selectedFeature : [];
    // let featureList : any = null;
    let featuresSelection = [];
    // if(selectedFeature.length > 0){

    //     event.forEach(elementInner => {
    //         selectedFeatureLocal.splice(selectedFeatureLocal.findIndex(a => a.id === itemToBeRemoved.id) , 1)
    //     });

    //     selectedFeatureLocal.forEach(element => {

    //         event.forEach(elementInner => {

    //             // add item
    //             // if(elementInner.id !== element.id){
    //             //     featureList = selectedFeatureLocal.concat(element)
    //             // }
    //         });

    //     });
    // }else{
    //     featureList = selectedFeatureLocal.concat(event)
    // }

    event &&
      event.forEach((element) => {
        featuresSelection.push(element.id);
      });

    setCoreEngineForm({ ...coreEngineForm, features: featuresSelection });
    setSelectedFeature(event);
    togglePopup();
  };

  const deleteFeature = (idx) => {
    // ////
    const newList = [
      ...selectedFeature.slice(0, idx),
      ...selectedFeature.slice(idx + 1),
    ];

    let featuresSelection = []; //coreEngineForm?.features;
    newList.forEach((element) => {
      featuresSelection.push(element.id);
    });
    setCoreEngineForm({ ...coreEngineForm, features: featuresSelection });
    setSelectedFeature(newList);
  };

  function getValues(myObj) {
    let arr = [];
    arr = Object.keys(myObj).map((key, index) => (
      <tr key={index}>
        <td>{key}</td>
        <td>{myObj[key]}</td>
        <td></td>
      </tr>
    ));
    return arr;
  }

  return (
    <>
      {confirmData.isShow ? (
        <ConfirmationPopup
          data={confirmData}
          confirmCloseEvent={closeConfirm}
          confirmOkEvent={() => onUpdateCancel()}
        />
      ) : (
        ""
      )}

      {showPopup ? (
        <FeatureSearchDialog
          title="Feature Search"
          closePopup={togglePopup}
          addSelectedFeature={getSelectedFeature}
          selected_flist={coreEngineForm?.features}
        />
      ) : null}

      <form noValidate onSubmit={(e)=>e.preventDefault()}>
        <div className="row">
          <div className="col-6">
            <Inputcomp
              type="text"
              nameClass="form-control custom-form-lh2"
              name="engine_key"
              handleChange={true}
              isDisabled={!props?.isNew}
              onChangeInput={handleChangeFormWithouspace}
              label="Key (Core Engine Key)"
              isRequired={true}
              value={coreEngineForm.engine_key}
            />
            {!coreEngineForm.engine_key && error.isValid && (
              <label className="text-danger fs-12">{REQUIRED_ERROR}</label>
            )}
          </div>

          <div className="col-6 ">
            <div className="d-flex flex-column">
              <SelectInputChoises
                label="Type"
                isRequired={true}
                onChangeInput={handleChangeFormSelect}
                name="test_type"
                optionArray={CHOICES?.TestType?.CHOICE_LIST}
                nameClass="form-control custom-form-lh2 custom-select"
                isDisabled={!props?.isNew}
                selectedOption={coreEngineForm.test_type}
              />
              {!coreEngineForm.test_type && error.isValid && (
                <label className="text-danger fs-12">{REQUIRED_ERROR}</label>
              )}
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-6">
            <div className="d-flex flex-column">
              <SelectInputChoises
                label="Execution"
                isRequired={true}
                onChangeInput={handleChangeFormSelect}
                name="execution"
                optionArray={CHOICES?.ExecutionKind?.CHOICE_LIST}
                nameClass="form-control custom-form-lh2 custom-select"
                isDisabled={!props?.isNew}
                selectedOption={coreEngineForm.execution}
              />
              {!coreEngineForm.execution && error.isValid && (
                <label className="text-danger fs-12">{REQUIRED_ERROR}</label>
              )}
            </div>
          </div>

          <div className="col-6 ">
            <div className="row">
              <div className="col-8">
                <Inputcomp
                  type="text"
                  nameClass="form-control custom-form-lh2"
                  name="capacity_units"
                  handleChange={true}
                  isDisabled={
                    !props?.isNew ||
                    coreEngineForm.capacity_uom ==
                      CHOICES?.UomKind?.CHOICE_MAP?.Unlimited
                  }
                  onChangeInput={handleChangeForm}
                  label="Capacity"
                  isRequired={true}
                  value={coreEngineForm.capacity_units}
                />
                {/* coreEngineForm?.capacity_uom != 'Unlimited' && coreEngineForm.capacity_units */}
                {coreEngineForm?.capacity_uom != "Unlimited" &&
                  !coreEngineForm.capacity_units &&
                  error.isValid && (
                    <label className="text-danger fs-12">
                      {REQUIRED_ERROR}
                    </label>
                  )}
              </div>

              <div className="col-4">
                <div className="d-flex flex-column">
                  <SelectInputChoises
                    label="Unit"
                    isRequired={true}
                    onChangeInput={handleChangeFormSelect}
                    name="capacity_uom"
                    optionArray={CHOICES?.UomKind?.CHOICE_LIST}
                    nameClass="form-control custom-form-lh2 custom-select"
                    isDisabled={!props?.isNew}
                    selectedOption={coreEngineForm.capacity_uom}
                  />
                  {!coreEngineForm.capacity_uom && error.isValid && (
                    <label className="text-danger fs-12">
                      {REQUIRED_ERROR}
                    </label>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Features */}
        {props?.isNew && (
          <div className="row mt-4">
            <div className="col-12 mt-4 border-bottom">
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex flex-column">
                  <h4 className="mb-1 fw-500 fs-16">
                    Features<label className="text-danger">*</label>
                  </h4>
                  <p className="fs-12 mb-1">Add features to this core engine</p>
                  {coreEngineForm?.features.length < 1 && error.isValid && (
                    <label className="text-danger fs-12  mb-1">
                      {REQUIRED_ERROR}
                    </label>
                  )}
                </div>
                <a
                  className="btn btn-primary btn-sm"
                  onClick={() => togglePopup()}
                >
                  Add Feature
                </a>
              </div>
            </div>

            <div className="mt-2">
              <table className="table data-table data-table-vertically-center">
                <thead className="table-primary">
                  <tr>
                    <th>Name</th>
                    <th>Value</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {selectedFeature && selectedFeature.length > 0 ? (
                    selectedFeature.map((f, idx) => (
                      <tr id={"tr_" + idx} key={idx}>
                        <td>{f?.name}</td>
                        <td>{f?.value}</td>
                        <td>
                          <a
                            onClick={() => deleteFeature(idx)}
                            className="pull-right btn link text-danger"
                          >
                            <i className="fa fa-trash" aria-hidden="true"></i>
                          </a>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={6} className="text-center">
                        {ERROR_MESSAGES.noRecordsFound}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {/* Provider Info */}
        <div className="row mt-4">
          <div className="col-12 mt-4 border-bottom">
            <div className="d-flex flex-column">
              <h4 className="mb-1 fw-500 fs-14">Provider Info</h4>
              <p className="fs-12 mb-1">
                {CONTENT_MESSAGES.coreEnginProviderInfoDesc}
              </p>
            </div>
          </div>

          <div className="table-responsive mt-2">
            <table className="table data-table data-table-vertically-center  ">
              <thead className="table-primary">
                <tr>
                  <th>Key</th>
                  <th>Value</th>
                  <th></th>
                </tr>
              </thead>
              {props?.isNew ? (
                <tbody>
                  {state?.rows.length > 0 ? (
                    state?.rows.map((item, idx) => (
                      <tr id={"tr_" + idx} key={idx}>
                        <td>
                          <Inputcomp
                            type="text"
                            nameClass="form-control custom-form-lh2"
                            name="key"
                            handleChange={true}
                            isDisabled={false}
                            onChangeInput={(event) =>
                              handleChangeBuildArgs(idx, "key", event)
                            }
                            label=""
                            value={state.rows[idx].key}
                            id={"tr_key_" + idx}
                          />
                        </td>
                        <td>
                          <Inputcomp
                            type="text"
                            nameClass="form-control custom-form-lh2"
                            name="value"
                            handleChange={true}
                            isDisabled={false}
                            onChangeInput={(event) =>
                              handleChangeBuildArgs(idx, "value", event)
                            }
                            label=""
                            value={state.rows[idx].value}
                            id={"tr_value_" + idx}
                          />
                        </td>
                        <td>
                          <a
                            onClick={() => handleRemoveRow(idx)}
                            className="pull-right btn link text-danger"
                          >
                            <i className="fa fa-trash" aria-hidden="true"></i>
                          </a>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={6} className="text-center">
                        {ERROR_MESSAGES.noRecordsFound}
                      </td>
                    </tr>
                  )}
                </tbody>
              ) : props?.coreEngineDetail?.provider ? (
                getValues(props?.coreEngineDetail?.provider)
              ) : (
                <tr>
                  <td colSpan={6} className="text-center">
                    {ERROR_MESSAGES.noRecordsFound}
                  </td>
                </tr>
              )}
            </table>

            {props?.isNew && (
              <a onClick={handleAddRow} className="btn pull-right link">
                + Add Row
              </a>
            )}
          </div>
        </div>

        {/* Configuration */}
        <div className="row mt-4">
          <div className="col-12 mt-4 border-bottom">
            <div className="d-flex flex-column">
              <h4 className="mb-1 fw-500 fs-14">
                Configuration<label className="text-danger">*</label>
                {/* {state1?.rows1.length} */}
              </h4>
              <p className="fs-12 mb-1">
                List of variables critical to execution of this core engine.
                Configuration is typically not exposed to end users.
              </p>
              {!coreEngineForm?.variables && error.isValid && (
                <label className="text-danger fs-12  mb-1">
                  {REQUIRED_ERROR}
                </label>
              )}
            </div>
          </div>

          <div className="table-responsive mt-2">
            <table className="table data-table data-table-vertically-center  ">
              <thead className="table-primary">
                <tr>
                  <th>Key</th>
                  <th>Value</th>
                  <th></th>
                </tr>
              </thead>
              {props?.isNew ? (
                <tbody>
                  {state1?.rows1.length > 0 ? (
                    state1?.rows1.map((item, idx) => (
                      <tr id={"tr_" + idx} key={idx}>
                        <td>
                          <Inputcomp
                            type="text"
                            nameClass="form-control custom-form-lh2"
                            name="key"
                            handleChange={true}
                            isDisabled={false}
                            onChangeInput={(event) =>
                              handleChangeBuildArgs1(idx, "key", event)
                            }
                            label=""
                            value={state1.rows1[idx].key}
                            id={"tr_key_" + idx}
                            isRequired={true}
                          />
                        </td>
                        <td>
                          <Inputcomp
                            type="text"
                            nameClass="form-control custom-form-lh2"
                            name="value"
                            handleChange={true}
                            isDisabled={false}
                            onChangeInput={(event) =>
                              handleChangeBuildArgs1(idx, "value", event)
                            }
                            label=""
                            value={state1.rows1[idx].value}
                            id={"tr_value_" + idx}
                            isRequired={true}
                          />
                        </td>
                        <td>
                          <a
                            onClick={() => handleRemoveRow1(idx)}
                            className="pull-right btn link text-danger"
                          >
                            <i className="fa fa-trash" aria-hidden="true"></i>
                          </a>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={6} className="text-center">
                        {ERROR_MESSAGES.noRecordsFound}
                      </td>
                    </tr>
                  )}
                </tbody>
              ) : (
                <tbody>
                  {/*   variables */}
                  {props?.coreEngineDetail?.variables ? (
                    getValues(props?.coreEngineDetail?.variables)
                  ) : (
                    <tr>
                      <td colSpan={6} className="text-center">
                        No Configuration
                      </td>
                    </tr>
                  )}
                </tbody>
              )}
            </table>

            {props?.isNew && (
              <a onClick={handleAddRow1} className="btn pull-right link">
                + Add Row
              </a>
            )}
          </div>
        </div>
        {/* END CONFIGURATION */}
        {props?.isNew && !props?.isEditForm && (
          <div className="d-flex justify-content-end my-3">
            <Link to={"/app/core-engines"} className="btn btn-secondary me-3">
              Cancel
            </Link>
            <button className="btn btn-primary" onClick={onSubmit}>
              Submit
            </button>
          </div>
        )}
        {props?.isNew && props?.isEditForm && (
          <div className="d-flex justify-content-between my-3">
            <AuditLog logData={props?.coreEngineDetail} />
            <div>
              <button
                className="btn btn-secondary  me-3"
                onClick={onUpdateCancelConfirm}
              >
                Cancel
              </button>
              <button className="btn btn-primary" onClick={onUpdate}>
                Save
              </button>
            </div>
          </div>
        )}
      </form>
    </>
  );
};
export default CoreEngineForm;
