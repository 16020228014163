import React, { Component, useEffect } from "react";
import ReactDOM from "react-dom";
import Select from "react-select";
import { defaultTheme } from "react-select";
import "./styleSelect.scss";

const { colors } = defaultTheme;

const selectStyles = {
  control: (provided) => ({ ...provided, minWidth: 240, margin: 8 }),
  menu: () => ({ boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)" }),
};

const SearchableSelect = (props) => {
  const [isOpen, setIsOpen] = React.useState(false); //default value
  const [selectedValue, setSelected] = React.useState(props?.selectedOption);
  useEffect(() => {
    setSelected(props?.selectedOption);
  }, [props?.selectedOption]);
  //default value
  function toggleOpen() {
    setIsOpen(!isOpen);
  }

  function onSelectChange(event) {
    toggleOpen();
    setSelected(event.value);
    props?.onClick(event.value);
  }

  return (
    <div>
      <div className="d-flex align-items-center">
        <label className="form-label fw-500 fs-14">
          {props?.dropdownName}
          {props?.isRequired && <span className="text-danger">*</span>}
        </label>
        {props?.subLabel && (
          <label className="form-label fs-12 ms-2 text-ellipsis">
            ({props?.subLabel})
          </label>
        )}
      </div>
      <Dropdown
        isOpen={isOpen}
        onClose={toggleOpen}
        target={
          <button
            className={
              "selectDrop fld_H" +
              (isOpen ? "dropUP fld_H" : " fld_H") +
              (props?.isEditable
                ? props?.backgroundColor
                : " noClick disabled-dropdown fld_H")
            }
            onClick={toggleOpen}
          >
            {selectedValue ? ` ${selectedValue}` : props?.placeholder}
          </button>
        }
      >
        <Select
          className="dropOptionList"
          autoFocus
          components={{ DropdownIndicator, IndicatorSeparator: null }}
          backspaceRemovesValue={false}
          controlShouldRenderValue={false}
          hideSelectedOptions={false}
          isClearable={false}
          menuIsOpen
          onChange={onSelectChange}
          options={props?.optionList}
          placeholder="Search..."
          styles={selectStyles}
          tabSelectsValue={false}
        />
      </Dropdown>
    </div>
  );
};

const Dropdown = ({ children, isOpen, target, onClose }) => (
  <div style={{ position: "relative" }}>
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
);

const Menu = (props) => {
  const shadow = "hsla(218, 50%, 10%, 0.1)";
  return (
    <div
    className="selectDropInnerDrop"
      style={{
        backgroundColor: "white",
        borderRadius: 4,
        boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
        marginTop: 8,
        position: "absolute",
        zIndex: 2,
      }}
      {...props}
    />
  );
};
const Blanket = (props) => (
  <div
    style={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: "fixed",
      zIndex: 1,
    }}
    {...props}
  />
);

const DropdownIndicator = () => (
  <div style={{ color: colors.neutral20, height: 24, width: 32 }}>
    <svg>
      <path
        d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </div>
);

export default SearchableSelect;
