import React, { Component, useState } from "react";
import "./style.scss";
import { productService } from "../../_services/products.service";
import { productsUserService } from "../../_services/products_user.service";

import SelectInput from "../../widgets/Form/Select/SelectInput";
import PopupUsers from "./PopupUsers";
import { toast } from "react-toastify";
import ConfirmationPopup from "../../widgets/Popups/Confirmation-Popup";
import CHOICES from "../../_helpers/CHOICES";
import { AuthService } from "../../_services/auth.service";
import SelectInputChoises from "../../widgets/Form/Select/SelectInputChoises";
import Tooltip from "../../widgets/Tooltip/Tooltip";

import {
  SUCCESS_MESSAGES,
  CONTENT_MESSAGES,
  ERROR_MESSAGES,
} from "../../constants/app-constants";

const ProductUser = (props) => {
  const accessModule = AuthService.getAllAccessList("products_user");


  const current_user = AuthService.getUsername();

  let user_kind = JSON.parse(localStorage.getItem("localUser")).kind
  // console.log("kind 111",user_kind)
  //
  const [showPopup, setShowPopup] = useState(false);
  const [userList, setUserList] = useState(null);
  const [isOwner, setIsOwner] = useState(false);
  const [confirmData, setConfirmData] = useState({
    title: "",
    message: "",
    closeLabel: "",
    okLabel: "",
    isShow: false,
    selectedId: "",
  });

  const [error, setError] = useState({
    faildToLoad: false,
  });

  React.useEffect(() => {
    props?.prod_key ? getUsers() : "";
  }, [props?.prod_key]);

  function togglePopup() {
    setShowPopup(!showPopup);
  }

  function addUser(event) {
    togglePopup();
    props?.isLoading(true);
    event.role = CHOICES?.UserRole?.CHOICE_MAP?.Member;
    productsUserService.createProductUser(props?.prod_key, event).then((resp) => {
      if (resp.status == 200 && resp?.data) {
        toast.success(SUCCESS_MESSAGES.add);

        getUsers();
      } else {
        if (resp?.status === 422) {
          toast.error(resp?.data?.detail[0].msg);
        } else {
          toast.error(
            resp?.data?.detail ? resp?.data?.detail : resp?.data?.error
          );
        }
        props?.isLoading(false);
      }
    });
  }

  function getUsers() {
    props?.isLoading(true);
    productsUserService.getAllProductUser(props?.prod_key).then((users) => {
      if (users.status == 200 && users?.data) {
        const options = users?.data.map((d) => ({
          id: d.id,
          role: d.role,
          user: d.user,
        }));

        setUserList(options);
        let isOwner = Object.keys(options).some(function (k) {
          return (
            options[k].user.username === current_user &&
            options[k].role === CHOICES?.UserRole?.OWNER
          );
        });
        setIsOwner(isOwner);
        props?.isLoading(false);
        setError({
          ...error,
          faildToLoad: false,
        });
      } else {
        props?.isLoading(false);
        // if (users?.status === 422) {
        //     toast.error(users?.data?.detail[0].msg);
        // } else {
        //     toast.error(users?.data?.detail);
        // }
        setError({
          ...error,
          faildToLoad: true,
        });
      }
    });
  }

  function handleRemoveUser(id) {
    setConfirmData({
      ...confirmData,
      title: "Alert",
      message: ERROR_MESSAGES.reConformMessage,
      closeLabel: "Cancel",
      okLabel: "Confirm",
      isShow: true,
      selectedId: id,
    });
  }

  function updateUser(event, user) {
    props?.isLoading(true);

    let req = {
      user_id: user?.user?.id,
      role: event,
    };

    //

    productsUserService.updateProductUser(user.id, req).then((resp) => {
      //
      // console.log("resp",resp)
      if (resp.status == 200 && resp?.data) {
        toast.success(SUCCESS_MESSAGES.update);
        getUsers();
      } else {
        if (resp?.status === 422) {
          toast.error(resp?.data?.detail[0].msg);
        } else {
          toast.error(
            resp?.data?.detail ? resp?.data?.detail : resp?.data?.error
          );
        }
        props?.isLoading(false);
      }
    });

    // event.preventDefault();
  }

  function deleteConfirm(id) {
    closeConfirm();
    props?.isLoading(true);
    productsUserService.deleteProductUser(id).then((resp) => {
      // //
      if (resp.status == 200 && resp?.data) {
        if(resp?.data?.success){
          toast.success(SUCCESS_MESSAGES.delete);
          getUsers();
        }else{
          toast.error(ERROR_MESSAGES.unableDeleteUser);
          getUsers();
        }
       
      } else {
        if (resp?.status === 422) {
          toast.error(resp?.data?.detail[0].msg);
        } else {
          toast.error(
            resp?.data?.detail ? resp?.data?.detail : resp?.data?.error
          );
        }
        props?.isLoading(false);
      }
    });
  }

  function closeConfirm() {
    setConfirmData({
      ...confirmData,
      isShow: false,
      selectedId: "",
    });
  }

  function handleChangeFormSelect(event, user) {
    Object.keys(event).forEach(function (key) {
      updateUser(event[key], user);
    });
  }
//  console.log({accessModule, isOwner, user_kind})

  return (
    <>
      {showPopup ? (
        <PopupUsers
          title="Find User"
          closePopup={togglePopup}
          addSelectedUser={addUser}
        />
      ) : null}

      {confirmData.isShow ? (
        <ConfirmationPopup
          data={confirmData}
          confirmCloseEvent={closeConfirm}
          confirmOkEvent={() => deleteConfirm(confirmData?.selectedId)}
        />
      ) : (
        ""
      )}

      <div className="col-12 border-bottom">
        <div className="d-flex align-items-start justify-content-between">
          <div className="d-flex flex-column">
            <h4 className="mb-1 fw-500 fs-14">Users</h4>
            <p className="fs-12 mb-2">
              {CONTENT_MESSAGES.productUsersTabTitalDesc}
            </p>
          </div>
          <div className="d-flex align-items-center justify-content-between">
             <i
                aria-hidden="true"
                data-tip
                data-for={!accessModule.add && "addTip"}
              >
            <button
              disabled={!accessModule.add}
              className="btn btn-primary btn-sm"
              onClick={togglePopup}
            >
              Add User
            </button>
            </i>
            <Tooltip
              id="addTip"
              place="left"
              effect="solid"
              msgType="operation"
            />
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table data-table data-table-vertically-center  ">
          <thead className="table-primary">
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Username</th>
              <th>
                Role
                {/* {(!accessModule.update || !isOwner) && <i className="fa fa-info-circle ms-2" aria-hidden="true" data-tip data-for="updateTip"></i>} */}
              </th>
              <th>
                {/* {(!accessModule.delete || !isOwner) && <i className="fa fa-info-circle ms-2" aria-hidden="true" data-tip data-for="deleteTip"></i>}    */}
              </th>
            </tr>
            {/* <Tooltip id="updateTip" place="left" effect="solid" msgType="operation" /> */}
            {/* <Tooltip id="deleteTip" place="left" effect="solid" msgType="operation" /> */}
          </thead>
          <tbody>
            {userList && userList.length > 0 ? (
              userList.map((user, idx) => (
                <tr id={"tr_" + idx} key={idx}>
                  <td>
                    {user?.user?.first_name + " " + user?.user?.last_name}
                  </td>
                  <td>{user?.user?.email}</td>
                  <td>{user?.user?.username}</td>
                  <td>
                    <div className="form-group">
                      <i
                        aria-hidden="true"
                        data-tip
                        data-for={
                          (user_kind !== "Admin" && !isOwner && !accessModule.update) || (!isOwner && !accessModule.update) || (!accessModule.update) && "updateTip"
                        }
                      >
                        <SelectInputChoises
                          label=""
                          isRequired={true}
                          onChangeInput={(event) =>
                            handleChangeFormSelect(event, user)
                          }
                          name="role"
                          optionArray={CHOICES?.UserRole?.CHOICE_LIST}
                          nameClass="form-control custom-form-lh2 custom-select"
                          isDisabled={ (user_kind !== "Admin" && !isOwner && !accessModule.update) || (!isOwner && !accessModule.update) || (!accessModule.update)}
                          //  !isOwner || !accessModule.update)
                          //  || !isOwner

                          selectedOption={user?.role}
                        />
                      </i>
                      {/* || user?.role != CHOICES?.UserRole?.OWNER || user?.role == CHOICES?.UserRole?.MEMBER*/}
                      <Tooltip
                        id="updateTip"
                        place="left"
                        effect="solid"
                        msgType="operation"
                      />
                    </div>
                  </td>
                  <td
                    data-tip
                    data-for={
                      (user_kind !== "Admin" && !isOwner && !accessModule.delete) || (!isOwner && !accessModule.delete) || (!accessModule.delete) && "deleteTip"
                    }
                  >
                    <button
                      disabled={ 
                        (user_kind !== "Admin" && !isOwner && !accessModule.delete) || (!isOwner && !accessModule.delete) || (!accessModule.delete)
                        
                       }
                      onClick={() => handleRemoveUser(user?.id)}
                      className="pull-right btn link text-danger"
                    >
                      <i className="fa fa-trash" aria-hidden="true"></i>
                    </button>
                    <Tooltip
                      id="deleteTip"
                      place="left"
                      effect="solid"
                      msgType="operation"
                    />
                  </td>
                </tr>
              ))
            ) : error.faildToLoad ? (
              <tr>
                <td colSpan={6} className="text-center text-danger">
                  {ERROR_MESSAGES.failedLoadRecordsError}
                </td>
              </tr>
            ) : (
              <tr>
                <td colSpan={6} className="text-center">
                  {ERROR_MESSAGES.noRecordsFound}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};
export default ProductUser;
