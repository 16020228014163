// DON'T EDIT. THIS FILE IS GENERATED BY
// ./manage.py choices_export
// CHANGE MADES MANUALLY TO THIS FILE WILL BE LOST
// mynamespace/contribs/management/commands/choices_export.py
const CHOICES = {
  "BuildResult": {
    "CHOICE_LIST": [
      {
        "label": "Failure",
        "value": "Failure"
      },
      {
        "label": "Success",
        "value": "Success"
      },
      {
        "label": "Unknown",
        "value": "Unknown"
      }
    ],
    "CHOICE_MAP": {
      "Failure": "Failure",
      "Success": "Success",
      "Unknown": "Unknown"
    },
    "FAILURE": "Failure",
    "SUCCESS": "Success",
    "UNKNOWN": "Unknown"
  },
  "BuildStatus": {
    "CHOICE_LIST": [
      {
        "label": "Error",
        "value": "Error"
      },
      {
        "label": "In Progress",
        "value": "InProgress"
      },
      {
        "label": "Requested",
        "value": "Requested"
      },
      {
        "label": "Succeeded",
        "value": "Succeeded"
      }
    ],
    "CHOICE_MAP": {
      "Error": "Error",
      "InProgress": "In Progress",
      "Requested": "Requested",
      "Succeeded": "Succeeded"
    },
    "ERROR": "Error",
    "IN_PROGRESS": "InProgress",
    "REQUESTED": "Requested",
    "SUCCEEDED": "Succeeded"
  },
  "ExecutionKind": {
    "CHOICE_LIST": [
      {
        "label": "Local",
        "value": "Local"
      },
      {
        "label": "Remote",
        "value": "Remote"
      },
      {
        "label": "Unknown",
        "value": "Unknown"
      }
    ],
    "CHOICE_MAP": {
      "Local": "Local",
      "Remote": "Remote",
      "Unknown": "Unknown"
    },
    "LOCAL": "Local",
    "REMOTE": "Remote",
    "UNKNOWN": "Unknown"
  },
  "ReportKind": {
    "CHOICE_LIST": [
      {
        "label": "Robotframework output file",
        "value": "File"
      },
      {
        "label": "Report Portal Launch for the test",
        "value": "RpLaunch"
      },
      {
        "label": "Remote browser session link",
        "value": "Session"
      }
    ],
    "CHOICE_MAP": {
      "File": "Robotframework output file",
      "RpLaunch": "Report Portal Launch for the test",
      "Session": "Remote browser session link"
    },
    "FILE": "File",
    "RP_LAUNCH": "RpLaunch",
    "SESSION": "Session"
  },
  "TestType": {
    "APIBehaviourREST": "APIBehaviourREST",
    "APIPerformanceREST": "APIPerformanceREST",
    "CHOICE_LIST": [
      {
        "label": "Acceptance test of REST APIs",
        "value": "APIBehaviourREST"
      },
      {
        "label": "Performance test of REST APIs",
        "value": "APIPerformanceREST"
      },
      {
        "label": "Acceptance test of Mobile Web applications",
        "value": "MobileWebAppBehaviour"
      },
      {
        "label": "Performance test of Mobile Web applications",
        "value": "MobileWebAppPerformance"
      },
      {
        "label": "Acceptance test of Mobile native applications",
        "value": "NativeAppBehaviour"
      },
      {
        "label": "Performance test of Mobile native applications",
        "value": "NativeAppPerformance"
      },
      {
        "label": "Acceptance test of Web applications",
        "value": "WebAppBehaviour"
      },
      {
        "label": "Performance test of Web applications",
        "value": "WebAppPerformance"
      }
    ],
    "CHOICE_MAP": {
      "APIBehaviourREST": "Acceptance test of REST APIs",
      "APIPerformanceREST": "Performance test of REST APIs",
      "MobileWebAppBehaviour": "Acceptance test of Mobile Web applications",
      "MobileWebAppPerformance": "Performance test of Mobile Web applications",
      "NativeAppBehaviour": "Acceptance test of Mobile native applications",
      "NativeAppPerformance": "Performance test of Mobile native applications",
      "WebAppBehaviour": "Acceptance test of Web applications",
      "WebAppPerformance": "Performance test of Web applications"
    },
    "MobileWebAppBehaviour": "MobileWebAppBehaviour",
    "MobileWebAppPerformance": "MobileWebAppPerformance",
    "NativeAppBehaviour": "NativeAppBehaviour",
    "NativeAppPerformance": "NativeAppPerformance",
    "WebAppBehaviour": "WebAppBehaviour",
    "WebAppPerformance": "WebAppPerformance"
  },
  "UomKind": {
    "CHOICE_LIST": [
      {
        "label": "Parallels",
        "value": "Parallels"
      },
      {
        "label": "Unlimited",
        "value": "Unlimited"
      },
      {
        "label": "User Hours",
        "value": "UserHours"
      }
    ],
    "CHOICE_MAP": {
      "Parallels": "Parallels",
      "Unlimited": "Unlimited",
      "UserHours": "User Hours"
    },
    "PARALLELS": "Parallels",
    "UNLIMITED": "Unlimited",
    "USER_HOUR": "UserHours"
  },
  "UserKind": {
    "ADMIN": "Admin",
    "CHOICE_LIST": [
      {
        "label": "Admin",
        "value": "Admin"
      },
      {
        "label": "Product",
        "value": "Product"
      },
      {
        "label": "System",
        "value": "System"
      }
    ],
    "CHOICE_MAP": {
      "Admin": "Admin",
      "Product": "Product",
      "System": "System"
    },
    "PRODUCT": "Product",
    "SYSTEM": "System"
  },
  "UserRole": {
    "CHOICE_LIST": [
      {
        "label": "Member",
        "value": "Member"
      },
      {
        "label": "Owner",
        "value": "Owner"
      }
    ],
    "CHOICE_MAP": {
      "Member": "Member",
      "Owner": "Owner"
    },
    "MEMBER": "Member",
    "OWNER": "Owner"
  }
};
export default CHOICES;
