import { Link, useLocation, navigate } from "@reach/router";
import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { coreEngineService } from "../../_services/coreengine.service";
import Loader from "../../widgets/loader/Loader";
import CoreEngineSidebar from "./CoreEngineSidebar";
import CoreEngineForm from "./CoreEngineForm";
import { FETCH_LIMIT, ERROR_MESSAGES } from "../../constants/app-constants";
import { toast } from "react-toastify";

const CoreEngineNew = (props) => {
  const search = useLocation().search;
  const location = useLocation();

  const [loadMore, setLoadMore] = useState(true);

  const [loading, setLoading] = useState(false);

  const [isEdit, setIsEdit] = useState(true);

  const [coreEngineList, setCoreEngineList] = useState(null);

  const [selectedDropdown, setSelectedDropdown] = useState({
    showFilter: false,
    limit: FETCH_LIMIT,
    offset: 0,
    pageNumber: 0,
  });

  const [errorSide, setErrorSide] = useState({
    isError: false,
    errMsg: "",
  });

  const [isLoadingSidebar, setIsLoadingSidebar] = useState(false);

  React.useEffect(() => {
    getAllCoreEngine();
  }, []);

  function getAllCoreEngine() {
    // setLoading(true)
    setIsLoadingSidebar(true);
    coreEngineService
      .getAllCoreEngine(selectedDropdown.limit, selectedDropdown.offset)
      .then((resp) => {
        if (resp.status == 200 && resp?.data) {
          setCoreEngineList(resp.data);
          // setLoading(false)
          setIsLoadingSidebar(false);
          setErrorSide({
            ...errorSide,
            isError: false,
            errMsg: "",
          });
        } else if (resp?.status == 408) {
          setErrorSide({
            ...errorSide,
            isError: true,
            errMsg: ERROR_MESSAGES.timeExceedError,
          });
          //   setLoading(false);
          setIsLoadingSidebar(false);
        } else {
          setErrorSide({
            ...errorSide,
            isError: true,
            errMsg: ERROR_MESSAGES.failedLoadRecordsError,
          });
          // setLoading(false)
          setIsLoadingSidebar(false);
          toast.error(
            resp?.data?.detail ? resp?.data?.detail : resp?.data?.error
          );
        }
        // toast.error(resp?.data?.detail ? resp?.data?.detail : resp?.data?.error);
      });
  }

  function handelLoading(event) {
    setLoading(event);
  }

  function fetchMoreData(e) {
    // if(e){
    //     let pgNo = selectedValue.pageNumber+1;
    //     let offset = pgNo*selectedValue.limit;
    //     setSelectedValue({...selectedValue, pageNumber : pgNo})
    //     let appReq = {
    //       "q":selectedValue.q,
    //       "limit":selectedValue.limit,
    //       "offset":offset
    //     }
    //     featureService.getAllFeatures(appReq).then(applist => {
    //       if (applist.status == 200 && applist?.data) {
    //         if( applist?.data.length > 0){
    //           let newlist = featurelist.concat( applist?.data)
    //           setLoadMore(true)
    //           setFeaturesList(newlist)
    //         }else{
    //           setLoadMore(false)
    //         }
    //       }
    //     })
    //   }
  }

  return (
    <div className="containWrap-inner">
      {loading ? <Loader /> : ""}

      {/* {error.errorStatus ? (<ErrorPopup data={error.errorData} closePopup={closeError} />) : ''} */}
      <div className="col-12">
        <div className="row">
          <div className="col-4">
            <Card className="bg-white custom-card list-option h-100">
              <Card.Header className="shadow-none p-3">
                <div className="d-flex align-items-center justify-content-between">
                  <Card.Title>
                    <h4 className="m-0">Core Engines</h4>
                  </Card.Title>
                </div>
              </Card.Header>
              <Card.Body>
                {isLoadingSidebar ? (
                  <div className="text-center"> Please Wait....</div>
                ) : errorSide.isError ? (
                  <div className="text-center text-danger">
                    {" "}
                    {errorSide.errMsg}
                  </div>
                ) : (
                  <CoreEngineSidebar
                    sidedata={coreEngineList}
                    getMoreData={fetchMoreData}
                    loadFeature={loadMore}
                  />
                )}
              </Card.Body>
            </Card>
          </div>
          <div className="col-8 ps-0">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between">
                <Card className="bg-white custom-card custom-card-full">
                  <Card.Header className="shadow-none p-3">
                    <div className="d-flex align-items-center justify-content-between">
                      <Card.Title className="m-0">
                        <h4 className="m-0">Add New Core Engine</h4>
                      </Card.Title>
                      <Link className="no-link" to={`/app/core-engines`}>
                        <i className="fa fa-times" aria-hidden="true"></i>
                      </Link>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <CoreEngineForm
                      isNew={isEdit}
                      isLoading={handelLoading}
                      isEditForm={false}
                    />
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoreEngineNew;
