import React, { useState } from "react";
import "./style.scss";
import { navigate } from "@reach/router";

const Form = (props) => {
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  return (
    <form
      className="form text-start"
      method="post"
      onSubmit={(event) => {
        props.handleSubmit(event);
      }}
    >
      <div className="form-group custom-login-form">
        <label className={"fw-500 fs-18"}>
          Username<sup className="text-danger">*</sup>
        </label>
        <input
          className="form-control custom-form-lh2"
          type="text"
          name="username"
          onChange={props.handleUpdate}
          onKeyUp={props.handleUpdate}
          required
          autoComplete="false"
        />
      </div>
      <div className="form-group custom-login-form mt-3">
        <label className={"fw-500 fs-18"}>
          Password<sup className="text-danger">*</sup>
        </label>
        <div className="pass-wrapper">
          <input
            className="form-control custom-form-lh2"
            type={passwordShown ? "text" : "password"}
            name="password"
            onChange={props.handleUpdate}
            onKeyUp={props.handleUpdate}
            required
            minLength={3}
            maxLength={40}
            autoComplete="false"
          />
          <span onClick={togglePasswordVisiblity}>
            {passwordShown ? (
              <i className="fa fa-eye" aria-hidden="true"></i>
            ) : (
              <i className="fa fa-eye-slash" aria-hidden="true"></i>
            )}
          </span>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-end ">
        <input
          disabled={!props.isValidform}
          className="btn btn-primary mt-3"
          type="submit"
          value="Log In"
        />
      </div>
    </form>
  );
};

export default Form;
