import { Link, useLocation, navigate } from "@reach/router";

import React, { useState } from "react";
import { Card } from "react-bootstrap";

import { applicationService } from "../../_services/application.service";
import Loader from "../../widgets/loader/Loader";
import ErrorPopup from "../../widgets/Popups/Error-Popup";
import ApplicationSidebar from "./ApplicationSidebar";
import ApplicationForm from "./ApplicationForm";

const ApplicationNew = (props) => {
  const search = useLocation().search;
  const location = useLocation();
  const prod_key_from_url = new URLSearchParams(search).get("product");
  const [app_key, setApp_key] = useState("");
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState({
    errorStatus: false,
    errorData: "",
  });
  const [appList, setAppList] = useState(null);

  const [isEdit, setIsEdit] = useState(true);

  function closeError() {
    setError({ ...error, errorStatus: false });
    window.localStorage.removeItem("localUser");
    navigate(`/app/login`);
  }

  React.useEffect(() => {
    getAppList();
  }, [location]);

  function getAppList() {
    setLoading(true);
    applicationService.getAllApplication(prod_key_from_url).then((apps) => {
      if (apps.status == 200 && apps?.data) {
        const options = apps?.data.map((d) => ({
          app_key: d.app_key.trim(),
          name: d.name.trim(),
          enabled: d.enabled,
        }));
        setAppList(options);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  }

  function handelLoading(event) {
    setLoading(event);
  }

  return (
    <div className="containWrap-inner">
      {loading ? <Loader /> : ""}
      {error.errorStatus ? (
        <ErrorPopup data={error.errorData} closePopup={closeError} />
      ) : (
        ""
      )}
      <div className="col-12">
        <div className="row">
          <div className="col-4">
            <Card className="bg-white custom-card list-option h-100">
              <Card.Header className="shadow-none p-3">
                <div className="d-flex align-items-center justify-content-between">
                  <Card.Title>
                    <h4 className="m-0">Applications</h4>
                  </Card.Title>
                </div>
                {prod_key_from_url && (
                  <>
                    <span className="fs-16 me-2">of</span>
                    <Link
                      to={`/app/products/${prod_key_from_url}`}
                      className="fs-16 m-0 link text-primary"
                    >
                      {prod_key_from_url}
                    </Link>
                  </>
                )}
              </Card.Header>
              <Card.Body>
                <div className="h-100 d-flex flex-column">
                  <ApplicationSidebar
                    appList={appList}
                    prod_key={prod_key_from_url}
                  />
                </div>
              </Card.Body>
            </Card>
          </div>
          <div className="col-8 ps-0">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between">
                <Card className="bg-white custom-card custom-card-full">
                  <Card.Header className="shadow-none p-3">
                    <div className="d-flex align-items-center justify-content-between">
                      <Card.Title className="m-0">
                        <h4 className="m-0">Add New Application</h4>
                      </Card.Title>
                      <Link
                        className="no-link"
                        to={`/app/products/${prod_key_from_url}`}
                      >
                        <i className="fa fa-times" aria-hidden="true"></i>
                      </Link>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <ApplicationForm
                      isNew={isEdit}
                      isLoading={handelLoading}
                      isEditForm={false}
                      prod_key={prod_key_from_url}
                    />
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationNew;
