import React from "react";
import { Card } from "react-bootstrap";
import "./styleModel.scss";
import CHOICES from "../../_helpers/CHOICES";

const PopupMedium = (props) => {
  const data = props.testReport;

  return (
    <div className="popup">
      <div className="popup_inner">
        <Card className="bg-white custom-card" style={{ border: 0 }}>
          <Card.Header className="bg-gray">
            <div className="row">
              <div className="col-12">
                <div className="d-flex align-items-center justify-content-between">
                  <Card.Title className="card-title fs-22">
                    {props.title}
                  </Card.Title>
                  {/* -({props.reportId}) */}
                  <button className="close bg-gray" onClick={props.closePopup}>
                    X
                  </button>
                </div>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <div className="row">
              <div className="col-12">
                <div className="table-responsive">
                  <table className="table table-bordered data-table">
                    <tbody>
                      {data &&
                        data.length > 0 &&
                        data &&
                        data.map((appone, index) => (
                          <tr key={index}>
                            <td>
                              <div>
                                <div>
                                  <b>
                                  {appone?.name}
                                  </b>
                                </div>
                                <div>
                                  {appone?.kind === CHOICES.ReportKind.FILE && (
                                    <div>
                                      {CHOICES.ReportKind.CHOICE_MAP.File}
                                    </div>
                                  )}
                                  {appone?.kind ===
                                    CHOICES.ReportKind.RP_LAUNCH && (
                                    <div>
                                      {CHOICES.ReportKind.CHOICE_MAP.RpLaunch}
                                    </div>
                                  )}
                                  {appone?.kind ===
                                    CHOICES.ReportKind.SESSION && (
                                    <div>
                                      {CHOICES.ReportKind.CHOICE_MAP.Session}
                                    </div>
                                  )}
                                  {appone?.kind !== CHOICES.ReportKind.FILE &&
                                    appone?.kind !==
                                      CHOICES.ReportKind.RP_LAUNCH &&
                                    appone?.kind !==
                                      CHOICES.ReportKind.SESSION && (
                                      <div>Unknown</div>
                                    )}
                                </div>
                              </div>
                            </td>
                            <td className="text-end">
                              <a
                                target={"_blank"}
                                className="link"
                                href={appone?.url}
                              >
                                View
                              </a>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default PopupMedium;
