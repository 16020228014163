import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import "./style.scss";

import { Link } from "@reach/router";
import { ERROR_MESSAGES } from "../../constants/app-constants";

const ProductSidebar = (props) => {
  function fetchMoreData() {
    props?.getMoreData(true);
  }

  return (
    <div className="mngScroll_outer">
      {props?.productList ? (
        props?.productList < 1 ? (
          <div className="text-center text-danger">
            {" "}
            {ERROR_MESSAGES.noRecordsFound}
          </div>
        ) : (
          <div id="scrollableDiv" className="app-build-list mngScroll_Content">
            <InfiniteScroll
              dataLength={props?.productList.length}
              next={fetchMoreData}
              hasMore={props?.loadmore}
              loader={
                props?.sidedata?.length < 1 ? (
                  <p className="fw-600 fs-14 text-center">
                    {ERROR_MESSAGES.noRecordsFound}
                  </p>
                ) : (
                  <h4>Loading...</h4>
                )
              }
              scrollableTarget="scrollableDiv"
            >
              {props?.productList &&
                props?.productList.map((appone, index) => (
                  <div
                    className={`${
                      appone.enabled
                        ? "scrollChild"
                        : "scrollChild row-disabled-sidebar"
                    }`}
                    key={index}
                  >
                    <Link
                      to={"/app/products/" + appone.prod_key}
                      className="no-link"
                    >
                      <span className="fw-600 fs-16 mb-1 click-able">
                        {" "}
                        {appone.name}{" "}
                      </span>{" "}
                      <br />
                      <span className="fs-14 mb-1">
                        {" "}
                        {appone.prod_key}, {appone.owner}{" "}
                      </span>
                    </Link>
                  </div>
                ))}
            </InfiniteScroll>
          </div>
        )
      ) : (
        <div className="text-center text-danger">
          {" "}
          {ERROR_MESSAGES.noRecordsFound}
        </div>
      )}
    </div>
  );
};

export default ProductSidebar;
