import React, { useState, useEffect } from "react";
import "./style.scss";
import { productService } from "../../_services/products.service";
import { buildsService } from "../../_services/builds.service";
import { buildsApplicationService } from "../../_services/builds_application.service";
import Inputcomp from "../../widgets/Form/input/Input";
import CheckboxComponent from "../../widgets/Form/Checkbox/CheckboxComponent";
import SelectInput from "../../widgets/Form/Select/SelectInput";
import { useLocation, navigate } from "@reach/router";
import Badge from "react-bootstrap/Badge";
import Form from "react-bootstrap/Form";
import ErrorPopup from "../../widgets/Popups/Error-Popup";
import BadgeComp from "../../widgets/Badges/BadgeComp";
import CHOICES from "../../_helpers/CHOICES";
import { toast } from "react-toastify";
import {
  REQUIRED_ERROR,
  CONTENT_MESSAGES,
  ERROR_MESSAGES,
  BUILD_ARGS,
  PARALLEL_ARGS,
  ZEPHY_ARGS,
} from "../../constants/app-constants";

interface CopyKey {
  isCopy: string;
}

interface TestEngine {
  capacity_units: number;
  capacity_uom: string;
  execution: string;
  id: string;
  name: string;
  test_type: string;
}

interface ExecutionEngine {
  name: string;
  execution_engine_key: string;
}

interface ArrArgs {
  rows: Array<String>;
  buildArgs: Array<String>;
  rowsParallelArgs: Array<String>;
  rowsZephyrArgs: Array<String>;
  parallelArgs: Array<String>;
  zephyrArgs: Array<String>;
}

const BuildsForm = (props) => {
  const search = useLocation().search;
  const location = useLocation();
  const appFromUrl = new URLSearchParams(search).get("app");
  const failed_builds_only_FromUrl = new URLSearchParams(search).get(
    "failed_builds_only"
  );
  const my_builds_only_FromUrl = new URLSearchParams(search).get(
    "my_builds_only"
  );
  
  const q_FromUrl = new URLSearchParams(search).get("q");

  const [error, setError] = useState({
    errorStatus: false,
    errorData: "",
    isValid: false,
    name: "Build Name Required",
    repo_url: "GIT Repo URL Required",
    test_type: "Test Engine Required",
    build_dir: "Script Location/ Directory in Repo Required",
  });

  const [coreDropdown, setCoreDropdown] = useState([]);
  const [executionEngineDropdown, setExecutionEngineDropdown] = useState([])
  const [defaultCoreEngine, setDefaultCoreEngine] = useState<
    string | undefined
  >(undefined);
  const [defaultExecutionEngine, setDefaultExecutionEngine] = useState<
    string | undefined
  >(undefined);

  const [copyKey, setCopyKey] = useState({
    isCopy: "0",
  });

  const [appTestForm, setAppTestForm] = useState({
    name: "",
    url: "",
    commitid: "",
    core_engine: "",
    build_args: null,
    parallel_args: null,
    scriptdirectory: "",
    execution: "",
    execution_engine_key: "",
    appId: "",
    app_key: props?.app,
    auth_key: props?.auth,
    capacity_units: "-",
    core_engine_id: "",
    capacity_uom: "",
    repo_branch_name: "",
    repo_url: "",
    repo_access_id: "",
    repo_access_key: "",
    parallel_run: false,
    pabot_args: {},
    rp_upload_results_eot: false,
    update_results_in_reportportal: false,
    build_dir: ".",
    is_errored: false,
    error_message: "",
    no_of_re_runs: 0,
    update_results_to_zephyr: false,
    zephyr_cycle_custom_fields: {},
    test_type: "",
  });

  const [state, setState] = useState({
    rows: [],
    buildArgs: [],
    rowsParallelArgs: [],
    rowsZephyrArgs: [],
    zephyrArgs: [],
    parallelArgs: [],
  });

  useEffect(() => {
    props?.appDetail?.application?.app_key
      ? getCoreEngines(props?.appDetail?.application?.app_key)
      : getCoreEngines(props?.app);

    getExecutionEngines()

    state.rows.length === 0 ? handleAddRow(BUILD_ARGS) : "";
  }, []);

  const getCoreEngines = (param) => {
    buildsService.getCoreEngines(param).then((coreListResp) => {
      if (coreListResp.status === 200 && coreListResp?.data) {
        const coreList = coreListResp?.data?.map((d) => ({
          id: d.core_engine.id,
          test_type: d.core_engine.test_type,
          name: d.core_engine.test_type + " - " + d.core_engine.engine_key,
          execution: d.core_engine.execution,
          capacity_units: d.core_engine.capacity_units,
          capacity_uom: d.core_engine.capacity_uom,
        }));
        setCoreDropdown(coreList);
        setDefaultCoreEngine(coreList[0].id);
        setAppTestForm({
          ...appTestForm,
          core_engine: coreList[0].test_type,
          capacity_units: coreList[0].capacity_units,
          execution: coreList[0].execution,
          core_engine_id: coreList[0].test_type,
          capacity_uom: coreList[0].capacity_uom,
        });
      }
    });
  };

  const getExecutionEngines = () => {
    buildsService.getExecutionEngines().then((executionEngineResp) => {
      if (executionEngineResp.status === 200 && executionEngineResp?.data) {
        const executionEngineList = executionEngineResp?.data?.map((d) => ({
          execution_engine_key: d.execution_engine_key,
          name: d.name
        }));
        let robotIndex = executionEngineList.findIndex(engine => engine.execution_engine_key === 'ROBOT');
        if (robotIndex !== -1) {
          let robotEntry = executionEngineList.splice(robotIndex, 1)[0];
          executionEngineList.unshift(robotEntry);
        }
        setExecutionEngineDropdown(executionEngineList);
        setDefaultExecutionEngine(executionEngineList[0].execution_engine_key);
        
        setAppTestForm({
          ...appTestForm,
          execution_engine_key: executionEngineList[0].execution_engine_key
        });
        
      }
    });
  };

  const getApplicationDetails = () => {
    const appidParam = new URLSearchParams(search).get("app");
    if (appidParam) {
      // getCoreEngines(appidParam)
      buildsApplicationService
        .getApplicationDetails(appidParam)
        .then((appbuildResp) => {
          if (appbuildResp.status == 200 && appbuildResp?.data) {
            setAppTestForm({
              ...appTestForm,
              app_key: appidParam,
              auth_key: appbuildResp?.data?.framework_auth_key,
            });
          }
        });
    }
  };

  const copyToClipboard = (key) => {
    switch (key) {
      case "buildid":
        setCopyKey({ ...copyKey, isCopy: "1" });
        navigator.clipboard.writeText(props?.appDetail.id);
        break;
      case "authkey":
        setCopyKey({ ...copyKey, isCopy: "2" });
        navigator.clipboard.writeText(
          props?.appDetail?.application?.framework_auth_key
        );
        break;
      default:
        break;
    }

    setTimeout(() => {
      setCopyKey({ ...copyKey, isCopy: "0" });
    }, 3000);
  };

  const handleChangeForm = (event) => {
    Object.keys(event).forEach(function (key) {
      setAppTestForm({ ...appTestForm, [key]: event[key] });
    });
    error.isValid && validateForm();
  };

  const handleChangeFormCore = (event) => {
    if (event.target.value !== "") {
      getEngine(event.target.value);
    }
    error.isValid && validateForm();
  };

  const handleChangeFormExecution = (event) => {
    if (event.target.value !== "") {
      getExecutionEngine(event.target.value);
    }
    error.isValid && validateForm();
  };

  const handleCheckBoxs = (checked, name) => {
    if (name === "no_of_re_runs") {
      setAppTestForm((prevState) => ({
        ...prevState,
        no_of_re_runs: checked ? 1 : 0,
      }));
    } else {
      setAppTestForm((prevState) => ({
        ...prevState,
        [name]: checked,
      }));
    }

    if (name === "parallel_run" && checked) {
      state.rowsParallelArgs.length === 0 ? handleAddRow(PARALLEL_ARGS) : "";
    }

    if (name === "update_results_to_zephyr" && checked) {
      state.rowsZephyrArgs.length === 0 ? handleAddRow(ZEPHY_ARGS) : "";
    }
  };

  const getEngine = (val) => {
    setAppTestForm({
      ...appTestForm,
      core_engine: "",
      capacity_units: "-",
      execution: "",
      core_engine_id: "",
      capacity_uom: "",
    });

    const corelist = coreDropdown;
    corelist.filter((item: any) => {
      if (item.id == val) {
        setAppTestForm({
          ...appTestForm,
          core_engine: item.test_type,
          capacity_units: item.capacity_units,
          execution: item.execution,
          core_engine_id: item.test_type,
          capacity_uom: item.capacity_uom,
        });
      }
    });
  };

  const getExecutionEngine = (engine_key) => {
    setAppTestForm({
      ...appTestForm,
      execution_engine_key: ""
    })

    const executionEngineList = executionEngineDropdown;
    executionEngineList.filter((item: any) => {
      if (item.execution_engine_key == engine_key) {
        setAppTestForm({
          ...appTestForm,
          execution_engine_key: engine_key
        });
      }
    });
  }

  const onSubmit = (event) => {
    event.preventDefault();
    props?.onChange(true);

    if (!validateForm()) {
      toast.error(ERROR_MESSAGES.checkAllFieldsMessage);
      props?.onChange(false);
      return false;
    }

    let valueReq = {
      name: appTestForm.name,
      repo_url: appTestForm.url,
      repo_access_id: appTestForm.repo_access_id,
      repo_access_key: appTestForm.repo_access_key,
      repo_commit_sha: appTestForm.commitid,
      test_type: appTestForm.core_engine,
      build_args: appTestForm.build_args,
      build_dir: appTestForm.scriptdirectory,
      execution: appTestForm.execution,
      execution_engine_key: appTestForm.execution_engine_key,
      repo_branch_name: appTestForm.repo_branch_name,
      no_of_re_runs: appTestForm.no_of_re_runs,
      rp_upload_results_eot: appTestForm.rp_upload_results_eot,
      update_results_in_reportportal:
        appTestForm.update_results_in_reportportal,
      parallel_run: appTestForm.parallel_run,
      pabot_args: appTestForm.parallel_args,
      update_results_to_zephyr: appTestForm.update_results_to_zephyr,
      zephyr_cycle_custom_fields: appTestForm.zephyr_cycle_custom_fields,
    };
    
    // props?.onChange(false);

    buildsService
      .createAppBuild(valueReq, appTestForm.app_key, appTestForm.auth_key)
      .then((response) => {
        if (response.status == 200 && response?.data) {
          let app = `app=${appFromUrl}`;
          let failed_builds_only = `&failed_builds_only=${failed_builds_only_FromUrl}`;
          let my_builds_only = `&my_builds_only=${my_builds_only_FromUrl}`;
          let q = q_FromUrl ? `&q=${q_FromUrl}` : "";

          navigate(
            `/app/builds/${response?.data?.id}?${app}${failed_builds_only}${my_builds_only}${q}`
          );
          props?.onChange(false);
        } else if (response?.status == 408) {
          toast.error(ERROR_MESSAGES.timeExceedError);
          props?.onChange(false);
        } else {
          response?.status !== 500 ? toast.error(response?.data?.detail) : "";
          props?.onChange(false);
        }
      });
  };

  const validateForm = () => {
    if (
      appTestForm.name &&
      appTestForm.url &&
      appTestForm.core_engine &&
      appTestForm.scriptdirectory &&
      appTestForm.execution
    ) {
      setError({ ...error, isValid: false });
      return true;
    } else {
      setError({ ...error, isValid: true });
      return false;
    }
  };

  const getValues = (myObj) => {
    const renderValue = (value) => {
      if (Array.isArray(value)) {
        return value.map((val, index) => (
          <Badge key={index} className="primary me-2 fs-14">
            {val}
          </Badge>
        ));
      } else if (typeof value === 'object' && value !== null) {
        return (
          <Badge className="primary me-2 fs-14">
            {JSON.stringify(value)}
          </Badge>
        );
      } else {
        return (
          <Badge className="primary me-2 fs-14">
            {value}
          </Badge>
        );
      }
    };

    return Object.keys(myObj).map((key) => (
      <tr key={key}>
        <td>{key}</td>
        <td>{renderValue(myObj[key])}</td>
      </tr>
    ));
  };

  const handleChangeParallelArgs = (idx, fieldName, e) => {
    const { name, value } = e;

    const rowsParallelArgs: any = [...state.rowsParallelArgs];

    switch (fieldName) {
      case "name":
        rowsParallelArgs[idx] = {
          name: name,
          value: rowsParallelArgs[idx].value,
        };
        break;
      case "value":
        rowsParallelArgs[idx] = {
          name: rowsParallelArgs[idx].name,
          value: value.split(","),
        };
        break;
      default:
        break;
    }

    let result;
    if (rowsParallelArgs.length > 0) {
      result = rowsParallelArgs.reduce(function (r, e) {
        r[e.name] = e.value;
        return r;
      }, {});
    }

    setState({ ...state, rowsParallelArgs, parallelArgs: result });
    setAppTestForm({ ...appTestForm, parallel_args: result });
  };

  const handleChangeZephyrArgs = (idx, fieldName, e) => {
    const { name, value } = e;

    const rowsZephyrArgs: any = [...state.rowsZephyrArgs];

    switch (fieldName) {
      case "name":
        rowsZephyrArgs[idx] = {
          name: name,
          value: rowsZephyrArgs[idx].value,
        };
        break;
      case "value":
        rowsZephyrArgs[idx] = {
          name: rowsZephyrArgs[idx].name,
          value: value.split(","),
        };
        break;
      default:
        break;
    }

    let result;
    if (rowsZephyrArgs.length > 0) {
      result = rowsZephyrArgs.reduce(function (r, e) {
        r[e.name] = e.value;
        return r;
      }, {});
    }
    setState({ ...state, rowsZephyrArgs, zephyrArgs: result });
    setAppTestForm({ ...appTestForm, zephyr_cycle_custom_fields: result });
  };

  const handleChangeBuildArgs = (idx, fieldName, e) => {
    const { name, value } = e;

    const rows = [...state.rows];

    switch (fieldName) {
      case "name":
        rows[idx] = {
          name: name,
          value: rows[idx].value,
        };
        break;
      case "value":
        rows[idx] = {
          name: rows[idx].name,
          value: value.split(","),
        };
        break;
      default:
        break;
    }

    let result;
    if (rows.length > 0) {
      result = rows.reduce(function (r, e) {
        r[e.name] = e.value;
        return r;
      }, {});
    }

    setState({ ...state, rows, buildArgs: result });
    setAppTestForm({ ...appTestForm, build_args: result });
  };

  const handleAddRow = (addRowFor) => {
    const item = {
      name: "",
      value: "",
    };
    if (addRowFor === PARALLEL_ARGS) {
      setState({
        ...state,
        rowsParallelArgs: [...state.rowsParallelArgs, item],
      });
    } else if (addRowFor === ZEPHY_ARGS) {
      setState({
        ...state,
        rowsZephyrArgs: [...state.rowsZephyrArgs, item],
      });
    } else {
      setState({ ...state, rows: [...state.rows, item] });
    }
  };

  const handleRemoveRow = (idx, removeRowFor) => {
    let newList;
    if (removeRowFor === BUILD_ARGS) {
      newList = [...state.rows.slice(0, idx), ...state.rows.slice(idx + 1)];
      setState({ ...state, rows: newList });
    } else if (removeRowFor === ZEPHY_ARGS) {
      newList = [
        ...state.rowsZephyrArgs.slice(0, idx),
        ...state.rowsZephyrArgs.slice(idx + 1),
      ];
      setState({ ...state, rowsZephyrArgs: newList });
    } else {
      newList = [
        ...state.rowsParallelArgs.slice(0, idx),
        ...state.rowsParallelArgs.slice(idx + 1),
      ];
      setState({ ...state, rowsParallelArgs: newList });
    }
  };

  const closeError = () => {
    setError({ ...error, errorStatus: false });
  };

  return (
    <>
      {error.errorStatus ? (
        <ErrorPopup data={error.errorData} closePopup={closeError} />
      ) : (
        ""
      )}
      {props?.appDetail ? (
        <form noValidate>
          <div className="row mt-4">
            <div className="col-12 col-md-6">
              <div className="d-flex flex-column">
                <label className="mb-1 fw-500 fs-14">Product</label>
                <label className="fs-14">
                  {props?.appDetail?.application?.product?.name} (
                  {props?.appDetail?.application?.product?.prod_key})
                </label>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="d-flex flex-column">
                <label className="mb-1 fw-500 fs-14">Application</label>
                <label className="fs-14">
                  {props?.appDetail?.application?.name} (
                  {props?.appDetail?.application?.app_key})
                </label>
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-12 col-md-6">
              <Inputcomp
                type="text"
                nameClass="form-control custom-form-lh2"
                name="name"
                handleChange={false}
                isDisabled={true}
                label="Build Name"
                value={props?.appDetail.name}
              />
            </div>
            <div className="col-12 col-md-6">
              <div className="d-flex flex-column">
                <label className="mb-1 fw-500 fs-14">Build Id</label>
                <label className="fs-14">
                  <span className="me-2">{props?.appDetail.id}</span>
                  <span
                    className={
                      copyKey.isCopy == "1"
                        ? "bg-success badge border border-dark pe-none"
                        : "badge bg-light text-dark border border-dark shadow-sm pe-cursor"
                    }
                    onClick={() => copyToClipboard("buildid")}
                  >
                    {copyKey.isCopy == "1" ? "Copied" : "Copy"}
                    {/* <i className="fa fa-clone"></i>    */}
                  </span>
                </label>
              </div>

              {/* <Inputcomp
                                    type="text"
                                    nameClass="form-control custom-form-lh2"
                                    name="appId"
                                    handleChange={false}
                                    isDisabled={true}
                                    label="Build Id"
                                    value={props?.appDetail.id}
                                /> */}
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-12 col-md-6">
              <div className="d-flex flex-column">
                <label className="mb-1 fw-500 fs-14">Status / Result</label>
                <label className="fs-14">
                  <div className="d-flex result-capsule">
                    {props?.appDetail.status ===
                      CHOICES.BuildStatus.IN_PROGRESS && (
                        <BadgeComp
                          badgePosition={"top"}
                          badgeType={"bg-warning text-dark left"}
                          label={CHOICES.BuildStatus.CHOICE_MAP.InProgress}
                        />
                      )}
                    {props?.appDetail.status === CHOICES.BuildStatus.ERROR && (
                      <BadgeComp
                        badgePosition={"top"}
                        badgeType={"bg-danger left"}
                        label={CHOICES.BuildStatus.CHOICE_MAP.Error}
                      />
                    )}
                    {props?.appDetail.status ===
                      CHOICES.BuildStatus.REQUESTED && (
                        <BadgeComp
                          badgePosition={"top"}
                          badgeType={"bg-info text-dark left"}
                          label={CHOICES.BuildStatus.CHOICE_MAP.Requested}
                        />
                      )}
                    {props?.appDetail.status ===
                      CHOICES.BuildStatus.SUCCEEDED && (
                        <BadgeComp
                          badgePosition={"top"}
                          badgeType={"bg-success left"}
                          label={CHOICES.BuildStatus.CHOICE_MAP.Succeeded}
                        />
                      )}
                    {props?.appDetail.status !==
                      CHOICES.BuildStatus.IN_PROGRESS &&
                      props?.appDetail.status !== CHOICES.BuildStatus.ERROR &&
                      props?.appDetail.status !==
                      CHOICES.BuildStatus.REQUESTED &&
                      props?.appDetail.status !==
                      CHOICES.BuildStatus.SUCCEEDED && (
                        <BadgeComp
                          badgePosition={"left"}
                          badgeType={"bg-warning text-dark left"}
                          label={"Unknown"}
                        />
                      )}

                    {props?.appDetail.result ===
                      CHOICES.BuildResult.FAILURE && (
                        <BadgeComp
                          badgePosition={"top"}
                          badgeType={"bg-danger right"}
                          label={CHOICES.BuildResult.CHOICE_MAP.Failure}
                        />
                      )}
                    {props?.appDetail.result ===
                      CHOICES.BuildResult.SUCCESS && (
                        <BadgeComp
                          badgePosition={"top"}
                          badgeType={"bg-success right"}
                          label={CHOICES.BuildResult.CHOICE_MAP.Success}
                        />
                      )}
                    {props?.appDetail.result ===
                      CHOICES.BuildResult.UNKNOWN && (
                        <BadgeComp
                          badgePosition={"top"}
                          badgeType={"bg-secondary right"}
                          label={CHOICES.BuildResult.CHOICE_MAP.Unknown}
                        />
                      )}
                    {props?.appDetail.result !== CHOICES.BuildResult.FAILURE &&
                      props?.appDetail.result !== CHOICES.BuildResult.SUCCESS &&
                      props?.appDetail.result !==
                      CHOICES.BuildResult.UNKNOWN && (
                        <BadgeComp
                          badgePosition={"left"}
                          badgeType={"bg-secondary right"}
                          label={CHOICES.BuildResult.CHOICE_MAP.Unknown}
                        />
                      )}
                  </div>
                </label>
              </div>
            </div>
            <div className="col-12">
              {props?.appDetail?.is_errored && (
                <p className="fs-14 alert alert-danger">
                  {props?.appDetail?.error_message}
                </p>
              )}
            </div>
            {/* <div className="col-12 col-md-6">
                                <div className="d-flex flex-column">
                                    <label className="mb-1 fw-500 fs-14">Result</label>
                                    <label className="fs-14">

                                        {props?.appDetail.result == 'NotAvailable' && (<>{props?.appDetail.result} <i className="fa fa-exclamation-triangle text-danger ms-2" aria-hidden="true"></i></>)}

                                    </label>
                                </div>
                            </div> */}
          </div>
          <div className="row pt-4">
            <div className="col-12">
              <div className="d-flex flex-column">
                <label className="mb-1 fw-500 fs-14">Authkey</label>
                <label className="fs-14">
                  <span className="me-2">
                    {props?.appDetail?.application?.framework_auth_key}
                  </span>
                  <span
                    className={
                      copyKey.isCopy == "2"
                        ? "bg-success badge border border-dark pe-none"
                        : "badge bg-light text-dark border border-dark shadow-sm pe-cursor"
                    }
                    onClick={() => copyToClipboard("authkey")}
                  >
                    {copyKey.isCopy == "2" ? "Copied" : "Copy"}
                    {/* <i className="fa fa-clone"></i> */}
                  </span>
                </label>
              </div>
            </div>
          </div>

          <div className="col-12 mt-4 border-bottom">
            <div className="d-flex flex-column">
              <h4 className="mb-1 fw-500 fs-14">Execution Engine</h4>
              <p className="fs-12">
                {CONTENT_MESSAGES.buildExecutionEngineSecTitalDesc}
              </p>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-12">
              <div className="d-flex flex-column">
                <SelectInput
                  label="Execution Engine"
                  optionArray={executionEngineDropdown}
                  selectedOption={ props?.appDetail?.execution_engine?.name ||defaultExecutionEngine}
                  nameClass="form-control custom-form-lh2"
                  isDisabled={true}
                />
              </div>
            </div>
          </div>

          <div className="col-12 mt-4 border-bottom">
            <div className="d-flex flex-column">
              <h4 className="mb-1 fw-500 fs-14">Test Engine</h4>
              <p className="fs-12">
                {CONTENT_MESSAGES.buildTestEngineSecTitalDesc}
              </p>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-12">
              <div className="d-flex flex-column">
                <SelectInput
                  label="Test Engine"
                  optionArray={coreDropdown}
                  selectedOption={
                    props?.appDetail?.test_engine?.core_engine?.id ||
                    defaultCoreEngine
                  }
                  nameClass="form-control custom-form-lh2"
                  isDisabled={true}
                />
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-12 col-md-6">
              <div className="d-flex flex-column">
                <label className="mb-1 fw-500 fs-14">Number of builds</label>
                <label className="fs-14">
                  {props?.appDetail?.test_build_count}
                </label>
              </div>
            </div>
          </div>

          <div className="col-12 mt-4 border-bottom">
            <div className="d-flex flex-column">
              <h4 className="mb-1 fw-500 fs-14">Test Automation Script</h4>
              <p className="fs-12">{CONTENT_MESSAGES.buildScriptDesc}</p>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-12">
              <Inputcomp
                type="text"
                nameClass="form-control custom-form-lh2"
                name="url"
                handleChange={false}
                isDisabled={true}
                label="GIT Repo URL"
                value={props?.appDetail.repo_url}
              />
            </div>
            <div className="col-12 mt-4">
              <Inputcomp
                type="text"
                nameClass="form-control custom-form-lh2"
                name="commitid"
                handleChange={false}
                isDisabled={true}
                label="Commit Sha"
                value={props?.appDetail.repo_commit_sha}
              />
            </div>
            <div className="col-12 mt-4">
              <Inputcomp
                type="text"
                nameClass="form-control custom-form-lh2"
                name="repo_branch_name"
                handleChange={false}
                isDisabled={true}
                label="Feature branch"
                value={props?.appDetail.repo_branch_name}
              />
            </div>
            <div className="col-12 mt-4 ">
              <Inputcomp
                type="text"
                nameClass="form-control custom-form-lh2"
                name="scriptdirectory"
                handleChange={false}
                isDisabled={true}
                label="Script Location/ Directory in Repo"
                sublabel={CONTENT_MESSAGES?.buildScriptLocationDesc}
                value={props?.appDetail.build_dir}
              />
            </div>
            {/* <div className="col-6 mt-4">
              <Inputcomp
                type="text"
                nameClass="form-control custom-form-lh2"
                name="repouser"
                handleChange={false}
                isDisabled={true}
                label="Repo User"
                value={props?.appDetail.repo_access_id}
              />
            </div>

            <div className="col-6 mt-4">
              <Inputcomp
                type="text"
                nameClass="form-control custom-form-lh2"
                name="accesstoken"
                handleChange={false}
                isDisabled={true}
                label="Access Token"
                value={props?.appDetail.repo_access_key}
              />
            </div> */}
            <div className="col-6 mt-4">
              <CheckboxComponent
                label={"Parallel Execution?"}
                type={"checkbox"}
                id={"parallel_run"}
                nameField={"parallel_run"}
                defaultChecked={props?.appDetail.parallel_run}
                labelClassName={"form-label fw-500 fs-14"}
                onClick={() => { }}
              />
            </div>
            {props?.appDetail.parallel_run && (
              <>
                <div className="col-12 mt-4 border-bottom">
                  <div className="d-flex flex-column">
                    <h4 className="mb-1 fw-500 fs-14 form-label">
                      Parallel Run Arguments
                    </h4>
                    <p className="fs-12">
                      {CONTENT_MESSAGES.parallelRunArgsDesc}
                    </p>
                  </div>
                </div>
                <div className="table-responsive mt-4">
                  <table className="table data-table data-table-vertically-center ">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props?.appDetail.pabot_args &&
                        Object.keys(props?.appDetail.pabot_args).length > 0 ? (
                        Object.keys(props?.appDetail.pabot_args).map((key, idx) => (
                          <tr id={"tr_" + idx} key={idx}>
                            <td>
                              <Inputcomp
                                type="text"
                                nameClass="form-control custom-form-lh2"
                                name="name"
                                handleChange={true}
                                isDisabled={true}
                                label=""
                                value={key}
                                id={"tr_key_" + idx}
                              />
                            </td>
                            <td>
                              <Inputcomp
                                type="text"
                                nameClass="form-control custom-form-lh2"
                                name="value"
                                handleChange={true}
                                isDisabled={true}
                                label=""
                                value={props?.appDetail.pabot_args[key]}
                                id={"tr_value_" + idx}
                              />
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={6} className="text-center">
                            {ERROR_MESSAGES.noRecordsFound}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </>
            )}
            <div className="col-6 mt-4">
              <CheckboxComponent
                label={"Zephyr Scale Update?"}
                type={"checkbox"}
                id={"update_results_to_zephyr"}
                nameField={"update_results_to_zephyr"}
                labelClassName={"form-label fw-500 fs-14"}
                defaultChecked={props?.appDetail.update_results_to_zephyr}
                onClick={() => { }}
              />
            </div>
            {props?.appDetail.update_results_to_zephyr && (
              <>
                <div className="col-12 mt-4 border-bottom">
                  <div className="d-flex flex-column">
                    <h4 className="mb-1 fw-500 fs-14 form-label">
                      Zephyr Scale Custom Fields
                    </h4>
                    <p className="fs-12">
                      {CONTENT_MESSAGES.zephyrScaleCustomFields}
                    </p>
                  </div>
                </div>
                <div className="table-responsive mt-4">
                  <table className="table data-table data-table-vertically-center ">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props?.appDetail.zephyr_cycle_custom_fields &&
                        Object.keys(props?.appDetail.zephyr_cycle_custom_fields).length > 0 ? (
                        Object.keys(props?.appDetail.zephyr_cycle_custom_fields).map((key, idx) => (
                          <tr id={"tr_" + idx} key={idx}>
                            <td>
                              <Inputcomp
                                type="text"
                                nameClass="form-control custom-form-lh2"
                                name="name"
                                handleChange={true}
                                isDisabled={true}
                                label=""
                                value={key}
                                id={"tr_key_" + idx}
                              />
                            </td>
                            <td>
                              <Inputcomp
                                type="text"
                                nameClass="form-control custom-form-lh2"
                                name="value"
                                handleChange={true}
                                isDisabled={true}
                                label=""
                                value={props?.appDetail.zephyr_cycle_custom_fields[key]}
                                id={"tr_value_" + idx}
                              />
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={6} className="text-center">
                            {ERROR_MESSAGES.noRecordsFound}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </>
            )}
            <div className="col-6 mt-4">
              <CheckboxComponent
                label={"Rerun Required?"}
                type={"checkbox"}
                id={"no_of_re_runs"}
                nameField={"no_of_re_runs"}
                defaultChecked={props?.appDetail.no_of_re_runs}
                labelClassName={"form-label fw-500 fs-14"}
                onClick={() => { }}
              />
            </div>
            <div className="col-12 mt-4 border-bottom">
              <div className="d-flex flex-column">
                <h4 className="mb-1 fw-500 fs-14">Arguments</h4>
                <p className="fs-12">{CONTENT_MESSAGES.buildArgumentsDesc}</p>
              </div>
            </div>

            <div className="table-responsive mt-4">
              <table className="table data-table data-table-vertically-center ">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>
                  {props?.appDetail?.build_args &&
                    typeof props?.appDetail?.build_args === "object" ? (
                    getValues(props?.appDetail?.build_args)
                  ) : (
                    <tr>
                      <td colSpan={6} className="text-center">
                        {ERROR_MESSAGES.noRecordsFound}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="col-12 mt-4">
                <h4 className="mb-1 fw-500 fs-14">Report Portal</h4>
                <div className="d-flex flex-row">
                  <CheckboxComponent
                    label={"Upload Results Required?"}
                    type={"checkbox"}
                    id={"update_results_in_reportportal"}
                    nameField={"update_results_in_reportportal"}
                    defaultChecked={props?.appDetail.update_results_in_reportportal}
                    onClick={() => { }}
                  />
                  {
                    props?.appDetail.update_results_in_reportportal &&
                    <CheckboxComponent
                      label={"Upload Results Post Execution?"}
                      type={"checkbox"}
                      id={"rp_upload_results_eot"}
                      nameField={"rp_upload_results_eot"}
                      defaultChecked={props?.appDetail.rp_upload_results_eot}
                      labelClassName={"left-margin-25"}
                      onClick={() => { }}
                    />
                  }
                </div>
              </div>
            </div>
          </div>
        </form>
      ) : (
        <>
          <div className="row mt-3">
            <div className="col-12 col-md-6">
              <Inputcomp
                type="text"
                nameClass="form-control custom-form-lh2"
                name="name"
                handleChange={true}
                isDisabled={false}
                onChangeInput={handleChangeForm}
                label="Build Name"
                isRequired={true}
                value={appTestForm.name}
              />
              {!appTestForm.name && error.isValid && (
                <label className="text-danger fs-12">{REQUIRED_ERROR}</label>
              )}
            </div>
          </div>

          <div className="col-12 mt-4 border-bottom">
            <div className="d-flex flex-column">
              <h4 className="mb-1 fw-500 fs-14">
                Execution Engine
              </h4>
              <p className="fs-12">
                {CONTENT_MESSAGES.buildExecutionEngineSecTitalDesc}
              </p>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12">
              <div className="d-flex flex-column">
                <Form.Select
                  aria-label="Default select example"
                  className="custom-form-lh2"
                  onChange={handleChangeFormExecution}
                  name="execution_engine"
                >
                  {executionEngineDropdown.map((item: ExecutionEngine, key) => {
                    return (
                      <option
                        key={key}
                        value={item.execution_engine_key}
                      // selected={defaultCoreEngine ?? ""}
                      >
                        {item.name}
                      </option>
                    );
                  })}
                </Form.Select>

                {/* {!appTestForm.execution_engine_key && error.isValid && (
                  <label className="text-danger fs-12">{REQUIRED_ERROR}</label>
                )} */}
              </div>
            </div>
          </div>

          <div className="col-12 mt-4 border-bottom">
            <div className="d-flex flex-column">
              <h4 className="mb-1 fw-500 fs-14">
                Test Engine<span className="text-danger">*</span>
              </h4>
              <p className="fs-12">
                {CONTENT_MESSAGES.buildTestEngineSecTitalDesc}
              </p>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12">
              <div className="d-flex flex-column">
                <Form.Select
                  aria-label="Default select example"
                  className="custom-form-lh2"
                  onChange={handleChangeFormCore}
                  name="core_engine"
                >
                  {coreDropdown.map((item: TestEngine, key) => {
                    return (
                      <option
                        key={key}
                        value={item.id}
                      // selected={defaultCoreEngine ?? ""}
                      >
                        {item.name}
                      </option>
                    );
                  })}
                </Form.Select>

                {!appTestForm.core_engine && error.isValid && (
                  <label className="text-danger fs-12">{REQUIRED_ERROR}</label>
                )}
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-12 col-md-6">
              <div className="d-flex flex-column">
                <label className="mb-1 fw-500 fs-14">Available Capacity</label>
                {appTestForm.capacity_uom === "Unlimited" && (
                  <label className="fs-14">{appTestForm.capacity_uom}</label>
                )}
                {appTestForm.capacity_uom !== "Unlimited" && (
                  <label className="fs-14">
                    {appTestForm.capacity_units} {appTestForm.capacity_uom}
                  </label>
                )}
              </div>
            </div>
            {/* <div className="col-12 col-md-6">
                            <div className="d-flex flex-column">
                                <label className="mb-1 fw-500 fs-14">Number of builds</label>
                                <label className="fs-14">
                                    -
                                </label>
                            </div>
                        </div> */}
          </div>

          <div className="col-12 mt-4 border-bottom">
            <div className="d-flex flex-column">
              <h4 className="mb-1 fw-500 fs-14">Test Automation Script</h4>
              <p className="fs-12">{CONTENT_MESSAGES.buildScriptDesc}</p>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-12">
              <Inputcomp
                type="text"
                nameClass="form-control custom-form-lh2"
                name="url"
                handleChange={true}
                isDisabled={false}
                onChangeInput={handleChangeForm}
                label="GIT Repo URL"
                isRequired={true}
              />
              {!appTestForm.url && error.isValid && (
                <label className="text-danger fs-12">{REQUIRED_ERROR}</label>
              )}
            </div>

            <div className="col-12 mt-4">
              <Inputcomp
                type="text"
                nameClass="form-control custom-form-lh2"
                name="commitid"
                handleChange={true}
                isDisabled={false}
                onChangeInput={handleChangeForm}
                label="Commit Sha"
              // isRequired={true}
              />
              {/* {!appTestForm.commitid && error.isValid && (
                <label className="text-danger fs-12">{REQUIRED_ERROR}</label>
              )} */}
              {/* {!appTestForm.commitid && error.isValid && <label className="text-danger fs-12">{REQUIRED_ERROR}</label>} */}
            </div>

            <div className="col-12 mt-4">
              <Inputcomp
                type="text"
                nameClass="form-control custom-form-lh2"
                name="repo_branch_name"
                handleChange={true}
                isDisabled={false}
                onChangeInput={handleChangeForm}
                label="Feature branch"
              />
            </div>
            <div className="col-12 mt-4 ">
              <Inputcomp
                type="text"
                nameClass="form-control custom-form-lh2"
                name="scriptdirectory"
                handleChange={true}
                isDisabled={false}
                onChangeInput={handleChangeForm}
                label="Script Location/ Directory in Repo"
                sublabel={CONTENT_MESSAGES?.buildScriptLocationDesc}
                isRequired={true}
              />

              {!appTestForm.scriptdirectory && error.isValid && (
                <label className="text-danger fs-12">{REQUIRED_ERROR}</label>
              )}
            </div>

            <div className="col-6 mt-4">
              <Inputcomp
                type="text"
                nameClass="form-control custom-form-lh2"
                name="repo_access_id"
                handleChange={true}
                isDisabled={false}
                onChangeInput={handleChangeForm}
                label="Repo User"
              />
            </div>

            <div className="col-6 mt-4">
              <Inputcomp
                type="text"
                nameClass="form-control custom-form-lh2"
                name="repo_access_key"
                handleChange={true}
                isDisabled={false}
                onChangeInput={handleChangeForm}
                label="Access Token"
              />
            </div>

            <div className="col-6 mt-4">
              <CheckboxComponent
                label={"Parallel Execution?"}
                type={"checkbox"}
                id={"parallel_run"}
                nameField={"parallel_run"}
                defaultChecked={appTestForm.parallel_run}
                labelClassName={"form-label fw-500 fs-14"}
                onClick={(e) => handleCheckBoxs(e, "parallel_run")}
              />
            </div>

            {appTestForm.parallel_run && (
              <>
                <div className="col-12 mt-4 border-bottom">
                  <div className="d-flex flex-column">
                    <h4 className="mb-1 fw-500 fs-14 form-label">
                      Parallel Run Arguments
                    </h4>
                    <p className="fs-12">
                      {CONTENT_MESSAGES.parallelRunArgsDesc}
                    </p>
                  </div>
                </div>

                {/* Parameters start */}
                <div className="table-responsive mt-4">
                  <table className="table data-table data-table-vertically-center ">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      {state.rowsParallelArgs &&
                        state.rowsParallelArgs.length > 0 ? (
                        state.rowsParallelArgs.map((item, idx) => (
                          <tr id={"tr_" + idx} key={idx}>
                            <td>
                              <Inputcomp
                                type="text"
                                nameClass="form-control custom-form-lh2"
                                name="name"
                                handleChange={true}
                                isDisabled={false}
                                onChangeInput={(event) =>
                                  handleChangeParallelArgs(idx, "name", event)
                                }
                                label=""
                                value={state.rowsParallelArgs[idx].name}
                                id={"tr_key_" + idx}
                              />
                            </td>
                            <td>
                              <Inputcomp
                                type="text"
                                nameClass="form-control custom-form-lh2"
                                name="value"
                                handleChange={true}
                                isDisabled={false}
                                onChangeInput={(event) =>
                                  handleChangeParallelArgs(idx, "value", event)
                                }
                                label=""
                                value={state.rowsParallelArgs[idx].value}
                                id={"tr_value_" + idx}
                              />
                            </td>
                            <td>
                              <a
                                onClick={() =>
                                  handleRemoveRow(idx, PARALLEL_ARGS)
                                }
                                className="pull-right btn link text-danger"
                              >
                                <i
                                  className="fa fa-trash"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={6} className="text-center">
                            {ERROR_MESSAGES.noRecordsFound}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>

                  <a
                    onClick={() => handleAddRow(PARALLEL_ARGS)}
                    className="btn pull-right link"
                  >
                    + Add Row
                  </a>
                </div>
                {/* Parameters end */}
              </>
            )}

            {/* Zephyr section start */}
            <div className="col-6 mt-4">
              <CheckboxComponent
                label={"Zephyr Scale Update?"}
                type={"checkbox"}
                id={"update_results_to_zephyr"}
                nameField={"update_results_to_zephyr"}
                labelClassName={"form-label fw-500 fs-14"}
                defaultChecked={appTestForm.update_results_to_zephyr}
                onClick={(e) => handleCheckBoxs(e, "update_results_to_zephyr")}
              />
            </div>
            {appTestForm.update_results_to_zephyr && (
              <>
                <div className="col-12 mt-4 border-bottom">
                  <div className="d-flex flex-column">
                    <h4 className="mb-1 fw-500 fs-14 form-label">
                      Zephyr Scale Custom Fields
                    </h4>
                    <p className="fs-12">
                      {CONTENT_MESSAGES.zephyrScaleCustomFields}
                    </p>
                  </div>
                </div>

                <div className="table-responsive mt-4">
                  <table className="table data-table data-table-vertically-center ">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      {state.rowsZephyrArgs &&
                        state.rowsZephyrArgs.length > 0 ? (
                        state.rowsZephyrArgs.map((item, idx) => (
                          <tr id={"tr_" + idx} key={idx}>
                            <td>
                              <Inputcomp
                                type="text"
                                nameClass="form-control custom-form-lh2"
                                name="name"
                                handleChange={true}
                                isDisabled={false}
                                onChangeInput={(event) =>
                                  handleChangeZephyrArgs(idx, "name", event)
                                }
                                label=""
                                value={state.rowsZephyrArgs[idx].name}
                                id={"tr_key_" + idx}
                              />
                            </td>
                            <td>
                              <Inputcomp
                                type="text"
                                nameClass="form-control custom-form-lh2"
                                name="value"
                                handleChange={true}
                                isDisabled={false}
                                onChangeInput={(event) =>
                                  handleChangeZephyrArgs(idx, "value", event)
                                }
                                label=""
                                value={state.rowsZephyrArgs[idx].value}
                                id={"tr_value_" + idx}
                              />
                            </td>
                            <td>
                              <a
                                onClick={() => handleRemoveRow(idx, ZEPHY_ARGS)}
                                className="pull-right btn link text-danger"
                              >
                                <i
                                  className="fa fa-trash"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={6} className="text-center">
                            {ERROR_MESSAGES.noRecordsFound}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>

                  <a
                    onClick={() => handleAddRow(ZEPHY_ARGS)}
                    className="btn pull-right link"
                  >
                    + Add Row
                  </a>
                </div>
                {/* Zephyr section end */}
              </>
            )}

            <div className="col-6 mt-4">
              <CheckboxComponent
                label={"Rerun Required?"}
                type={"checkbox"}
                id={"no_of_re_runs"}
                nameField={"no_of_re_runs"}
                defaultChecked={appTestForm.no_of_re_runs}
                labelClassName={"form-label fw-500 fs-14"}
                onClick={(e) => handleCheckBoxs(e, "no_of_re_runs")}
              />
            </div>
          </div>

          <div className="col-12 mt-4 border-bottom">
            <div className="d-flex flex-column">
              <h4 className="mb-1 fw-500 fs-14">Arguments</h4>
              <p className="fs-12">{CONTENT_MESSAGES.buildArgumentsDesc}</p>
            </div>
          </div>

          <div className="table-responsive mt-4">
            <table className="table data-table data-table-vertically-center ">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                {state.rows && state.rows.length > 0 ? (
                  state.rows.map((item, idx) => (
                    <tr id={"tr_" + idx} key={idx}>
                      <td>
                        <Inputcomp
                          type="text"
                          nameClass="form-control custom-form-lh2"
                          name="name"
                          handleChange={true}
                          isDisabled={false}
                          onChangeInput={(event) =>
                            handleChangeBuildArgs(idx, "name", event)
                          }
                          label=""
                          value={state.rows[idx].name}
                          id={"tr_key_" + idx}
                        />
                      </td>
                      <td>
                        <Inputcomp
                          type="text"
                          nameClass="form-control custom-form-lh2"
                          name="value"
                          handleChange={true}
                          isDisabled={false}
                          onChangeInput={(event) =>
                            handleChangeBuildArgs(idx, "value", event)
                          }
                          label=""
                          value={state.rows[idx].value}
                          id={"tr_value_" + idx}
                        />
                      </td>
                      <td>
                        <a
                          onClick={() => handleRemoveRow(idx, BUILD_ARGS)}
                          className="pull-right btn link text-danger"
                        >
                          <i className="fa fa-trash" aria-hidden="true"></i>
                        </a>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={6} className="text-center">
                      {ERROR_MESSAGES.noRecordsFound}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            <a
              onClick={() => handleAddRow(BUILD_ARGS)}
              className="btn pull-right link"
            />
            <a onClick={handleAddRow} className="btn pull-right link">
              + Add Row
            </a>
          </div>

          <div className="col-12 mt-4">
            <h4 className="mb-1 fw-500 fs-14">Report Portal</h4>
            <div className="d-flex flex-row">
              <CheckboxComponent
                label={"Upload Results Required?"}
                type={"checkbox"}
                id={"update_results_in_reportportal"}
                nameField={"update_results_in_reportportal"}
                defaultChecked={appTestForm.update_results_in_reportportal}
                onClick={(e) =>
                  handleCheckBoxs(e, "update_results_in_reportportal")
                }
              />
              {appTestForm.update_results_in_reportportal &&
                <CheckboxComponent
                  label={"Upload Results Post Execution?"}
                  type={"checkbox"}
                  id={"rp_upload_results_eot"}
                  nameField={"rp_upload_results_eot"}
                  defaultChecked={appTestForm.rp_upload_results_eot}
                  labelClassName={"left-margin-25"}
                  onClick={(e) => handleCheckBoxs(e, "rp_upload_results_eot")}
                />
              }
            </div>
          </div>

          <div className="d-flex justify-content-end my-3">
            <button className="btn btn-primary" onClick={onSubmit}>
              Submit
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default BuildsForm;
