import React, { useState } from "react";
import "./style.scss";
import { navigate } from "@reach/router";

const Form = (props) => {
  const [passwordShownExisting, setPasswordShownExisting] = useState(false);
  const [passwordShownConform, setPasswordShownConform] = useState(false);
  const [passwordShownNew, setPasswordShownNew] = useState(false);

  const togglePasswordVisiblity = (typeField: string) => {
    if (typeField == "Existing") {
      setPasswordShownExisting(passwordShownExisting ? false : true);
    } else if (typeField == "Conform") {
      setPasswordShownConform(passwordShownConform ? false : true);
    } else {
      setPasswordShownNew(passwordShownNew ? false : true);
    }
  };
  return (
    <form
      className="form text-start"
      method="post"
      onSubmit={(event) => {
        props.handleSubmit(event);
      }}
      autoComplete="off"
    >
      <div className="form-group custom-login-form mt-1">
        <label className={"fw-500 fs-18 mb-1"}>
        Current password <sup className="text-danger">*</sup>
        </label>
        <div className="pass-wrapper mb-0">
          <input
            className="form-control custom-form-lh2"
            type={passwordShownExisting ? "text" : "password"}
            name="existingPassword"
            onChange={props.handleUpdate}
            onKeyUp={props.handleUpdate}
            // required
            minLength={3}
            maxLength={40}
            autoComplete="off"
          />
          <span onClick={() => togglePasswordVisiblity("Existing")}>
            {passwordShownExisting ? (
              <i className="fa fa-eye" aria-hidden="true"></i>
            ) : (
              <i className="fa fa-eye-slash" aria-hidden="true"></i>
            )}
          </span>
        </div>
        <span className="err"> {props?.stateErr?.existingPasswordErr}</span>
      </div>

      <div className="form-group custom-login-form mt-1">
        <label className={"fw-500 fs-18 mb-1"}>
          New password<sup className="text-danger">*</sup>
        </label>
        <div className="pass-wrapper mb-0">
          <input
            className="form-control custom-form-lh2"
            type={passwordShownNew ? "text" : "password"}
            name="newPassword"
            onChange={props.handleUpdate}
            onKeyUp={props.handleUpdate}
            // required
            minLength={3}
            maxLength={40}
            autoComplete="off"
          />
          <span onClick={() => togglePasswordVisiblity("New")}>
            {passwordShownNew ? (
              <i className="fa fa-eye" aria-hidden="true"></i>
            ) : (
              <i className="fa fa-eye-slash" aria-hidden="true"></i>
            )}
          </span>
        </div>
        <span className="err"> {props?.stateErr?.newPasswordErr}</span>
      </div>

      <div className="form-group custom-login-form mt-1">
        <label className={"fw-500 fs-18  mb-1"}>
          Confirm new password<sup className="text-danger">*</sup>
        </label>
        <div className="pass-wrapper mb-0">
          <input
            className="form-control custom-form-lh2"
            type={passwordShownConform ? "text" : "password"}
            name="confirmNewpassword"
            onChange={props.handleUpdate}
            onKeyUp={props.handleUpdate}
            // required
            minLength={3}
            maxLength={40}
            autoComplete="off"
          />
          <span onClick={() => togglePasswordVisiblity("Conform")}>
            {passwordShownConform ? (
              <i className="fa fa-eye" aria-hidden="true"></i>
            ) : (
              <i className="fa fa-eye-slash" aria-hidden="true"></i>
            )}
          </span>
        </div>
        <span className="err"> {props?.stateErr?.confirmNewpasswordErr}</span>
      </div>

      <div className="d-flex align-items-center justify-content-end ">
        <input
          // disabled={!props.isValidform}
          onClick={() => {
            navigate(-1);
          }}
          className="btn btn-primary mt-3 "
          type="button"
          value="Cancel"
          style={{ marginRight: "20px" }}
        />
        <input
          // disabled={!props.isValidform}
          className="btn btn-primary mt-3  "
          type="submit"
          value="Submit"
        />
      </div>
    </form>
  );
};

export default Form;
