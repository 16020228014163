import React from "react";
import image from "../../images/error-404.png";
import "./style.scss";
const browser = typeof window !== "undefined" && window;
import { CONTENT_MESSAGES } from "../../constants/app-constants";
const NotFoundPage = () => {
  return browser ? (
    <div className={"no-access-page"}>
      <img src={image} className="No_Access lock" alt="No_Access" />
      <h1 className="mt-4 fs-20">{CONTENT_MESSAGES.notFoundPageTital}</h1>
      <p className="fs-16">{CONTENT_MESSAGES.notFoundPageDesc}</p>
    </div>
  ) : null;
};

export default NotFoundPage;
