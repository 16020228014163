import React from "react";
import "./styleSelect.scss";

const SelectInput = (props) => {
  const [selectedValue, setSelected] = React.useState(
    props?.selectedOption ? props?.selectedOption : ""
  ); //default value

  function inputHandleChange(event) {
    props?.onChangeInput({ [event.target.name]: event.target.value });
    event.preventDefault();
  }

  return (
    <div className="form-group">
      {props?.label && (
        <label className="form-label fw-500 fs-14">
          {props?.label}
          {props?.isRequired && <span className="text-danger">*</span>}
        </label>
      )}
      <select
        name={props.name}
        value={props.selectedOption}
        onChange={inputHandleChange}
        className={props.nameClass}
        disabled={props.isDisabled}
      >
        <option value="">Select {props?.label}</option>
        {props.optionArray && props.optionArray.length > 0 ? (
          props.optionArray.map((one, index) => (
            <option key={index} value={one.id}>
              {one.name}
            </option>
          ))
        ) : (
          <></>
        )}
      </select>
    </div>
  );
};

export default SelectInput;
