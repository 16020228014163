import api from "./api";

export const testEngineService = {
  getAllTestEngine,
  getTestEngineDetail,
  createTestEngine,
  updatetestEngine,
  deleteTestEngine,
};

function getAllTestEngine(app_key: any, limit = 100, offset = 0) {
  // 'https://api.kltaf.frizbane.io/api/applications/gfdgdfgfdg/test-engines?limit=100&offset=0'
  let limitParam = limit ? "?limit=" + limit : "";
  let offsetParam = offset
    ? "&offset=" + offset
    : offset == 0
    ? "&offset=0"
    : "";
  return api.get(
    `api/applications/${app_key}/test-engines${limitParam}${offsetParam}`
  );
}

function getTestEngineDetail(id: any) {
  // 'https://api.kltaf.frizbane.io/api/test-engines/656667e3-2285-4b33-8bb1-0c78f600bc15'
  return api.get(`api/test-engines/${id}`);
}

function createTestEngine(param: any, app_key: any) {
  // 'https://api.kltaf.frizbane.io/api/applications/gfdgdfgfdg/test-engines'

  // {
  //     "enabled": param?.enabled ? true : false,
  //     "capacity_units": param?.capacity_units ? param?.capacity_units : 'Unlimited',
  //     "variables": param?.variables ? param?.variables : {},
  //     "features": param?.features ? param?.features : [],
  //     "engine_key": param?.engine_key
  // }
  return api
    .post(`api/applications/${app_key}/test-engines`, param)
    .then((response) => {
      return response;
    });
}

function updatetestEngine(id: any, param: any) {
  // 'https://api.kltaf.frizbane.io/api/test-engines/656667e3-2285-4b33-8bb1-0c78f600bc15'
  return api
    .post(`api/test-engines/${id}`, {
      enabled: param?.enabled ? true : false,
      capacity_units: param?.capacity_units
        ? param?.capacity_units
        : "Unlimited",
      variables: param?.variables ? param?.variables : {},
      features: param?.features ? param?.features : [],
      engine_key: param?.engine_key,
    })
    .then((response) => {
      return response;
    });
}

function deleteTestEngine(id: any) {
  // https://api.kltaf.frizbane.io/api/test-engines/656667e3-2285-4b33-8bb1-0c78f600bc15
  // https://api.kltaf.frizbane.io/api/test-engines/a2113ced-bd55-4a18-beb2-484d44bd2d44
  return api.delete(`api/test-engines/${id}`);
}
