import React, { useState } from "react";
import "./style.scss";
import { testEngineService } from "../../_services/testengine.service";
import { coreEngineService } from "../../_services/coreengine.service";
import Inputcomp from "../../widgets/Form/input/Input";
import SelectInput from "../../widgets/Form/Select/SelectInput";
import { Link, navigate } from "@reach/router";

import { toast } from "react-toastify";
import ConfirmationPopup from "../../widgets/Popups/Confirmation-Popup";
import AuditLog from "../../widgets/AuditLog/AuditLog";
import {
  REQUIRED_ERROR,
  CONTENT_MESSAGES,
  ERROR_MESSAGES,
  SUCCESS_MESSAGES
} from "../../constants/app-constants";


const TestEngineForm = (props) => {
  const [prodKey, setProdKey] = useState("");
  const [appKey, setAppKey] = useState("");
  const [coreDropdown, setCoreDropdown] = useState(null);

  const [features, setFeatures] = useState({
    featureList: null,
    selectedFeatures: null,
  });

  const [appTestForm, setAppTestForm] = useState({
    id: "",
    core_engine: "",
    execution: "",
    capacity_units: "-",
    core_engine_id: "",
    capacity_uom: "",
    allocated_capacity: 0,
    variables: null,
  });


  // React.useEffect(() => {
  //   setAppTestForm({
  //     ...appTestForm,
  //     id: props?.testEngine?.core_engine?.id,
  //     core_engine: "",
  //     execution: "",
  //     capacity_units: "-",
  //     core_engine_id: "",
  //     capacity_uom: "",
  //     allocated_capacity: props?.testEngine?.capacity_units,
  //     variables: null,
  //   })
  // }, [props?.testEngine]);


  const [error, setError] = useState({
    isValid: false,
    core_engine: "Core Engine Required",
    execution: "Execution Required",
    allocated_capacity: "Allocated Capacity Required",
    features: "Atleast one feature must be selected",
  });

  const [error1, setError1] = useState({
    errorData: "",
    isValid: false,
  });

  const [confirmData, setConfirmData] = useState({
    title: "",
    message: "",
    closeLabel: "",
    okLabel: "",
    isShow: false,
    selectedId: "",
  });


  // ------------CONFIGURATION
  const [state1, setState1] = useState({
    rows1: [],
    buildArgs1: [],
  });

  function handleChangeBuildArgs1(idx, fieldName, e) {
    const { key, value } = e;
    const rows1 = [...state1.rows1];

    // rows1[idx] = {
    //     "key": key ? key : rows1[idx].key,
    //     "value": value ? value : rows1[idx].value
    // };

    switch (fieldName) {
      case "key":
        rows1[idx] = {
          key: key,
          value: value ? value : rows1[idx].value,
        };
        break;
      case "value":
        rows1[idx] = {
          key: key ? key : rows1[idx].key,
          value: value,
        };
        break;
      default:
        break;
    }

    let result;
    if (rows1.length > 0) {
      result = rows1.reduce(function (r, e) {
        r[e.key] = e.value;
        return r;
      }, {});
    }

    setState1({ ...state1, rows1, buildArgs1: result });
    setAppTestForm({ ...appTestForm, variables: result });
  }


  function handleRemoveRow1(idx) {
    const newList = [
      ...state1.rows1.slice(0, idx),
      ...state1.rows1.slice(idx + 1),
    ];
    setState1({ ...state1, rows1: newList });

    let result;
    if (newList.length > 0) {
      result = newList.reduce(function (r, e) {
        r[e.key] = e.value;
        return r;
      }, {});

      ////
    }
    setAppTestForm({ ...appTestForm, variables: result });
  }

  function getValues(myObj) {
    let arr = [];
    arr = Object.keys(myObj).map((key, index) => (
      <tr key={index}>
        <td>{key}</td>
        <td>{myObj[key]}</td>
        <td></td>
      </tr>
    ));
    return arr;
  }


  function handleAddRow1() {
    const item = {
      key: "",
      value: "",
    };
    //
    setState1({ ...state1, rows1: [...state1.rows1, item] });
    //
    // let rowsOld = state1.rows1
    // let newRow = rowsOld.concat(item)
    //
    // setState1({ ...state1, rows1: newRow });
  }
  //------------CONFIGURATION


  React.useEffect(() => {
    let testEngine = props?.testEngine;
    getCoreEngines();
    //----SET CONFIGURATION configuration
    let rows1 = [];
    testEngine?.variables &&
      Object.keys(testEngine?.variables).map((key, index) => {
        let k = {
          key: key,
          value: testEngine?.variables[key],
        };
        rows1.push(k);
      });
    setState1({ ...state1, rows1, buildArgs1: testEngine?.variables });
    //----END SET CONFIGURATION configuration

    // let result;
    // if (rows1.length > 0) {
    //   result = rows1.reduce(function (r, e) {
    //     r[e.key] = e.value;
    //     return r;
    //   }, {});
    // }
    setAppTestForm({ ...appTestForm, variables:  testEngine?.variables });


  }, [props?.test_engine_id, props?.testEngine]);


  React.useEffect(() => {
    setProdKey(props?.prod_key);
    setAppKey(props?.app_key);
  }, [props?.prod_key]);

  function getCoreEngines() {
    coreEngineService.getAllCoreEngine().then((resp) => {
      if (resp.status == 200 && resp?.data) {
        const coreList = resp?.data?.map((d) => ({
          id: d?.id,
          engine_key: d?.engine_key,
          test_type: d?.test_type,
          name: d?.engine_key,
          execution: d?.execution,
          capacity_units: d?.capacity_units,
          capacity_uom: d?.capacity_uom,
          features: d?.features,
        }));
        setCoreDropdown(coreList);
        // setDetails
        props?.testEngine?.core_engine?.id
          ? getEngine(props?.testEngine?.core_engine?.id, coreList)
          : "";
     
        } else {
        if (resp?.status === 422) {
          toast.error(resp?.data?.detail[0].msg);
        } else {
          toast.error(
            resp?.data?.detail ? resp?.data?.detail : resp?.data?.error
          );
        }
        props?.isLoading(false);
      }
    });
  }



  function onSubmit(event) {
    props?.isLoading(true);
    // alert("Add")
    let validate1 = validateForm();
    let validate2 = validateForm1();
    if (validate1 && validate2) {
      // props?.isLoading(false)
      let fa: any = [];
      if (features?.selectedFeatures) {
        features?.selectedFeatures.forEach((element) => {
          if (element.isChecked) {
            fa.push(element.id);
          }
        });
      }

      let req = {
        engine_key: appTestForm?.core_engine_id,
        features: fa,
        enabled: true,
        capacity_units: appTestForm?.allocated_capacity
          ? appTestForm?.allocated_capacity
          : "0",
        variables: appTestForm?.variables,
      };

      props?.isLoading(false);
      testEngineService.createTestEngine(req, appKey).then((resp) => {
        if (resp.status == 200 && resp?.data) {
          props?.isLoading(false);
          toast.success(SUCCESS_MESSAGES.add);
          navigate(
            `/app/products/test-engines/${resp?.data?.id}${
              "?product=" + prodKey
            }${"&app=" + appKey}`
          );
        } else {
          if (resp?.status === 422) {
            toast.error(resp?.data?.detail[0].msg);
          } else {
            toast.error(
              resp?.data?.detail ? resp?.data?.detail : resp?.data?.error
            );
          }
          props?.isLoading(false);
        }
      });
    } else {
      toast.error(ERROR_MESSAGES.checkAllFieldsMessage);
      props?.isLoading(false);
    }
  }

  function onUpdate() {
    props?.isLoading(true);
    let validate1 = validateForm();
    let validate2 = validateForm1();
    //
    if (validate1 && validate2) {
      // props?.isLoading(false)
      let fa: any = [];
      if (features?.selectedFeatures) {
        features?.selectedFeatures.forEach((element) => {
          if (element.isChecked) {
            fa.push(element.id);
          }
        });
      }

      let req = {
        engine_key: appTestForm?.core_engine_id,
        features: fa,
        enabled: true,
        capacity_units: appTestForm?.allocated_capacity
          ? appTestForm?.allocated_capacity
          : "0",
        variables: appTestForm?.variables,
      };

      // //
      testEngineService
        .updatetestEngine(props?.test_engine_id, req)
        .then((resp) => {
          //// //
          if (resp.status == 200 && resp?.data) {
            props?.isLoading(false);
            props?.handelEdit(false);
            toast.success(SUCCESS_MESSAGES.update);
            // navigate(`/app/products/test-engines/${resp?.data?.id}${'?product=' + prodKey}${'&app=' + appKey}`)
          } else {
            if (resp?.status === 422) {
              toast.error(resp?.data?.detail[0].msg);
            } else {
              toast.error(
                resp?.data?.detail ? resp?.data?.detail : resp?.data?.error
              );
            }
            props?.isLoading(false);
          }
        });
    } else {
      toast.error(ERROR_MESSAGES.checkAllFieldsMessage);
      props?.isLoading(false);
    }

    // props?.isLoading(true)
    // if(!validateForm()){
    //     props?.isLoading(false)
    //     return false;
    // }
    // //// //
    // testEngineService.updatetestEngine(appTestForm).then(resp => {
    //     //// //
    //     if (resp.status == 200 && resp?.data) {
    //         props?.isLoading(false)
    //         props?.handelEdit(false)
    //         navigate(`/app/products/product-apps/${appTestForm?.app_key}${"?product="+prodKey}`)
    //     }else{
    //         if(resp?.status === 422){
    //             toast.error(resp?.data?.detail[0].msg);
    //         }else{
    //             toast.error(resp?.data?.detail ? resp?.data?.detail : resp?.data?.error);
    //         }
    //         props?.isLoading(false)
    //     }
    // })
  }


  function variableValidation(param) {
   
    if (!param) {
      return false;
    }
    if (Object.keys(param).length <1) {
      return false;
    }
    for (const [key, value] of Object.entries(param)) {
      if (key.trim() == "" || value == "") {
        return false;
      }
    }
    return true;
  }

  function validateForm() {
  //  const [state1, setState1] = useState({
  //   rows1: [],
  //   buildArgs1: [],
  // });
  // if (appTestForm.core_engine &&
  //   features?.selectedFeatures && features?.selectedFeatures.length>0 &&
  //   state1.rows1 && state1.rows1.length > 0
  //  )

     let validVar = variableValidation(appTestForm?.variables);
    
    if (appTestForm.core_engine &&
       features?.selectedFeatures && features?.selectedFeatures.length>0 &&
       validVar
      //  appTestForm?.variables && Object.keys(appTestForm?.variables).length > 0
      ) {
      setError({ ...error, isValid: false });
      return true;
    } else {
      setError({ ...error, isValid: true });
      return false;
    }
  }

  function validateForm1() {
    if (appTestForm?.capacity_uom !== "Unlimited") {
      if (appTestForm.allocated_capacity < 1) {
        setError1({
          ...error1,
          isValid: true,
          errorData: "Allocated capacity should be greater than 0",
        });
        return false;
      } else if (
        appTestForm.allocated_capacity > parseInt(appTestForm.capacity_units)
      ) {
        setError1({
          ...error1,
          isValid: true,
          errorData:
            "Allocated capacity should be less than " +
            appTestForm.capacity_units,
        });
        return false;
      } else {
        setError1({ ...error1, isValid: false, errorData: "" });
        return true;
      }
    } else {
      setError1({ ...error1, isValid: false, errorData: "" });
      return true;
    }
  }

  function onUpdateCancelConfirm() {
    setConfirmData({
      ...confirmData,
      title: "Alert",
      message: ERROR_MESSAGES?.reConformFormNotSaveMessage,
      closeLabel: "Cancel",
      okLabel: "Confirm",
      isShow: true,
    });
  }

  function closeConfirm() {
    setConfirmData({
      ...confirmData,
      isShow: false,
    });
  }

  function onUpdateCancel() {
    closeConfirm();
    props?.handelEditWithoutChange(false);
  }

  function handleChangeForm(event) {
    // //// //

    Object.keys(event).forEach(function (key) {
      //// //
      setAppTestForm({ ...appTestForm, [key]: event[key] });
    });

    error1.isValid && validateForm1();
  }

  function handleChangeFormCore(event) {
    Object.keys(event).forEach(function (key) {
      getEngine(event[key], coreDropdown);
    });
  }

  function getEngine(val, val1) {
    setAppTestForm({
      ...appTestForm,
      id: "",
      core_engine: "",
      capacity_units: "-",
      execution: "",
      core_engine_id: "",
      capacity_uom: "",
      allocated_capacity: 0,
    });
    setFeatures({ ...features, featureList: null, selectedFeatures: null });

    const corelist = coreDropdown ? coreDropdown : val1;

    if(!corelist){
      props?.isLoading(false);
    }
    if(corelist && corelist.length<1){
      props?.isLoading(false);
    }
    
    corelist &&
      corelist.filter(function (item) {
        if (item.id == val) {
          
          let arr = {
            ...appTestForm,
            id: item.id,
            core_engine: item.test_type,
            capacity_units: item.capacity_units,
            execution: item.execution,
            core_engine_id: item.engine_key,
            capacity_uom: item.capacity_uom,
            allocated_capacity: props?.testEngine?.capacity_units
              ? props?.testEngine?.capacity_units
              : 0,

              variables:  props?.testEngine?.variables 
          };
          setAppTestForm(arr);
        
          const featureList = item?.features.map((d) => ({
            id: d?.id,
            name: d?.name,
            value: d?.value,
            isChecked: false,
          }));
          props?.testEngine
            ? handleCheckOnload(featureList)
            : setFeatures({ ...features, featureList: featureList });
          
            setTimeout(()=>{
              props?.isLoading(false);
            },900)
        
        }
      });
     
      
  }

  function handleCheckChange(event) {
    var value = event.target.value;
    features?.featureList.forEach((element, index) => {
      if (element?.id == value) {
        features.featureList[index].isChecked = event.target.checked;
      }
    });
    let k = features?.featureList.filter((x) => x.isChecked);
    setFeatures({
      ...features,
      featureList: features?.featureList,
      selectedFeatures: k ? k : null,
    });
  }

  function handleCheckOnload(featureList) {
    let tfl = props?.testEngine?.features;
    let fl = featureList;
    tfl.forEach((element) => {
      fl.forEach((f, index) => {
        if (f?.id == element) {
          fl[index].isChecked = true;
        }
      });
    });
    let k = fl.filter((x) => x.isChecked);
    setFeatures({
      ...features,
      featureList: fl,
      selectedFeatures: k ? k : null,
    });
    
  }

  return (
    <>
      {confirmData.isShow ? (
        <ConfirmationPopup
          data={confirmData}
          confirmCloseEvent={closeConfirm}
          confirmOkEvent={() => onUpdateCancel()}
        />
      ) : (
        ""
      )}

      <form noValidate onSubmit={(e)=>e.preventDefault()}>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="d-flex flex-column">
              <SelectInput
                label="Core Engine"
                isRequired={true}
                onChangeInput={handleChangeFormCore}
                name="core_engine"
                optionArray={coreDropdown}
                nameClass="form-control custom-form-lh2 custom-select"
                isDisabled={!props?.isNew}
                selectedOption={appTestForm?.id}
                // selectedOption={
                //   appTestForm?.id
                //     ? appTestForm?.id
                //     : props?.testEngine?.core_engine?.id
                // }
              />
              {!appTestForm?.core_engine && error?.isValid && (
                <label className="text-danger fs-12">{REQUIRED_ERROR}</label>
              )}
            </div>
          </div>

          <div className="col-12 col-md-6 mt-4 mt-md-0">
            <div className="d-flex flex-column">
              <label className="mb-1 fw-500 fs-14">Execution</label>
              <label className="fs-14 mt-3">
                {appTestForm?.execution
                  ? appTestForm?.execution
                  : "Not Available"}
              </label>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12 ">
            <div className="d-flex flex-column">
              <label className="mb-1 fw-500 fs-14">Allocated Capacity</label>
              {appTestForm?.capacity_uom === "Unlimited" && (
                <>
                  <label className="fs-12">
                    Available Capacity=<span className="fw-500">Unlimited</span>
                  </label>
                  <label className="fs-14 mt-2">Unlimited</label>
                </>
              )}

              {parseInt(appTestForm?.capacity_units) > 0 && (
                <>
                  <label className="fs-14">
                    Available Capacity=
                    <span className="fw-500">
                      {appTestForm?.capacity_units} {appTestForm?.capacity_uom}
                    </span>
                  </label>
                  <div className="col-6 mt-2">
                    <div className="d-flex align-items-center w-100">
                      <div className="col-8">
                        <Inputcomp
                          type="text"
                          nameClass="form-control custom-form-lh2"
                          name="allocated_capacity"
                          handleChange={true}
                          isDisabled={!props?.isNew}
                          onChangeInput={handleChangeForm}
                          placeholder="Allocated Capacity"
                          isRequired={true}
                          value={
                            appTestForm?.allocated_capacity
                          }
                          // value={
                          //   appTestForm?.allocated_capacity
                          //     ? appTestForm?.allocated_capacity
                          //     : props?.testEngine?.capacity_units
                          // }
                        />
                      </div>
                      <div className="col-4">
                        <span className="ms-2">
                          {appTestForm?.capacity_uom}
                        </span>
                      </div>
                    </div>
                    {error1?.isValid && (
                      <label className="text-danger fs-12">
                        {error1.errorData}
                      </label>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        
        {/* CONFIGURATION */}
       
         <div className="row mt-4">
          <div className="col-12 mt-4 border-bottom">
            <div className="d-flex flex-column">
              <h4 className="mb-1 fw-500 fs-14">
                Configuration<label className="text-danger">*</label>
                {/* {state1?.rows1.length} */}
              </h4>
              <p className="fs-12 mb-1">
               {CONTENT_MESSAGES.coreEnginConfigDescription}
              </p>

               {/* { state1?.rows1 && state1?.rows1.length > 0 ? "": (
                  error?.isValid &&
                  <label className="text-danger fs-12">{REQUIRED_ERROR}</label>
                )} */}

                { appTestForm?.variables && appTestForm?.variables.length > 0 ? "": (
                  error?.isValid &&
                  <label className="text-danger fs-12">{REQUIRED_ERROR}</label>
                )}

              {/* {!appTestForm?.variables && error.isValid && (
                <label className="text-danger fs-12  mb-1">
                  {REQUIRED_ERROR}
                </label>
              )} */}
            </div>
          </div>

          <div className="table-responsive mt-2">
            <table className="table data-table data-table-vertically-center  ">
              <thead className="table-primary">
                <tr>
                  <th>Key</th>
                  <th>Value</th>
                  <th></th>
                </tr>
              </thead>
              {props?.isNew ? (
                <tbody>
                  {state1?.rows1.length > 0 ? (
                    state1?.rows1.map((item, idx) => (
                      <tr id={"tr_" + idx} key={idx}>
                        <td>
                          <Inputcomp
                            type="text"
                            nameClass="form-control custom-form-lh2"
                            name="key"
                            handleChange={true}
                            isDisabled={false}
                            onChangeInput={(event) =>
                              handleChangeBuildArgs1(idx, "key", event)
                            }
                            label=""
                            value={state1.rows1[idx].key}
                            id={"tr_key_" + idx}
                            isRequired={true}
                          />
                        </td>
                        <td>
                          <Inputcomp
                            type="text"
                            nameClass="form-control custom-form-lh2"
                            name="value"
                            handleChange={true}
                            isDisabled={false}
                            onChangeInput={(event) =>
                              handleChangeBuildArgs1(idx, "value", event)
                            }
                            label=""
                            value={state1.rows1[idx].value}
                            id={"tr_value_" + idx}
                            isRequired={true}
                          />
                        </td>
                        <td>
                          <a
                            onClick={() => handleRemoveRow1(idx)}
                            className="pull-right btn link text-danger"
                          >
                            <i className="fa fa-trash" aria-hidden="true"></i>
                          </a>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={6} className="text-center">
                        {ERROR_MESSAGES.noRecordsFound}
                      </td>
                    </tr>
                  )}
                </tbody>
              ) : (
                <tbody>
                  {/*   variables */}
                  {props?.testEngine?.variables &&Object.keys(props?.testEngine?.variables).length > 0  ? (
                    getValues(props?.testEngine?.variables)
                    
                  ) : (
                    <tr>
                      <td colSpan={6} className="text-center">
                         {ERROR_MESSAGES?.noRecordsFound}
                      </td>
                    </tr>
                  )}
                </tbody>
              )}
            </table>

            {props?.isNew && (
              <a onClick={handleAddRow1} className="btn pull-right link">
                + Add Row
              </a>
            )}
          </div>
        </div>

        {/* END CONFIGURATION */}
       
        <div className="col-12 mt-4 border-bottom">
          <div className="d-flex flex-column">
            <h4 className="mb-1 fw-500 fs-14">
              Features<span className="text-danger">*</span>
            </h4>
            <p className="fs-12 m-0">
              {CONTENT_MESSAGES.testEngineFeaturesSecDesc}
            </p>
            {/* && !error?.isValid */}
            { features?.selectedFeatures && features?.selectedFeatures.length > 0 ? "": (
              error?.isValid &&
              <label className="text-danger fs-12">{REQUIRED_ERROR}</label>
            )}
            

          
            
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-9">
            <div className="table-responsive mt-2">
              <table className="table table-bordered data-table data-table-vertically-center">
                <tbody>
                  {features &&
                    features?.featureList &&
                    features?.featureList.map((item, index) => (
                      <tr id={"tr_" + index} key={index}>
                        <td className="text-center">
                          <div className="checklist-custom-disabled">
                            <input
                              type="checkbox"
                              value={item.id}
                              name={"check_" + index}
                              id={"check_" + index}
                              onChange={handleCheckChange}
                              checked={item.isChecked}
                              disabled={!props?.isNew}
                              className="checklist"
                            />
                            <label htmlFor={"check_" + index}></label>
                          </div>
                        </td>

                        <td>
                          <div className="feature-value">
                            <span className="fs-14 fw-500"> {item.value}</span>
                            <span className="fs-12"> {item.name}</span>
                          </div>
                        </td>
                      </tr>
                    ))}
                  {features?.featureList && features?.featureList.length < 1 && (
                    <tr>
                      <td colSpan={4} className="text-center">
                        <div className="p-2">{ERROR_MESSAGES?.noRecordsFound}</div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {props?.isNew && !props?.isEditForm && (
          <div className="d-flex justify-content-end my-3">
            <Link to={"/app/products"} className="btn btn-secondary me-3">
              Cancel
            </Link>
            <button className="btn btn-primary" onClick={onSubmit}>
              Submit
            </button>
          </div>
        )}

        {props?.isNew && props?.isEditForm && (
          <div className="d-flex justify-content-between my-3">
            <AuditLog logData={props?.testEngine} />
            <div>
              <button
                className="btn btn-secondary  me-3"
                onClick={onUpdateCancelConfirm}
              >
                Cancel
              </button>
              <button className="btn btn-primary" onClick={onUpdate}>
                Save
              </button>
            </div>
          </div>
        )}
      </form>
    </>
  );
};
export default TestEngineForm;
