import api from "./api";
import { FETCH_LIMIT } from "../constants/app-constants";

export const featureService = {
  getAllFeatures,
  getFeaturesDetail,
  createFeatures,
  updateFeatures,
  deleteFeatures,
};

function getAllFeatures(param: any) {
  // 'https://api.kltaf.frizbane.io/api/features?q=API%20Behaviour%20-%20Local%20Engine&limit=100&offset=0' \
  let limitParam = param?.limit
    ? "limit=" + param?.limit + "&"
    : "limit=" + FETCH_LIMIT + "&";
  let offsetParam = param?.offset ? "offset=" + param?.offset : "offset=0";
  let q = param?.q ? "q=" + param?.q + "&" : "";

  return api.get(`api/features?${q}${limitParam}${offsetParam}`);
}

function getFeaturesDetail(id: any) {
  // https://api.kltaf.frizbane.io/api/features/0e11ab4d-c061-4e18-84fb-affe7c5ee3e7
  return api.get(`api/features/${id}`);
}

function createFeatures(param: any) {
  return api.post(`api/features`, param).then((response) => {
    return response;
  });
}

function updateFeatures(id: any, param: any) {
  return api.post(`api/features/${id}`, param).then((response) => {
    return response;
  });
}

function deleteFeatures(id: any) {
  // https://api.kltaf.frizbane.io/api/features/d1369ce7-16be-418f-b573-bb7d797b884b
  return api.delete(`api/features/${id}`);
}
