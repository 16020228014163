import { Link, useLocation, navigate } from "@reach/router";
import React, { useState } from "react";

import { Card } from "react-bootstrap";
import { userService } from "../../_services/user.service";
import Loader from "../../widgets/loader/Loader";
import { toast } from "react-toastify";
import ConfirmationPopup from "../../widgets/Popups/Confirmation-Popup";
import { FETCH_LIMIT } from "../../constants/app-constants";
import UsersSidebar from "./UsersSidebar";
import UsersForm from "./UsersForm";
import Inputcomp from "../../widgets/Form/input/Input";
import CHOICES from "../../_helpers/CHOICES";
import SelectInputChoises from "../../widgets/Form/Select/SelectInputChoises";
import { AuthService } from "../../_services/auth.service";
import Tooltip from "../../widgets/Tooltip/Tooltip";
import AuditLog from "../../widgets/AuditLog/AuditLog";

import { ERROR_MESSAGES } from "../../constants/app-constants";
const usersDetail = (props) => {
  const search = useLocation().search;
  const location = useLocation();
  const q_FromUrl = new URLSearchParams(search).get("q");
  const kind_FromUrl = new URLSearchParams(search).get("kind");
  let dataUser = AuthService.getCurrentUser();
  const accessModule = AuthService.getAllAccessList("user");
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [loadMore, setLoadMore] = useState(true);
  const [userList, setUserList] = useState(null);
  const [userDetail, setUserDetail] = useState(null);

  const [confirmData, setConfirmData] = useState({
    title: "",
    message: "",
    closeLabel: "",
    okLabel: "",
    isShow: false,
    selectedId: "",
  });

  const [selectedValue, setSelectedValue] = useState({
    q: q_FromUrl ? q_FromUrl : "",
    kind: kind_FromUrl ? kind_FromUrl : "",
    showFilter: true,
    limit: FETCH_LIMIT,
    offset: 0,
    pageNumber: 0,
  });

  const [error, setError] = useState({
    errorStatus: false,
    errorData: "",
    noAccessModule: false,
    noAccessModuleMessage: "",
  });

  const [errorSide, setErrorSide] = useState({
    isError: false,
    errMsg: "",
  });

  const [isLoadingSidebar, setIsLoadingSidebar] = useState(false);

  React.useEffect(() => {
    !q_FromUrl && !kind_FromUrl ? getAllUsers() : "";
  }, []);

  React.useEffect(() => {
    getuserDetail();
    setSelectedValue({
      ...selectedValue,
      q: q_FromUrl ? q_FromUrl : "",
      kind: kind_FromUrl ? kind_FromUrl : "",
      showFilter: q_FromUrl || kind_FromUrl ? true : false,
    });
    getAllUsers();
    editFeature(false);
  }, [location]);

  function getAllUsers() {
    // setLoading(true)
    setIsLoadingSidebar(true);
    userService.getAllUser(selectedValue).then((resp) => {
      // //
      if (resp.status == 200 && resp?.data) {
        setUserList(resp.data);
        if (resp?.data.length > 0) {
          setLoadMore(true);
        } else {
          setLoadMore(false);
        }
        // setLoading(false)
        setIsLoadingSidebar(false);
        setErrorSide({
          ...errorSide,
          isError: false,
          errMsg: "",
        });
      } else if (resp?.status == 408) {
        setErrorSide({
          ...errorSide,
          isError: true,
          errMsg: ERROR_MESSAGES.timeExceedError,
        });
        //   setLoading(false);
        setIsLoadingSidebar(false);
      } else {
        // setLoading(false)
        setIsLoadingSidebar(false);
        setErrorSide({
          ...errorSide,
          isError: true,
          errMsg: ERROR_MESSAGES.failedLoadRecordsError,
        });
      }
    });
  }

  // function getAllUsers(){
  //     setLoading(true)
  //     userService.getAllUser(selectedValue).then(resp => {
  //         // ////
  //         if (resp.status == 200 && resp?.data) {
  //             setUserList(resp.data)
  //             if(resp?.data.length > 0){
  //                 setLoadMore(true)
  //             }else{
  //                 setLoadMore(false)
  //             }
  //             setLoading(false)
  //         } else {
  //             setLoading(false)
  //             if (resp?.status === 422) {
  //                 toast.error(resp?.data?.detail[0].msg);
  //             } else {
  //                 toast.error(resp?.data?.detail ? resp?.data?.detail : resp?.data?.error);
  //             }
  //         }
  //     });
  // }

  function getuserDetail() {
    setLoading(true);
    userService.getUser(props?.user_name).then((resp) => {
      ////

      if (resp.status == 200 && resp?.data) {
        // if (resp?.data?.portals == "{}" ) resp.data.portals = ''
        setError({
          ...error,
          noAccessModule: false,
          noAccessModuleMessage: "",
        });
        setUserDetail(resp?.data);
        setLoading(false);
      } else if (resp?.status == 408) {
        setError({
          ...error,
          noAccessModule: true,
          noAccessModuleMessage: ERROR_MESSAGES.timeExceedError,
        });
        setLoading(false);
      } else {
        setLoading(false);

        if (resp?.status === 422) {
          setError({
            ...error,
            noAccessModule: false,
            noAccessModuleMessage: "",
          });
          toast.error(resp?.data?.detail[0].msg);
        } else if (resp?.status === 404) {
          setError({
            ...error,
            noAccessModule: true,
            noAccessModuleMessage: resp?.data?.error
              ? resp?.data?.error
              : ERROR_MESSAGES.noAccessMsg,
          });
        } else {
          // setError({...error, noAccessModule:false, noAccessModuleMessage:''})
          // toast.error(resp?.data?.detail ? resp?.data?.detail : resp?.data?.error);
          setError({
            ...error,
            noAccessModule: true,
            noAccessModuleMessage: resp?.data?.error
              ? resp?.data?.error
              : ERROR_MESSAGES?.detailsFailsMessage,
          });
        }
      }
    });
  }

  function editFeature(event) {
    setIsEdit(event);
  }

  function editFeatureAfterEdit(event) {
    // // ////
    setIsEdit(event);
    getuserDetail();
    getAllUsers();
  }

  function handelLoading(event) {
    setLoading(event);
  }

  function deleteFeature() {
    setConfirmData({
      ...confirmData,
      title: "Alert",
      message: ERROR_MESSAGES.reConformMessage,
      closeLabel: "Cancel",
      okLabel: "Confirm",
      isShow: true,
      selectedId: userDetail.app_key,
    });
  }

  function closeConfirm() {
    setConfirmData({
      ...confirmData,
      isShow: false,
      selectedId: "",
    });
  }

  function deleteConfirm() {
    // closeConfirm();
    // setLoading(true)
    // userService.deleteFeatures(props?.id).then(appRes => {
    //     if (appRes.status == 200 && appRes?.data) {
    //         setLoading(false)
    //         let q = q_FromUrl ? "?q="+q_FromUrl : ''
    //         navigate(`/app/features/${q}`);
    //     } else {
    //         setLoading(false);
    //         if (appRes?.status === 422) {
    //             toast.error(appRes?.data?.detail[0].msg);
    //         } else {
    //             toast.error(appRes?.data?.detail);
    //         }
    //     }
    //     // // // ////
    //     // setLoading(false)
    //     //
    // });
  }

  function toggleOpen() {
    setSelectedValue({
      ...selectedValue,
      showFilter: !selectedValue.showFilter,
    });
  }

  function handleChangeForm(event) {
    Object.keys(event).forEach(function (key) {
      setSelectedValue({ ...selectedValue, [key]: event[key] });
    });
  }

  function onSubmit() {
    let q = selectedValue.q ? `?q=${selectedValue.q}` : "";
    let kind = "";
    if (q) {
      kind = selectedValue.kind ? `&kind=${selectedValue.kind}` : "";
    } else {
      kind = selectedValue.kind ? `?kind=${selectedValue.kind}` : "";
    }
    navigate(`/app/users/${props?.user_name}${q}${kind}`);
  }

  function fetchMoreData(e) {
    if (e) {
      let pgNo = selectedValue.pageNumber + 1;
      let offset = pgNo * selectedValue.limit;

      setSelectedValue({ ...selectedValue, pageNumber: pgNo });

      let appReq = {
        q: selectedValue.q,
        kind: selectedValue.kind,
        limit: selectedValue.limit,
        offset: offset,
      };

      userService.getAllUser(appReq).then((resp) => {
        if (resp.status == 200 && resp?.data) {
          if (resp?.data.length > 0) {
            let newlist = userList.concat(resp?.data);
            setLoadMore(true);
            setUserList(newlist);
          } else {
            setLoadMore(false);
          }
        }
      });
    }
  }

  function addUser() {
    let q = selectedValue.q ? `?q=${selectedValue.q}` : "";
    let kind = "";
    if (q) {
      kind = selectedValue.kind ? `&kind=${selectedValue.kind}` : "";
    } else {
      kind = selectedValue.kind ? `?kind=${selectedValue.kind}` : "";
    }
    navigate(`/app/users/new${q}${kind}`);
  }

  function handleChangeFormSelect(event) {
    Object.keys(event).forEach(function (key) {
      setSelectedValue({ ...selectedValue, [key]: event[key] });
    });
  }

  function getActiveButton(event) {
    handelLoading(true);
    // if(!validateForm()){
    //     handelLoading(false)
    //     return false;
    // }
    let req = {
      first_name: userDetail?.first_name,
      last_name: userDetail?.last_name,
      email: userDetail?.email,
      is_active: event.target.checked ? true : false,
      kind: userDetail?.kind,
      report_portal_id: userDetail?.report_portal_id
        ? userDetail?.report_portal_id
        : 0,
      report_portal_user_id: userDetail?.report_portal_user_id
        ? userDetail?.report_portal_user_id
        : "",
    };

    userService.updateUser(props?.user_name, req).then((resp) => {
      if (resp.status == 200 && resp?.data) {
        handelLoading(false);
        editFeatureAfterEdit(false);
      } else {
        if (resp?.status === 422) {
          toast.error(resp?.data?.detail[0].msg);
        } else {
          toast.error(
            resp?.data?.detail ? resp?.data?.detail : resp?.data?.error
          );
        }
        handelLoading(false);
      }
    });
  }

  return (
    <div className="containWrap-inner">
      {loading ? <Loader /> : ""}
      {confirmData.isShow ? (
        <ConfirmationPopup
          data={confirmData}
          confirmCloseEvent={closeConfirm}
          confirmOkEvent={deleteConfirm}
        />
      ) : (
        ""
      )}
      {!error.noAccessModule ? (
        <div className="col-12">
          <div className="row">
            <div className="col-4">
              <Card className="bg-white custom-card list-option h-100">
                <Card.Header className="shadow-none p-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <Card.Title>
                      <h4 className="m-0">Users</h4>
                    </Card.Title>
                    <div className="d-flex align-items-center">
                      <div className="text-end my-2 me-3">
                        <i
                          className="fa fa-filter"
                          aria-hidden="true"
                          onClick={toggleOpen}
                        ></i>
                      </div>
                      <div className="d-flex align-items-center">
                        <button
                          disabled={!accessModule.add}
                          onClick={addUser}
                          className="btn btn-sm btn-primary"
                        >
                          Add User
                        </button>
                        {!accessModule.add && (
                          <i
                            className="fa fa-info-circle ms-2"
                            aria-hidden="true"
                            data-tip
                            data-for="addTip"
                          ></i>
                        )}
                        <Tooltip
                          id="addTip"
                          place="right"
                          effect="solid"
                          msgType="operation"
                        />
                      </div>
                    </div>
                  </div>
                </Card.Header>
                <Card.Body>
                  <div className="h-100 d-flex flex-column">
                    <div
                      className={
                        selectedValue.showFilter
                          ? "d-block mb-3 bg-d-gray p-2"
                          : "d-none"
                      }
                    >
                      <div className="">
                        <div className="mt-2">
                          <Inputcomp
                            type="text"
                            nameClass="form-control custom-form-lh2"
                            name="q"
                            handleChange={true}
                            isDisabled={false}
                            onChangeInput={handleChangeForm}
                            label="Search by username/ name/ email"
                            isRequired={false}
                            placeholder={"Search by username, name or email"}
                            value={selectedValue.q}
                            onKeyPress={onSubmit}
                          />
                        </div>

                        <div className="mt-2">
                          <SelectInputChoises
                            label="User Role"
                            onChangeInput={handleChangeFormSelect}
                            name="kind"
                            optionArray={CHOICES?.UserKind?.CHOICE_LIST}
                            nameClass="form-control custom-form-lh2 custom-select"
                            selectedOption={selectedValue?.kind}
                          />
                        </div>

                        <div className="d-flex justify-content-end mt-3">
                          <input
                            className="btn btn-primary"
                            type="submit"
                            value="Search"
                            onClick={onSubmit}
                          />
                        </div>
                      </div>
                    </div>
                    {isLoadingSidebar ? (
                      <div className="text-center"> Please Wait....</div>
                    ) : errorSide.isError ? (
                      <div className="text-center text-danger">
                        {" "}
                        {errorSide.errMsg}
                      </div>
                    ) : (
                      <UsersSidebar
                        sidedata={userList}
                        getMoreData={fetchMoreData}
                        loadFeature={loadMore}
                      />
                    )}
                    {/* <UsersSidebar sidedata={userList} getMoreData={fetchMoreData} loadFeature={loadMore}/> */}
                  </div>
                </Card.Body>
              </Card>
            </div>
            <div className="col-8 ps-0">
              <div className="col-12">
                <div className="d-flex align-items-center justify-content-between">
                  <Card className="bg-white custom-card custom-card-full">
                    <Card.Header className="shadow-none p-3">
                      <div className="d-flex align-items-start justify-content-between">
                        <Card.Title className="m-0">
                          <h4 className="">Edit User</h4>
                        </Card.Title>

                        <div className="d-flex align-items-center">
                          <div
                            className={
                              userDetail?.username !== dataUser?.username
                                ? "toggle"
                                : "toggle row-disabled-opacity"
                            }
                          >
                            <input
                              type="checkbox"
                              className="toggle-checkbox"
                              id="proddisabled"
                              name="darkmode"
                              value="on"
                              onChange={getActiveButton}
                              checked={userDetail?.is_active}
                              disabled={
                                userDetail?.username == dataUser?.username
                              }
                            />
                            <label
                              className="toggle-label"
                              htmlFor="proddisabled"
                            >
                              <span className="toggle-inner"></span>
                              <span className="toggle-switch"></span>
                            </label>
                          </div>

                          <Link className="no-link" to={`/app/users`}>
                            <i
                              className="fa fa-times ms-2"
                              aria-hidden="true"
                            ></i>
                          </Link>
                        </div>
                      </div>
                    </Card.Header>
                    <Card.Body>
                      {userDetail && (
                        <UsersForm
                          isNew={isEdit}
                          isLoading={handelLoading}
                          handelEditWithoutChange={editFeature}
                          handelEdit={editFeatureAfterEdit}
                          isEditForm={true}
                          username={props?.user_name}
                          userDetail={userDetail}
                        />
                      )}

                      {!isEdit && (
                        <div className="d-flex justify-content-between align-items-center mt-3 mb-1">
                          <AuditLog logData={userDetail} />
                          <div>
                            <button
                              disabled={!accessModule.update}
                              className="btn btn-primary"
                              onClick={() => editFeature(true)}
                            >
                              Edit
                            </button>
                            {!accessModule.update && (
                              <i
                                className="fa fa-info-circle ms-2"
                                aria-hidden="true"
                                data-tip
                                data-for="updateTip"
                              ></i>
                            )}
                            <Tooltip
                              id="updateTip"
                              place="left"
                              effect="solid"
                              msgType="operation"
                            />
                          </div>
                        </div>
                      )}
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="row err-msg-container">
          <div className="col-12">
            <Card className="bg-white custom-card list-option">
              <Card.Body>
                <div className="row err-msg">
                  <label className="text-center">
                    {error.noAccessModuleMessage
                      ? error.noAccessModuleMessage
                      : ERROR_MESSAGES.noAccessMsg}
                  </label>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      )}
    </div>
  );
};

export default usersDetail;
