import { Link, useLocation, navigate } from "@reach/router";
import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { testEngineService } from "../../_services/testengine.service";
import Loader from "../../widgets/loader/Loader";
import ErrorPopup from "../../widgets/Popups/Error-Popup";
import { toast } from "react-toastify";
import ConfirmationPopup from "../../widgets/Popups/Confirmation-Popup";
import TestEngineSidebar from "./TestEngineSidebar";
import TestEngineForm from "./TestEngineForm";
import { AuthService } from "../../_services/auth.service";
import Tooltip from "../../widgets/Tooltip/Tooltip";
import AuditLog from "../../widgets/AuditLog/AuditLog";
import { ERROR_MESSAGES } from "../../constants/app-constants";

const TestEngineDetail = (props) => {
  const accessModule = AuthService.getAllAccessList("products_apps_testengine");
  const search = useLocation().search;
  const location = useLocation();

  const prod_key_from_url = new URLSearchParams(search).get("product");
  const app_key_from_url = new URLSearchParams(search).get("app");

  const tabParam = new URLSearchParams(search).get("tab");
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [testEngineDetail, setTestEngineDetail] = useState(null);

  const [error, setError] = useState({
    errorStatus: false,
    errorData: "",
    isValid: false,
    name: "App Name Required",
    prod_key: "Product Key Required",
    app_key: "App Key Required",
    noAccessModule: false,
    noAccessModuleMessage: "",
  });

  const [settings, setSettings] = useState({
    showFilter: false,
    isEditable: false,
    buttonIndex: 0,
  });

  const [confirmData, setConfirmData] = useState({
    title: "",
    message: "",
    closeLabel: "",
    okLabel: "",
    isShow: false,
    selectedId: "",
  });

  const [testEngineList, setTestEngineList] = useState(null);

  React.useEffect(() => {
    editApplication(false);
    setSettings({ ...settings, buttonIndex: 0 });
  }, [location]);

  React.useEffect(() => {
    settings.buttonIndex == 1 ? getTestEngine() : "";
  }, [settings.buttonIndex]);

  function closeError() {
    setError({ ...error, errorStatus: false });
  }

  React.useEffect(() => {
    getTestEngine();
  }, []);

  React.useEffect(() => {
    setLoading(true);
    getTestEngineDetails();
  }, [location]);

  function getTestEngineDetails() {
    setLoading(true);
    testEngineService
      .getTestEngineDetail(props?.test_engine_id)
      .then((resp) => {
        if (resp.status == 200 && resp?.data) {
          // console.log("testEngine getTestEngineDetails res",resp?.data)

          setTestEngineDetail(resp?.data);
          // setLoading(false);
        } else {
          setLoading(false);
          if (resp?.status === 422) {
            toast.error(resp?.data?.detail[0].msg);
          } else if (resp?.status === 404) {
            setError({
              ...error,
              noAccessModule: true,
              noAccessModuleMessage: resp?.data?.error
                ? resp?.data?.error
                : ERROR_MESSAGES.noAccessMsg,
            });
          } else {
            // toast.error(resp?.data?.detail ? resp?.data?.detail : resp?.data?.error);
            setError({
              ...error,
              noAccessModule: true,
              noAccessModuleMessage: resp?.data?.error
                ? resp?.data?.error
                : ERROR_MESSAGES?.detailsFailsMessage,
            });
          }
        }
      });
  }

  function getTestEngine() {
    setLoading(true);
    testEngineService.getAllTestEngine(app_key_from_url).then((engine) => {
      if (engine.status == 200 && engine?.data) {
        const options = engine?.data.map((d) => ({
          id: d?.id,
          features: d?.features,
          enabled: d?.enabled,
          core_engine: d?.core_engine?.engine_key,
          test_type: d?.core_engine?.test_type,
          execution: d?.core_engine?.execution,
          capacity_units: d?.core_engine?.capacity_units,
          capacity_uom: d?.core_engine?.capacity_uom,
          created_at: d?.created_at,
        }));

        setTestEngineList(options);
        setLoading(false);
      } else {
        setLoading(false);
        if (engine?.status === 422) {
          toast.error(engine?.data?.detail[0].msg);
        } else {
          toast.error(engine?.data?.detail);
        }
      }
    });
  }

  function editApplication(event) {
    setIsEdit(event);
  }

  function editApplicationAfterEdit(event) {
    setIsEdit(event);
    getTestEngineDetails();
  }

  function handelLoading(event) {
    setLoading(event);
  }

  function closeConfirm() {
    setConfirmData({
      ...confirmData,
      isShow: false,
      selectedId: "",
    });
  }

  function deleteConfirm() {
    closeConfirm();
    setLoading(true);
    testEngineService.deleteTestEngine(confirmData?.selectedId).then((resp) => {
      if (resp.status == 200 && resp?.data) {
        setLoading(false);
        toast.success("Deleted Succefully");
        let product = prod_key_from_url ? "?product=" + prod_key_from_url : "";
        navigate(`/app/products/product-apps/${app_key_from_url}${product}`);
      } else {
        if (resp?.status === 422) {
          toast.error(resp?.data?.detail[0].msg);
        } else {
          toast.error(
            resp?.data?.detail ? resp?.data?.detail : resp?.data?.error
          );
        }
        setLoading(false);
      }
    });
  }

  const deleteTestEngine = () => {
    setConfirmData({
      ...confirmData,
      title: "Alert",
      message: ERROR_MESSAGES.reConformMessage,
      closeLabel: "Cancel",
      okLabel: "Confirm",
      isShow: true,
      selectedId: props?.test_engine_id,
    });
  };

  return (
    <div className="containWrap-inner">
      {loading ? <Loader /> : ""}

      {error.errorStatus ? (
        <ErrorPopup data={error.errorData} closePopup={closeError} />
      ) : (
        ""
      )}

      {confirmData.isShow ? (
        <ConfirmationPopup
          data={confirmData}
          confirmCloseEvent={closeConfirm}
          confirmOkEvent={deleteConfirm}
        />
      ) : (
        ""
      )}

      {!error.noAccessModule ? (
        <div className="col-12">
          <div className="row">
            <div className="col-4">
              <Card className="bg-white custom-card list-option h-100">
                <Card.Header className="shadow-none p-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <Card.Title>
                      <h4 className="m-0">Test Engines</h4>
                    </Card.Title>
                    <div className="d-flex align-items-center justify-content-between">
                    <i
                      aria-hidden="true"
                      data-tip
                      data-for={!accessModule.add && "addTipTestEngine"}
                    >
                     <button
                        disabled={!accessModule.add}
                        onClick={() =>
                          navigate(
                            `/app/products/test-engines/new${
                              "?product=" + prod_key_from_url
                            }${"&app=" + app_key_from_url}`
                          )
                        }
                        className="btn btn-sm btn-primary"
                      >
                        Add Test Engine
                      </button>
                      </i>

                      <Tooltip
                        id="addTipTestEngine"
                        place="right"
                        effect="solid"
                        msgType="operation"
                      />
                    </div>
                  </div>
                  {prod_key_from_url && (
                    <>
                      <span className="fs-16 me-2">of</span>
                      <Link
                        to={`/app/products/${prod_key_from_url}`}
                        className="fs-16 m-0 link text-primary"
                      >
                        {prod_key_from_url}
                      </Link>
                      <span className="fs-16 mx-2">/</span>
                      <Link
                        to={`/app/products/product-apps/${app_key_from_url}${
                          "?product=" + prod_key_from_url
                        }`}
                        className="fs-16 m-0 link text-primary"
                      >
                        {app_key_from_url}
                      </Link>
                    </>
                  )}
                </Card.Header>
                <Card.Body>
                  <div className="h-100 d-flex flex-column">
                    <TestEngineSidebar
                      testEngineList={testEngineList}
                      prod_key={prod_key_from_url}
                      app_key={app_key_from_url}
                    />
                  </div>
                </Card.Body>
              </Card>
            </div>
            <div className="col-8 ps-0">
              <div className="col-12">
                <div className="d-flex align-items-center justify-content-between">
                  <Card className="bg-white custom-card custom-card-full">
                    <Card.Header className="shadow-none p-3">
                      <div className="d-flex align-items-start justify-content-between">
                        <Card.Title className="m-0">
                          <h4 className="">
                            {testEngineDetail ? (
                              <span>
                                {testEngineDetail?.core_engine?.engine_key} (
                                {testEngineDetail?.core_engine?.test_type})
                              </span>
                            ) : (
                              <span>Test Engine Detail</span>
                            )}
                          </h4>
                        </Card.Title>

                        <div className="d-flex align-items-center justify-content-between">
                         
                        <i
                      aria-hidden="true"
                      data-tip
                      data-for={!accessModule.delete && "deleteTipTestEngineDetail"}
                    >
                          <button
                            disabled={!accessModule.delete}
                            onClick={deleteTestEngine}
                            className="text-danger fs-20 py-0 mx-2 btn btn-link"
                          >
                            <i className="fa fa-trash" aria-hidden="true"></i>
                          </button>
                          </i>

                          <Tooltip
                        id="deleteTipTestEngineDetail"
                        place="right"
                        effect="solid"
                        msgType="operation"
                      />

                       
                          <Link
                            className="no-link"
                            to={`/app/products/product-apps/${app_key_from_url}${
                              "?product=" + prod_key_from_url
                            }`}
                          >
                            <i className="fa fa-times" aria-hidden="true"></i>
                          </Link>
                        </div>
                      </div>
                    </Card.Header>
                    <Card.Body>
                      {testEngineDetail && (
                        <TestEngineForm
                          isNew={isEdit}
                          testEngine={testEngineDetail}
                          isEditForm={true}
                          isLoading={handelLoading}
                          handelEdit={editApplicationAfterEdit}
                          handelEditWithoutChange={editApplication}
                          prod_key={prod_key_from_url}
                          test_engine_id={props?.test_engine_id}
                        />
                      )}

                      {!isEdit && (
                        <div className="d-flex justify-content-between align-items-center mt-3 mb-1">
                          <AuditLog logData={testEngineDetail} />
                          <div>
                            <i
                                aria-hidden="true"
                                data-tip
                                data-for={!accessModule.update?"editTipTestEngin":""}
                              >
                            <button
                              disabled={!accessModule.update}
                              className="btn btn-primary"
                              onClick={() => editApplication(true)}
                            >
                              Edit
                            </button>
                            </i>
                           
                            <Tooltip
                              id="editTipTestEngin"
                              place="left"
                              effect="solid"
                              msgType="operation"
                            />
                          </div>
                        </div>
                      )}
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="row err-msg-container">
          <div className="col-12">
            <Card className="bg-white custom-card list-option">
              <Card.Body>
                <div className="row err-msg">
                  <label className="text-center">
                    {error.noAccessModuleMessage
                      ? error.noAccessModuleMessage
                      : ERROR_MESSAGES.noAccessMsg}
                  </label>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      )}
    </div>
  );
};

export default TestEngineDetail;

