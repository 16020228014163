import React, { useState } from "react";
import { navigate } from "@reach/router";
// import { isLoggedIn } from "../../_services/auth1"

import { AuthService } from "../../_services/auth.service";
import { TokenService } from "../../_services/token.service";
import { userService } from "../../_services/user.service";

import CardComponent from "../../widgets/Card/Card-Component";
import Form from "./Form";
import View from "../../widgets/LoginView";
import { Card } from "react-bootstrap";
import Loader from "../../widgets/loader/Loader";
import ErrorPopup from "../../widgets/Popups/Error-Popup";
import { useStoreState, useStoreActions } from "easy-peasy";
import { toast } from "react-toastify";
import { ERROR_MESSAGES ,SUCCESS_MESSAGES} from "../../constants/app-constants";

const Login = () => {
  let userLoginData = AuthService.getCurrentUser();

  const stateStore = useStoreState((state) => state?.auth?.userData);
  const stateAction = useStoreActions((actions) => actions?.auth?.setAuth);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    errorStatus: false,
    errorData: "",
  });

  const [productList, setProductList] = useState(null);
  const [state, setState] = useState({
    username: ``,
    password: ``,

    existingPassword: ``,
    newPassword: ``,
    confirmNewpassword: ``,
  });

  const [stateErr, setStateErr] = useState({
    existingPasswordErr: ``,
    newPasswordErr: ``,
    confirmNewpasswordErr: ``,
  });

  const [isValidform, setIsValid] = useState(false);

  const [userDetail, setUserDetail] = useState(null);
  const [errorUserDetails, setErrorUserDetails] = useState({
    errorStatus: false,
    errorData: "",
    noAccessModule: false,
    noAccessModuleMessage: "",
  });

  React.useEffect(() => {
    //  console.log("user id",TokenService.getUserId())
    getuserDetail();
  }, []);

  //START USER API FUNCTIONS
  function getuserDetail() {
    setLoading(true);
    userService.getUser(userLoginData?.username).then((resp) => {
      if (resp.status == 200 && resp?.data) {
        setErrorUserDetails({
          ...error,
          noAccessModule: false,
          noAccessModuleMessage: "",
        });
        setUserDetail(resp?.data);
        setLoading(false);
      } else if (resp?.status == 408) {
        setErrorUserDetails({
          ...error,
          noAccessModule: true,
          noAccessModuleMessage: ERROR_MESSAGES.timeExceedError,
        });
        setLoading(false);
      } else {
        setLoading(false);

        if (resp?.status === 422) {
          setErrorUserDetails({
            ...error,
            noAccessModule: false,
            noAccessModuleMessage: "",
          });
          toast.error(resp?.data?.detail[0].msg);
        } else if (resp?.status === 404) {
          setErrorUserDetails({
            ...error,
            noAccessModule: true,
            noAccessModuleMessage: resp?.data?.error
              ? resp?.data?.error
              : ERROR_MESSAGES.noAccessMsg,
          });
        } else {
          // setError({...error, noAccessModule:false, noAccessModuleMessage:''})
          // toast.error(resp?.data?.detail ? resp?.data?.detail : resp?.data?.error);
          setErrorUserDetails({
            ...error,
            noAccessModule: true,
            noAccessModuleMessage: resp?.data?.error
              ? resp?.data?.error
              : ERROR_MESSAGES?.detailsFailsMessage,
          });
        }
      }
    });
  }


 
function updateUser() {
    let req = {
      first_name: userDetail?.first_name,
      last_name: userDetail?.last_name,
      email: userDetail?.email,
      is_active: userDetail?.is_active,
      kind: userDetail?.kind,
      password: state?.newPassword,
    };

    userService.updateUser(userLoginData?.username, req).then((resp) => {
      if (resp.status == 200 && resp?.data) {
        setLoading(false);
        toast.success(SUCCESS_MESSAGES.update);
        AuthService.logout() && navigate(`/app/login`);
  
      } else if (resp?.status == 408) {
        toast.error(ERROR_MESSAGES.timeExceedError);
        setLoading(false);
      } else {
        if (resp?.status === 422) {
          toast.error(resp?.data?.detail[0].msg);
        } else {
          toast.error(
            resp?.data?.detail ? resp?.data?.detail : resp?.data?.error
          );
        }
        setLoading(false);
      }
    });
  }

//END USER API FUNCTIONS

  function handleUpdate(event) {
    setState({ ...state, [event.target.name]: event.target.value });
    onValid(event);
  }

  function validate() {
    let valid = true;
    let pw = state.newPassword.replace(/ /g, "") 
    let tempErr = {
      existingPasswordErr: ``,
      newPasswordErr: ``,
      confirmNewpasswordErr: ``,
    };

    if (state.existingPassword == "") {
      tempErr.existingPasswordErr = "Required";
      valid = false;
    }
    if (pw == "") {
      tempErr.newPasswordErr = "Required";
      valid = false;
    } 

    if (state.confirmNewpassword == "") {
      tempErr.confirmNewpasswordErr =
        "Required.";
      valid = false;
    }
    else if(pw.length<6){
      tempErr.newPasswordErr = "Password length should be greater than 6";
      valid = false;
    }else if (state.confirmNewpassword == "") {
      tempErr.confirmNewpasswordErr =
        "Required.";
      valid = false;
    }
    else if (state.confirmNewpassword !== state.newPassword) {
      tempErr.confirmNewpasswordErr =
        "New password and confirm password does not match.";
      valid = false;
    }
    setStateErr({
      ...stateErr,
      ...tempErr,
    });
    return valid;
  }

  function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    // if (state.confirmNewpassword !== state.newPassword) {
    //   setLoading(false);
    //   toast.error("New password and confirm password does not match.");
    //   return;
    // }
    // console.log("pass valid",validate())
    // console.log("pass stateErr",stateErr)
    if (validate()) {
      AuthService.login(userLoginData.username, state.existingPassword).then(
        async (res) => {
          if (res.status == 200) {
            updateUser();
          } else {
            let msg = {
              title: "Error",
              message: "",
            };
            if (res?.status == "408") {
              msg.message = res?.data;
            } else if (res?.detail){
              //401 wrong password
              msg.message = res?.detail;
            }else{
              msg.message = ERROR_MESSAGES.changePasswordErr;
            }

            setError({
              ...error,
              errorStatus: true,
              errorData: JSON.stringify(msg),
            });
            setLoading(false);
          }
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          let msg = {
            title: "Error",
            message: resMessage,
          };
          setError({
            ...error,
            errorStatus: true,
            errorData: JSON.stringify(msg),
          });
          setLoading(false);
        }
      );
    } else {
      setLoading(false);
    }
  }


  function onValid(val) {
    // const rows = [...state.username];
    // const rows1 = [...state.password];
    // //
    const { username, password } = state;
    username && password && val.target.value != ""
      ? setIsValid(true)
      : setIsValid(false);
  }

  function closeError() {
    setError({ ...error, errorStatus: false });
  }

  return (
    <View>
      {loading ? <Loader /> : ""}
      {error.errorStatus ? (
        <ErrorPopup data={error.errorData} closePopup={closeError} />
      ) : (
        ""
      )}

      {errorUserDetails.noAccessModule ? (
        <CardComponent nameofclass="bg-white custom-card text-center p-3 p-lg-3 px-lg-5 rounded cust-password-cart">
          <div className="row err-msg-container">
            <div className="col-12">
              <Card className="bg-white custom-card list-option">
                <Card.Body>
                  <div className="row err-msg">
                    <label className="text-center">
                      {errorUserDetails.noAccessModuleMessage}
                      {/* {errorUserDetails.noAccessModuleMessage
                    ? errorUserDetails.noAccessModuleMessage
                    : ERROR_MESSAGES.noAccessMsg} */}
                    </label>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </CardComponent>
      ) : (
        <CardComponent nameofclass="bg-white custom-card text-center p-3 p-lg-3 px-lg-5 rounded cust-password-cart">
          <Card.Title>
            <h2>Change password</h2>
          </Card.Title>
          <Card.Body className="px-0">
            {/* <p>For this demo, please log in with the username <code>superadmin</code> and the password <code>erebus</code></p> */}
            <Form
              handleUpdate={handleUpdate}
              handleSubmit={handleSubmit}
              isValidform={isValidform}
              stateErr = {stateErr}
            />
          </Card.Body>
        </CardComponent>
      )}
    </View>
  );
};

export default Login;
