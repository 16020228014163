import React from "react";
import "./styleInput.scss";

const Inputcomp = (props) => {
  function inputHandleChange(event) {
    props?.onChangeInput({ [event.target.name]: event.target.value });
  }

  const handleKeyPress = (event) => {
    if (
      props?.onKeyPress &&
      (event.code === "Enter" || event.code === "NumpadEnter")
    ) {
      props?.onKeyPress({ [event.target.name]: event.target.value });
    }
  };

  return (
    <>
      {props?.handleChange ? (
        <div className="form-group custom-form">
          {props?.label ? (
            <label className={"form-label fw-500 fs-14"}>
              {props?.label}
              {props?.isRequired && <span className="text-danger">*</span>}
            </label>
          ) : (
            ""
          )}
          {props?.sublabel ? (
            <>
              <br />
              <label className="mb-1 fs-12">{props?.sublabel}</label>
            </>
          ) : (
            ""
          )}
          <div className="fld_align">
            <input
              type={props?.type}
              name={props?.name}
              autoComplete="false"
              onChange={inputHandleChange}
              className={props?.nameClass + " fld_H"}
              placeholder={props?.placeholder}
              id={props?.id}
              value={props?.value}
              disabled={props?.isDisabled}
              required={props?.isRequired ? true : false}
              minLength={props?.isMinLength ? props?.isMinLength : ""}
              maxLength={props?.isMaxLength ? props?.isMaxLength : ""}
              // pattern={props?.isPattern ? props?.isPattern : ''}
              onKeyUp={inputHandleChange}
              onKeyPress={handleKeyPress}
            />
          </div>
        </div>
      ) : (
        <div className="form-group custom-form">
          {props?.label ? (
            <label className={"form-label fw-500 fs-14"}>
              {props?.label}
              {props?.isRequired && <span className="text-danger">*</span>}
            </label>
          ) : (
            ""
          )}
          {props?.sublabel ? (
            <>
              <br />
              <label className="mb-1 fs-12">{props?.sublabel}</label>
            </>
          ) : (
            ""
          )}
          <input
            type={props?.type}
            className={props?.nameClass}
            name={props?.name}
            placeholder={props?.placeholder}
            id={props?.id}
            value={props?.value ? props?.value : ""}
            disabled={props?.isDisabled}
          />
        </div>
      )}
    </>
  );
};

export default Inputcomp;
