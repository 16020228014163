import React from "react";
import "./styleInput.scss";

const InputWithIcon = (props) => {
  function inputHandleChange(event) {
    props?.onChangeInput({ [event.target.name]: event.target.value });
  }

  const handleKeyPress = (event) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      props?.onKeyPress({ [event.target.name]: event.target.value });
    }
  };

  return (
    <div className={`form-group custom-form ${props?.ipWithButton}`}>
      {props?.label ? (
        <label className={"form-label fw-500 fs-14"}>
          {props?.label}
          {props?.isRequired && <span className="text-danger">*</span>}
        </label>
      ) : (
        ""
      )}
      {props?.sublabel ? (
        <>
          <br />
          <label className="mb-1 fs-12">{props?.sublabel}</label>
        </>
      ) : (
        ""
      )}
      <input
        type={props?.type}
        autoComplete="false"
        name={props?.name}
        onChange={inputHandleChange}
        className={props?.nameClass}
        placeholder={props?.placeholder}
        id={props?.id}
        value={props?.value}
        disabled={props?.isDisabled}
        required={props?.isRequired ? true : false}
        minLength={props?.isMinLength ? props?.isMinLength : ""}
        maxLength={props?.isMaxLength ? props?.isMaxLength : ""}
        // pattern={props?.isPattern ? props?.isPattern : ''}
        onKeyUp={inputHandleChange}
        onKeyPress={handleKeyPress}
      />

      {props?.btnName && (
        <button
          type="submit"
          className="submitButton"
          onClick={props?.getResult}
          disabled={props?.isDisabledButton}
        >
          {props?.isDisabledButton ? (
            <div className="spinner-border spinner-border-lg align-center"></div>
          ) : (
            props?.btnName
          )}
        </button>
      )}
    </div>
  );
};

export default InputWithIcon;
