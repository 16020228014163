import { Link, useLocation, navigate } from "@reach/router";
import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { PAGINATION_FETCH_LIMIT } from "../../constants/app-constants";
import { userService } from "../../_services/user.service";
import SelectInputChoises from "../../widgets/Form/Select/SelectInputChoises";
import { toast } from "react-toastify";
import Inputcomp from "../../widgets/Form/input/Input";
import Loader from "../../widgets/loader/Loader";
import ErrorPopup from "../../widgets/Popups/Error-Popup";
import CHOICES from "../../_helpers/CHOICES";
import { AuthService } from "../../_services/auth.service";
import Tooltip from "../../widgets/Tooltip/Tooltip";
import {
  CONTENT_MESSAGES,
  ERROR_MESSAGES,
} from "../../constants/app-constants";

const UserList = (props) => {
  const search = useLocation().search;
  const q_FromUrl = new URLSearchParams(search).get("q");
  const kind_FromUrl = new URLSearchParams(search).get("kind");
  const accessModule = AuthService.getAllAccessList("user");
  const [userList, setUserList] = useState(null);

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState({
    errorStatus: false,
    errorData: "",
    faildToLoad: false,
    timeExceedError: false,
  });

  const [selectedValue, setSelectedValue] = useState({
    q: q_FromUrl ? q_FromUrl : "",
    kind: kind_FromUrl ? kind_FromUrl : "",
    showFilter: false,
    limit: PAGINATION_FETCH_LIMIT,
    offset: 0,
    pageNumber: 0,
  });


  React.useEffect(() => {
    setSelectedValue({
      ...selectedValue,
      q: q_FromUrl,
      kind: kind_FromUrl,
      showFilter: q_FromUrl || kind_FromUrl ? true : false,
    });
  }, [q_FromUrl, kind_FromUrl]);

  function getAllUsers() {
    setLoading(true);
    userService.getAllUser(selectedValue).then((resp) => {
      if (resp.status == 200 && resp?.data) {
        setUserList(resp.data);
        setLoading(false);
        setError({
          ...error,
          faildToLoad: false,
          timeExceedError: false,
        });
      } else if (resp?.status == 408) {
        setError({
          ...error,
          timeExceedError: true,
        });
        setLoading(false);
      } else {
        setLoading(false);
        // if (resp?.status === 422) {
        //     toast.error(resp?.data?.detail[0].msg);
        // } else {
        //     toast.error(resp?.data?.detail ? resp?.data?.detail : resp?.data?.error);
        // }
        setError({
          ...error,
          faildToLoad: true,
        });
      }
    });
  }

  function closeError() {
    setError({ ...error, errorStatus: false });
    window.localStorage.removeItem("localUser");
    navigate(`/app/login`);
  }

  function onSubmit() {
    let q = selectedValue.q ? `?q=${selectedValue.q}` : "";
    let kind = "";
    if (q) {
      kind = selectedValue.kind ? `&kind=${selectedValue.kind}` : "";
    } else {
      kind = selectedValue.kind ? `?kind=${selectedValue.kind}` : "";
    }
    navigate(`/app/users${q}${kind}`);
    getAllUsers();
  }

  function toggleOpen() {
    setSelectedValue({
      ...selectedValue,
      showFilter: !selectedValue.showFilter,
    });
  }

  function handleChangeForm(event) {
    Object.keys(event).forEach(function (key) {
      setSelectedValue({ ...selectedValue, [key]: event[key] });
    });
  }

  React.useEffect(() => {
    getAllUsers();
  }, [selectedValue.offset]);

  function resetPage(param) {
    if (param === "next") {
      let pgNo = selectedValue.pageNumber + 1;
      let offset = pgNo * selectedValue.limit;
      setSelectedValue({ ...selectedValue, offset: offset, pageNumber: pgNo });
    } else {
      if (selectedValue.pageNumber > 0) {
        let pgNo = selectedValue.pageNumber - 1;
        let offset = pgNo * selectedValue.limit;
        setSelectedValue({
          ...selectedValue,
          offset: offset,
          pageNumber: pgNo,
        });
      }
    }
  }

  function getNew() {
    let q = selectedValue.q ? `?q=${selectedValue.q}` : "";
    let kind = "";
    if (q) {
      kind = selectedValue.kind ? `&kind=${selectedValue.kind}` : "";
    } else {
      kind = selectedValue.kind ? `?kind=${selectedValue.kind}` : "";
    }
    navigate(`/app/users/new${q}${kind}`);
  }

  function getDetails(id) {
    let q = selectedValue.q ? `?q=${selectedValue.q}` : "";
    let kind = "";
    if (q) {
      kind = selectedValue.kind ? `&kind=${selectedValue.kind}` : "";
    } else {
      kind = selectedValue.kind ? `?kind=${selectedValue.kind}` : "";
    }

    navigate(`/app/users/${id}${q}${kind}`);
  }

  function handleChangeFormSelect(event) {
    Object.keys(event).forEach(function (key) {
      setSelectedValue({ ...selectedValue, [key]: event[key] });
    });
  }


  return (
    <div className="containWrap-inner">
      {loading ? <Loader /> : ""}
      {error.errorStatus ? (
        <ErrorPopup data={error.errorData} closePopup={closeError} />
      ) : (
        ""
      )}

      <Card className="bg-white custom-card ">
        <Card.Body>
          <div className="row">
            <div className="col-9">
              <Card.Title>
                <h4 className="m-0">User</h4>
              </Card.Title>
              <Card.Text>{CONTENT_MESSAGES.userListTitalDesc}</Card.Text>
            </div>

            <div className="col-3">
              <div className="d-flex align-items-center justify-content-end">
                <div className="py-1 px-2">
                  <i
                    className="fa fa-filter"
                    aria-hidden="true"
                    onClick={toggleOpen}
                  ></i>
                </div>

                <div className="ms-2">
                  <button
                    disabled={!accessModule.add}
                    onClick={getNew}
                    className="btn btn-sm btn-primary"
                  >
                    Add User
                  </button>
                </div>
                {!accessModule.add && (
                  <i
                    className="fa fa-info-circle ms-2"
                    aria-hidden="true"
                    data-tip
                    data-for="addTip"
                  ></i>
                )}
                <Tooltip
                  id="addTip"
                  place="left"
                  effect="solid"
                  msgType="operation"
                />
              </div>
            </div>
          </div>

          {selectedValue.showFilter && (
            <div className="col-12 bg-d-gray p-4 mt-3">
              <div className="row">
                <div className="col-12 col-md-5">
                  <Inputcomp
                    type="text"
                    nameClass="form-control custom-form-lh2"
                    name="q"
                    handleChange={true}
                    isDisabled={false}
                    onChangeInput={handleChangeForm}
                    label="Search by username/ name/ email"
                    isRequired={false}
                    placeholder={"Search by username, name or email"}
                    value={selectedValue.q}
                    onKeyPress={onSubmit}
                  />
                </div>
                <div className="col-12 col-md-5">
                  <SelectInputChoises
                    label="User Role"
                    onChangeInput={handleChangeFormSelect}
                    name="kind"
                    optionArray={CHOICES?.UserKind?.CHOICE_LIST}
                    nameClass="form-control custom-form-lh2 custom-select"
                    selectedOption={selectedValue?.kind}
                  />
                </div>

                <div className="col-12 col-md-2">
                  <div className="flex-end-custom">
                    <input
                      className="btn btn-primary pull-right"
                      type="submit"
                      value="Search"
                      onClick={onSubmit}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </Card.Body>
      </Card>

      <Card className="bg-white custom-card mt-3 mb-3 custom-card-full">
        <Card.Body>
          <div className="table-responsive table-custom">
            <table className="table data-table data-table-vertically-center  ">
              {/*  table-hover  data-table */}
              <thead>
                <tr className="table-primary">
                  <th>Username</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Role</th>
                </tr>
              </thead>

              <tbody>
                {userList && userList.length > 0 ? (
                  userList.map((one, index) => (
                    <tr
                      key={index}
                      className={`${one.is_active ? "" : "row-disabled"}`}
                    >
                      <td>
                        <a
                          onClick={() => getDetails(one?.username)}
                          className="no-link click-able"
                        >
                          {one?.username}
                        </a>
                      </td>
                      <td>
                        {" "}
                        {one?.first_name} {one?.last_name}
                      </td>
                      <td> {one?.email} </td>
                      <td> {one?.kind} </td>
                      {/* CHOICES.BuildStatus.CHOICE_MAP.InProgress  */}
                    </tr>
                  ))
                ) : error.timeExceedError ? (
                  <tr>
                    <td colSpan={10} className="text-center text-danger ">
                      {ERROR_MESSAGES.timeExceedError}
                    </td>
                  </tr>
                ) : error.faildToLoad ? (
                  <tr>
                    <td colSpan={10} className="text-center text-danger ">
                      {ERROR_MESSAGES.failedLoadRecordsError}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td colSpan={10} className="text-center">
                      {ERROR_MESSAGES.noRecordsFound}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {userList && (
              <div className="d-flex justify-content-end pt-3 pb-1">
                {selectedValue.offset < 1 ? (
                  <button className="btn btn-primary btn-sm" disabled>
                    <i className="fa fa-chevron-left" aria-hidden="true"></i>
                  </button>
                ) : (
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={() => resetPage("prev")}
                  >
                    <i className="fa fa-chevron-left" aria-hidden="true"></i>
                  </button>
                )}

                {userList && userList.length > 0 ? (
                  <button
                    className="btn btn-primary btn-sm ms-2"
                    onClick={() => resetPage("next")}
                  >
                    <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  </button>
                ) : (
                  <button className="btn btn-primary btn-sm ms-2" disabled>
                    <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  </button>
                )}
              </div>
            )}
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default UserList;
