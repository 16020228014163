import React, { useState } from "react";
import "./style.scss";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "@reach/router";
import { ERROR_MESSAGES } from "../../constants/app-constants";

const UsersSidebar = (props) => {
  function fetchMoreData() {
    props?.getMoreData(true);
  }

  return (
    <div className="mngScroll_outer">
      {props?.sidedata ? (
        props?.sidedata.length < 1 ? (
          <div className="text-center text-danger">
            {ERROR_MESSAGES.noRecordsFound}
          </div>
        ) : (
          <div id="scrollableDiv" className="app-build-list mngScroll_Content">
            <InfiniteScroll
              dataLength={props?.sidedata.length}
              next={fetchMoreData}
              hasMore={props?.loadFeature}
              loader={
                props?.sidedata.length < 1 ? (
                  <p className="fw-600 fs-14 text-center">
                    {ERROR_MESSAGES.noRecordsFound}
                  </p>
                ) : (
                  <h4>Loading...</h4>
                )
              }
              scrollableTarget="scrollableDiv"
            >
              {props?.sidedata &&
                props?.sidedata.map((appone, index) => (
                  <div
                    className={`${
                      appone.is_active
                        ? "scrollChild"
                        : "scrollChild row-disabled-sidebar"
                    }`}
                    key={index}
                  >
                    <Link
                      to={"/app/users/" + appone.username}
                      className="no-link"
                    >
                      <div className="fw-600 fs-14 mb-1 click-able">
                        {" "}
                        {appone.first_name} {appone.last_name}
                      </div>
                      <div>
                        {" "}
                        {appone.email}, {appone.username}{" "}
                      </div>
                    </Link>
                  </div>
                ))}
            </InfiniteScroll>
          </div>
        )
      ) : (
        <div className="text-center text-danger">
          {ERROR_MESSAGES.noRecordsFound}
        </div>
      )}
    </div>
  );
};

export default UsersSidebar;
