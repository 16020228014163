import React, { Component, useState } from "react";
import { Link, useLocation, navigate } from "@reach/router";
import "./style.scss";
import { toast } from "react-toastify";
import ConfirmationPopup from "../../widgets/Popups/Confirmation-Popup";
import FeatureSearchDialog from "./FeatureSearchDialog";
import { coreEngineService } from "../../_services/coreengine.service";
import { AuthService } from "../../_services/auth.service";
import Tooltip from "../../widgets/Tooltip/Tooltip";
import {
  CONTENT_MESSAGES,
  ERROR_MESSAGES,
  SUCCESS_MESSAGES,
} from "../../constants/app-constants";
const CoreEngineFeature = (props) => {
  const accessModule = AuthService.getAllAccessList("coreengine_feature");
  const [showPopup, setShowPopup] = useState(false);
  const [selectedFeature, setSelectedFeature] = useState([]);
  const [coreEngineForm, setCoreEngineForm] = useState({
    id: "",
    old_engine_key: "",
    engine_key: "",
    test_type: "",
    execution: "",
    provider: null,
    capacity_uom: "Unlimited",
    capacity_units: 0,
    variables: null,
    is_heavy_duty: false,
    features: [],
  });
  const [confirmData, setConfirmData] = useState({
    title: "",
    message: "",
    closeLabel: "",
    okLabel: "",
    isShow: false,
    selectedId: "",
  });

  React.useEffect(() => {
    //
    let core = props?.coreEngineDetail;
    let featuresSelection = []; //coreEngineForm?.features;
    core?.features &&
      core?.features.forEach((element) => {
        featuresSelection.push(element.id);
      });

    setCoreEngineForm({
      ...coreEngineForm,
      id: core?.id,
      old_engine_key: core?.engine_key,
      engine_key: core?.engine_key,
      test_type: core?.test_type,
      execution: core?.execution,
      provider: core?.provider ? core?.provider : null,
      capacity_uom: core?.capacity_uom ? core?.capacity_uom : "Unlimited",
      capacity_units: core?.capacity_units ? core?.capacity_units : 0,
      variables: core?.variables ? core?.variables : null,
      is_heavy_duty: false,
      features: featuresSelection ? featuresSelection : [],
    });

    setSelectedFeature(core?.features ? core?.features : []);
  }, [props?.coreEngineDetail]);

  function togglePopup() {
    setShowPopup(!showPopup);
  }

  function handleRemoveFeature(id) {
    setConfirmData({
      ...confirmData,
      title: "Alert",
      message: ERROR_MESSAGES.reConformMessage,
      closeLabel: "Cancel",
      okLabel: "Confirm",
      isShow: true,
      selectedId: id,
    });
  }

  const deleteConfirm = (idx) => {
    //
    closeConfirm();
    props?.isLoading(true);
    const newList = [
      ...selectedFeature.slice(0, idx),
      ...selectedFeature.slice(idx + 1),
    ];
    let featuresSelection = []; //coreEngineForm?.features;
    newList.forEach((element) => {
      featuresSelection.push(element.id);
    });

    //
    setCoreEngineForm({ ...coreEngineForm, features: featuresSelection });
    setSelectedFeature(newList);
    onUpdate(featuresSelection);
  };

  function closeConfirm() {
    setConfirmData({
      ...confirmData,
      isShow: false,
      selectedId: "",
    });
  }

  const getSelectedFeature = (event) => {
    let featuresSelection = [];
    event.forEach((element) => {
      featuresSelection.push(element.id);
    });

    setCoreEngineForm({ ...coreEngineForm, features: featuresSelection });
    setSelectedFeature(event);
    togglePopup();
    onUpdate(featuresSelection);
  };

  function onUpdate(featuresSelection) {
    props?.isLoading(true);
    if (!validateForm()) {
      props?.isLoading(false);
      toast.error(
        "Core Engine Feature uable to Updated. Please Try After Some Time."
      );
      return false;
    }

    let req = {
      engine_key: coreEngineForm?.engine_key,
      test_type: coreEngineForm?.test_type,
      execution: coreEngineForm?.execution,
      provider: coreEngineForm?.provider ? coreEngineForm?.provider : {},
      capacity_uom: coreEngineForm?.capacity_uom
        ? coreEngineForm?.capacity_uom
        : "Unlimited",
      capacity_units: coreEngineForm?.capacity_units
        ? coreEngineForm?.capacity_units
        : 0,
      variables: coreEngineForm?.variables ? coreEngineForm?.variables : {},
      is_heavy_duty: false,
      features: featuresSelection,
    };

    coreEngineService
      .updateCoreEngine(coreEngineForm?.old_engine_key, req)
      .then((resp) => {
        //
        if (resp.status == 200 && resp?.data) {
          props?.isLoading(false);
          toast.success(SUCCESS_MESSAGES.update);
          props?.editAfterFeture(false);
        } else {
          if (resp?.status === 422) {
            toast.error(resp?.data?.detail[0].msg);
          } else {
            toast.error(
              resp?.data?.detail ? resp?.data?.detail : resp?.data?.error
            );
          }
          props?.isLoading(false);
        }
      });
  }

  function validateForm() {
    if (
      coreEngineForm.engine_key &&
      coreEngineForm.test_type &&
      coreEngineForm.execution &&
      coreEngineForm.test_type &&
      coreEngineForm?.features.length > 0 &&
      coreEngineForm?.capacity_uom &&
      coreEngineForm.variables
    ) {
      if (
        coreEngineForm?.capacity_uom != "Unlimited" &&
        !coreEngineForm.capacity_units
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  return (
    <>
      {confirmData.isShow ? (
        <ConfirmationPopup
          data={confirmData}
          confirmCloseEvent={closeConfirm}
          confirmOkEvent={() => deleteConfirm(confirmData?.selectedId)}
        />
      ) : (
        ""
      )}

      {showPopup ? (
        <FeatureSearchDialog
          title="Feature Search"
          closePopup={togglePopup}
          addSelectedFeature={getSelectedFeature}
          selected_flist={coreEngineForm?.features}
        />
      ) : null}

      <div className="col-12 border-bottom">
        <div className="d-flex align-items-start justify-content-between">
          <div className="d-flex flex-column">
            <h4 className="mb-1 fw-500 fs-14">Features</h4>
            <p className="fs-12 mb-2">
              {CONTENT_MESSAGES.coreEngineFeaturesTitalDesc}
            </p>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <button
              disabled={!accessModule.add}
              className="btn btn-primary btn-sm"
              onClick={() => togglePopup()}
            >
              Add Feature
            </button>
            {!accessModule.add && (
              <i
                className="fa fa-info-circle ms-2"
                aria-hidden="true"
                data-tip
                data-for="addTip"
              ></i>
            )}
            <Tooltip
              id="addTip"
              place="left"
              effect="solid"
              msgType="operation"
            />
          </div>
        </div>
      </div>
      <div className="table-responsive mt-2">
        <table className="table data-table data-table-vertically-center  ">
          <thead className="table-primary">
            <tr>
              <th>Name</th>
              <th>Value</th>
              <th className="text-center">
                {!accessModule.delete && (
                  <i
                    className="fa fa-info-circle ms-2"
                    aria-hidden="true"
                    data-tip
                    data-for="deleteTip"
                  ></i>
                )}
                <Tooltip
                  id="deleteTip"
                  place="left"
                  effect="solid"
                  msgType="operation"
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {selectedFeature && selectedFeature.length > 0 ? (
              selectedFeature.map((app, idx) => (
                <tr id={"tr_" + idx} key={idx}>
                  <td>{app?.name}</td>
                  <td>{app?.value}</td>
                  <td className="text-center actions">
                    <button
                      disabled={!accessModule.delete}
                      onClick={() => handleRemoveFeature(idx)}
                      className="btn link text-danger"
                    >
                      <i className="fa fa-trash" aria-hidden="true"></i>
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6} className="text-center">
                  {ERROR_MESSAGES.noRecordsFound}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};
export default CoreEngineFeature;
