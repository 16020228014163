import React, { Component, useState } from "react";
import "./style.scss";
import { productService } from "../../_services/products.service";
import Inputcomp from "../../widgets/Form/input/Input";
import { Link, navigate } from "@reach/router";
import PopupUsers from "./PopupUsers";
import { toast } from "react-toastify";
import ConfirmationPopup from "../../widgets/Popups/Confirmation-Popup";
import AuditLog from "../../widgets/AuditLog/AuditLog";
import {
  REQUIRED_ERROR,
  SUCCESS_MESSAGES,
  CONTENT_MESSAGES,
  ERROR_MESSAGES,
} from "../../constants/app-constants";

const ProductForm = (props) => {
  const [showPopup, setShowPopup] = useState(false);

  const [appTestForm, setAppTestForm] = useState({
    old_prod_key: "",
    prod_key: "",
    name: "",
    owner_id: "",
    enabled: true,
    owner_name: "",
    email: "",
    username: "",
    portals: null,
    report_portal_id: 0,
    report_portal_name: "",
  });

  const [error, setError] = useState({
    errorStatus: false,
    errorData: "",
    isValid: false,
    name: "Product Name Required",
    prod_key: "Product Key Required",
    owner_id: "Primary Owner Details Required",
  });

  const [state, setState] = useState({
    rows: [],
    buildArgs: [],
  });

  const [confirmData, setConfirmData] = useState({
    title: "",
    message: "",
    closeLabel: "",
    okLabel: "",
    isShow: false,
    selectedId: "",
  });

  React.useEffect(() => {
    state.rows.length == 0 ? handleAddRow() : "";
  }, []);

  React.useEffect(() => {
    // //
    let prod = props?.productDetail;
    setAppTestForm({
      ...appTestForm,
      old_prod_key: prod?.prod_key,
      prod_key: prod?.prod_key,
      name: prod?.name,
      owner_id: prod?.owner?.id,
      enabled: prod?.enabled,
      owner_name:
        prod?.owner?.first_name && prod?.owner?.last_name
          ? prod?.owner?.first_name + " " + prod?.owner?.last_name
          : "",
      email: prod?.owner?.email,
      username: prod?.owner?.username,
      portals: prod?.portals ? prod?.portals : null,
      report_portal_id: prod?.owner?.report_portal_id,
      report_portal_name: prod?.owner?.report_portal_user_id,
    });
    // //
    prod?.portals
      ? setState({ ...state, rows: prod?.portals, buildArgs: prod?.portals })
      : "";
  }, [props?.productDetail]);

  function togglePopup() {
    setShowPopup(!showPopup);
  }

  function handleChangeForm(event) {
    // //
    Object.keys(event).forEach(function (key) {
      setAppTestForm({ ...appTestForm, [key]: event[key] });
    });
  }

  const handleChangeFormWithoutSpace = (event) => {
    Object.keys(event).forEach(function (key) {
      let value = event[key].replace(/\s/g, "");
      setAppTestForm({ ...appTestForm, [key]: value });
    });
  };

  function handleChangeBuildArgs(idx, fieldName, e) {
    const { key, name, url } = e;
    const rows = [...state.rows];

    switch (fieldName) {
      case "key":
        rows[idx] = {
          key: key,
          name: name ? name : rows[idx].name,
          url: url ? url : rows[idx].url,
        };
        break;
      case "name":
        rows[idx] = {
          key: key ? key : rows[idx].key,
          name: name,
          url: url ? url : rows[idx].url,
        };
        break;
      case "url":
        rows[idx] = {
          key: key ? key : rows[idx].key,
          name: name ? name : rows[idx].name,
          url: url,
        };
        break;
      default:
        break;
    }

    let result;
    if (rows.length > 0) {
      result = rows?.filter((x) => x.key && x.name && x.url);
    }
    setState({ ...state, rows, buildArgs: rows });
    setAppTestForm({ ...appTestForm, portals: result });

    // const { key, name, url } = e;
    // const rows = [...state.rows];

    // rows[idx] = {
    //     "key": key ? key : rows[idx].key,
    //     "name": name ? name : rows[idx].name,
    //     "url": url ? url : rows[idx].url
    // };
    // let result;
    // if (rows.length > 0) {
    //     result = rows?.filter(x => x.key && x.name && x.url);
    // }
    // //
    // setState({ ...state, rows, buildArgs: rows });
    // setAppTestForm({ ...appTestForm, portals: result });
  }

  function handleAddRow() {
    const item = {
      key: "",
      name: "",
      url: "",
    };

    setState({ ...state, rows: [...state.rows, item] });
  }

  function handleRemoveRow(idx) {
    // //
    const newList = [...state.rows.slice(0, idx), ...state.rows.slice(idx + 1)];
    // //

    setState({ ...state, rows: newList });
    setAppTestForm({ ...appTestForm, portals: newList });
    // setState({ ...state, rows: state.rows.slice(0, -1) });
  }

  function onSubmit(event) {
    props?.isLoading(true);
    if (!validateForm()) {
      toast.error(ERROR_MESSAGES.checkAllFieldsMessage);
      props?.isLoading(false);
      return false;
    }

    productService.createProduct(appTestForm).then((resp) => {
      if (resp.status == 200 && resp?.data) {
        props?.isLoading(false);
        // props?.handelEdit(false);
        toast.success(SUCCESS_MESSAGES.add);
        navigate(`/app/products/${resp?.data?.prod_key}`);
      } else if (resp?.status == 408) {
        toast.error(ERROR_MESSAGES.timeExceedError);
        props?.isLoading(false);
      } else {
        if (resp?.status === 422) {
          toast.error(resp?.data?.detail[0].msg);
        } else {
          toast.error(
            resp?.data?.detail ? resp?.data?.detail : resp?.data?.error
          );
        }
        props?.isLoading(false);
      }
    });
  }

  function onUpdate() {
    props?.isLoading(true);
    if (!validateForm()) {
      toast.error(ERROR_MESSAGES.checkAllFieldsMessage);
      props?.isLoading(false);
      return false;
    }
    //
    productService.updateProduct(appTestForm).then((resp) => {
      //
      if (resp.status == 200 && resp?.data) {
        props?.isLoading(false);
        props?.handelEdit(false);
        toast.success(SUCCESS_MESSAGES.update);
        navigate(`/app/products/${resp?.data?.prod_key}`);
      } else if (resp?.status == 408) {
        toast.error(ERROR_MESSAGES.timeExceedError);
        props?.isLoading(false);
      } else {
        if (resp?.status === 422) {
          toast.error(resp?.data?.detail[0].msg);
        } else {
          toast.error(
            resp?.data?.detail ? resp?.data?.detail : resp?.data?.error
          );
        }
        props?.isLoading(false);
      }
    });
  }

  function validateForm() {
    if (appTestForm.name && appTestForm.prod_key && appTestForm.owner_id) {
      setError({ ...error, isValid: false });
      return true;
    } else {
      setError({ ...error, isValid: true });
      return false;
    }
  }

  function getSelectedUser(event) {
    // //
    setAppTestForm({
      ...appTestForm,
      owner_id: event.id,
      owner_name: event.first_name + " " + event.last_name,
      email: event.email,
      username: event.username,
      report_portal_id: event.report_portal_id,
      report_portal_name: event.report_portal_user_id,
    });
    togglePopup();
  }

  function onUpdateCancelConfirm() {
    setConfirmData({
      ...confirmData,
      title: "Alert",
      message: ERROR_MESSAGES?.reConformFormNotSaveMessage,
      closeLabel: "Cancel",
      okLabel: "Confirm",
      isShow: true,
    });
  }

  function closeConfirm() {
    setConfirmData({
      ...confirmData,
      isShow: false,
    });
  }

  function onUpdateCancel() {
    closeConfirm();
    props?.handelEditWithoutChange(false);
  }

  return (
    <>
      {showPopup ? (
        <PopupUsers
          title="Find User"
          closePopup={togglePopup}
          addSelectedUser={getSelectedUser}
        />
      ) : null}
      {confirmData.isShow ? (
        <ConfirmationPopup
          data={confirmData}
          confirmCloseEvent={closeConfirm}
          confirmOkEvent={() => onUpdateCancel()}
        />
      ) : (
        ""
      )}

      <form noValidate onSubmit={(e)=>e.preventDefault()}>
        <div className="row">
          <div className="col-12 col-md-6">
            <Inputcomp
              type="text"
              nameClass="form-control custom-form-lh2"
              name="prod_key"
              handleChange={true}
              isDisabled={!props?.isNew || props?.isEditForm}
              onChangeInput={handleChangeFormWithoutSpace}
              label="Key (Prod Key)"
              isRequired={true}
              value={appTestForm.prod_key}
            />
            {!appTestForm.prod_key && error.isValid && (
              <label className="text-danger fs-12">{REQUIRED_ERROR}</label>
            )}
          </div>
          <div className="col-12 col-md-6">
            <Inputcomp
              type="text"
              nameClass="form-control custom-form-lh2"
              name="name"
              handleChange={true}
              isDisabled={!props?.isNew}
              onChangeInput={handleChangeForm}
              label="Name"
              isRequired={true}
              value={appTestForm.name}
            />
            {!appTestForm.name && error.isValid && (
              <label className="text-danger fs-12">{REQUIRED_ERROR}</label>
            )}
          </div>
        </div>

        <div className="col-12 mt-3 border-bottom">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex flex-column">
              <h4 className="mb-1 fw-500 fs-16">
                Primary Owner<label className="text-danger">*</label>
              </h4>
              <p className="fs-12 mb-1">
                {CONTENT_MESSAGES.productDetailPrimaryOwnerDesc}
              </p>
              {!appTestForm.owner_id && error.isValid && (
                <label className="text-danger fs-12">{REQUIRED_ERROR}</label>
              )}
            </div>
            {props?.isNew && !props?.isEditForm && (
              <a
                className="btn btn-primary btn-sm"
                onClick={() => togglePopup()}
              >
                Select User
              </a>
            )}
            {props?.isNew && props?.isEditForm && (
              <a
                className="btn btn-primary btn-sm"
                onClick={() => togglePopup()}
              >
                Change
              </a>
            )}
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-12 col-md-4">
            <div className="d-flex flex-column">
              <label className="mb-1 fw-500 fs-14">Name</label>
              <label className="fs-14">
                {appTestForm.owner_name ? appTestForm.owner_name : "-"}
              </label>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="d-flex flex-column">
              <label className="mb-1 fw-500 fs-14">Email</label>
              <label className="fs-14">
                {appTestForm.email ? appTestForm.email : "-"}
              </label>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="d-flex flex-column">
              <label className="mb-1 fw-500 fs-14">Username</label>
              <label className="fs-14">
                {appTestForm.username ? appTestForm.username : "-"}
              </label>
            </div>
          </div>
        </div>

        <div className="col-12 mt-3 border-bottom">
          <div className="d-flex flex-column">
            <h4 className="mb-1 fw-500 fs-16">Portal Configuration</h4>
            <p className="fs-12 mb-1">
              {CONTENT_MESSAGES.productDetailPortalConfigDesc}
            </p>
          </div>
        </div>

        {props?.isNew ? (
          <div className="table-responsive mt-3">
            <table className="table data-table data-table-vertically-center ">
              <thead className="table-primary">
                <tr>
                  <th>Key</th>
                  <th>Name</th>
                  <th>URL</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {state?.rows.length > 0 ? (
                  state?.rows.map((item, idx) => (
                    <tr id={"tr_" + idx} key={idx}>
                      <td>
                        <Inputcomp
                          type="text"
                          nameClass="form-control custom-form-lh2"
                          name="key"
                          handleChange={true}
                          isDisabled={false}
                          onChangeInput={(event) =>
                            handleChangeBuildArgs(idx, "key", event)
                          }
                          label=""
                          value={state.rows[idx].key}
                          id={"tr_key_" + idx}
                        />
                      </td>
                      <td>
                        <Inputcomp
                          type="text"
                          nameClass="form-control custom-form-lh2"
                          name="name"
                          handleChange={true}
                          isDisabled={false}
                          onChangeInput={(event) =>
                            handleChangeBuildArgs(idx, "name", event)
                          }
                          label=""
                          value={state.rows[idx].name}
                          id={"tr_name_" + idx}
                        />
                      </td>
                      <td>
                        <Inputcomp
                          type="text"
                          nameClass="form-control custom-form-lh2"
                          name="url"
                          handleChange={true}
                          isDisabled={false}
                          onChangeInput={(event) =>
                            handleChangeBuildArgs(idx, "url", event)
                          }
                          label=""
                          value={state.rows[idx].url}
                          id={"tr_url_" + idx}
                        />
                      </td>
                      <td>
                        <a
                          onClick={() => handleRemoveRow(idx)}
                          className="pull-right btn link text-danger"
                        >
                          <i className="fa fa-trash" aria-hidden="true"></i>
                        </a>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={6} className="text-center">
                      {ERROR_MESSAGES.noRecordsFound}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            <a onClick={handleAddRow} className="btn pull-right link">
              + Add Row
            </a>
          </div>
        ) : (
          <div className="table-responsive mt-3">
            <table className="table data-table data-table-vertically-center ">
              <thead className="table-primary">
                <tr>
                  <th>Key</th>
                  <th>Name</th>
                  <th>URL</th>
                </tr>
              </thead>
              <tbody>
                {props?.productDetail?.portals.length > 0 ? (
                  props?.productDetail?.portals.map((item, idx) => (
                    <tr id={"tr_" + idx} key={idx}>
                      <td>{item.key}</td>
                      <td>{item.name}</td>
                      <td>{item.url}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={6} className="text-center">
                      {ERROR_MESSAGES.noRecordsFound}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}

        {props?.isNew && !props?.isEditForm && (
          <div className="d-flex justify-content-end my-3">
            <Link to={"/app/products"} className="btn btn-secondary me-3">
              Cancel
            </Link>
            <button className="btn btn-primary" onClick={onSubmit}>
              Submit
            </button>
          </div>
        )}
        {props?.isNew && props?.isEditForm && (
          <div className="d-flex justify-content-between my-3">
            <AuditLog logData={props?.productDetail} />
            <div>
              <button
                className="btn btn-secondary  me-3"
                onClick={onUpdateCancelConfirm}
              >
                Cancel
              </button>
              <button className="btn btn-primary" onClick={onUpdate}>
                Save
              </button>
            </div>
          </div>
        )}
      </form>
    </>
  );
};
export default ProductForm;
