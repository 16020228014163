import React from "react";
import lock from "../../images/lock.png";
import "./style.scss";

import { CONTENT_MESSAGES } from "../../constants/app-constants";

const browser = typeof window !== "undefined" && window;

const LimitedAccess = () => {
  return browser ? (
    <div className={"no-access-page"}>
      <img src={lock} className="No_Access lock" alt="No_Access" />

      <h1 className="mt-4 fs-20">{CONTENT_MESSAGES.accessDeniedTital}</h1>
      <p className="fs-16">{CONTENT_MESSAGES.accessDeniedDesc}</p>
    </div>
  ) : null;
};

export default LimitedAccess;
