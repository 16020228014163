import { Link, useLocation, navigate } from "@reach/router";
import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { applicationService } from "../../_services/application.service";
import { testEngineService } from "../../_services/testengine.service";
import Loader from "../../widgets/loader/Loader";
import ErrorPopup from "../../widgets/Popups/Error-Popup";
import ButtonGroupComponent from "../../widgets/Button/Buttongroup/Button-Group";
import { tabItems } from "./Tabhelper";
import { toast } from "react-toastify";
import ConfirmationPopup from "../../widgets/Popups/Confirmation-Popup";
import ApplicationSidebar from "./ApplicationSidebar";
import ApplicationForm from "./ApplicationForm";
import TestEngine from "./TestEngine";
import { AuthService } from "../../_services/auth.service";
import Tooltip from "../../widgets/Tooltip/Tooltip";
import AuditLog from "../../widgets/AuditLog/AuditLog";
import { ERROR_MESSAGES } from "../../constants/app-constants";

const ApplicationDetail = (props) => {
  const accessModule = AuthService.getAllAccessList("products_apps");
  const search = useLocation().search;
  const location = useLocation();
  const prod_key_from_url = new URLSearchParams(search).get("product");

  const tabParam = new URLSearchParams(search).get("tab");

  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [appDetail, setAppDetail] = useState(null);

  const [error, setError] = useState({
    errorStatus: false,
    errorData: "",
    isValid: false,
    name: "App Name Required",
    prod_key: "Product Key Required",
    app_key: "App Key Required",
    noAccessModule: false,
    noAccessModuleMessage: "",
  });

  const [appList, setAppList] = useState(null);

  const [settings, setSettings] = useState({
    showFilter: false,
    isEditable: false,
    buttonIndex: 0,
  });

  const [confirmData, setConfirmData] = useState({
    title: "",
    message: "",
    closeLabel: "",
    okLabel: "",
    isShow: false,
    selectedId: "",
  });

  const [testEngineList, setTestEngineList] = useState(null);

  const handleChange = (event) => {
    setSettings({ ...settings, buttonIndex: event });
  };

  React.useEffect(() => {
    editApplication(false);
    setSettings({ ...settings, buttonIndex: 0 });
  }, [location]);

  React.useEffect(() => {
    settings.buttonIndex == 1 ? getTestEngine() : "";
  }, [settings.buttonIndex]);

  function closeError() {
    setError({ ...error, errorStatus: false });
  }

  React.useEffect(() => {
    getAppList();
  }, []);

  React.useEffect(() => {
    setLoading(true);
    getAppDetails();
  }, [location]);

  function getAppList() {
    setLoading(true);
    applicationService.getAllApplication(prod_key_from_url).then((apps) => {
      if (apps.status == 200 && apps?.data) {
        const options = apps?.data.map((d) => ({
          app_key: d.app_key,
          name: d.name,
          enabled: d.enabled,
          framework_auth_key: d.enabled,
          created_at: d.created_at,
        }));
        setAppList(options);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  }

  function getAppDetails() {
    setLoading(true);
    applicationService.getApplicationDetail(props?.app_key).then((resp) => {
      if (resp.status == 200 && resp?.data) {
        setAppDetail(resp?.data);
        setLoading(false);
      } else {
        setLoading(false);
        if (resp?.status === 422) {
          toast.error(resp?.data?.detail[0].msg);
        } else if (resp?.status === 404) {
          setError({
            ...error,
            noAccessModule: true,
            noAccessModuleMessage: resp?.data?.error
              ? resp?.data?.error
              : ERROR_MESSAGES.noAccessMsg,
          });
        } else {
          setError({
            ...error,
            noAccessModule: true,
            noAccessModuleMessage: resp?.data?.error
              ? resp?.data?.error
              : ERROR_MESSAGES?.detailsFailsMessage,
          });
          // toast.error(resp?.data?.detail ? resp?.data?.detail : resp?.data?.error);
        }
      }
    });
  }

  function getTestEngine() {
    setLoading(true);
    testEngineService.getAllTestEngine(props?.app_key).then((engine) => {
      if (engine.status == 200 && engine?.data) {
        const options = engine?.data.map((d) => ({
          id: d?.id,
          features: d?.features,
          enabled: d?.enabled,
          core_engine: d?.core_engine?.engine_key,
          test_type: d?.core_engine?.test_type,
          execution: d?.core_engine?.execution,
          capacity_units: d?.core_engine?.capacity_units,
          capacity_uom: d?.core_engine?.capacity_uom,
          created_at: d?.created_at,
        }));

        setTestEngineList(options);
        setLoading(false);
      } else {
        setLoading(false);
        if (engine?.status === 422) {
          toast.error(engine?.data?.detail[0].msg);
        } else {
          toast.error(engine?.data?.detail);
        }
      }
    });
  }

  function editApplication(event) {
    setIsEdit(event);
  }

  function editApplicationAfterEdit(event) {
    setIsEdit(event);
    getAppList();
  }

  function handelLoading(event) {
    setLoading(event);
  }

  function getActiveButton(event) {
    let newData = {
      app_build_count: appDetail?.app_build_count,
      app_key: appDetail?.app_key,
      created_at: appDetail?.created_at,
      enabled: event.target.checked,
      framework_auth_key: appDetail?.framework_auth_key,
      id: appDetail?.id,
      name: appDetail?.name,
      portals: appDetail?.portals,
      product: appDetail?.product,
      updated_at: appDetail?.updated_at,
    };
    setAppDetail(newData);

    handelLoading(true);
    if (!validateForm()) {
      handelLoading(false);
      return false;
    }

    let appTestForm = {
      name: appDetail?.name,
      enabled: event.target.checked ? true : false,
      portals: {},
    };

    applicationService
      .updateApplication(appTestForm, appDetail?.app_key)
      .then((resp) => {
        if (resp.status == 200 && resp?.data) {
          handelLoading(false);
          getAppList();
          editApplication(false);
        } else {
          if (resp?.status === 422) {
            toast.error(resp?.data?.detail[0].msg);
          } else {
            toast.error(
              resp?.data?.detail ? resp?.data?.detail : resp?.data?.error
            );
          }
          handelLoading(false);
        }
      });
  }

  function validateForm() {
    if (appDetail.name && appDetail.app_key) {
      setError({ ...error, isValid: false });
      return true;
    } else {
      setError({ ...error, isValid: true });
      return false;
    }
  }

  function deleteApplication() {
    setConfirmData({
      ...confirmData,
      title: "Alert",
      message: ERROR_MESSAGES.reConformMessage,
      closeLabel: "Cancel",
      okLabel: "Confirm",
      isShow: true,
      selectedId: appDetail.app_key,
    });
  }

  function closeConfirm() {
    setConfirmData({
      ...confirmData,
      isShow: false,
      selectedId: "",
    });
  }

  function deleteConfirm() {
    closeConfirm();
    setLoading(true);
    applicationService
      .deleteApplication(confirmData?.selectedId)
      .then((appRes) => {
        if (appRes.status == 200 && appRes?.data) {
          setLoading(false);
          navigate(`/app/products/${prod_key_from_url}`);
        } else {
          setLoading(false);
          if (appRes?.status === 422) {
            toast.error(appRes?.data?.detail[0].msg);
          } else {
            toast.error(appRes?.data?.detail);
          }
        }

        // setLoading(false)
        //
      });
  }

  return (
    <div className="containWrap-inner">
      {loading ? <Loader /> : ""}

      {error.errorStatus ? (
        <ErrorPopup data={error.errorData} closePopup={closeError} />
      ) : (
        ""
      )}

      {confirmData.isShow ? (
        <ConfirmationPopup
          data={confirmData}
          confirmCloseEvent={closeConfirm}
          confirmOkEvent={deleteConfirm}
        />
      ) : (
        ""
      )}

      {!error.noAccessModule ? (
        <div className="col-12">
          <div className="row">
            <div className="col-4">
              <Card className="bg-white custom-card list-option h-100">
                <Card.Header className="shadow-none p-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <Card.Title>
                      <h4 className="m-0">Applications</h4>
                    </Card.Title>
                    <div className="d-flex align-items-center justify-content-between">
                     <i
                        aria-hidden="true"
                        data-tip
                        data-for={!accessModule.add && "addTipApp"} 
                      >
                      <button
                        disabled={!accessModule.add}
                        onClick={() =>
                          navigate(
                            `/app/products/product-apps/new${
                              "?product=" + prod_key_from_url
                            }`
                          )
                        }
                        className="btn btn-sm btn-primary"
                      >
                        Add Application
                      </button>
                      </i>
                      {/* {!accessModule.add && (
                        <i
                          className="fa fa-info-circle ms-2"
                          aria-hidden="true"
                          data-tip
                          data-for="addTipApp"
                        ></i>
                      )} */}
                     
                      <Tooltip
                        id="addTipApp"
                        place="right"
                        effect="solid"
                        msgType="operation"
                      />
                    </div>
                  </div>
                  {prod_key_from_url && (
                    <>
                      <span className="fs-16 me-2">of</span>
                      <Link
                        to={`/app/products/${prod_key_from_url}`}
                        className="fs-16 m-0 link text-primary"
                      >
                        {prod_key_from_url}
                      </Link>
                    </>
                  )}
                </Card.Header>
                <Card.Body>
                  <div className="h-100 d-flex flex-column">
                    <ApplicationSidebar
                      appList={appList}
                      prod_key={prod_key_from_url}
                    />
                  </div>
                </Card.Body>
              </Card>
            </div>
            <div className="col-8 ps-0">
              <div className="col-12">
                <div className="d-flex align-items-center justify-content-between">
                  <Card className="bg-white custom-card custom-card-full">
                    <Card.Header className="shadow-none p-3">
                      <div className="d-flex align-items-start justify-content-between mt-3 mb-1">
                        <Card.Title>
                          <h4 className="">
                            {appDetail ? (
                              <span>
                                {appDetail?.name} ({appDetail?.app_key})
                              </span>
                            ) : (
                              <span>Application Detail</span>
                            )}
                          </h4>
                        </Card.Title>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center">
                           
                            <i
                               aria-hidden="true"
                               data-tip
                               data-for={(!accessModule.enabled)? "enableTipApp":""}
                             >
                            <div
                              className={
                                !accessModule.enabled
                                  ? "toggle row-disabled-opacity"
                                  : "toggle"
                              }
                            >
                              <input
                                type="checkbox"
                                disabled={!accessModule.enabled}
                                className="toggle-checkbox"
                                id="appEnable"
                                name="darkmode"
                                value="on"
                                onChange={getActiveButton}
                                checked={appDetail?.enabled}
                              />
                              <label
                                className="toggle-label"
                                htmlFor="appEnable"
                              >
                                <span className="toggle-inner"></span>
                                <span className="toggle-switch"></span>
                              </label>
                            </div>
                            </i>

                            <Tooltip
                              id="enableTipApp"
                              place="left"
                              effect="solid"
                              msgType="operation"
                            />

                            <i
                               aria-hidden="true"
                               data-tip
                               data-for={(!accessModule.delete || !accessModule.delete)? "deleteTip":""}
                             >
                            <button
                              disabled={!accessModule.delete}
                              onClick={deleteApplication}
                              className="text-danger fs-20 py-0 mx-2 btn btn-link"
                            >
                              <i className="fa fa-trash" aria-hidden="true"></i>
                            </button>
                            </i>

                            {/* {(!accessModule.delete || !accessModule.delete) && (
                              <i
                                className="fa fa-info-circle me-3"
                                aria-hidden="true"
                                data-tip
                                data-for="deleteTip"
                              ></i>
                            )} */}
                            <Tooltip
                              id="deleteTip"
                              place="left"
                              effect="solid"
                              msgType="operation"
                            />
                          </div>
                          <Link
                            className="no-link click-able"
                            to={`/app/products/${prod_key_from_url}`}
                          >
                            <i className="fa fa-times" aria-hidden="true"></i>
                          </Link>
                        </div>
                      </div>
                      <ButtonGroupComponent
                        valueSelected={settings.buttonIndex}
                        options={tabItems}
                        onClick={handleChange}
                      />
                    </Card.Header>
                    <Card.Body>
                      {settings?.buttonIndex == 0 && (
                        <ApplicationForm
                          isNew={isEdit}
                          appDetail={appDetail}
                          isEditForm={true}
                          isLoading={handelLoading}
                          handelEdit={editApplicationAfterEdit}
                          handelEditWithoutChange={editApplication}
                          prod_key={prod_key_from_url}
                        />
                      )}

                      {settings?.buttonIndex == 1 && (
                        <TestEngine
                          testEngineList={testEngineList}
                          isLoading={handelLoading}
                          app_key={appDetail?.app_key}
                          prod_key={prod_key_from_url}
                        />
                      )}

                      {settings?.buttonIndex == 0 && !isEdit && (
                        <div className="d-flex justify-content-between align-items-center mt-3 mb-1">
                          <AuditLog logData={appDetail} />
                          <div>
                          
                          <i
                            aria-hidden="true"
                            data-tip
                            data-for={!accessModule.update && "editTip"}
                          >
                            <button
                              disabled={!accessModule.update}
                              className="btn btn-primary"
                              onClick={() => editApplication(true)}
                            >
                              Edit
                            </button>
                            </i>
                            <Tooltip
                              id="editTip"
                              place="right"
                              effect="solid"
                              msgType="operation"
                            />
                          </div>
                        </div>
                      )}
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="row err-msg-container">
          <div className="col-12">
            <Card className="bg-white custom-card list-option">
              <Card.Body>
                <div className="row err-msg">
                  <label className="text-center">
                    {error.noAccessModuleMessage
                      ? error.noAccessModuleMessage
                      : ERROR_MESSAGES.noAccessMsg}
                  </label>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      )}
    </div>
  );
};

export default ApplicationDetail;
