import api from "./api";

export const applicationService = {
  getAllApplication,
  getApplicationDetail,
  createApplication,
  updateApplication,
  deleteApplication,
};

// Applications

function getAllApplication(prod_key: any) {
  // https://api.kltaf.frizbane.io/api/products/test-mandar/applications'
  return api.get(`api/products/${prod_key}/applications`);
}

function getApplicationDetail(app_key: any) {
  // https://api.kltaf.frizbane.io/api/applications/Test-app1'
  return api.get(`api/applications/${app_key}`);
}

function createApplication(param: any, prodKey: any) {
  // https://api.kltaf.frizbane.io/api/products/test-mandar/applications'

  return api
    .post(`api/products/${prodKey}/applications`, param)
    .then((response) => {
      return response;
    });
}

function updateApplication(param: any, app_key: any) {
  // 'https://api.kltaf.frizbane.io/api/applications/Test-app2'
  return api.post(`api/applications/${app_key}`, param).then((response) => {
    return response;
  });
}

function deleteApplication(app_key: any) {
  // 'https://api.kltaf.frizbane.io/api/applications/Test-app112'
  return api.delete(`api/applications/${app_key}`);
}
