import React, { useState } from 'react'
import Loader from '../../widgets/loader/Loader'
import { Card } from 'react-bootstrap';
import ExecutionEngineSidebar from './ExecutionEngineSidebar';
import { executionEngineService } from '../../_services/executionengine.service';
import { ERROR_MESSAGES, FETCH_LIMIT } from '../../constants/app-constants';
import { toast } from 'react-toastify';
import { Link } from 'gatsby';
import ExecutionEngineForm from './ExecutionEngineForm';

const ExecutionEngineNew = () => {
  const [loading, setLoading] = useState(false);
  const [isLoadingSidebar, setIsLoadingSidebar] = useState(false);
  const [isEdit, setIsEdit] = useState(true);
  const [errorSide, setErrorSide] = useState({
    isError: false,
    errMsg: "",
  });
  const [selectedDropdown, setSelectedDropdown] = useState({
    showFilter: false,
    limit: FETCH_LIMIT,
    offset: 0,
    pageNumber: 0,
  });
  const [loadMore, setLoadMore] = useState(true);

  const [executionEngineList, setExecutionEngineList] = useState(null);

  React.useEffect(() => {
    getAllCoreEngine();
  }, []);

  function handelLoading(event) {
    setLoading(event);
  }

  function getAllCoreEngine() {
    setIsLoadingSidebar(true);
    executionEngineService
      .getAllExecutionEngine(selectedDropdown.limit, selectedDropdown.offset)
      .then((resp) => {
        if (resp.status == 200 && resp?.data) {
          setExecutionEngineList(resp.data);
          // setLoading(false)
          setIsLoadingSidebar(false);
          setErrorSide({
            ...errorSide,
            isError: false,
            errMsg: "",
          });
        } else if (resp?.status == 408) {
          setErrorSide({
            ...errorSide,
            isError: true,
            errMsg: ERROR_MESSAGES.timeExceedError,
          });
          setIsLoadingSidebar(false);
        } else {
          setErrorSide({
            ...errorSide,
            isError: true,
            errMsg: ERROR_MESSAGES.failedLoadRecordsError,
          });
          setIsLoadingSidebar(false);
          toast.error(
            resp?.data?.detail ? resp?.data?.detail : resp?.data?.error
          );
        }
      });
  }

  function fetchMoreData(e) { }

  return (
    <div className="containWrap-inner">
      {loading ? <Loader /> : ""}

      {/* {error.errorStatus ? (<ErrorPopup data={error.errorData} closePopup={closeError} />) : ''} */}
      <div className="col-12">
        <div className="row">
          <div className="col-4">
            <Card className="bg-white custom-card list-option h-100">
              <Card.Header className="shadow-none p-3">
                <div className="d-flex align-items-center justify-content-between">
                  <Card.Title>
                    <h4 className="m-0">Execution Engines</h4>
                  </Card.Title>
                </div>
              </Card.Header>
              <Card.Body>
                {isLoadingSidebar ? (
                  <div className="text-center"> Please Wait....</div>
                ) : errorSide.isError ? (
                  <div className="text-center text-danger">
                    {" "}
                    {errorSide.errMsg}
                  </div>
                ) : (
                  <ExecutionEngineSidebar
                    sidedata={executionEngineList}
                    getMoreData={fetchMoreData}
                    loadFeature={loadMore}
                  />
                )}
              </Card.Body>
            </Card>
          </div>
          <div className="col-8 ps-0">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between">
                <Card className="bg-white custom-card custom-card-full">
                  <Card.Header className="shadow-none p-3">
                    <div className="d-flex align-items-center justify-content-between">
                      <Card.Title className="m-0">
                        <h4 className="m-0">Add New Execution Engine</h4>
                      </Card.Title>
                      <Link className="no-link" to={`/app/execution-engines`}>
                        <i className="fa fa-times" aria-hidden="true"></i>
                      </Link>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <ExecutionEngineForm
                      isNew={isEdit}
                      isLoading={handelLoading}
                      isEditForm={false}
                    />
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExecutionEngineNew