import { Link, useLocation, navigate } from "@reach/router";

import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { toast } from "react-toastify";
import { testEngineService } from "../../_services/testengine.service";
import Loader from "../../widgets/loader/Loader";
import ErrorPopup from "../../widgets/Popups/Error-Popup";
import TestEngineSidebar from "./TestEngineSidebar";
import TestEngineForm from "./TestEngineForm";

const TestEngineNew = (props) => {
  const search = useLocation().search;
  const location = useLocation();
  const prod_key_from_url = new URLSearchParams(search).get("product");
  const app_key_from_url = new URLSearchParams(search).get("app");
  const [app_key, setApp_key] = useState("");
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState({
    errorStatus: false,
    errorData: "",
  });
  const [testEngineList, setTestEngineList] = useState(null);

  const [isEdit, setIsEdit] = useState(true);

  function closeError() {
    setError({ ...error, errorStatus: false });
    window.localStorage.removeItem("localUser");
    navigate(`/app/login`);
  }

  React.useEffect(() => {
    gettestEngineList();
  }, [location]);

  function gettestEngineList() {
    setLoading(true);
    testEngineService.getAllTestEngine(app_key_from_url).then((engine) => {
      if (engine.status == 200 && engine?.data) {
        const options = engine?.data.map((d) => ({
          id: d?.id,
          features: d?.features,
          enabled: d?.enabled,
          core_engine: d?.core_engine?.engine_key,
          test_type: d?.core_engine?.test_type,
          execution: d?.core_engine?.execution,
          capacity_units: d?.core_engine?.capacity_units,
          capacity_uom: d?.core_engine?.capacity_uom,
          created_at: d?.created_at,
        }));
        setTestEngineList(options);
        setLoading(false);
      } else {
        setLoading(false);
        if (engine?.status === 422) {
          toast.error(engine?.data?.detail[0].msg);
        } else {
          toast.error(engine?.data?.detail);
        }
      }
    });
  }

  function handelLoading(event) {
    setLoading(event);
  }

  return (
    <div className="containWrap-inner">
      {loading ? <Loader /> : ""}
      {error.errorStatus ? (
        <ErrorPopup data={error.errorData} closePopup={closeError} />
      ) : (
        ""
      )}
      <div className="col-12">
        <div className="row">
          <div className="col-4">
            <Card className="bg-white custom-card list-option h-100">
              <Card.Header className="shadow-none p-3">
                <div className="d-flex align-items-center justify-content-between">
                  <Card.Title>
                    <h4 className="m-0">Test Engines</h4>
                  </Card.Title>
                </div>
                {prod_key_from_url && (
                  <>
                    <span className="fs-16 me-2">of</span>
                    <Link
                      to={`/app/products/${prod_key_from_url}`}
                      className="fs-16 m-0 link text-primary"
                    >
                      {prod_key_from_url}
                    </Link>
                    <span className="fs-16 mx-2">/</span>
                    <Link
                      to={`/app/products/product-apps/${app_key_from_url}${
                        "?product=" + prod_key_from_url
                      }`}
                      className="fs-16 m-0 link text-primary"
                    >
                      {app_key_from_url}
                    </Link>
                  </>
                )}
              </Card.Header>
              <Card.Body>
                <div className="h-100 d-flex flex-column">
                  <TestEngineSidebar
                    testEngineList={testEngineList}
                    prod_key={prod_key_from_url}
                    app_key={app_key_from_url}
                  />
                </div>
              </Card.Body>
            </Card>
          </div>
          <div className="col-8 ps-0">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between">
                <Card className="bg-white custom-card custom-card-full">
                  <Card.Header className="shadow-none p-3">
                    <div className="d-flex align-items-center justify-content-between">
                      <Card.Title className="m-0">
                        <h4 className="m-0">Add New Test Engine</h4>
                      </Card.Title>
                      <Link
                        className="no-link"
                        to={`/app/products/product-apps/${app_key_from_url}${
                          "?product=" + prod_key_from_url
                        }`}
                      >
                        <i className="fa fa-times" aria-hidden="true"></i>
                      </Link>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <TestEngineForm
                      isNew={isEdit}
                      isLoading={handelLoading}
                      isEditForm={false}
                      prod_key={prod_key_from_url}
                      app_key={app_key_from_url}
                    />
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestEngineNew;
