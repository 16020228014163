import React, { Component, useState } from "react";
import "./style.scss";

import { Link } from "@reach/router";

const ApplicationSidebar = (props) => {
  return (
    <div className="mngScroll_outer">
      {props?.appList ? (
        <div className="app-build-list mngScroll_Content">
          {props?.appList &&
            props?.appList.map((app, index) => (
              <div
                className={`${
                  app.enabled
                    ? "scrollChild"
                    : "scrollChild row-disabled-sidebar"
                }`}
                key={index}
              >
                <Link
                  to={`/app/products/product-apps/${app.app_key}${
                    "?product=" + props?.prod_key
                  }`}
                  className="no-link"
                >
                  <span className="fw-600 fs-16 mb-1 click-able">
                    {" "}
                    {app.name}{" "}
                  </span>{" "}
                  <br />
                  <span className="fs-14 mb-1"> {app.app_key}</span>
                </Link>
              </div>
            ))}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ApplicationSidebar;
