import React, { useState } from "react";
import { Link, navigate } from "@reach/router";
import "./style.scss";
import { testEngineService } from "../../_services/testengine.service";
import { toast } from "react-toastify";
import ConfirmationPopup from "../../widgets/Popups/Confirmation-Popup";
import { AuthService } from "../../_services/auth.service";
import Tooltip from "../../widgets/Tooltip/Tooltip";
import {
  CONTENT_MESSAGES,
  ERROR_MESSAGES,
} from "../../constants/app-constants";
const TestEngine = (props) => {
  const accessModule = AuthService.getAllAccessList("products_apps_testengine");
  const [testEngineList, setTestEngineList] = useState(null);
  const [app_key, setAppKey] = useState(null);
  const [prod_key, setProdKey] = useState(null);
  const [confirmData, setConfirmData] = useState({
    title: "",
    message: "",
    closeLabel: "",
    okLabel: "",
    isShow: false,
    selectedId: "",
  });

  React.useEffect(() => {
    setTestEngineList(props?.testEngineList);
    setAppKey(props?.app_key);
    setProdKey(props?.prod_key);
  }, [props?.testEngineList]);

  function getTestEngine() {
    props?.isLoading(true);
    testEngineService.getAllTestEngine(props?.app_key).then((engine) => {
      if (engine.status == 200 && engine?.data) {
        const options = engine?.data.map((d) => ({
          id: d?.id,
          features: d?.features,
          enabled: d?.enabled,
          core_engine: d?.core_engine?.engine_key,
          test_type: d?.core_engine?.test_type,
          execution: d?.core_engine?.execution,
          capacity_units: d?.core_engine?.capacity_units,
          capacity_uom: d?.core_engine?.capacity_uom,
          created_at: d?.created_at,
        }));

        setTestEngineList(options);
        props?.isLoading(false);
      } else {
        props?.isLoading(false);
        if (engine?.status === 422) {
          toast.error(engine?.data?.detail[0].msg);
        } else {
          toast.error(engine?.data?.detail);
        }
      }
    });
  }

  function handleRemoveApplication(id) {
    setConfirmData({
      ...confirmData,
      title: "Alert",
      message: ERROR_MESSAGES?.reConformMessage,
      closeLabel: "Cancel",
      okLabel: "Confirm",
      isShow: true,
      selectedId: id,
    });
  }

  function deleteConfirm(id) {
    closeConfirm();
    props?.isLoading(true);
    testEngineService.deleteTestEngine(id).then((resp) => {
      if (resp.status == 200 && resp?.data) {
        toast.success("Deleted Succefully");
        getTestEngine();
      } else {
        if (resp?.status === 422) {
          toast.error(resp?.data?.detail[0].msg);
        } else {
          toast.error(
            resp?.data?.detail ? resp?.data?.detail : resp?.data?.error
          );
        }
        props?.isLoading(false);
      }
    });
  }

  function closeConfirm() {
    setConfirmData({
      ...confirmData,
      isShow: false,
      selectedId: "",
    });
  }

  return (
    <>
      {confirmData.isShow ? (
        <ConfirmationPopup
          data={confirmData}
          confirmCloseEvent={closeConfirm}
          confirmOkEvent={() => deleteConfirm(confirmData?.selectedId)}
        />
      ) : (
        ""
      )}

      <div className="col-12 border-bottom">
        <div className="d-flex align-items-start justify-content-between">
          <div className="d-flex flex-column">
            <h4 className="mb-1 fw-500 fs-14">Test Engines</h4>
            <p className="fs-12 mb-2">
              {CONTENT_MESSAGES.appTestEnginesTableDesc}
            </p>
          </div>
          <div className="d-flex align-items-center justify-content-between">
          <i 
            aria-hidden="true"
            data-tip
            data-for={!accessModule.add && "addTipEngine"}
          >
            <button
              disabled={!accessModule.add}
              onClick={() =>
                navigate(
                  `/app/products/test-engines/new?product=${prod_key}&app=${app_key}`
                )
              }
              className="btn btn-sm btn-primary"
            >
              Add Test Engine
            </button>
            </i>
            <Tooltip
              id="addTipEngine"
              place="left"
              effect="solid"
              msgType="operation"
            />
          </div>
        </div>
      </div>
      <div className="table-responsive mt-2">
        <table className="table data-table data-table-vertically-center  ">
          <thead className="table-primary">
            <tr>
              <th>Core Engine</th>
              <th>Type</th>
              <th>Execution</th>
              <th>Allocated Capacity</th>
              <th className="text-center actions">
               
              </th>
            </tr>
          </thead>
          <tbody>
            {testEngineList &&
              testEngineList.map((engine, idx) => (
                <tr id={"tr_" + idx} key={idx}>
                  <td>
                    <Link
                      to={`/app/products/test-engines/${engine?.id}${
                        "?product=" + props?.prod_key
                      }${"&app=" + props?.app_key}`}
                      className="no-link click-able"
                    >
                      {engine?.core_engine}
                    </Link>
                  </td>
                  <td>{engine?.test_type}</td>

                  <td>{engine?.execution}</td>
                  <td>
                    {engine?.capacity_uom === "Unlimited"
                      ? "Unlimited"
                      : engine?.capacity_units + " " + engine?.capacity_uom}
                  </td>
                  <td>

                  <i
                    aria-hidden="true"
                    data-tip
                    data-for= {!accessModule.add && "deleteTip"}
                  >
                      <button
                        disabled={!accessModule.delete}
                        onClick={() => handleRemoveApplication(engine?.id)}
                        className="btn link text-danger"
                      >
                        <i className="fa fa-trash" aria-hidden="true"></i>
                      </button>
                    </i>
                      <Tooltip
                        id="deleteTip"
                        place="top"
                        effect="solid"
                        msgType="operation"
                      />
                  </td>
                </tr>
              ))}
            {testEngineList && !testEngineList.length && (
              <tr>
                <td colSpan={4} className="text-center">
                  <div className="p-2">{ERROR_MESSAGES.noRecordsFound}</div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};
export default TestEngine;
