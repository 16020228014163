import React from "react";
import "./styleInput.scss";
import ReactTooltip from "react-tooltip";
import { TOOLTIP_MESSAGES } from "../../constants/app-constants";

const Tooltip = (props) => {
  return (
    <ReactTooltip id={props?.id} place={props?.place} effect={props?.effect}>
      {TOOLTIP_MESSAGES[props?.msgType]}
    </ReactTooltip>
  );
};

export default Tooltip;
