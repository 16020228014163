import { Link, useLocation, navigate } from "@reach/router";
import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { coreEngineService } from "../../_services/coreengine.service";
import Loader from "../../widgets/loader/Loader";
import { toast } from "react-toastify";
import ConfirmationPopup from "../../widgets/Popups/Confirmation-Popup";
import { FETCH_LIMIT } from "../../constants/app-constants";
import CoreEngineSidebar from "./CoreEngineSidebar";
import CoreEngineForm from "./CoreEngineForm";
import CoreEngineFeature from "./CoreEngineFeature";
import ButtonGroupComponent from "../../widgets/Button/Buttongroup/Button-Group";
import { tabItems } from "./Tabhelper";
import { AuthService } from "../../_services/auth.service";
import Tooltip from "../../widgets/Tooltip/Tooltip";
import AuditLog from "../../widgets/AuditLog/AuditLog";

import {
  SUCCESS_MESSAGES,
  ERROR_MESSAGES,
} from "../../constants/app-constants";
const CoreEngineDetail = (props) => {
  const location = useLocation();
  const accessModule = AuthService.getAllAccessList("coreengine");
  const [loadMore, setMoreCoreEngine] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [coreEngineList, setCoreEngineList] = useState(null);
  const [coreEngineDetail, setCoreEngineDetail] = useState(null);

  const [confirmData, setConfirmData] = useState({
    title: "",
    message: "",
    closeLabel: "",
    okLabel: "",
    isShow: false,
    selectedId: "",
  });

  const [selectedValue, setSelectedValue] = useState({
    showFilter: true,
    limit: FETCH_LIMIT,
    offset: 0,
    pageNumber: 0,
  });

  const [settings, setSettings] = useState({
    showFilter: false,
    isEditable: false,
    buttonIndex: 0,
  });

  const [error, setError] = useState({
    errorStatus: false,
    errorData: "",
    noAccessModule: false,
    noAccessModuleMessage: "",
  });

  const [errorSide, setErrorSide] = useState({
    isError: false,
    errMsg: "",
  });

  const [isLoadingSidebar, setIsLoadingSidebar] = useState(false);

  React.useEffect(() => {
    getAllCoreEngine();
  }, []);

  function getAllCoreEngine() {
    // setLoading(true)
    setIsLoadingSidebar(true);
    coreEngineService
      .getAllCoreEngine(selectedValue.limit, selectedValue.offset)
      .then((resp) => {
        //
        if (resp.status == 200 && resp?.data) {
          setCoreEngineList(resp.data);
          // setLoading(false)
          setIsLoadingSidebar(false);
        } else if (resp?.status == 408) {
          setErrorSide({
            ...errorSide,
            isError: true,
            errMsg: ERROR_MESSAGES.timeExceedError,
          });
          //   setLoading(false);
          setIsLoadingSidebar(false);
        } else {
          setErrorSide({
            ...errorSide,
            isError: true,
            errMsg: ERROR_MESSAGES.failedLoadRecordsError,
          });
          // setLoading(false)
          setIsLoadingSidebar(false);
          toast.error(
            resp?.data?.detail ? resp?.data?.detail : resp?.data?.error
          );
        }
      });
  }

  React.useEffect(() => {
    getCoreEngineDetail();
    editCoreEngine(false);
  }, [location]);

  function getCoreEngineDetail() {
    setLoading(true);
    coreEngineService.getCoreEngineDetail(props?.engine_key).then((resp) => {
      if (resp.status == 200 && resp?.data) {
        // if (resp?.data?.portals == "{}" ) resp.data.portals = ''
        setError({
          ...error,
          noAccessModule: false,
          noAccessModuleMessage: "",
        });
        setCoreEngineDetail(resp?.data);
        setLoading(false);
      } else if (resp?.status == 408) {
        setError({
          ...error,
          noAccessModule: true,
          noAccessModuleMessage: ERROR_MESSAGES.timeExceedError,
        });
        setLoading(false);
      } else {
        setLoading(false);
        if (resp?.status === 422) {
          setError({
            ...error,
            noAccessModule: false,
            noAccessModuleMessage: "",
          });
          toast.error(resp?.data?.detail[0].msg);
        } else if (resp?.status === 404) {
          setError({
            ...error,
            noAccessModule: true,
            noAccessModuleMessage: resp?.data?.error
              ? resp?.data?.error
              : ERROR_MESSAGES.noAccessMsg,
          });
        } else {
          // setError({...error, noAccessModule:false, noAccessModuleMessage:''})
          // toast.error(resp?.data?.detail ? resp?.data?.detail : resp?.data?.error);
          setError({
            ...error,
            noAccessModule: true,
            noAccessModuleMessage: resp?.data?.error
              ? resp?.data?.error
              : ERROR_MESSAGES?.detailsFailsMessage,
          });
        }
      }
    });
  }

  function editCoreEngine(event) {
    setIsEdit(event);
  }

  function editCoreEngineAfterEdit(event) {
    setIsEdit(event);
    getCoreEngineDetail();
    getAllCoreEngine();
  }

  function editAfterFeture() {
    getCoreEngineDetail();
  }

  function handelLoading(event) {
    setLoading(event);
  }

  function deleteCoreEngine() {
    setConfirmData({
      ...confirmData,
      title: "Alert",
      message: ERROR_MESSAGES.reConformMessage,
      closeLabel: "Cancel",
      okLabel: "Confirm",
      isShow: true,
      selectedId: props?.engine_key,
    });
  }

  function closeConfirm() {
    setConfirmData({
      ...confirmData,
      isShow: false,
      selectedId: "",
    });
  }

  function deleteConfirm() {
    closeConfirm();
    setLoading(true);
    coreEngineService
      .deleteCoreEngine(confirmData?.selectedId)
      .then((appRes) => {
        if (appRes.status == 200 && appRes?.data) {
          setLoading(false);
          toast.success(SUCCESS_MESSAGES.delete);
          navigate(`/app/core-engines`);
        } else if (appRes?.status == 408) {
          setLoading(false);
          toast.error(ERROR_MESSAGES.timeExceedError);
        } else {
          setLoading(false);
          if (appRes?.status === 422) {
            toast.error(appRes?.data?.detail[0].msg);
          } else {
            toast.error(appRes?.data?.detail);
          }
        }
      });
  }

  function fetchMoreData(e) {
    //
    if (e) {
      let pgNo = selectedValue.pageNumber + 1;
      let offset = pgNo * selectedValue.limit;
      setSelectedValue({ ...selectedValue, pageNumber: pgNo });

      coreEngineService
        .getAllCoreEngine(selectedValue.limit, offset)
        .then((resp) => {
          if (resp.status == 200 && resp?.data) {
            if (resp?.data.length > 0) {
              let newlist = coreEngineList.concat(resp?.data);
              setMoreCoreEngine(true);
              setCoreEngineList(newlist);
            } else {
              setMoreCoreEngine(false);
            }
          }
        });
    }
  }

  const handleTabChange = (event) => {
    setSettings({ ...settings, buttonIndex: event });
  };

  return (
    <div className="containWrap-inner">
      {loading ? <Loader /> : ""}
      {confirmData.isShow ? (
        <ConfirmationPopup
          data={confirmData}
          confirmCloseEvent={closeConfirm}
          confirmOkEvent={deleteConfirm}
        />
      ) : (
        ""
      )}

      {!error.noAccessModule ? (
        <div className="col-12">
          <div className="row">
            <div className="col-4">
              <Card className="bg-white custom-card list-option h-100">
                <Card.Header className="shadow-none p-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <Card.Title>
                      <h4 className="m-0">Core Engines</h4>
                    </Card.Title>
                    <div className="d-flex align-items-center justify-content-between">
                      <button
                        disabled={!accessModule.add}
                        onClick={() => navigate("/app/core-engines/new")}
                        className="btn btn-sm btn-primary"
                      >
                        Add Core Engine
                      </button>
                      {!accessModule.add && (
                        <i
                          className="fa fa-info-circle ms-2"
                          aria-hidden="true"
                          data-tip
                          data-for="addTip"
                        ></i>
                      )}
                      <Tooltip
                        id="addTip"
                        place="right"
                        effect="solid"
                        msgType="operation"
                      />
                    </div>
                  </div>
                </Card.Header>
                <Card.Body>
                  {isLoadingSidebar ? (
                    <div className="text-center"> Please Wait....</div>
                  ) : errorSide.isError ? (
                    <div className="text-center text-danger">
                      {" "}
                      {errorSide.errMsg}
                    </div>
                  ) : (
                    <CoreEngineSidebar
                      sidedata={coreEngineList}
                      getMoreData={fetchMoreData}
                      loadFeature={loadMore}
                    />
                  )}
                </Card.Body>
              </Card>
            </div>
            <div className="col-8 ps-0">
              <div className="col-12">
                <div className="d-flex align-items-center justify-content-between">
                  <Card className="bg-white custom-card custom-card-full">
                    <Card.Header className="shadow-none p-3">
                      <div className="d-flex align-items-start justify-content-between">
                        <Card.Title className="m-0">
                          <h4 className="">
                            {!isEdit
                              ? "Edit Core Engine"
                              : "view Core Engine detail"}
                          </h4>
                        </Card.Title>

                        <div className="d-flex align-items-center">
                          {!isEdit && (
                            <button
                              disabled={!accessModule.delete}
                              onClick={deleteCoreEngine}
                              className="text-danger fs-20 py-0 mx-2 btn btn-link"
                            >
                              <i className="fa fa-trash" aria-hidden="true"></i>
                            </button>
                          )}
                          {!accessModule.delete && (
                            <i
                              className="fa fa-info-circle me-3"
                              aria-hidden="true"
                              data-tip
                              data-for="deleteTip"
                            ></i>
                          )}
                          <Tooltip
                            id="deleteTip"
                            place="left"
                            effect="solid"
                            msgType="operation"
                          />

                          <Link className="no-link" to={`/app/core-engines`}>
                            <i className="fa fa-times" aria-hidden="true"></i>
                          </Link>
                        </div>
                      </div>
                      {!isEdit && (
                        <div className="col-12 mt-3">
                          <ButtonGroupComponent
                            valueSelected={settings.buttonIndex}
                            options={tabItems}
                            onClick={handleTabChange}
                          />
                        </div>
                      )}
                    </Card.Header>
                    <Card.Body>
                      {coreEngineDetail && settings?.buttonIndex == 0 && (
                        <CoreEngineForm
                          isNew={isEdit}
                          isLoading={handelLoading}
                          handelEditWithoutChange={editCoreEngine}
                          handelEdit={editCoreEngineAfterEdit}
                          isEditForm={true}
                          id={props?.id}
                          coreEngineDetail={coreEngineDetail}
                        />
                      )}

                      {coreEngineDetail && settings?.buttonIndex == 1 && (
                        <CoreEngineFeature
                          isLoading={handelLoading}
                          coreEngineDetail={coreEngineDetail}
                          editAfterFeture={editAfterFeture}
                        />
                      )}

                      {!isEdit && settings?.buttonIndex == 0 && (
                        <div className="d-flex justify-content-between align-items-center mt-3 mb-1">
                          <AuditLog logData={coreEngineDetail} />
                          <div>
                            <button
                              disabled={!accessModule.update}
                              className="btn btn-primary"
                              onClick={() => editCoreEngine(true)}
                            >
                              Edit
                            </button>
                            {!accessModule.add && (
                              <i
                                className="fa fa-info-circle ms-2"
                                aria-hidden="true"
                                data-tip
                                data-for="updateTip"
                              ></i>
                            )}
                            <Tooltip
                              id="updateTip"
                              place="left"
                              effect="solid"
                              msgType="operation"
                            />
                          </div>
                        </div>
                      )}
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="row err-msg-container">
          <div className="col-12">
            <Card className="bg-white custom-card list-option">
              <Card.Body>
                <div className="row err-msg">
                  <label className="text-center">
                    {error.noAccessModuleMessage
                      ? error.noAccessModuleMessage
                      : ERROR_MESSAGES.noAccessMsg}
                  </label>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      )}
    </div>
  );
};

export default CoreEngineDetail;
