export const tabItems = [
  {
    label: "Details",
    tabindex: 0,
  },
  {
    label: "Features",
    tabindex: 1,
  },
];
