import React from 'react'
import { ERROR_MESSAGES } from '../../constants/app-constants'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Link } from 'gatsby';

const ExecutionEngineSidebar = (props) => {
    function fetchMoreData() {
        props?.getMoreData(true);
    }
  return (
    <div className="mngScroll_outer">
      {props?.sidedata ? (
        props?.sidedata.length < 1 ? (
          <div className="text-center text-danger">
            {ERROR_MESSAGES.noRecordsFound}
          </div>
        ) : (
          <div id="scrollableDiv" className="app-build-list mngScroll_Content">
            <InfiniteScroll
              dataLength={props?.sidedata.length}
              next={fetchMoreData}
              hasMore={props?.loadFeature}
              loader={
                props?.sidedata.length < 1 ? (
                  <p className="fw-600 fs-14 text-center">
                    {ERROR_MESSAGES.noRecordsFound}
                  </p>
                ) : (
                  <h4>Loading...</h4>
                )
              }
              scrollableTarget="scrollableDiv"
            >
              {props?.sidedata &&
                props?.sidedata.map((appone, index) => (
                  <div className="scrollChild" key={index}>
                    <Link
                      to={"/app/execution-engines/" + appone.execution_engine_key}
                      className="no-link"
                    >
                      <div className="fw-600 fs-14 mb-1 click-able">
                        {" "}
                        {appone.execution_engine_key}{" "}
                      </div>
                      <div> {appone.name} </div>
                    </Link>
                  </div>
                ))}
            </InfiniteScroll>
          </div>
        )
      ) : (
        <div className="text-center text-danger">
          {ERROR_MESSAGES.noRecordsFound}
        </div>
      )}
    </div>
  )
}

export default ExecutionEngineSidebar