import React, { Component, useState } from "react";
import { Link, useLocation, navigate } from "@reach/router";
import "./style.scss";
import { productService } from "../../_services/products.service";
import { applicationService } from "../../_services/application.service";
import { toast } from "react-toastify";
import ConfirmationPopup from "../../widgets/Popups/Confirmation-Popup";
import { AuthService } from "../../_services/auth.service";
import Tooltip from "../../widgets/Tooltip/Tooltip";
import {
  CONTENT_MESSAGES,
  ERROR_MESSAGES,
} from "../../constants/app-constants";

const ProductApplication = (props) => {
  const accessModule = AuthService.getAllAccessList("products_apps");
  const [appList, setAppList] = useState(null);
  const [confirmData, setConfirmData] = useState({
    title: "",
    message: "",
    closeLabel: "",
    okLabel: "",
    isShow: false,
    selectedId: "",
  });

  const [error, setError] = useState({
    faildToLoad: false,
  });

  React.useEffect(() => {
    props?.prod_key ? getApplication() : "";
  }, [props?.prod_key]);

  function getApplication() {
    props?.isLoading(true);
    applicationService.getAllApplication(props?.prod_key).then((apps) => {
      if (apps.status == 200 && apps?.data) {
        const options = apps?.data.map((d) => ({
          app_key: d.app_key.trim(),
          name: d.name.trim(),
          enabled: d.enabled,
          framework_auth_key: d.framework_auth_key,
          created_at: d.created_at,
        }));
        setAppList(options);
        props?.isLoading(false);
        setError({
          ...error,
          faildToLoad: false,
        });
      } else {
        props?.isLoading(false);
        // if (apps?.status === 422) {
        //     toast.error(apps?.data?.detail[0].msg);
        // } else {
        //     toast.error(apps?.data?.detail);
        // }
        setError({
          ...error,
          faildToLoad: true,
        });
      }
    });
  }

  function handleRemoveApplication(id) {
    setConfirmData({
      ...confirmData,
      title: "Alert",
      message: ERROR_MESSAGES.reConformMessage,
      closeLabel: "Cancel",
      okLabel: "Confirm",
      isShow: true,
      selectedId: id,
    });
  }

  function deleteConfirm(id) {
    closeConfirm();
    props?.isLoading(true);
    applicationService.deleteApplication(id).then((resp) => {
      if (resp.status == 200 && resp?.data) {
        getApplication();
      } else {
        if (resp?.status === 422) {
          toast.error(resp?.data?.detail[0].msg);
        } else {
          toast.error(
            resp?.data?.detail ? resp?.data?.detail : resp?.data?.error
          );
        }
        props?.isLoading(false);
      }
    });
  }

  function closeConfirm() {
    setConfirmData({
      ...confirmData,
      isShow: false,
      selectedId: "",
    });
  }

  return (
    <>
      {confirmData.isShow ? (
        <ConfirmationPopup
          data={confirmData}
          confirmCloseEvent={closeConfirm}
          confirmOkEvent={() => deleteConfirm(confirmData?.selectedId)}
        />
      ) : (
        ""
      )}

      <div className="col-12 border-bottom">
        <div className="d-flex align-items-start justify-content-between">
          <div className="d-flex flex-column">
            <h4 className="mb-1 fw-500 fs-14">Apps</h4>
            <p className="fs-12 mb-2">
              {CONTENT_MESSAGES.productDetailAppTableDesc}
            </p>
          </div>

          <i aria-hidden="true" data-tip data-for={!accessModule.add&&"addTip"}>
            <div className="d-flex align-items-center justify-content-between">
              <button
                disabled={!accessModule.add}
                onClick={() =>
                  navigate(
                    `/app/products/product-apps/new?product=${props?.prod_key}`
                  )
                }
                className="btn btn-sm btn-primary"
              >
                Add Application
              </button>
              {/* {!accessModule.add && <i className="fa fa-info-circle ms-2" aria-hidden="true" data-tip data-for="addTip"></i>} */}
            </div>
          </i>
          <Tooltip
            id="addTip"
            place="left"
            effect="solid"
            msgType="operation"
          />
        </div>
      </div>
      <div className="table-responsive mt-2">
        <table className="table data-table data-table-vertically-center  ">
          <thead className="table-primary">
            <tr>
              <th>Key (App Key)</th>
              <th>Name</th>
              <th className="text-center actions">
                {/* {!accessModule.add && <i className="fa fa-info-circle" aria-hidden="true" data-tip data-for="deleteTip"></i>} */}
                {/* <Tooltip id="deleteTip" place="top" effect="solid" msgType="operation" /> */}
              </th>
            </tr>
          </thead>
          <tbody>
            {appList &&
              appList.map((app, idx) => (
                <tr
                  id={"tr_" + idx}
                  key={idx}
                  className={`${app.enabled ? "" : "row-disabled"}`}
                >
                  <td>
                    <Link
                      to={`/app/products/product-apps/${app?.app_key}${
                        "?product=" + props?.prod_key
                      }`}
                      className="no-link click-able"
                    >
                      {app?.app_key}
                    </Link>
                  </td>
                  <td>{app?.name}</td>
                  <td
                    className="text-center actions"
                    aria-hidden="true"
                    data-tip
                    data-for={!accessModule.delete && "deleteTip"}
                  >
                    <button
                      disabled={!accessModule.delete}
                      onClick={() => handleRemoveApplication(app?.app_key)}
                      className="btn link text-danger"
                    >
                      <i className="fa fa-trash" aria-hidden="true"></i>
                    </button>
                    <Tooltip
                      id="deleteTip"
                      place="top"
                      effect="solid"
                      msgType="operation"
                    />
                  </td>
                </tr>
              ))}
            {appList &&
              appList.length < 1 &&
              (error.faildToLoad ? (
                <tr>
                  <td colSpan={6} className="text-center text-danger">
                    {ERROR_MESSAGES.failedLoadRecordsError}
                  </td>
                </tr>
              ) : (
                <tr>
                  <td colSpan={6} className="text-center">
                    {ERROR_MESSAGES.noRecordsFound}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
export default ProductApplication;
