import { Link, useLocation, navigate } from "@reach/router";
import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { PAGINATION_FETCH_LIMIT } from "../../constants/app-constants";
import { coreEngineService } from "../../_services/coreengine.service";

import { toast } from "react-toastify";
import Loader from "../../widgets/loader/Loader";
import ConfirmationPopup from "../../widgets/Popups/Confirmation-Popup";
import { AuthService } from "../../_services/auth.service";
import Tooltip from "../../widgets/Tooltip/Tooltip";

import {
  SUCCESS_MESSAGES,
  CONTENT_MESSAGES,
  ERROR_MESSAGES,
} from "../../constants/app-constants";
const CoreEngines = () => {
  const accessModule = AuthService.getAllAccessList("coreengine");

  const [coreEngineList, setCoreEngineList] = useState(null);
  const [loading, setLoading] = useState(false);

  const [confirmData, setConfirmData] = useState({
    title: "",
    message: "",
    closeLabel: "",
    okLabel: "",
    isShow: false,
    selectedId: "",
  });

  const [selectedDropdown, setSelectedDropdown] = useState({
    showFilter: false,
    limit: PAGINATION_FETCH_LIMIT,
    offset: 0,
    pageNumber: 0,
  });

  const [error, setError] = useState({
    faildToLoad: false,
    timeExceedError: false,
  });

  React.useEffect(() => {
    setCoreEngineList(null);
    getAllCoreEngine();
  }, []);

  function getAllCoreEngine() {
    setLoading(true);
    coreEngineService
      .getAllCoreEngine(selectedDropdown.limit, selectedDropdown.offset)
      .then((resp) => {
        if (resp.status == 200 && resp?.data) {
          setCoreEngineList(resp.data);
          setLoading(false);
          setError({
            ...error,
            faildToLoad: false,
          });
        } else if (resp?.status == 408) {
          setError({
            ...error,
            timeExceedError: true,
          });
          setLoading(false);
        } else {
          setLoading(false);
          // if (resp?.status === 422) {
          //     toast.error(resp?.data?.detail[0].msg);
          // } else {
          //     toast.error(resp?.data?.detail ? resp?.data?.detail : resp?.data?.error);
          // }
          setError({
            ...error,
            faildToLoad: true,
          });
        }
      });
  }

  React.useEffect(() => {
    getAllCoreEngine();
  }, [selectedDropdown.offset]);

  function resetPage(param) {
    if (param === "next") {
      let pgNo = selectedDropdown.pageNumber + 1;
      let offset = pgNo * selectedDropdown.limit;
      setSelectedDropdown({
        ...selectedDropdown,
        offset: offset,
        pageNumber: pgNo,
      });
    } else {
      if (selectedDropdown.pageNumber > 0) {
        let pgNo = selectedDropdown.pageNumber - 1;
        let offset = pgNo * selectedDropdown.limit;
        setSelectedDropdown({
          ...selectedDropdown,
          offset: offset,
          pageNumber: pgNo,
        });
      }
    }
  }

  function handleRemove(id) {
    setConfirmData({
      ...confirmData,
      title: "Alert",
      message: ERROR_MESSAGES?.reConformMessage,
      closeLabel: "Cancel",
      okLabel: "Confirm",
      isShow: true,
      selectedId: id,
    });
  }

  function deleteConfirm(id) {
    closeConfirm();
    setLoading(true);
    coreEngineService.deleteCoreEngine(id).then((resp) => {
      //
      if (resp.status == 200 && resp?.data) {
        toast.success(SUCCESS_MESSAGES.delete);
        // setLoading(false)
        getAllCoreEngine();
      } else {
        if (resp?.status == 408) {
          toast.error(ERROR_MESSAGES.timeExceedError);
        }
        if (resp?.status === 422) {
          toast.error(resp?.data?.detail[0].msg);
        } else {
          toast.error(
            resp?.data?.detail ? resp?.data?.detail : resp?.data?.error
          );
        }
        setLoading(false);
      }
    });
  }

  function closeConfirm() {
    setConfirmData({
      ...confirmData,
      isShow: false,
      selectedId: "",
    });
  }

  return (
    <div className="containWrap-inner">
      {loading ? <Loader /> : ""}

      {confirmData.isShow ? (
        <ConfirmationPopup
          data={confirmData}
          confirmCloseEvent={closeConfirm}
          confirmOkEvent={() => deleteConfirm(confirmData?.selectedId)}
        />
      ) : (
        ""
      )}

      <Card className="bg-white custom-card">
        <Card.Body>
          <div className="row">
            <div className="col-9">
              <Card.Title>
                <h4 className="m-0">Core Engines</h4>
              </Card.Title>
              <Card.Text>
                {CONTENT_MESSAGES.coreEnginListingTitalDesc}
              </Card.Text>
            </div>

            <div className="col-3">
              <div className="d-flex align-items-center justify-content-end">
                <button
                  disabled={!accessModule.add}
                  onClick={() => navigate(`/app/core-engines/new`)}
                  className="btn btn-sm btn-primary"
                >
                  Add Core Engine
                </button>

                {!accessModule.add && (
                  <i
                    className="fa fa-info-circle ms-2"
                    aria-hidden="true"
                    data-tip
                    data-for="addTip"
                  ></i>
                )}
                <Tooltip
                  id="addTip"
                  place="left"
                  effect="solid"
                  msgType="operation"
                />
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>

      <Card className="bg-white custom-card mt-3 mb-3 custom-card-full">
        <Card.Body>
          <div className="table-responsive table-custom">
            <table className="table data-table data-table-vertically-center  ">
              {/*  table-hover  data-table */}
              <thead>
                <tr className="table-primary">
                  <th>Key (Core Engine Key)</th>
                  <th>Test type</th>
                  <th>Execution</th>
                  <th className="text-center">
                    {!accessModule.delete && (
                      <i
                        className="fa fa-info-circle"
                        aria-hidden="true"
                        data-tip
                        data-for="deleteTip"
                      ></i>
                    )}
                    <Tooltip
                      id="deleteTip"
                      place="left"
                      effect="solid"
                      msgType="operation"
                    />
                  </th>
                </tr>
              </thead>

              <tbody>
                {coreEngineList && coreEngineList.length > 0 ? (
                  coreEngineList.map((one, index) => (
                    <tr key={index}>
                      <td>
                        <Link
                          to={`/app/core-engines/${one?.engine_key}`}
                          className="no-link click-able"
                        >
                          {one?.engine_key}
                        </Link>
                      </td>
                      <td> {one?.test_type} </td>
                      <td> {one?.execution} </td>

                      <td className="text-center actions">
                        <button
                          disabled={!accessModule.delete}
                          onClick={() => handleRemove(one?.engine_key)}
                          className=" btn link text-danger"
                        >
                          <i className="fa fa-trash" aria-hidden="true"></i>
                        </button>
                      </td>
                    </tr>
                  ))
                ) : error.timeExceedError ? (
                  <tr>
                    <td colSpan={10} className="text-center text-danger ">
                      {ERROR_MESSAGES.timeExceedError}
                    </td>
                  </tr>
                ) : error.faildToLoad ? (
                  <tr>
                    <td colSpan={10} className="text-center text-danger ">
                      {ERROR_MESSAGES.failedLoadRecordsError}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td colSpan={10} className="text-center">
                      {ERROR_MESSAGES.noRecordsFound}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {coreEngineList && (
              <div className="d-flex justify-content-end pt-3 pb-1">
                {selectedDropdown.offset < 1 ? (
                  <button className="btn btn-primary btn-sm" disabled>
                    <i className="fa fa-chevron-left" aria-hidden="true"></i>
                  </button>
                ) : (
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={() => resetPage("prev")}
                  >
                    <i className="fa fa-chevron-left" aria-hidden="true"></i>
                  </button>
                )}

                {coreEngineList && coreEngineList.length > 0 ? (
                  <button
                    className="btn btn-primary btn-sm ms-2"
                    onClick={() => resetPage("next")}
                  >
                    <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  </button>
                ) : (
                  <button className="btn btn-primary btn-sm ms-2" disabled>
                    <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  </button>
                )}
              </div>
            )}
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default CoreEngines;
