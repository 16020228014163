import { Link, useLocation, navigate } from "@reach/router";
import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { featureService } from "../../_services/feature.service";
import Loader from "../../widgets/loader/Loader";
import { toast } from "react-toastify";
import ConfirmationPopup from "../../widgets/Popups/Confirmation-Popup";
import { FETCH_LIMIT } from "../../constants/app-constants";
import FeaturesSidebar from "./FeaturesSidebar";
import FeaturesForm from "./FeaturesForm";
import Inputcomp from "../../widgets/Form/input/Input";
import { AuthService } from "../../_services/auth.service";
import Tooltip from "../../widgets/Tooltip/Tooltip";
import AuditLog from "../../widgets/AuditLog/AuditLog";
import {
  SUCCESS_MESSAGES,
  ERROR_MESSAGES,
} from "../../constants/app-constants";

const featureDetail = (props) => {
  const search = useLocation().search;
  const location = useLocation();
  const q_FromUrl = new URLSearchParams(search).get("q");
  const accessModule = AuthService.getAllAccessList("feature");

  const [loadMoreFeature, setMoreFeature] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [featurelist, setFeaturesList] = useState(null);
  const [featureDetail, setFeatureDetail] = useState(null);

  const [confirmData, setConfirmData] = useState({
    title: "",
    message: "",
    closeLabel: "",
    okLabel: "",
    isShow: false,
    selectedId: "",
  });

  const [selectedValue, setSelectedValue] = useState({
    q: q_FromUrl ? q_FromUrl : "",
    showFilter: false,
    limit: FETCH_LIMIT,
    offset: 0,
    pageNumber: 0,
  });

  const [error, setError] = useState({
    errorStatus: false,
    errorData: "",
    noAccessModule: false,
    noAccessModuleMessage: "",
  });

  const [errorSide, setErrorSide] = useState({
    isError: false,
    errMsg: "",
  });

  const [isLoadingSidebar, setIsLoadingSidebar] = useState(false);

  React.useEffect(() => {
    // q_FromUrl && setSelectedValue({...selectedValue, q:q_FromUrl});
    getAllFeatureList();
  }, []);

  React.useEffect(() => {
    getFeatureDetail();
    editFeature(false);
  }, [location]);

  React.useEffect(() => {
    setSelectedValue({ ...selectedValue, q: q_FromUrl });
    getAllFeatureList();
  }, [location]);

  function getAllFeatureList() {
    // setLoading(true)
    setIsLoadingSidebar(true);
    featureService.getAllFeatures(selectedValue).then((options) => {
      if (options.status == 200 && options?.data) {
        setFeaturesList(options?.data);
        // setLoading(false)
        setIsLoadingSidebar(false);
      } else if (options?.status == 408) {
        setErrorSide({
          ...errorSide,
          isError: true,
          errMsg: ERROR_MESSAGES.timeExceedError,
        });
        //   setLoading(false);
        setIsLoadingSidebar(false);
      } else {
        setErrorSide({
          ...errorSide,
          isError: true,
          errMsg: ERROR_MESSAGES.failedLoadRecordsError,
        });
        // setLoading(false)
        setIsLoadingSidebar(false);
      }
    });
  }
  // function getAllFeatureList() {
  //     setLoading(true)
  //     featureService.getAllFeatures(selectedValue).then(options => {
  //         if (options.status == 200 && options?.data) {
  //             setFeaturesList(options?.data)
  //             setLoading(false)
  //         } else {
  //             if(options?.status == 408){
  //                 toast.error(ERROR_MESSAGES.timeExceedError);
  //             }
  //             setLoading(false)
  //             if (options?.status === 422) {
  //                 toast.error(options?.data?.detail[0].msg);
  //             } else {
  //                 toast.error(options?.data?.detail);
  //             }
  //         }
  //     });
  // }

  function getFeatureDetail() {
    setLoading(true);
    featureService.getFeaturesDetail(props?.id).then((resp) => {
      if (resp.status == 200 && resp?.data) {
        // if (resp?.data?.portals == "{}" ) resp.data.portals = ''
        setError({
          ...error,
          noAccessModule: false,
          noAccessModuleMessage: "",
        });
        setFeatureDetail(resp?.data);
        setLoading(false);
      } else if (resp?.status == 408) {
        setError({
          ...error,
          noAccessModule: true,
          noAccessModuleMessage: ERROR_MESSAGES.timeExceedError,
        });
        setLoading(false);
      } else {
        setLoading(false);
        if (resp?.status === 422) {
          // setError({...error, noAccessModule:false, noAccessModuleMessage:''})
          setError({
            ...error,
            noAccessModule: true,
            noAccessModuleMessage: resp?.data?.detail[0].msg
              ? resp?.data?.detail[0].msg
              : ERROR_MESSAGES?.detailsFailsMessage,
          });
          toast.error(resp?.data?.detail[0].msg);
        } else if (resp?.status === 404) {
          setError({
            ...error,
            noAccessModule: true,
            noAccessModuleMessage: resp?.data?.error
              ? resp?.data?.error
              : ERROR_MESSAGES.noAccessMsg,
          });
        } else {
          // setError({...error, noAccessModule:false, noAccessModuleMessage:''})
          // toast.error(resp?.data?.detail ? resp?.data?.detail : resp?.data?.error);
          setError({
            ...error,
            noAccessModule: true,
            noAccessModuleMessage: resp?.data?.error
              ? resp?.data?.error
              : ERROR_MESSAGES?.detailsFailsMessage,
          });
        }
      }
    });
  }

  function editFeature(event) {
    setIsEdit(event);
  }

  function editFeatureAfterEdit(event) {
    // // //
    setIsEdit(event);
    getFeatureDetail();
    getAllFeatureList();
  }

  function handelLoading(event) {
    setLoading(event);
  }

  function deleteFeature() {
    setConfirmData({
      ...confirmData,
      title: "Alert",
      message: ERROR_MESSAGES.reConformMessage,
      closeLabel: "Cancel",
      okLabel: "Confirm",
      isShow: true,
      selectedId: featureDetail.app_key,
    });
  }

  function closeConfirm() {
    setConfirmData({
      ...confirmData,
      isShow: false,
      selectedId: "",
    });
  }

  function deleteConfirm() {
    closeConfirm();
    setLoading(true);
    featureService.deleteFeatures(props?.id).then((appRes) => {
      if (appRes.status == 200 && appRes?.data) {
        setLoading(false);
        let q = q_FromUrl ? "?q=" + q_FromUrl : "";
        toast.success(SUCCESS_MESSAGES.delete);
        navigate(`/app/features/${q}`);
      } else {
        setLoading(false);
        if (appRes?.status === 422) {
          toast.error(appRes?.data?.detail[0].msg);
        } else {
          toast.error(appRes?.data?.detail);
        }
      }
    });
  }

  function toggleOpen() {
    setSelectedValue({
      ...selectedValue,
      showFilter: !selectedValue.showFilter,
    });
  }

  function handleChangeForm(event) {
    Object.keys(event).forEach(function (key) {
      setSelectedValue({ ...selectedValue, [key]: event[key] });
    });
  }

  function onSubmit() {
    let q = selectedValue.q ? `?q=${selectedValue.q}` : "";
    navigate(`/app/features/${props?.id}${q}`);
  }

  function fetchMoreData(e) {
    if (e) {
      let pgNo = selectedValue.pageNumber + 1;
      let offset = pgNo * selectedValue.limit;
      setSelectedValue({ ...selectedValue, pageNumber: pgNo });

      let appReq = {
        q: selectedValue.q,
        limit: selectedValue.limit,
        offset: offset,
      };
      featureService.getAllFeatures(appReq).then((applist) => {
        if (applist.status == 200 && applist?.data) {
          if (applist?.data.length > 0) {
            let newlist = featurelist.concat(applist?.data);
            setMoreFeature(true);
            setFeaturesList(newlist);
          } else {
            setMoreFeature(false);
          }
        }
      });
    }
  }

  function addFeature() {
    let q = q_FromUrl ? "?q=" + q_FromUrl : "";
    navigate(`/app/features/new${q}`);
  }
  return (
    <div className="containWrap-inner">
      {loading ? <Loader /> : ""}
      {confirmData.isShow ? (
        <ConfirmationPopup
          data={confirmData}
          confirmCloseEvent={closeConfirm}
          confirmOkEvent={deleteConfirm}
        />
      ) : (
        ""
      )}

      {!error.noAccessModule ? (
        <div className="col-12">
          <div className="row">
            <div className="col-4">
              <Card className="bg-white custom-card list-option h-100">
                <Card.Header className="shadow-none p-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <Card.Title>
                      <h4 className="m-0">Features</h4>
                    </Card.Title>
                    <div className="d-flex align-items-center">
                      <div className="text-end my-2 me-3">
                        <i
                          className="fa fa-filter"
                          aria-hidden="true"
                          onClick={toggleOpen}
                        ></i>
                      </div>
                      <div className="d-flex align-items-center">
                        <button
                          disabled={!accessModule.add}
                          onClick={addFeature}
                          className="btn btn-sm btn-primary"
                        >
                          Add Feature
                        </button>
                        {!accessModule.add && (
                          <i
                            className="fa fa-info-circle ms-2"
                            aria-hidden="true"
                            data-tip
                            data-for="addTip"
                          ></i>
                        )}
                        <Tooltip
                          id="addTip"
                          place="right"
                          effect="solid"
                          msgType="operation"
                        />
                      </div>
                    </div>
                  </div>
                </Card.Header>
                <Card.Body>
                  <div className="h-100 d-flex flex-column">
                    <div
                      className={
                        selectedValue.showFilter
                          ? "d-block mb-3 bg-d-gray p-2"
                          : "d-none"
                      }
                    >
                      <div className="">
                        <div className="mt-2">
                          <Inputcomp
                            type="text"
                            nameClass="form-control custom-form-lh2"
                            name="q"
                            handleChange={true}
                            isDisabled={false}
                            onChangeInput={handleChangeForm}
                            label=""
                            isRequired={false}
                            placeholder={"Search by feature name or value"}
                            value={selectedValue.q}
                            onKeyPress={onSubmit}
                          />
                        </div>

                        <div className="d-flex justify-content-end mt">
                          <input
                            className="btn btn-primary"
                            type="submit"
                            value="Search"
                            onClick={onSubmit}
                          />
                        </div>
                      </div>
                    </div>
                    {isLoadingSidebar ? (
                      <div className="text-center"> Please Wait ....</div>
                    ) : errorSide.isError ? (
                      <div className="text-center text-danger">
                        {" "}
                        {errorSide.errMsg}
                      </div>
                    ) : (
                      <FeaturesSidebar
                        sidedata={featurelist}
                        getMoreData={fetchMoreData}
                        loadFeature={loadMoreFeature}
                      />
                    )}
                  </div>
                </Card.Body>
              </Card>
            </div>
            <div className="col-8 ps-0 ">
              <div className="col-12">
                <div className="d-flex align-items-center justify-content-between">
                  <Card className="bg-white custom-card custom-card-full">
                    <Card.Header className="shadow-none p-3">
                      <div className="d-flex align-items-start justify-content-between ">
                        <Card.Title className="m-0">
                          <h4 className="">Edit Feature</h4>
                        </Card.Title>

                        <div className="d-flex align-items-center">
                          <button
                            disabled={!accessModule.delete}
                            onClick={deleteFeature}
                            className="text-danger fs-20 px-3 btn btn-link"
                          >
                            <i className="fa fa-trash" aria-hidden="true"></i>
                          </button>
                          {!accessModule.delete && (
                            <i
                              className="fa fa-info-circle me-3"
                              aria-hidden="true"
                              data-tip
                              data-for="deleteTip"
                            ></i>
                          )}
                          <Tooltip
                            id="deleteTip"
                            place="left"
                            effect="solid"
                            msgType="operation"
                          />
                          <Link className="no-link" to={`/app/features`}>
                            <i className="fa fa-times" aria-hidden="true"></i>
                          </Link>
                        </div>
                      </div>
                    </Card.Header>
                    <Card.Body>
                      {featureDetail && (
                        <FeaturesForm
                          isNew={isEdit}
                          isLoading={handelLoading}
                          handelEditWithoutChange={editFeature}
                          handelEdit={editFeatureAfterEdit}
                          isEditForm={true}
                          id={props?.id}
                          featueDetail={featureDetail}
                        />
                      )}

                      {!isEdit && (
                        <div className="d-flex justify-content-between align-items-center mt-3 mb-1">
                          <AuditLog logData={featureDetail} />
                          <div>
                            <button
                              disabled={!accessModule.update}
                              className="btn btn-primary"
                              onClick={() => editFeature(true)}
                            >
                              Edit
                            </button>
                            {!accessModule.update && (
                              <i
                                className="fa fa-info-circle ms-2"
                                aria-hidden="true"
                                data-tip
                                data-for="updateTip"
                              ></i>
                            )}
                            <Tooltip
                              id="updateTip"
                              place="left"
                              effect="solid"
                              msgType="operation"
                            />
                          </div>
                        </div>
                      )}
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="row err-msg-container">
          <div className="col-12">
            <Card className="bg-white custom-card list-option">
              <Card.Body>
                <div className="row err-msg">
                  <label className="text-center">
                    {error.noAccessModuleMessage
                      ? error.noAccessModuleMessage
                      : ERROR_MESSAGES.noAccessMsg}
                  </label>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      )}
    </div>
  );
};

export default featureDetail;
